import React, {Component} from "react";
import {Switch, Route, NavLink} from "react-router-dom";
import {
    SearchOutlined,
    AppstoreOutlined,
    EnvironmentOutlined,
    DashboardOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import {
    Layout,
    Menu,   
    Input,
    Button,
    Drawer,
    Checkbox,
    message
} from "antd";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {allFunctionMenu} from "../../actions";
import {renderFormatMsg} from "../common/function";
import "../../assets/css/leftmenu.css";

import {MenuList} from "../menu/menuleft";
const {Header, Sider, Content} = Layout;
const {Search} = Input;
const {SubMenu} = Menu;

class Leftmenu extends React.Component {
    constructor(props) {
        super(props);
    }
    
    state = {
        serValue: "",
        visible: false,
        addgroupenable: false,
        favoriteGroupData: [],
        allFavGroup: [],
        groupname: "",
        functionsListcopy: [{
            title: renderFormatMsg("menu.travel", "Travel"),
            icon: <DashboardOutlined/>,
            key: "sub5",
            submenu: [
                {
                    title: renderFormatMsg("menu.positions", "Positions"),
                    icon: <EnvironmentOutlined/>,
                    key: "sub4",
                    submenu: [
                        {
                            subTitle: renderFormatMsg(
                                "menu.positions.Currentpositions",
                                "Current positions"
                            ),
                            favorite: false,
                            key: "27",
                            path: "/functionpage"
                        },
                        {
                            subTitle: renderFormatMsg(
                                "menu.positions.Findthenearestvehicle",
                                "Find the nearest vehicle"
                            ),
                            favorite: false,
                            key: "28",
                            path: "/functionpage"
                        },
                        {
                            subTitle: renderFormatMsg(
                                "menu.positions.Create/editzones",
                                "Create / edit zones"
                            ),
                            favorite: false,
                            key: "29",
                            path: "/functionpage"
                        },
                        {
                            subTitle: renderFormatMsg(
                                "menu.positions.Listofzones",
                                "List of zones"
                            ),
                            favorite: false,
                            key: "30",
                            path: "/functionpage"
                        }
                    ]
                },
                {
                    subTitle: renderFormatMsg(
                        "menu.travel.Ongoingtravel",
                        "Ongoing travel"
                    ),
                    favorite: false,
                    key: "31",
                    path: "/functionpage"
                },
                {
                    subTitle: renderFormatMsg(
                        "menu.travel.Completedtrips",
                        "Completed trips"
                    ),
                    favorite: false,
                    key: "32",
                    path: "/functionpage",
                    subSubMenu: true
                },
                {
                    subTitle: renderFormatMsg("menu.travel.Travellog", "Travel log"),
                    favorite: false,
                    path: "/functionpage",
                    key: "33"
                },
                {
                    subTitle: renderFormatMsg(
                        "menu.travel.Congestiontax",
                        "Congestion tax"
                    ),
                    favorite: false,
                    path: "/functionpage",
                    key: "34"
                },
                {
                    subTitle: renderFormatMsg("menu.travel.Travelstop", "Travel stop"),
                    favorite: false,
                    path: "/functionpage",
                    key: "35"
                },
                {
                    subTitle: renderFormatMsg(
                        "menu.travel.Travelpurposerules",
                        "Travel purpose rules"
                    ),
                    favorite: false,
                    path: "/functionpage",
                    key: "36"
                },
                {
                    subTitle: renderFormatMsg(
                        "menu.travel.Autoexportdrivingrecords",
                        "Autoexport driving records"
                    ),
                    favorite: false,
                    path: "/autoexport",
                    key: "37"
                },
                {
                    subTitle: renderFormatMsg(
                        "menu.travel.Manuallogbook",
                        "Manual logbook"
                    ),
                    favorite: false,
                    path: "/functionpage",
                    key: "38"
                },
                {
                    subTitle: renderFormatMsg("menu.travel.Changeuser", "Change user"),
                    favorite: false,
                    path: "/functionpage",
                    key: "39"
                },
                {
                    subTitle: renderFormatMsg(
                        "menu.travel.Certificatetravel",
                        "Certificate travel"
                    ),
                    favorite: false,
                    path: "/functionpage",
                    key: "40"
                }
            ]
        },],
        functionsList: MenuList,
        favoriteData: [],
        leftSideMenu: []
    };

    componentDidMount() {
        this.props.dispatch(allFunctionMenu(this.state.functionsList));
    }

    onSearch = value => {
        this.setState({serValue: value});
    };
    onClose = () => {
        this.setState({visible: false, addgroupenable: false, groupname: ""});
    };
    addFav = () => {
        this.setState({visible: true});
    };

    addGroup = () => {
        this.setState({addgroupenable: true});
    };

    submitgroupdata = () => {
        if (this.state.groupname === "") {
            message.error("Please enter group name");
        } else {
            let newGroup = {
                grpName: this.state.groupname,
                groupArr: this.state.favoriteGroupData
            };
            this.setState(
                {
                    allFavGroup: [...this.state.allFavGroup, newGroup],
                    addgroupenable: false,
                    favoriteGroupData: []
                },
                () => console.log("this.state.allFavGroup", this.state.allFavGroup)
            );
        }
    };

    onChangecheck = (checked, {subTitle, title, icon}) => {
        // console.log("e", e);
        // console.log("subindex => ", subindex);
        // console.log("subTitle => ", subtitle);
        // console.log("funct => ", funct);
        // index
        // subindex
        // subTitle
        // title
        // icon
        var _leftSideMenu = [...this.state.leftSideMenu];
        var _functionsList = [...this.state.functionsList];
        var ifItIsGrouppedItems = false;
        if (this.state.addgroupenable && this.state.groupname !== "") {
            ifItIsGrouppedItems = true;
        }

        if (checked) {
            // add
            if (ifItIsGrouppedItems) {
                console.log("ifpart ifItIsGrouppedItems => ", ifItIsGrouppedItems);

                let index = _leftSideMenu.findIndex(
                    menu => menu.menuName === this.state.groupname
                );
                console.log("ifpart  index => ", index);

                if (index >= 0) {
                    _leftSideMenu[index].subItems.push({
                        subTitle: subTitle
                    });
                } else {
                    _leftSideMenu.push({
                        isGroup: true,
                        menuName: this.state.groupname,
                        subItems: [{subTitle: subTitle, categoryName: title}]
                    });
                }
            } else {
                _leftSideMenu.push({
                    isGroup: false,
                    menuName: title,
                    subItems: [{subTitle: subTitle, categoryName: title}]
                });
            }

            _functionsList.map((fList, index) => {
                fList.submenu.map((subMenu, subIndex) => {
                    if (subMenu.subTitle === subTitle) {
                        subMenu.favorite = true;
                    }
                });
            });
        } else {
            // remove
            _leftSideMenu.map((menu, menuIndex) => {
                menu.subItems.map((o, subItemsIndex) => {
                    if (o.subTitle === subTitle) {
                        menu.subItems.splice(subItemsIndex, 1);
                    }
                    if (menu.subItems.length === 0) {
                        _leftSideMenu.splice(menuIndex, 1);
                    }
                });
            });

            _functionsList.map((fList, index) => {
                fList.submenu.map((subMenu, subIndex) => {
                    if (subMenu.subTitle === subTitle) {
                        subMenu.favorite = false;
                    }
                });
            });
        }
        
        console.log("_leftSideMenu => ", _leftSideMenu);

        this.setState({
            leftSideMenu: _leftSideMenu,
            functionsList: _functionsList
        });
       
    };

    onstarclick = (e, index, subindex, subtitle, funct, checked) => {
        let newsubmenu;
        let updateEleIndex;
        let newfunctionlist = [...this.state.functionsList];
        let funcEleIndex = this.state.functionsList.findIndex(
            x => x.title === funct
        );
        this.state.functionsList.map(item => {
            // updateEleIndex = item.submenu.findIndex(x => x.subTitle === subtitle);
            // console.log("updateEleIndex 1", updateEleIndex);
            // console.log("item.submenu", item.title);
            // console.log("funct", funct);
            // console.log("item.title === funct", item.title === funct);
            if (item.title === funct) {
                newsubmenu = item.submenu;
                // console.log("newsubmenu in if condition", newsubmenu);
            }
        });
        if (checked) {
            // console.log("in if condition");
            const filteredArray = this.state.favoriteData.filter(
                item => item.subtitle !== subtitle
            );
            let groupmenus = [];
            // this.state.favoriteGroupData.map((item, index) =>

            //   // (item.groupArr.map(menus => (groupmenus.push(menus))))
            // );
            const groupfilterArr =
                // console.log("grp filtergroupmenu", filtergroupmenu);
                console.log("grp groupmenus", groupmenus);
            // const filteredmenus = groupmenus.filter(
            //   item => item.subtitle !== subtitle
            // );
            // console.log("grp filteredmenus", filteredmenus);
            console.log("filteredArray", filteredArray);
            console.log("newsubmenu", newsubmenu);
            newsubmenu[subindex].favorite = false;
            newfunctionlist[funcEleIndex].submenu = newsubmenu;
            console.log("newfunctionlist unchecked", newfunctionlist);
            this.setState(
                {
                    functionsList: newfunctionlist,
                    favoriteData: filteredArray
                },
                () => console.log("favoriteData uncheck", this.state.favoriteData)
            );
        } else {
            let newChecked = {
                index: index,
                title: funct,
                // submenu: [{ subtitle: subtitle, icon: icon }]
                subtitle: subtitle
            };

            newsubmenu[subindex].favorite = true;

            newfunctionlist[funcEleIndex].submenu = newsubmenu;
            console.log("newfunctionlist checked", newfunctionlist);

            this.setState(
                {
                    functionsList: newfunctionlist,
                    favoriteData: [...this.state.favoriteData, newChecked]
                },
                () => {
                    console.log("favoriteData", this.state.favoriteData);
                }
            );
        }
    };

    render() {
        const {leftSideMenu, functionsList, favoriteData} = this.state;
        return (
            <>
                <Menu theme="dark" mode="inline">
                    {/* <Menu.Item key="1" icon={<SearchOutlined/>}>
                        <Search
                            placeholder="input search text"
                            allowClear
                            onSearch={this.onSearch}
                            style={{width: 200}}
                            className="searchInput"
                        />
                    </Menu.Item> */}

                    <Menu.Item key="2" icon={<AppstoreOutlined/>}>
                        <NavLink to="/">
                            {renderFormatMsg("menu.Dashboard", "Dashboard")}
                        </NavLink>
                    </Menu.Item>

                </Menu>
                    {/* stared meny */}
                    
                    {/* <Menu className="favrites-menu navbar2" theme="dark" mode="inline">
                        <Menu.Item
                            key="3"
                            icon={<PlusCircleOutlined/>}
                            onClick={this.addFav}
                        ></Menu.Item> */}

                        {/* {leftSideMenu.length &&
                        leftSideMenu.map((menu, index) =>
                            menu.isGroup ? (
                                <SubMenu key={`group-${index}`} title={menu.menuName}>
                                    {menu.subItems.length &&
                                    menu.subItems.map((subMenu, subMenuIndex) => (
                                        <Menu.Item key={`favgrp-${subMenuIndex}`}>
                                            {subMenu.subTitle}
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                            ) : (
                                <Menu.Item key={`fav-${index}`}>
                                    {menu.subItems[0].subTitle}
                                </Menu.Item>
                            )
                        )} */}
                    {/* </Menu> */}
                            {/*stared meny end*/}
                    {/* <Menu
                        theme="dark"
                        mode="inline"
                        onSelect={this.seletedMenu}
                    >
                        {functionsList.map((menu, index) => (
                            <SubMenu
                                key={menu.key}
                                title={menu.title}
                                icon={menu.icon}
                                className="ant-menu-submenu-Li"
                                onTitleClick={this.onTitleClick}
                            >
                                {menu.submenu.map((submenu, subIndex) => (
                                    <>
                                        {submenu.subSubMenu === undefined ?
                                            <Menu.Item
                                                key={submenu.key}
                                                className="submenuclass"
                                            >
                                                <NavLink
                                                to={submenu.urlpath}
                                                >
                                                    {submenu.subTitle}
                                                </NavLink> */}

                                                {/*submenu.favorite === true ? (
                                                    <StarFilled
                                                        className="Favstar"
                                                        onClick={() =>
                                                            this.onChangecheck(false, {
                                                                subTitle: submenu.subTitle,
                                                                title: menu.title,
                                                                icon: menu.icon
                                                            })
                                                        }
                                                    />
                                                ) : (
                                                    <StarOutlined
                                                        className="Favstar"
                                                        onClick={() =>
                                                            this.onChangecheck(true, {
                                                                subTitle: submenu.subTitle,
                                                                title: menu.title,
                                                                icon: menu.icon
                                                            })
                                                        }
                                                        
                                                    />
                                                    )*/}
                                            {/* </Menu.Item>
                                            :
                                            <SubMenu key="360" title="Completed trip" className="subsubmenu">
                                                <Menu.Item key="108">
                                                    <NavLink to="/travels/completedtrips/detailview">
                                                        Detail View
                                                    </NavLink>
                                                </Menu.Item>
                                                <Menu.Item key="109">
                                                    <NavLink to="/travels/completedtrips/standardview">
                                                        Standard View(v1)
                                                    </NavLink>
                                                </Menu.Item> */}
                                                {/* <Menu.Item key="110">
                                                    <NavLink to="/travels/completedtrips/standardview_v2">
                                                        Standard View(v2)
                                                    </NavLink>
                                                </Menu.Item> */}
                                            {/* </SubMenu>
                                        }
                                    </>
                                ))}
                            </SubMenu>
                        ))}
                    </Menu>  */}
                <Drawer
                    className="favriot-drawer"
                    title="Choose Favorites"
                    placement="right"
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    width={400}
                >
                    <Button
                        type="primary"
                        className="CreateGroup"
                        onClick={this.addGroup}
                    >
                        Create group
                    </Button>
                    <>
                        {/* <Form> */}
                        {this.state.addgroupenable === true ? (
                            <div className="creactgroup-div">
                                <Input
                                    placeholder="Enter groupname"
                                    name="groupname"
                                    onChange={e =>
                                        this.setState({[e.target.name]: e.target.value})
                                    }
                                />
                                <Button type="primary" onClick={this.submitgroupdata}>
                                    add
                                </Button>
                            </div>
                        ) : null}
                        {/* </Form> */}
                        {this.state.functionsList.map((funct, index) => (
                            <>
                                <h3>{funct.title}</h3>
                                {funct.submenu.map((subfunc, subindex) => (
                                    <div className="fun-list">
                                        <p>{subfunc.subTitle}</p>
                                        <Checkbox
                                            checked={subfunc.favorite === true}
                                            onChange={e =>
                                                this.onChangecheck(e.target.checked, {
                                                    subTitle: subfunc.subTitle,
                                                    title: funct.title,
                                                    icon: funct.icon
                                                })
                                            }
                                        ></Checkbox>
                                    </div>
                                ))}
                            </>
                        ))}
                    </>
                </Drawer>
            </>
        );
    }
}

export default withRouter(connect()(Leftmenu));
