import React, { FC, useEffect, useState, useRef } from "react";
import { Button, Drawer } from "antd";
import { useDispatch, useSelector, connect } from "react-redux";
import { FormInstance } from "antd/es/form/hooks/useForm";
import { Value } from "devextreme-react/range-selector";

interface IDrawerProps {
    pageSetOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    pageOpenDrawer: boolean;
    title?: string;
    mode?: "create" | "update";
    form?: FormInstance;
    children?: JSX.Element;
    deleteObjectCallback?: () => void;
}

const SideDrawer: FC<IDrawerProps> = (props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.pageOpenDrawer) {
            showDrawer();
        }
    }, [props.pageOpenDrawer]);

    const showDrawer = (): void => {
        setOpen(!open);
        props.pageSetOpenDrawer(!open);
    };

    const hideDrawer = (): void => {
        showDrawer();
    };

    return (
        <>
            <Drawer
                size="large"
                title={props.title}
                placement="right"
                onClose={showDrawer}
                open={open}
                footer={
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={() => {
                                //TODO submit form
                                props?.form?.validateFields().then((values) => {
                                    props?.form?.submit();
                                });
                                hideDrawer();
                            }}
                            type="primary"
                            key="submit"
                            htmlType="submit"
                        >
                            {props.mode == "create" ? "Create" : "Update"}
                        </Button>
                        <Button
                            onClick={() => {
                                props.deleteObjectCallback &&
                                    props.deleteObjectCallback();
                                hideDrawer();
                            }}
                            style={{ marginRight: 8 }}
                            type="primary"
                            danger
                        >
                            Remove
                        </Button>
                    </div>
                }
            >
                <>{props.children}</>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: {
    widgets: {
        selectedMenu: any;
        allFuncMenu: any;
        submenukey: any;
        submenutitle: any;
        selectedSubmenu: any;
        selectedFuncTitle: any;
    };
    login: { loginUser: any };
}) => {
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        loggedInUser: state.login.loginUser,
    };
};

export default connect(mapStateToProps)(SideDrawer);
