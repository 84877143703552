import { setLoader } from "../auth";
import instance from "../../instance";
import { callFail, callSuccess } from "../../utils/callback";
import getStorageValue from "../../utils/getStorageValue";
import * as Apis from "../../utils/api";
import { callback } from "chart.js/helpers";
import { IOrganisationCascaderOption } from "../../global-types";

export const getOrganisations = () => (dispatch: any) => {
    dispatch(setLoader(true));

    Apis.GetOrgs()
        .then((res) => {
            if (res.Status === "NOK") {
                console.log("error", res);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res,
                });
            } else {
                dispatch({
                    type: "GET_ORGANISATIONS",
                    payload: res,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getOrganisations actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const getOrganisationTree = () => (dispatch: any) => {
    dispatch(setLoader(true));
    Apis.GetOrganisationTree()
        .then((res) => {
            console.log("getOrganisationTree res ", res);
            if (res.Status === "OK") {
                dispatch({
                    type: "GET_ORGANISATION_TREE",
                    payload: res.Results,
                });
            } else {
                console.log("error", res);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getOrganisations actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const getOrganisation = (organisationId: string) => (dispatch: any) => {
    dispatch(setLoader(true));
    Apis.getOrganisation(organisationId)
        .then((res: any) => {
            console.log("GetOrganisation res ", res);
            if (res.Status === "OK") {
                dispatch({
                    type: "GET_ORGANISATION",
                    payload: res.Results,
                });
            } else {
                console.log("error", res);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error: any) => {
            console.log("error in GetOrganisation actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const updateOrganisation =
    (formData: any, callbackSuccess?: any, callbackFail?: any) =>
    (dispatch: any) => {
        dispatch(setLoader(true));
        Apis.updateOrganisation(formData)
            .then((res: any) => {
                console.log("updateOrganisation res ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "UPDATE_ORGANISATION",
                            payload: res.Results,
                        },
                        callbackSuccess(res)
                    );
                } else {
                    console.log("error", res);
                    dispatch(
                        {
                            type: "SET_ERRORS",
                            payload: res,
                        },
                        callbackFail(res)
                    );
                }
                dispatch(setLoader(false));
            })
            .catch((error: any) => {
                console.log(
                    "error in updateOrganisation actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

export const deleteOrganisation =
    (
        organisationId: string,
        organisationParentsIds: number[],
        callbackSuccess?: any,
        callbackFail?: any
    ) =>
    (dispatch: any) => {
        dispatch(setLoader(true));
        Apis.deleteOrganisation(organisationId, organisationParentsIds)
            .then((res: any) => {
                console.log("deleteOrganisation res ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "DELETE_ORGANISATION",
                            payload: res,
                        },
                        callbackSuccess(res)
                    );
                } else {
                    console.log("error", res);
                    dispatch(
                        {
                            type: "SET_ERRORS",
                            payload: res,
                        },
                        callbackFail(res)
                    );
                }
                dispatch(setLoader(false));
            })
            .catch((error: any) => {
                console.log(
                    "error in deleteOrganisation actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

export const createOrganisation =
    (data: any, callbackSuccess?: any, callbackFail?: any) =>
    (dispatch: any) => {
        dispatch(setLoader(true));
        Apis.createOrganisation(data)
            .then((res: any) => {
                console.log("createOrganisation res ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "CREATE_ORGANISATION",
                            payload: res.Results,
                        },
                        
                    );
                    callbackSuccess(data)(res.Results)
                } else {
                    console.log("error", res);
                    dispatch(
                        {
                            type: "SET_ERRORS",
                            payload: res,
                        },
                        callbackFail(res)
                    );
                }
                dispatch(setLoader(false));
            })
            .catch((error: any) => {
                console.log(
                    "error in createOrganisation actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

export const findOrganisationIdPath = (
    orgId: number,
    orgs: IOrganisationCascaderOption[]
): number[] => {
    let foundOrgPathIds: number[] = [];
    const findCurrentOrganisation = (
        orgId: number,
        orgs: IOrganisationCascaderOption[]
    ) => {
        let found = orgs.find((org) => {
            if (org.value === orgId) {
                return true;
            } else if (org.children)
                if (org.children.length !== 0)
                    return findCurrentOrganisation(orgId, org.children);
        });
        if (found) {
            foundOrgPathIds.push(Number(found.value));
            return foundOrgPathIds;
        }
    };
    const orgsPath = findCurrentOrganisation(orgId, orgs);
    return orgsPath ? orgsPath : [];
};
