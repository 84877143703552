import React, {Component, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {List, Typography, Form, Input, Button, Checkbox, Row, Col, Modal} from "antd";
import logo from "../../assets/Images/jiricom_logo.png";
// import BgImage from "../../assets/Images/backgroundimage.png";
import logoJiricom from "../../assets/Images/logoJiricom.png"

import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {adminLogin} from "../../actions/login/login";
import "./login.css";
import Alert from "../../utils/alert";
import {useHistory, useLocation} from "react-router-dom";
import getStorageValue from "../../utils/getStorageValue";
import ForgotPasswordFormContent from "../../components/forgotpassword/ForgotPasswordFormContent";
import LoginPasswordFormContent from "../../components/forgotpassword/LoginPasswordFormContent";
import SetNetPasswordForm from "../../components/forgotpassword/SetNewPasswordFormContent";

const Login = (props) => {
    const [state, setState] = useState({
        isLoginView: true,
        isForgotPassView: false,
        isResetPassView: false,
        isSettingNewPasswordView: false,
    });

    const setViews = (states) => {
        setState({isForgotPassView: states.isForgotPassView, isLoginView: states.isLoginView})
    }

    return (
        <>
            <Row>
                <Col sm={18} md={18} lg={18} xs={0}>
                    {/* <img src={BgImage} style={{width: "100%", height: "100vh", objectFit: "cover"}}/> */}
                    <div style={{width: "100%", height: "100vh", objectFit: "cover", backgroundColor: "#001529"}}>
                        <img src={logoJiricom} style={{marginTop: 24, marginLeft: 62}}/>
                    </div>
                    {/* <div style={{width: "100%", height: "100vh", objectFit: "cover", backgroundColor: 'grey'}}></div> */}
                </Col>
                <Col sm={6} md={6} lg={6} xs={24}
                    style={{background: "#FFF"}}
                >
                    {state.isLoginView && <LoginPasswordFormContent setViews={setViews} />}
                    {state.isForgotPassView && <ForgotPasswordFormContent />}
                    {/* state.isSettingNewPasswordView && <SetNetPasswordForm /> */}
                </Col>
            </Row>
        </>
    );
}


export default Login;
