import React from "react";
import { connect } from "react-redux";
import { List, Typography } from "antd";
import "../../assets/css/functionPage.css";

class FuctionPage extends React.Component {
  state = {
    selectedWidgetComp: [],
    selectedFunctions: [],
    selectedFuncTitle: ""
  };

  render() {
    return (
      <div className="functionPagediv">
        {/* <ul className="functionList">
          {this.props.selectedFuncTitle}
          {this.props.selectedSubmenu &&
            this.props.selectedSubmenu.map(menus => (
              <li
                className={
                  menus.subTitle === this.props.submenutitle
                    ? "activesubmenu"
                    : ""
                }
              >
                {menus.subTitle}
              </li>
            ))}
        </ul> */}
        <p className="functioncontent">{this.props.submenutitle} Page</p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle
    // allWidgetsdata: state.widgets.allWidgets
  };
};

export default connect(mapStateToProps)(FuctionPage);
