import { IDriver } from "../../global-types";
import * as antdFormats from "../../utils/antdFormats";

const initialState = {
    drivers: [],
    loading: true,
    updatedDriver: {},
    createdDriver: {},
};

const getUniqueDrivers = (dataArr: any) => {
    const seen = new Set();
    const uniqueDrivers = dataArr.filter((driver: any) => {
        const duplicate = seen.has(driver.UserId);
        seen.add(driver.UserId);
        return !duplicate;
    });
    return uniqueDrivers;
};

const driversReducer = (
    state = initialState,
    { type, payload }: any,
    callback: any
) => {
    switch (type) {
        case "GET_DRIVERS":
            let driversArr = [];
            // console.log("GET_DRIVERS REDUCER ", payload);
            if (Array.isArray(payload)) {
                // console.log("getDrivers API Res is ARRAY ", payload);
                // setDrivers(getUniqueDrivers(payload));
                driversArr = payload;
            } else {
                // console.log("getDrivers API Res is ordinary OBJECT", payload);
                driversArr = Object.keys(payload).map((key) => payload[key]);
                //setDrivers(getUniqueDrivers(driversArr));
            }
            //FYI Removing last element which is an "OK" from backend.
            driversArr = driversArr.slice(0, -1);
            // console.log("getDrivers converted to array:  ", driversArr);

            driversArr.forEach((driver: IDriver) => {
                if (!driver.LicensePlate) driver.LicensePlate = "";
            });

            return {
                ...state,
                drivers: driversArr,
                callback,
            };

        case "GET_DRIVERS_TRAS_DEMO":
            return {
                ...state,
                drivers: payload,
                callback,
            };
        case "UPDATE_DRIVER":
            return {
                ...state,
                updatedDriver: payload,
                callback,
            };
        case "CREATE_DRIVER":
            return {
                ...state,
                createdDriver: payload,
                callback,
            };
        case "SET_LOADER":
            return {
                ...state,
                loading: payload,
            };
        default:
            return state;
    }
};

export default driversReducer;
