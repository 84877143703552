import { combineReducers } from "redux";

import widgets from "./widgets";
import language from "./language";
import vehicle from "./vehicle";
import loginReducer, { checkLoginReducer } from "./login/login";
import authReducer from "./general/authReduce";
import errorReducer from "./general/errorReducer";
import travelReducer from "./travels/travelsReducer";
import vehiclesReducer from "./vehicles/vehiclesReducer";
import organisationReducer from "./organisations/organisationReducer";
import userReducer from "./users/userReducer";
import alarmsReducer from "./alarms/alarmsReducer";
import driversReducer from "./drivers/drivers";
import templatesReducer from "./templates/templates";
import accessReducer from "./access/accessReducer"
import alcoholMeterReducer from "./alcoholMeters/alcoholMeters"
import notificationsReducer from "./notifications/notifications"

const rootReducer = combineReducers({
    widgets,
    language,
    vehicle,
    login: loginReducer,
    auth: authReducer,
    error: errorReducer,
    travels: travelReducer,
    vehicles: vehiclesReducer,
    organisations: organisationReducer,
    users: userReducer,
    alarms: alarmsReducer,
    drivers: driversReducer,
    templates: templatesReducer,
    access: accessReducer,
    alcoholMeters: alcoholMeterReducer,
    notifications: notificationsReducer,
});

const reducers = (state, action) => {
    return rootReducer(state, action);
};
export default reducers;
