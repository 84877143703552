import React, {Component, useState, useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {List, Typography, Form, Input, Button, Checkbox, Row, Col, Modal} from "antd";
import logo from "../../assets/Images/jiricom_logo.png";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {adminLogin} from "../../actions/login/login";
import "../../pages/login/login.css";
import Alert from "../../utils/alert";
import {useHistory, useLocation} from "react-router-dom";
import getStorageValue from "../../utils/getStorageValue";
import GenericPasswordForm from "./GenericPasswordForm.tsx";

//TODO optimise speed on feedback to user wrong password
//TODO loading animation
const LoginPasswordFormContent = (props) => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('')
    const history = useHistory();
    let from  = getStorageValue('pathHistory') !== null ? getStorageValue('pathHistory') : '/';
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        setLoading(true)
        dispatch(adminLogin(values, (response) => {
            if (response.Status === 'NOK') {
                setLoading(false)
                setErrorMessage( response.Message)
            } else {
                setLoading(false)
                setErrorMessage('');
                document.body.classList.add('add-sidebar');
                history.push(from);
            }
        }));
    };

    const onFinishFailed = (data) => {
        return false;
    };
   
    const formContent = () => {
        return (
            <>
                <Form.Item
                    name="email"
                    rules={[{required: true, message: 'Please input your Username!'}]}
                >
                    <Input type={"email"} prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder="Email"/>
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{required: true, message: 'Please input your Password!'}]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>
                <Form.Item>
                    <a className="login-form-forgot" onClick={() => {props.setViews({isForgotPassView: true, isLoginView: false})}}>
                        Forgot password
                    </a>
                </Form.Item>
            </>      
        )
    }

    return (
        <>
            <GenericPasswordForm 
                form={{name: 'normal_login', className: 'login-form', initialValues: {remember: true}, onFinish: onFinish, onFinishFailed: onFinishFailed}} 
                formContent={formContent} 
                errorMessage={errorMessage}
                loading={loading}
            />
        </>
    );
}


export default LoginPasswordFormContent;
