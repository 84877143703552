const initialState = {
    loginUser: {},
};

const loginReducer = (state = initialState, {type, payload}, callback) => {
    switch (type) {
        case "LOGIN_USER":
            return {
                ...state,
                loginUser: payload,
                callback
            };
        default:
            return state;
    }
}

export default loginReducer;