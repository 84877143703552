import React, { Component } from "react";
import {
    GoogleMap,
    LoadScript,
    InfoWindow,
    Marker as GoogleMarker,
} from "@react-google-maps/api";
export default class Marker extends Component {
    state = {
        showInfoWindow: false,
    };

    onLoad = () => {
        this.setState({
            showInfoWindow: true,
        });
    };

    onMarkerClicked = () => {
        this.setState({
            showInfoWindow: true,
        });
    };

    onInfoWindowCloseClick = () => {
        this.setState({
            showInfoWindow: false,
        });
    };

    render() {
        return (
            <>
                <GoogleMarker
                    onLoad={this.onLoad}
                    icon={this.props.icon}
                    position={this.props.position}
                    onClick={this.onMarkerClicked}
                >
                    {(this.props.info || this.props.info1) &&
                    this.state.showInfoWindow ? (
                        <InfoWindow onCloseClick={this.onInfoWindowCloseClick}>
                            <div style={{ textAlign: "center" }}>
                                {this.props.info ? (
                                    <div> {this.props.info} </div>
                                ) : null}
                                {this.props.info1 ? (
                                    <div>{this.props.info1}</div>
                                ) : null}
                            </div>
                        </InfoWindow>
                    ) : null}
                </GoogleMarker>
            </>
        );
    }
}
