import React, { Component } from "react";
import { Card, Table, Button, Modal, Form, Input } from "antd";
import translation from "../../translation/index";
import { EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { SweLangData } from "../../actions";
import "../../assets/css/language.css";

class SwidishLanSetting extends React.Component {
  formRef = React.createRef();
  langColumns = [
    {
      title: "Sr. No",
      dataIndex: "srno",
      key: "srno",
      render: (text, record, index) => (
        <span>
          {(this.state.currentPage - 1) * this.state.pageSize + index + 1}
        </span>
      )
    },
    {
      title: "Language Key",
      dataIndex: "languagekey",
      key: "languagekey"
    },
    {
      title: "Language Value",
      dataIndex: "languagevalue",
      key: "languagevalue"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Button
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          size="small"
          onClick={e => this.clickedit(e, record)}
        />
      )
    }
  ];

  langData = [
    {
      languagekey: "welcomeText",
      languagevalue: "Välkommen Linus"
    },
    {
      languagekey: "todaysDateText",
      languagevalue: "Dagens datum"
    },
    {
      languagekey: "totaNumOfTravels",
      languagevalue: "Totalt antal resor"
    },
    {
      languagekey: "totalTravelsTodayText",
      languagevalue: "Total antal resor idag"
    },
    {
      languagekey: "totalDrivingDisText",
      languagevalue: "Total körsträcka"
    },
    {
      languagekey: "totalDrivingDistodayText",
      languagevalue: "Total körsträcka idag (km)"
    },
    {
      languagekey: "numOfActiveVehiTodayText",
      languagevalue: "Antal aktiva fordon idag"
    },
    {
      languagekey: "numOfTravelsText",
      languagevalue: "Antal resor"
    },
    {
      languagekey: "totalkmText",
      languagevalue: "Total (km)"
    },
    {
      languagekey: "activeVehiText",
      languagevalue: "Aktiva fordon"
    },
    {
      languagekey: "currentAlramText",
      languagevalue: "Aktuella larm"
    },
    {
      languagekey: "ongoingTravelsText",
      languagevalue: "Pågående resor"
    },
    { languagekey: "table.CurrentAlarm.Level", languagevalue: "Nivå" },
    { languagekey: "table.CurrentAlarm.Time", languagevalue: "Tid" },
    { languagekey: "table.CurrentAlarm.Device", languagevalue: "Enhet" },
    { languagekey: "table.CurrentAlarm.Vehicle", languagevalue: "Fordon" },
    { languagekey: "table.CurrentAlarm.Type", languagevalue: "Typ" },
    {
      languagekey: "table.OngoingTravels.Travelid",
      languagevalue: "Rese-id"
    },
    {
      languagekey: "table.OngoingTravels.Licenseplate",
      languagevalue: "Registreringsskylt"
    },
    { languagekey: "table.OngoingTravels.Driver", languagevalue: "Förare" },
    { languagekey: "table.OngoingTravels.Start", languagevalue: "Start" },
    {
      languagekey: "table.OngoingTravels.Traveltime",
      languagevalue: "Restid"
    },
    { languagekey: "table.OngoingTravels.Distance", languagevalue: "Distans" },
    { languagekey: "bookedVehiText", languagevalue: "Bokade fordon" },
    {
      languagekey: "addWidgetToDashText",
      languagevalue: "Lägg till widgets i instrumentpanelen"
    },
    { languagekey: "widgetsText", languagevalue: "Widgets" },
    {
      languagekey: "bookedVehicleDes",
      languagevalue: "Visa bokade fordon"
    },
    {
      languagekey: "tableCurrentAlarmDes",
      languagevalue: "Tabell över aktuella larm"
    },
    {
      languagekey: "tableOngoingTravelsDes",
      languagevalue: "Tabell över pågående resor"
    },
    {
      languagekey: "chartNumOfTravelsDes",
      languagevalue: "Stapeldiagram för antal resor"
    },
    {
      languagekey: "chartTotalKmDes",
      languagevalue: "Stapeldiagram för totalt (km)"
    },
    {
      languagekey: "chartActiveVehicleDes",
      languagevalue: "Stapeldiagram för aktiva fordon"
    },
    {
      languagekey: "staticTodaysDateDes",
      languagevalue: "Visa dagens datum"
    },
    {
      languagekey: "staticTotalNumOfTravelsDes",
      languagevalue: "Visa totalt antal resor"
    },
    {
      languagekey: "staticTotalTravelsTodayDes",
      languagevalue: "Visa totala resor idag"
    },
    {
      languagekey: "staticTotalDrivingDisDes",
      languagevalue: "Visa total körsträcka"
    },
    {
      languagekey: "staticTotalDrivingDisTodayDes",
      languagevalue: "Visa total körsträcka idag (Km)"
    },
    {
      languagekey: "staticNumOfActiveVehiTodayDes",
      languagevalue: "Visa antalet aktiva fordon idag"
    },
    { languagekey: "addWidgetsBtn", languagevalue: "Lägg till widgets" },
    { languagekey: "changeLayoutBtn", languagevalue: "Ändra layout" },
    { languagekey: "changeDoneBtn", languagevalue: "Ändringar klara" },
    { languagekey: "menu.Dashboard", languagevalue: "instrumentbräda" },
    { languagekey: "menu.vehicle", languagevalue: "Fordon" },
    {
      languagekey: "menu.vehicle.vehicleStatus",
      languagevalue: "Fordonstatus"
    },
    {
      languagekey: "menu.vehicle.searchVehicles",
      languagevalue: "Besiktningar"
    },
    {
      languagekey: "menu.vehicle.Currentmeterreadings",
      languagevalue: "Aktuella mätaravläsningar"
    },
    {
      languagekey: "menu.vehicle.Batterystatus",
      languagevalue: "Batteri status"
    },
    { languagekey: "menu.vehicle.Refueling", languagevalue: "Tankning" },
    { languagekey: "menu.vehicle.Inspections", languagevalue: "Inspektioner" },
    {
      languagekey: "menu.vehicle.Vehicleservice",
      languagevalue: "Fordonsservice"
    },
    {
      languagekey: "menu.vehicle.Vehiclereplacement",
      languagevalue: "Fordonsbyte"
    },
    {
      languagekey: "menu.vehicle.Vehicleconfiguration",
      languagevalue: "Fordonskonfiguration"
    },
    {
      languagekey: "menu.vehicle.CO2emissions",
      languagevalue: "CO2-utsläpp"
    },
    { languagekey: "menu.vehiclecard", languagevalue: "Fordonskort" },
    {
      languagekey: "menu.vehiclecard.Vehicleoverview",
      languagevalue: "Fordonsöversikt"
    },
    {
      languagekey: "menu.vehiclecard.Basicdata",
      languagevalue: "Grundläggande information"
    },
    { languagekey: "menu.vehiclecard.Economy", languagevalue: "Ekonomi" },
    {
      languagekey: "menu.vehiclecard.Maintenance",
      languagevalue: "Underhåll"
    },
    { languagekey: "menu.vehiclecard.System", languagevalue: "Systemet" },
    { languagekey: "menu.vehiclecard.Notes", languagevalue: "Anteckningar" },
    {
      languagekey: "menu.vehiclecard.Certificatetravel",
      languagevalue: "Certifikatresor"
    },
    { languagekey: "menu.driver", languagevalue: "Förare" },
    {
      languagekey: "menu.driver.Latestidentifications",
      languagevalue: "Senaste identifieringar"
    },
    {
      languagekey: "menu.driver.Driveroverview",
      languagevalue: "Föraröversikt"
    },
    {
      languagekey: "menu.driver.Driveranalysis",
      languagevalue: "Föraranalys"
    },
    { languagekey: "menu.positions", languagevalue: "Positioner" },
    {
      languagekey: "menu.positions.Currentpositions",
      languagevalue: "Nuvarande positioner"
    },
    {
      languagekey: "menu.positions.Findthenearestvehicle",
      languagevalue: "Hitta närmaste fordon"
    },
    {
      languagekey: "menu.positions.Create/editzones",
      languagevalue: "Skapa / redigera zoner"
    },
    {
      languagekey: "menu.positions.Listofzones",
      languagevalue: "Lista över zoner"
    },
    { languagekey: "menu.travel", languagevalue: "Resa" },
    {
      languagekey: "menu.travel.Ongoingtravel",
      languagevalue: "Pågående resor"
    },
    {
      languagekey: "menu.travel.Completedtrips",
      languagevalue: "Slutförda resor"
    },
    { languagekey: "menu.travel.Travellog", languagevalue: "Reselogg" },
    {
      languagekey: "menu.travel.Congestiontax",
      languagevalue: "Trängselskatt"
    },
    { languagekey: "menu.travel.Travelstop", languagevalue: "Resestopp" },
    {
      languagekey: "menu.travel.Travelpurposerules",
      languagevalue: "Regler för resändamål"
    },
    {
      languagekey: "menu.travel.Autoexportdrivingrecords",
      languagevalue: "Autoexport körrekord"
    },
    {
      languagekey: "menu.travel.Manuallogbook",
      languagevalue: "Manuell loggbok"
    },
    { languagekey: "menu.travel.Changeuser", languagevalue: "Växla användare" },
    {
      languagekey: "menu.travel.Certificatetravel",
      languagevalue: "Certifikatresor"
    },
    { languagekey: "menu.reserve", languagevalue: "Boka" },
    { languagekey: "menu.reserve.Vehicle", languagevalue: "Fordon" },
    {
      languagekey: "menu.reserve.Vehicleservice",
      languagevalue: "Fordonsservice"
    },
    {
      languagekey: "menu.reserve.Bookotherusers",
      languagevalue: "Boka andra användare"
    },
    { languagekey: "menu.alarm", languagevalue: "Larm" },
    { languagekey: "menu.alarm.Devicealarm", languagevalue: "Enhetslarm" },
    { languagekey: "menu.alarm.Driveralarm", languagevalue: "Förarlarm" },
    { languagekey: "menu.alarm.Servicealarm", languagevalue: "Servicelarm" },
    {
      languagekey: "menu.alarm.Exchangealarm",
      languagevalue: "Byt larm"
    },
    { languagekey: "menu.alarm.Zonlarm", languagevalue: "Mina zoner" },
    { languagekey: "menu.alarm.Speed​​alarm", languagevalue: "Hastighetslarm" },
    {
      languagekey: "menu.alarm.Receiverofalarms",
      languagevalue: "Mottagare av larm"
    },
    { languagekey: "menu.maintenance", languagevalue: "Underhåll" },
    {
      languagekey: "menu.maintenance.Completedmaintenance",
      languagevalue: "Slutfört underhåll"
    },
    {
      languagekey: "menu.maintenance.Maintenancework",
      languagevalue: "underhållsarbete"
    },
    { languagekey: "menu.economy", languagevalue: "Ekonomi" },
    {
      languagekey: "menu.economy.Totalcostofownership(TCO)",
      languagevalue: "Total ägandekostnad (TCO)"
    },
    {
      languagekey: "menu.economy.Currentmonthlycost",
      languagevalue: "Aktuell månatlig kostnad"
    },
    { languagekey: "menu.economy.Leasing", languagevalue: "Leasing" },
    { languagekey: "menu.economy.Fuel", languagevalue: "Bränsle" },
    { languagekey: "menu.analyzes", languagevalue: "Analyser" },
    {
      languagekey: "menu.analyzes.Degreeofutilization",
      languagevalue: "Användningsgrad"
    },
    {
      languagekey: "menu.analyzes.Exportanalyzes",
      languagevalue: "Exportanalyser"
    },
    {
      languagekey: "menu.analyzes.Speeds(ISA)",
      languagevalue: "Hastigheter (ISA)"
    },
    { languagekey: "menu.analyzes.Fuel", languagevalue: "Bränsle" },
    {
      languagekey: "menu.analyzes.Fuel-import",
      languagevalue: "Bränsleimport"
    },
    {
      languagekey: "menu.analyzes.Drivingbehavior",
      languagevalue: "Körbeteende"
    },
    { languagekey: "menu.reportTemplates", languagevalue: "Rapportera mallar" },
    {
      languagekey: "menu.reportTemplates.Createyourownreports",
      languagevalue: "Skapa egna rapporter"
    },
    { languagekey: "menu.reportTemplates.Reports", languagevalue: "Rapporter" },
    {
      languagekey: "menu.reportTemplates.Autoexportreports",
      languagevalue: "Autoexport rapporterar"
    },
    { languagekey: "menu.system", languagevalue: "Systemet" },
    { languagekey: "menu.system.Overview", languagevalue: "Översikt" },
    { languagekey: "menu.system.Details", languagevalue: "Detaljer" },
    { languagekey: "menu.system.Activate", languagevalue: "Aktivera" },
    { languagekey: "menu.system.Boxalarm", languagevalue: "Boxlarm" },
    {
      languagekey: "menu.system.Feedback/Support",
      languagevalue: "Feedback / support"
    },
    {
      languagekey: "menu.system.Autoexportreports",
      languagevalue: "Autoexport rapporterar"
    },
    { languagekey: "menu.show", languagevalue: "Visa" },
    { languagekey: "menu.show.HideMapView", languagevalue: "Dölj kartvy" },
    {
      languagekey: "menu.show.Timelimitontravel",
      languagevalue: "Tidsgräns för resor"
    },
    {
      languagekey: "menu.show.Showonlyunlockedtrips",
      languagevalue: "Visa endast olåsta resor"
    },
    {
      languagekey: "menu.show.Showonlyowntrips",
      languagevalue: "Visa bara egna resor"
    },
    { languagekey: "menu.show.Showspeed", languagevalue: "Visa hastighet" },
    { languagekey: "menu.admin", languagevalue: "Administration" },
    { languagekey: "menu.admin.Organisation", languagevalue: "Organisation" },
    {
      languagekey: "menu.admin.Organisation.Organizationalstructure",
      languagevalue: "Organisationsstruktur"
    },
    {
      languagekey: "menu.admin.Organisation.Listorganization",
      languagevalue: "Lista organisation"
    },
    {
      languagekey: "menu.admin.Organisation.Createaneworganization",
      languagevalue: "Skapa en ny organisation"
    },
    { languagekey: "menu.admin.Permissions", languagevalue: "Behörigheter" },
    {
      languagekey: "menu.admin.Permissions.Listofpermissiontemplates",
      languagevalue: "Lista över behörighetsmallar"
    },
    {
      languagekey: "menu.admin.Permissions.Createpermissiontemplates",
      languagevalue: "Skapa behörighetsmallar"
    },
    { languagekey: "menu.admin.User", languagevalue: "Användare" },
    {
      languagekey: "menu.admin.User.Listusers",
      languagevalue: "Lista användare"
    },
    {
      languagekey: "menu.admin.User.Createusers",
      languagevalue: "Skapa användare"
    },
    { languagekey: "menu.admin.User.Userlog", languagevalue: "Användarlogg" },
    { languagekey: "menu.admin.Data", languagevalue: "Data" },
    {
      languagekey: "menu.admin.Data.Importvehicles",
      languagevalue: "Importera fordon"
    },
    {
      languagekey: "menu.admin.Data.Importusers",
      languagevalue: "Importera användare"
    },
    {
      languagekey: "menu.admin.Data.Importtravelpurposes",
      languagevalue: "Importera resesyften"
    },
    {
      languagekey: "menu.admin.Data.Importfuel",
      languagevalue: "Importera drivmedel"
    },
    {
      languagekey: "menu.admin.Data.Exportvehicles",
      languagevalue: "Exportera fordon"
    },
    {
      languagekey: "menu.admin.Backupofdata",
      languagevalue: "Back-up av data"
    },
    { languagekey: "menu.admin.Templates", languagevalue: "Mallar" },
    {
      languagekey: "menu.admin.Templates.Listofvehicletemplates",
      languagevalue: "Lista fordonsmall"
    },
    {
      languagekey: "menu.admin.Templates.Createvehicletemplate",
      languagevalue: "Skapa fordonsmall"
    },
    {
      languagekey: "menu.admin.Templates.Listofcosttemplates",
      languagevalue: "Lista kostnadsmallar"
    },
    {
      languagekey: "menu.admin.Templates.Createcosttemplate",
      languagevalue: "Skapa kostnadsmall"
    },
    {
      languagekey: "menu.admin.Templates.Listservicetemplate",
      languagevalue: "Lista servicemall"
    },
    {
      languagekey: "menu.admin.Templates.Createservicetemplate",
      languagevalue: "Skapa servicemall"
    },
    {
      languagekey: "menu.admin.Templates.Listvehiclereplacementtemplate",
      languagevalue: "Lista utbytesmall för fordon"
    },
    {
      languagekey: "menu.admin.Templates.Createvehiclereplacementtemplate",
      languagevalue: "Skapa bilbytesmall"
    },
    {
      languagekey: "menu.admin.Registervehicles",
      languagevalue: "Registrera fordon"
    },
    { languagekey: "menu.admin.Userlog", languagevalue: "Användarlogg" },
    { languagekey: "menu.mySetting", languagevalue: "Min inställning" },
    { languagekey: "menu.logOut", languagevalue: "Logga ut" }
  ];

  state = {
    currentPage: "1",
    pageSize: "10",
    showModal: false,
    selectedLangKey: "",
    selectedLangValue: "",
    languageKeyInput: "",
    languageValueInput: "",
    langData: this.langData
  };

  onChange = e => {
    this.setState({ currentPage: e.current, pageSize: e.pageSize });
    console.log("current", e);
  };

  clickedit = (e, record) => {
    console.log("record", record);
    this.setState(
      {
        languageKeyInput: record.languagekey,
        languageValueInput: record.languagevalue
      },
      () => {
        this.setState({
          showModal: true
        });
      }
    );
    console.log("clickedit this.formRef.current => ", this.formRef);

    this.formRef.current &&
      this.formRef.current.setFieldsValue({
        languageKeyInput: record.languagekey,
        languageValueInput: record.languagevalue
      });
  };

  submitedData = values => {
    console.log("Received values of form: ", values);
    const elementsIndex = this.props.sweLangData.findIndex(
      element => element.languagekey == values.languageKeyInput
    );
    translation.sv[values.languageKeyInput] = values.languageValueInput;
    console.log("translation.sv", translation);
    console.log("elementsIndex", elementsIndex);
    let newlangData = [...this.props.sweLangData];
    newlangData[elementsIndex] = {
      ...newlangData[elementsIndex],
      languagevalue: values.languageValueInput
    };
    this.props.dispatch(SweLangData(newlangData));
    console.log("newlangData", newlangData);
    this.setState({ showModal: false });
    this.formRef.current.resetFields();
  };

  handleClose = () => {
    this.setState({ showModal: false });
    this.formRef.current.resetFields();
  };

  render() {
    return (
      <>
        <Card
          title="Swedish language list"
          className="inset-box-shadowed-effect"
        >
          <Table
            columns={this.langColumns}
            dataSource={this.props.sweLangData}
            /* pagination={true} */
            onChange={e => this.onChange(e)}
            /* pagination={e => this.onChange(e)} */
          />
        </Card>
        <Modal
          title="Edit Language Value"
          visible={this.state.showModal}
          footer={null}
          /* cancelText="Close"
          okText="Save"
          onOk={this.editHandleOk}*/
          onCancel={this.handleClose}
        >
          <Form
            name="EditLangForm"
            layout="vertical"
            ref={this.formRef}
            initialValues={{
              languageKeyInput: this.state.languageKeyInput,
              languageValueInput: this.state.languageValueInput
            }}
            onFinish={this.submitedData}
          >
            <Form.Item label="Language Key" name="languageKeyInput">
              <Input disabled value={this.state.languageKeyInput} />
            </Form.Item>
            <Form.Item label="Language Value" name="languageValueInput">
              <Input />
            </Form.Item>
            <div className="langmodelbtn">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={this.handleClose}>
                  Close
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  // console.log("state mapstatetoprops", state);
  return {
    sweLangData: state.language.sweLangData
  };
};

export default connect(mapStateToProps)(SwidishLanSetting);
