import React, {
    FC,
    useEffect,
    useState,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import GenericMap from "../GenericMap";
import {
    GoogleMapsLibraries,
    IMapProps,
    IRealTimePositionMap,
    IMarkerProps,
} from "../data.d";
import { XOR } from "../../common/function";
import { Marker as GoogleMarker } from "@react-google-maps/api";

import { getVehiclePositions } from "../../../utils/api";

export const RealTimePositionMap: FC<IRealTimePositionMap> = (props) => {
    const [markers, setMarkers] = useState<IMarkerProps[]>([]);

    useEffect(() => {
        const getVehiclePositionsAPI = () => {
            getVehiclePositions(props.vehicleIdFromParams)
                .then((response) => {
                    setMarkers(
                        response.Results.map((positionObject: any) => {
                            return (
                                <GoogleMarker
                                    position={{
                                        lat: positionObject.Latitude,
                                        lng: positionObject.Longitude,
                                    }}
                                />
                            );
                        })
                    );
                })
                .catch(() => {});
        };
        getVehiclePositionsAPI();
        const interval = setInterval(() => {
            getVehiclePositionsAPI();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {markers && !!markers.length && (
                <>
                    <GenericMap markers={[markers[0]]} />
                </>
            )}
        </>
    );
};
