import React, {useState} from 'react';
import {Breadcrumb, Button, Drawer, Dropdown, Menu} from "antd";
import {Link} from "react-router-dom";
import "../../assets/css/header.css";
import {DownOutlined, FilterOutlined, SearchOutlined} from "@ant-design/icons";
/*
CHECK
*/
function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
}


const Header = props => {
    console.log("onlyheader props: ", props.onlyHeader)
    const [display, setDisplay] = useState(false);

    if(props.onlyHeader) {
        return (<div className="header">
        </div>)
    } else {
        return (<div className="header">
            {props.floating &&
            <>
                {!props.floating ? 
                    <Button className="filter-btn" icon={<FilterOutlined/>}
                        onClick={() => setDisplay(!display)}>Filter</Button> 
                    :
                    <Button className="filter-btn-float" icon={<FilterOutlined/>} 
                        onClick={() => setDisplay(!display)}/>
                }
            </>
            }
            <Drawer
                title="Search filters"
                width={720}
                onClose={() => {
                    setDisplay(false)
                }}
                visible={display}
                bodyStyle={{paddingBottom: 80}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => {
                            setDisplay(false)
                        }} style={{marginRight: 8}}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            setDisplay(false)
                        }} 
                            form={props.form_id} type="primary" key="submit" htmlType="submit">
                            Search
                        </Button>
                    </div>
                }
            >
                {props.children}
            </Drawer>
        </div>)
    }

    
}

export default Header;