import { IDriver, ITableColumnFilter } from "../../global-types";

const initialState = {
    notifications: [],
    notificationsByDeviceId: [],
};

const notificationsReducer = (
    state = initialState,
    { type, payload }: any,
    callback: any
) => {
    switch (type) {
        case "SET_LOADER":
            return {
                ...state,
                loading: payload,
            };
        case "GET_NOTIFICATIONS":
            return {
                ...state,
                notifications: payload.Result,
            };
        case "GET_NOTIFICATIONS_BY_DEVICE_ID":
            return {
                ...state,
                notificationsByDeviceId: payload.Result,
            };
        case "SET_ERRORS":
            return {
                ...state,
                hasData: false,
            };
        default:
            return state;
    }
};

export default notificationsReducer;
