import React, { useEffect, useState, useContext, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
    Empty,
    Space,
    Button,
    Row,
    Col,
    Checkbox,
    Skeleton,
    Drawer,
    DatePicker,
    Affix,
    Form,
    Input,
    Table,
    Select,
    Card,
    Popover,
} from "antd";
//import { Table,ExportTableButton } from "ant-table-extensions"
//import {  } from "ant-table-extensions";
import Header from "../../../../components/breadcrumbheader/Header";
import * as moment from "moment";
import {
    PictureOutlined,
    DownloadOutlined,
    SearchOutlined,
    EditOutlined,
    PlayCircleOutlined,
    ReadOutlined,
    ConsoleSqlOutlined,
    EnvironmentOutlined,
} from "@ant-design/icons";
import "../../../../assets/css/vehicelstatus.css";
import { Link, useHistory } from "react-router-dom";
import * as Apis from "../../../../utils/api";
import "../../../../assets/css/functionPage.css";
import RenderGM from "../../../../components/Map/Google";
import { CreateRouteExport } from "../../../../components/common/function";
import summaryData from "../../../../components/travel/travelExport";
import PickerTag from "antd/lib/date-picker/PickerTag";
import filterSearch from "../../../../components/search/filterSearch";
import {
    nNotis,
    getPreviousWeekDate,
} from "../../../../components/common/function";
import * as travelActions from "../../../../actions/travels/travels";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    const { Option } = Select;
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
            >
                {dataIndex === "RouteType" || dataIndex === "Driver" ? (
                    dataIndex === "Driver" ? (
                        <Select
                            ref={inputRef}
                            defaultValue={dataIndex.RouteType}
                            onChange={save}
                            onBlur={save}
                        >
                            <Option value="Driver">Driver 1</Option>
                            <Option value="driver">Driver 2</Option>
                            <Option value="Dr">Driver 3</Option>
                        </Select>
                    ) : (
                        <Select
                            ref={inputRef}
                            defaultValue={dataIndex.RouteType}
                            onChange={save}
                            onBlur={save}
                        >
                            <Select.Option value="Tjänsteresa">
                                Tjänsteresa
                            </Select.Option>
                            <Select.Option value="Privatresa">
                                Privatresa
                            </Select.Option>
                            <Select.Option value="Tanking">
                                Tankning
                            </Select.Option>
                            <Select.Option value="Kundresa">
                                Kundresa
                            </Select.Option>
                        </Select>
                    )
                ) : (
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                )}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}> {childNode}</td>;
};

const CompletedTrips = (props) => {
    const { RangePicker } = DatePicker;

    //const [editingRow, setEditingRow] = useState(null);
    const [form] = Form.useForm();
    const loading = useSelector((state) => state.auth.loading);
    const authCheckLoading = useSelector(
        (state) => state.auth.checkLoginLoading
    );
    const history = useHistory();
    const dispatch = useDispatch();
    const [searchVisible, setSearchvisible] = useState(false);
    const [SelrouteID, setSelRouteID] = useState("undefined");
    const [routeData, setRouteData] = useState();
    const [state, setState] = useState({
        Route: undefined,
    });
    const [travels, setTravels] = useState({
        travels: [],
        dateFrom: 0,
        dateTo: 0,
    });
    const [locallyStoredtravels, setLocallyStoredTravels] = useState({
        travels: [],
        dateFrom: 0,
        dateTo: 0,
    });
    const [locallyStoredVehicles, setLocallyStoredVehicles] = useState();
    const [vehicle, setVehicle] = useState([]);
    const [visibleInfo, setVisibleInfo] = useState(false);
    //TODO display companies as cascader component instead of using flat orgs.
    const [companies, setCompanies] = useState([]);
    const [orgsFlat, setOrgsFlat] = useState([]);
    const [users, setUsers] = useState([]);
    const [respNumber, setRespNumber] = useState([]);
    const [odometer, setOdometer] = useState({
        odometerStart: 0,
        odometerStop: 0,
    });
    //Open the drawer to left
    const OpenDrawer = (id) => {
        setSelRouteID(id);
        console.log("OpenDrawer parameter: " + id);

        Apis.getRouteById(id)
            .then((response) => {
                console.log("getRouteById Apis.getRouteById API RES", response);
                if (response.Status === "NOK") {
                    console.log("error in getRouteById", response);
                } else {
                    console.log("getRouteById api response: ", response);
                    let Coords = [];
                    response.Results.Geometrics.Polyline.map((x) =>
                        Coords.push({
                            lat: parseFloat(x.Latitude),
                            lng: parseFloat(x.Longitude),
                        })
                    );

                    setState({ Route: { Coords: Coords } });
                    //setRouteData(response.Route);
                    setRouteData(response.Results);

                    setVisibleInfo(true);
                    console.log("is travel drawer state: ", visibleInfo);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        //SelrouteID === "undefined" ? setVisibleInfo(false) : setVisibleInfo(true);
    };

    const getVehicle = () => {
        Apis.getAllVehicles()
            .then((res) => {
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    console.log("getAllVehicles api res: ", res);
                    if (vehicle.length === 0) {
                        var arr = [];
                        res.Vehicles.forEach(function (obj) {
                            arr.push({
                                key: obj.vID,
                                LicensePlate: obj.LicensePlate,
                            });
                        });
                        setLocallyStoredVehicles(res.Vehicles);
                        setVehicle(arr);

                        let respNumbers = [
                            ...new Set(
                                res.Vehicles.map(
                                    (vehicle) => vehicle.Group
                                ).filter((group) => {
                                    return group;
                                })
                            ),
                        ];

                        console.log("respnumbers gathered: ", respNumbers);
                        setRespNumber(respNumbers);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const gettravels = (data) => {
        console.log("travels state: ", travels);
        data = { ...data, routeType: data.RouteTypeId };
        delete data.RouteTypeId;
        console.log("data after converting routetype in gettravels: ", data);

        Apis.getAllRoutes(data)
            .then((res) => {
                console.log("getAllRoutes res", res);
                if (res.Status === "NOK") {
                    console.log("error");
                    console.log(res);
                } else {
                    /*
          Fix some key errors:
          */
                    if (!res.Routes) {
                        nNotis(
                            "error",
                            "top",
                            "Please change your search criterias",
                            3,
                            "No routes could be found"
                        );
                        return;
                    }
                    if (res.Routes.length <= 0) {
                        nNotis(
                            "error",
                            "top",
                            "Please change your search criterias",
                            3,
                            "No routes could be found"
                        );
                        return;
                    }

                    var arr = [];
                    res.Routes.forEach(function (obj) {
                        console.log("parse attest: ", obj.Attest);
                        arr.push({
                            DriverInfo: obj.DriverInfo,
                            CompanyName: obj.CompanyName,
                            VehicleCompanyId: obj.VehicleCompanyId,
                            key: obj.RouteID,
                            firstLat: obj.Coords.StartLat,
                            firstLon: obj.Coords.StartLng,
                            lastLat: obj.Coords.StopLat,
                            lastLon: obj.Coords.StopLng,
                            Attest: obj.Attest,
                            routeId: obj.RouteID,
                            LicensePlate: obj.LicensePlate,
                            Driver: obj.Driver,
                            DriverId: obj.DriverId,
                            StartAddress: obj.StartAddress,
                            StopAddress: obj.StopAddress,
                            Time:
                                obj.Time.StartTime + "<br>" + obj.Time.EndTime,
                            StartTime: obj.Time.StartTime,
                            EndTime: obj.Time.EndTime,
                            DateUnix: moment(obj.Time.StartTimeStamp).valueOf(),
                            TravelTime: obj.TravelTime,
                            TravelTimeSeconds: obj.TravelTimeSeconds,
                            Distance: obj.Distance,
                            DistanceKilometers: obj.DistanceKilometers,
                            Consumption: obj.Consumption,
                            Comment: obj.Comment,
                            RouteType: obj.RouteType,
                            Purpose: obj.Purpose,
                            odometerStop: obj.odometerStop,
                            odometerStart: obj.odometerStart,
                            Vid: obj.Vid,
                        });
                    });
                    setTravels({
                        travels: arr,
                        dateFrom: data.fromDate,
                        dateTo: data.toDate,
                    });
                    //new date-interval is inside current local stored travels date-interval
                    if (
                        data.fromDate > locallyStoredtravels.dateFrom &&
                        data.fromDate < locallyStoredtravels.dateTo &&
                        data.toDate > locallyStoredtravels.dateFrom &&
                        data.toDate < locallyStoredtravels.dateTo
                    ) {
                        //new date is inside here then dont update storedtravels
                    } else {
                        //setLocallyStoredTravels({travels: arr, dateFrom: data.fromDate, dateTo: data.toDate});
                        setLocallyStoredTravels({
                            travels: arr,
                            dateFrom: data.fromDate,
                            dateTo: data.toDate,
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        const data = {
            fromDate: moment(getPreviousWeekDate()),
            toDate: moment(),
            licenseplate: "",
            routeType: 0,
            RouteTypeId: 0,
            stopadress: "",
            startadress: "",
            travelpurpose: "",
            org: "",
            attest: "",
            driver: "",
            travelid: "",
            respNumber: "",
        };

        gettravels(data);
        getVehicle();
        Apis.getUsers()
            .then((res) => {
                console.log("API getUsers", res);
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    setUsers(res.Results);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        Apis.GetOrgs()
            .then((res) => {
                console.log("API ORGS ", res);
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    setCompanies(res.Companies);
                    setOrgsFlat(res.ORGS[Object.keys(res.ORGS)[0]]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //}, [history.location.pathname]);
    const routes = [
        {
            path: "",
            breadcrumbName: "Travel",
        },
        {
            path: "",
            breadcrumbName: "Completed Trips",
        },
    ];

    const RouteExport = () => {
        console.log("travels state: ", travels);
        let summaryDataObj = summaryData(travels.travels);
        let dateFromFriendly = moment(travels.dateFrom).format("ll");
        let dateToFriendly = moment(travels.dateTo).format("ll");
        CreateRouteExport(
            travels.travels,
            props.loggedInUser.Name,
            dateFromFriendly,
            dateToFriendly,
            summaryDataObj
        );
    };

    const driverNamePopoverContent = (contentObj) => {
        if (
            typeof contentObj === "object" &&
            Object.keys(contentObj).length === 0 &&
            contentObj.constructor === Object
        ) {
            return (
                <div>{<p> No data available for this specific Driver </p>}</div>
            );
        }
        if (Array.isArray(contentObj) && contentObj.length < 1) {
            return (
                <div>{<p> No data available for this specific Driver </p>}</div>
            );
        }
        return (
            <div>
                {Object.keys(contentObj).map((key, index) => {
                    return (
                        <div key={index}>
                            {key == "_" ? (
                                <> {contentObj[key]} </>
                            ) : (
                                <p>
                                    {key}: {contentObj[key]}{" "}
                                </p>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const onChangeAttestCheckbox = async (e, record) => {
        console.log("attest checked = ", e.target.checked, "record: ", record);
        if (e.target.checked) {
            record.Attest = "1";
        } else {
            record.Attest = "0";
        }
        travelActions.updateTravelToDb(record);
    };

    const columns1 = [
        {
            title: "Attest",
            dataIndex: "Attest",
            render: (_, record) => (
                <>
                    {console.log("Attest record ", record)}
                    {record.Attest === "1" ? (
                        <>
                            <Checkbox
                                defaultChecked
                                onChange={(e) =>
                                    onChangeAttestCheckbox(e, record)
                                }
                            />
                        </>
                    ) : (
                        <>
                            <Checkbox
                                onChange={(e) =>
                                    onChangeAttestCheckbox(e, record)
                                }
                            />
                        </>
                    )}
                </>
            ),
        },
        {
            title: "Vehicle",
            dataIndex: "LicensePlate",
            editable: false,
            sorter: (a, b) => a.LicensePlate.localeCompare(b.LicensePlate),
            render: (text, record) => (
                <Space>
                    <div>
                        <a
                            onClick={(e) =>
                                history.push(
                                    "/vehiclecard/overview/" +
                                        record.LicensePlate
                                )
                            }
                        >
                            {record.LicensePlate}
                        </a>
                        <br />
                        <a
                            onClick={(e) => {
                                OpenDrawer(record.routeId);
                            }}
                        >
                            {record.routeId}
                        </a>
                    </div>
                </Space>
            ),
        },
        {
            title: "Driver",
            dataIndex: "Driver",
            sorter: (a, b) => a.Driver.localeCompare(b.Driver),
            render: (_, record) => (
                <Popover content={driverNamePopoverContent(record.DriverInfo)}>
                    <p>{record.Driver}</p>
                </Popover>
            ),

            //editable: true,
        },
        {
            title: "MAP",
            render: (_, record) => (
                <Space size="middle">
                    <a
                        onClick={(e) => {
                            OpenDrawer(record.routeId);
                        }}
                    >
                        <EnvironmentOutlined />
                        {/* <PictureOutlined /> */}
                    </a>
                </Space>
            ),
        },
        {
            title: "Address",
            //dataIndex: ['StartAddress', 'StopAddress'],
            dataIndex: "StartAddress",
            //sorter: (a, b) => {console.log(a,b); a.StartAddress.localeCompare(b.StartAddress)},
            sorter: (a, b, sortOrder) => {
                if (a.StartAddress != null && b.StartAddress != null) {
                    a.StartAddress.localeCompare(b.StartAddress);
                }
                if (a.StartAddress) {
                    return sortOrder === "ascend" ? 1 : -1;
                }
                if (b.StartAddress) {
                    return sortOrder === "ascend" ? -1 : 1;
                }
                return 0;
            },
            render: (_, record) => (
                <Space size="middle">
                    <div>
                        {record.StartAddress}
                        <br />
                        {record.StopAddress}
                    </div>
                </Space>
            ),
        },
        {
            title: "Time",
            dataIndex: "StartTime",
            render: (_, record) => (
                <Space size="middle">
                    <div>
                        {record.StartTime}
                        <br />
                        {record.EndTime}
                    </div>
                </Space>
            ),
        },
        {
            title: "Traveltime",
            dataIndex: "TravelTime",
            sorter: (a, b) => a.TravelTime.localeCompare(b.TravelTime),
        },
        {
            title: "Distance",
            dataIndex: "Distance",
            sorter: (a, b) => a.Distance.localeCompare(b.Distance),
        },
        {
            title: "odometer",
            render: (_, record) => (
                <Space size="middle">
                    <div>
                        {record.odometerStart}
                        <br />
                        {record.odometerStop}
                    </div>
                </Space>
            ),
        },
        {
            title: "Consumption",
            dataIndex: "Consumption",
            sorter: (a, b) => a.Consumption.localeCompare(b.Consumption),
        },
        {
            title: "RouteType",
            dataIndex: "RouteType",
            sorter: (a, b) => a.RouteType.localeCompare(b.RouteType),
            editable: true,
        },
        {
            title: "Purpose",
            dataIndex: "Purpose",
            editable: true,
            sorter: (a, b) => a.Purpose.localeCompare(b.Purpose),
            render: (_, record) => (
                <Space size="middle">
                    {record.Purpose === "" ? (
                        <div></div>
                    ) : (
                        <div>{record.Purpose}</div>
                    )}
                </Space>
            ),
        },
    ];

    //TODO rename Tanking -> Tankning also in api
    const getRoutetypeID = (routetype) => {
        if (routetype === "Privatresa") {
            return 1;
        } else if (routetype === "Kundresa") {
            return 6;
        } else if (routetype === "Tanking") {
            return 3;
        } else if (routetype === "Tjänsteresa") {
            return 0;
        }
    };

    const getRouteTypeById = (RouteTypeId) => {
        if (RouteTypeId === 1) {
            return "Privatresa";
        } else if (RouteTypeId === 6) {
            return "Kundresa";
        } else if (RouteTypeId === 3) {
            return "Tanking";
        } else if (RouteTypeId === 0) {
            return "Tjänsteresa";
        }
    };

    const handleSave = (row) => {
        console.log("attest handlesave triggered row: ", row);
        const updateData = {
            Routeid: row.routeId,
            //"Driver":row.Driver,
            Purpose: row.Purpose,
            Type: getRoutetypeID(row.RouteType),
        };

        Apis.putEdittravel(row.routeId, updateData)
            .then((response) => {
                if (response.Status === "NOK") {
                    console.log("error api putEdittravel res: ", response);
                } else {
                    console.log("api putEdittravel res: ", response);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        const newData = [...travels["travels"]];
        const index = newData.findIndex((item) => row.routeId === item.routeId);
        const item = newData[index];
        console.log("api putEdittravel ui ", newData);
        newData.splice(index, 1, { ...item, ...row });
        setTravels({ travels: newData });
        setLocallyStoredTravels({ travels: newData });
    };

    const onCloseInfo = () => {
        setVisibleInfo(false);
    };
    const onCloseSearch = () => {
        setSearchvisible(false);
    };

    const [formSearch] = Form.useForm();
    formSearch.setFieldsValue({
        Picker: [moment().subtract(7, "d"), moment()],
    });

    function isGroupAsRespNumber(localVehicle) {
        //console.log("this.userInputRespNumber", this.userInputRespNumber)
        return localVehicle.Group == this.userInputRespNumber;
    }

    const getRouteVidsByResNumber = (userInputRespNumber) => {
        //console.log("getRouteVidByResNumber locallyStoredVehicles: ", locallyStoredVehicles)

        let vehicleIdsWithSameResNumber = locallyStoredVehicles
            .filter(isGroupAsRespNumber, {
                userInputRespNumber: userInputRespNumber,
            })
            .map((vehicle) => vehicle.vID);
        //let vehicleWithSameResNumber = locallyStoredVehicles.find(isGroupAsRespNumber, {userInputRespNumber: userInputRespNumber})
        //console.log("vehicle ids' found: ", vehicleIdsWithSameResNumber)

        return vehicleIdsWithSameResNumber;
    };

    const onFinish = (fieldsValue) => {
        const rangeTimeValue = fieldsValue["Picker"];
        const values = {
            ...fieldsValue,
            Picker: [
                rangeTimeValue[0].format("YYYY-MM-DD HH:mm:ss"),
                rangeTimeValue[1].format("YYYY-MM-DD HH:mm:ss"),
            ],
        };

        console.log("onfinish values ", values);

        let data = {
            toDate: values.Picker[1],
            fromDate: values.Picker[0],
            LicensePlate: values.vi,
            RouteType: values.RouteType,
            RouteTypeId: getRoutetypeID(values.RouteType),
            StopAddress: values.StopAddress,
            StartAddress: values.StartAddress,
            Purpose: values.Purpose,
            org: values.org,
            Attest: values.Attest,
            driver: Number(values.driver),
            routeId: values.routeId,
            respNumber: values.respNumber,
        };

        if (values.respNumber) {
            //console.log('values.respNumber', values.respNumber)
            let Vids = getRouteVidsByResNumber(values.respNumber);
            console.log("Vids found: ", Vids);

            data = { ...data, Vid: Vids };
        }

        console.log("data from form to submit:", data);
        let pickerDateFrom = moment(data.fromDate);
        let pickerDateTo = moment(data.toDate);

        if (
            pickerDateFrom >= locallyStoredtravels.dateFrom &&
            pickerDateFrom <= locallyStoredtravels.dateTo &&
            pickerDateTo >= locallyStoredtravels.dateFrom &&
            pickerDateTo <= locallyStoredtravels.dateTo
        ) {
            //search date is inside state date
            console.log("search date is inside state date");
            //filter routes locally only
            let filterSearchFormatData = {
                ...data,
                DateUnix: {
                    dateFrom: pickerDateFrom.valueOf(),
                    dateTo: pickerDateTo.valueOf(),
                },
                DriverId: values.driver,
                VehicleCompanyId: values.org,
            };
            delete filterSearchFormatData.toDate;
            delete filterSearchFormatData.fromDate;
            delete filterSearchFormatData.RouteTypeId;
            delete filterSearchFormatData.respNumber;
            delete filterSearchFormatData.driver;
            delete filterSearchFormatData.org;

            console.log("filterSearchFormatData", filterSearchFormatData);
            let filtered = filterSearch(
                filterSearchFormatData,
                locallyStoredtravels["travels"]
            );
            console.log("filtered ", filtered);

            if (!filtered || filtered.length <= 0) {
                nNotis(
                    "error",
                    "top",
                    "Please change your search criterias",
                    3,
                    "No routes could be found"
                );
                return;
            } else {
                setSearchvisible(false);
            }
            setTravels({ travels: filtered });
        } else {
            console.log("search date is outside state date interval");
            //search date is outside state date interval
            //filter routes by API
            let apiFormatData = {
                ...data,
                stopadress: data.StopAddress,
                startadress: data.StartAddress,
                licenseplate: data.LicensePlate,
                travelpurpose: data.Purpose,
                attest: data.Attest,
                travelid: data.routeId,
            };
            gettravels(apiFormatData);
            setSearchvisible(false);
        }

        //gettravels(data)
    };

    const rangeConfig = {
        rules: [
            {
                type: "array",
                required: true,
                message: "Please select time!",
            },
        ],
    };
    // console.log("columns1 columns ", columns1);
    // const columns = columns1.map((col, index, originalArr) => {
    //     console.log("columns col ", col);

    //     if (!col.editable) {
    //         return col;
    //     }

    //     return {
    //         ...col,
    //         onCell: (record) => ({
    //             record,
    //             editable: col.editable,
    //             dataIndex: col.dataIndex,
    //             title: col.title,
    //             handleSave,
    //         }),
    //     };
    // });
    // console.log(" columns after mapping ", columns);

    //Returns 2d array with key-value pair objects in inner array.
    // const companies_arr = Object.keys(companies).map(key => {
    //   return companies[key]
    // })

    const onRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                // record: row data
                // rowIndex: row index
                // event: event prototype
                console.log("click record ", record);
                console.log("row id ", rowIndex);
                OpenDrawer(record.routeId);
            },
        };
    };

    return (
        <div className="functionPagediv">
            <Header routes={routes} floating={true}></Header>
            <Affix
                offsetTop={150}
                onChange={(affixed) => console.log(affixed)}
                style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
            >
                <div>
                    <p>
                        <Button
                            size="large"
                            type="primary"
                            onClick={RouteExport}
                            icon={<DownloadOutlined />}
                        />
                    </p>
                    <p>
                        <Button
                            size="large"
                            type="primary"
                            onClick={() => setSearchvisible(true)}
                            icon={<SearchOutlined />}
                        />
                    </p>
                </div>
            </Affix>
            <Row justify="space-around" align="middle">
                <Col span={23}>
                    {/* <Header routes={routes} filter={false} />*/}
                    {loading || authCheckLoading ? (
                        <Skeleton active />
                    ) : (
                        <>
                            {console.log("travels travels ", travels.travels)}
                            {travels.travels.length === 0 ? (
                                <>
                                    {console.log(
                                        "travels should show empty component now"
                                    )}
                                    <Empty
                                        style={{}}
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                        imageStyle={{
                                            height: 60,
                                        }}
                                        description={
                                            <p>
                                                No travels made last week. Try
                                                search for a specific date
                                                interval to display travels.
                                            </p>
                                        }
                                    >
                                        <Button
                                            style={{
                                                marginRight: "46.5%",
                                            }}
                                            type="primary"
                                            onClick={() => {
                                                setSearchvisible(true);
                                            }}
                                        >
                                            Search Travels
                                        </Button>
                                    </Empty>
                                </>
                            ) : (
                                <>
                                    <Table
                                        components={{
                                            body: {
                                                row: EditableRow,
                                                cell: EditableCell,
                                            },
                                        }}
                                        //style={{ whiteSpace: "pre" }}
                                        // rowSelection={{}}
                                        columns={columns1}
                                        dataSource={travels["travels"]}
                                    />
                                </>
                            )}
                            {console.log(
                                "drawer searchVisible ",
                                searchVisible
                            )}
                            <Drawer
                                title="Search"
                                placement="right"
                                onClose={onCloseSearch}
                                open={searchVisible}
                                size="large"
                            >
                                <Form
                                    form={formSearch}
                                    layout="horizontal"
                                    onFinish={onFinish}
                                    labelCol={{
                                        span: 5,
                                    }}
                                    wrapperCol={{
                                        span: 18,
                                    }}
                                >
                                    <Form.Item
                                        name="Picker"
                                        label="Time interval"
                                        {...rangeConfig}
                                    >
                                        <RangePicker
                                            showTime
                                            format="YYYY-MM-DD HH:mm:ss"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Vehicle" name="vi">
                                        <Select>
                                            {vehicle.map((v) => (
                                                <Select.Option
                                                    value={v.LicensePlate}
                                                >
                                                    {v.LicensePlate}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="StartAddress"
                                        name="StartAddress"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="StopAddress"
                                        name="StopAddress"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Routetype"
                                        name="RouteType"
                                    >
                                        <Select>
                                            {/*<Select mode="multiple">*/}
                                            <Select.Option>Alla</Select.Option>
                                            <Select.Option value="Tjänsteresa">
                                                Tjänsteresa
                                            </Select.Option>
                                            <Select.Option value="Privatresa">
                                                Privatresa
                                            </Select.Option>
                                            <Select.Option value="Kundresa">
                                                Kundresa
                                            </Select.Option>
                                            <Select.Option value="Tankning">
                                                Tankning
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Travelpurpose"
                                        name="Purpose"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Organisation" name="org">
                                        <Select
                                            allowClear
                                            style={{ width: "100%" }}
                                            showSearch
                                            placeholder="Select companies"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {/*companies.map((v) => <Select.Option value={v.Id}>{v.Name}</Select.Option>)*/}
                                            {orgsFlat ? (
                                                orgsFlat.map((v) => (
                                                    <Select.Option value={v.Id}>
                                                        {v.Name}
                                                    </Select.Option>
                                                ))
                                            ) : (
                                                <Select.Option value="not available">
                                                    No Available Companies
                                                    Exists
                                                </Select.Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Driver" name="driver">
                                        <Select
                                            allowClear
                                            style={{ width: "100%" }}
                                            showSearch
                                            placeholder="Driver"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {users.map((v) => (
                                                <Select.Option value={v.UserID}>
                                                    {v.Name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Attest resor"
                                        name="Attest"
                                    >
                                        <Select>
                                            <Select.Option>Alla</Select.Option>
                                            <Select.Option value="0">
                                                Inte attesterade
                                            </Select.Option>
                                            <Select.Option value="1">
                                                Bara attesterade
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Route ID" name="routeId">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Responsibility"
                                        name="respNumber"
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "100%" }}
                                            showSearch
                                            placeholder="Driver"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {respNumber.map((v) => (
                                                <Select.Option value={v}>
                                                    {v}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Search
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Drawer>
                            <Drawer
                                title="Show Travel"
                                placement="right"
                                onClose={onCloseInfo}
                                visible={visibleInfo}
                                size="large"
                            >
                                <RenderGM
                                    height="50vh"
                                    center={
                                        state.Route !== undefined
                                            ? {
                                                  lat: state.Route.Coords[0]
                                                      .lat,
                                                  lng: state.Route.Coords[0]
                                                      .lng,
                                              }
                                            : { lat: 57.783113, lng: 14.161727 }
                                    }
                                    zoom={11}
                                    type="Route"
                                    data={routeData}
                                    position="relative"
                                    parentDrawerVisible={visibleInfo}
                                />

                                <hr />
                                {routeData === undefined ? (
                                    <></>
                                ) : (
                                    <>
                                        <Row gutter={[8, 8]}>
                                            <Col span={8}>
                                                <Card
                                                    title="Vehicle"
                                                    headStyle={{
                                                        lineHeight: "0.75",
                                                    }}
                                                    bodyStyle={{
                                                        padding:
                                                            "15px 24px 0px 24px",
                                                    }}
                                                >
                                                    <p>
                                                        {routeData.LicensePlate}
                                                    </p>
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card
                                                    title="Driver"
                                                    headStyle={{
                                                        lineHeight: "0.75",
                                                    }}
                                                    bodyStyle={{
                                                        padding:
                                                            "15px 24px 0px 24px",
                                                    }}
                                                >
                                                    <p>{routeData.Driver}</p>
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card
                                                    title="TravelTime"
                                                    headStyle={{
                                                        lineHeight: "0.75",
                                                    }}
                                                    bodyStyle={{
                                                        padding:
                                                            "15px 24px 0px 24px",
                                                    }}
                                                >
                                                    <p>
                                                        {routeData.TravelTime}
                                                    </p>
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card
                                                    title="Distance"
                                                    headStyle={{
                                                        lineHeight: "0.75",
                                                    }}
                                                    bodyStyle={{
                                                        padding:
                                                            "15px 24px 0px 24px",
                                                    }}
                                                >
                                                    <p>{routeData.Distance}</p>
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card
                                                    title="Consumption"
                                                    headStyle={{
                                                        lineHeight: "0.75",
                                                    }}
                                                    bodyStyle={{
                                                        padding:
                                                            "15px 24px 0px 24px",
                                                    }}
                                                >
                                                    <p>
                                                        {routeData.Consumption}
                                                    </p>
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card
                                                    title="RouteType"
                                                    headStyle={{
                                                        lineHeight: "0.75",
                                                    }}
                                                    bodyStyle={{
                                                        padding:
                                                            "15px 24px 0px 24px",
                                                    }}
                                                >
                                                    <p>{routeData.RouteType}</p>
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card
                                                    title="Purpose"
                                                    headStyle={{
                                                        lineHeight: "0.75",
                                                    }}
                                                    bodyStyle={{
                                                        padding:
                                                            "15px 24px 0px 24px",
                                                    }}
                                                >
                                                    <p>{routeData.Purpose}</p>
                                                </Card>
                                            </Col>
                                            <Col span={8}>
                                                <Card
                                                    title="Odometer"
                                                    headStyle={{
                                                        lineHeight: "0.75",
                                                    }}
                                                    bodyStyle={{
                                                        padding:
                                                            "15px 24px 0px 24px",
                                                    }}
                                                >
                                                    <p>
                                                        {routeData.StartOdo ? (
                                                            "Start: " +
                                                            routeData.StartOdo +
                                                            " km"
                                                        ) : (
                                                            <>
                                                                No Start
                                                                Odometer could
                                                                be registred
                                                            </>
                                                        )}
                                                    </p>
                                                    <p>
                                                        {routeData.StopOdo ? (
                                                            "Stop: " +
                                                            routeData.StopOdo +
                                                            " km"
                                                        ) : (
                                                            <>
                                                                No Stop Odometer
                                                                could be
                                                                registred
                                                            </>
                                                        )}
                                                    </p>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Drawer>
                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        loggedInUser: state.login.loginUser,
    };
};

export default connect(mapStateToProps)(CompletedTrips);
