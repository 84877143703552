import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Radio,
  Alert,
  Popover,
  Checkbox,
  Popconfirm
} from "antd";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import React from "react";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import "../../assets/css/calender.css";
import * as moment from "moment";
import _filter from "lodash/filter";
import _findIndex from "lodash/findIndex";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;
const { form } = Form;

class Calender extends React.Component {
  calendarComponentRef = React.createRef();

  state = {
    setIsModalVisible: false,
    setIsSecModalVisible: false,
    isDeleteModalVisible: false,
    selectfromSmallCal: "",
    selectedDate: "",
    selectedDateArr: [],
    copySelectedDateArr: [],
    pubNotifiVal: "",
    fromDate: "",
    toDate: "",
    company: "",
    vehicle: "",
    user: "",
    travel: "",
    reminderemail: "",
    remindertime: "",
    notes: "",
    eventId: ""
  };
  formRef = React.createRef();

  // show booking vehicle modal
  showModal = value => {
    this.setState({ setIsModalVisible: true, selectedDate: value._d });
  };

  //show Review vehicle booking
  showSecModal = () => {
    this.setState({ setIsSecModalVisible: true });
  };

  // calls when click on confirm button in show Review vehicle booking modal
  handleOkSec = () => {
    if (this.state.eventId === "") {
      //add new event
      const eventObj = {
        id: this.state.selectedDateArr.length + 1,
        date: moment(this.state.selectedDate).format("D-MM"),
        fromDate: moment(this.state.fromDate).format("YYYY-MM-DD LT"),
        toDate: moment(this.state.toDate).format("YYYY-MM-DD LT"),
        company: this.state.company,
        user: this.state.user,
        notes: this.state.notes,
        reminderemail: this.state.reminderemail,
        reminderinput: this.state.reminderinput,
        remindertime: this.state.remindertime,
        travel: this.state.travel,
        vehicle: this.state.vehicle,
        pubNotifiVal: this.state.pubNotifiVal
      };
      this.setState({
        selectedDateArr: [...this.state.selectedDateArr, eventObj],
        copySelectedDateArr: [...this.state.copySelectedDateArr, eventObj]
      });
    } else {
      //update event details
      let newElementArr = [
        {
          id: this.state.eventId,
          date: this.state.selectedDate,
          fromDate: moment(this.state.fromDate).format("YYYY-MM-DD"),
          toDate: moment(this.state.toDate).format("YYYY-MM-DD"),
          company: this.state.company,
          user: this.state.user,
          notes: this.state.notes,
          reminderemail: this.state.reminderemail,
          reminderinput: this.state.reminderinput,
          remindertime: this.state.remindertime,
          travel: this.state.travel,
          vehicle: this.state.vehicle,
          pubNotifiVal: this.state.pubNotifiVal
        }
      ];

      const result = this.state.selectedDateArr.map(x => {
        const item = newElementArr.find(({ id }) => id === x.id);
        return item ? item : x;
      });
      const resultcopy = this.state.copySelectedDateArr.map(x => {
        const item = newElementArr.find(({ id }) => id === x.id);
        return item ? item : x;
      });
      this.setState({
        selectedDateArr: result,
        copySelectedDateArr: resultcopy
      });
    }
    this.formRef.current.resetFields();
    this.setState({
      setIsSecModalVisible: false,
      setIsModalVisible: false,
      eventId: "",
      pubNotifiVal: "",
      fromDate: "",
      toDate: "",
      company: "",
      vehicle: "",
      user: "",
      travel: "",
      reminderemail: "",
      reminderinput: "",
      remindertime: "",
      notes: ""
    });
  };

  // calls when click on change button in show Review vehicle booking modal
  handleCancelSec = () => {
    this.setState({ setIsSecModalVisible: false });
  };

  // calls when submiting form in booking vehicle modal
  onFinish = data => {
    this.setState({
      fromDate: moment(data.bookedDate[0]).format("YYYY-MM-DD LT"),
      toDate: moment(data.bookedDate[1]).format("YYYY-MM-DD LT")
    });

    if (data) {
      this.showSecModal();
    }
  };

  // calls when getting any error on submitting form in booking vehicle modal
  onFinishFailed = errorInfo => {
    return (
      <Alert message="Error" description={errorInfo} type="error" showIcon />
    );
  };

  handleOk = () => {
    this.setState({ setIsModalVisible: false });
  };

  // closing booking vehicle modal
  handleCancel = () => {
    this.formRef.current.resetFields();
    this.setState({
      setIsModalVisible: false,
      eventId: "",
      pubNotifiVal: "",
      fromDate: "",
      toDate: "",
      company: "",
      vehicle: "",
      user: "",
      travel: "",
      reminderemail: "",
      remindertime: "",
      notes: ""
    });
  };

  //serching value on select inputs
  onSearch = value => {};

  // call on change of all select inputs
  onchangeselect = (e, key) => {
    this.setState({ [key]: e });
  };

  // on change input
  onchange = (e, key) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // on change of radio button
  onChangeRadio = e => {
    this.setState({ pubNotifiVal: e.target.value });
  };

  //on change of dates (range picker)
  onChangeDate = (dates, dateStrings) => {
    this.setState({ fromDate: dateStrings[0], toDate: dateStrings[1] });
  };

  // event display
  formatEvents() {
    return this.state.copySelectedDateArr.map(booking => {
      return {
        id: booking.id,
        title: booking.vehicle.substring(0, 6),
        start: new Date(booking.fromDate),
        end: new Date(booking.toDate)
      };
    });
  }

  //method on datepicker change
  handleDateClick = arg => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate(arg.dateStr);
    var jumtoselected = calendarApi.getDate(arg.dateStr);
    console.log("jumtoselected", jumtoselected);
    this.setState({ selectfromSmallCal: jumtoselected });
  };

  // method on event click
  handleEventClick = ({ event }) => {
    this.state.selectedDateArr.map(items => {
      if (JSON.stringify(items.id) === event._def.publicId) {
        this.setState({
          pubNotifiVal: items.pubNotifiVal,
          fromDate: items.fromDate,
          toDate: items.toDate,
          company: items.company,
          vehicle: items.vehicle,
          user: items.user,
          travel: items.travel,
          reminderemail: items.reminderemail,
          reminderinput: items.reminderinput,
          remindertime: items.remindertime,
          notes: items.notes,
          eventId: items.id,
          setIsModalVisible: true
        });
      }
    });
    this.formRef.current.setFieldsValue({
      pubNotifiVal: this.state.pubNotifiVal,
      bookedDate: [
        moment(moment(this.state.fromDate).format("YYYY-MM-DD")),
        moment(moment(this.state.toDate).format("YYYY-MM-DD"))
      ],
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      company: this.state.company,
      vehicle: this.state.vehicle,
      user: this.state.user,
      travel: this.state.travel,
      reminderemail: this.state.reminderemail,
      reminderinput: this.state.reminderinput,
      remindertime: this.state.remindertime,
      notes: this.state.notes,
      eventId: this.state.id
    });
  };

  // method pn event drop
  handleEventDrop = info => {
    let newFromDate = moment(info.event._instance.range.start).format(
      "YYYY-MM-DD"
    );
    let newToDate = moment(info.event._instance.range.end).format("YYYY-MM-DD");
    let newElementArr = [];
    const dragResult = this.state.selectedDateArr.map(x => {
      if (JSON.stringify(x.id) === info.event._def.publicId) {
        x.fromDate = newFromDate;
        x.toDate = newToDate;
        newElementArr.push(x);
      }
      const item = newElementArr.find(({ id }) => id === x.id);
      return item ? item : x;
    });
    this.setState({ selectedDateArr: dragResult });
  };

  // method on update event
  updateEvent = value => {
    const selectedMainArr = this.state.selectedDateArr;
    const selectedCopyArr = this.state.copySelectedDateArr;
    const index = _findIndex(selectedCopyArr, o => {
      return o.vehicle.substring(0, 6) === value;
    });
    if (index > -1) {
      // remove
      const filterData = _filter(selectedCopyArr, o => {
        return o.vehicle.substring(0, 6) !== value;
      });
      this.setState({
        copySelectedDateArr: filterData
      });
    } else {
      // add
      const filterData = _filter(selectedMainArr, o => {
        return o.vehicle.substring(0, 6) === value;
      });
      this.setState({
        copySelectedDateArr: [...this.state.copySelectedDateArr, ...filterData]
      });
    }
  };

  // on change check box
  onChangecheckbox = checkedValues => {
    this.updateEvent(checkedValues);
  };

  // modal show on delete event
  showdeleteEvent = () => {
    this.setState({ isDeleteModalVisible: true });
  };

  // method on event delete cancle
  deleteHandleCancel = () => {
    this.setState({ isDeleteModalVisible: false });
  };

  // method on confirm delete event
  deleteHandleOk = () => {
    let eventid = this.state.eventId;
    const eventsupdated = this.state.selectedDateArr.filter(
      prod => prod.id !== eventid
    );
    this.setState({
      selectedDateArr: eventsupdated,
      copySelectedDateArr: eventsupdated,
      setIsModalVisible: false,
      eventId: "",
      isDeleteModalVisible: false
    });
    this.formRef.current.resetFields();
  };

  render() {
    const { fromDate, toDate, vehicle, notes } = this.state;
    const rangeFromDate = moment(fromDate).format("YYYY-MM-DD");
    const rangeToDate = moment(toDate).format("YYYY-MM-DD");
    const dateFormat = "YYYY-MM-DD";
    return (
      <div className="dash-calender">
        <Row gutter={[16, { xs: 16, sm: 16, lg: 0 }]}>
          <Col xs={24} md={8} lg={6}>
            {/* <div className="site-calendar-demo-card inset-box-shadowed-effect p-4 ">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                editable={true}
                dateClick={this.handleDateClick}
                defaultDate="2021-04-15"
                selectable={true}
              />
            </div> */}
            <div className="Bookedcheck site-calendar-demo-card inset-box-shadowed-effect p-4 ">
              <h4>Booked Vehicle</h4>
              <Checkbox.Group
                style={{ width: "100%" }}
                defaultValue={[
                  "AUB027",
                  "DNR920",
                  "YHM340",
                  "HOL479",
                  "YMX767"
                ]}
              >
                <Row>
                  <Col span={24}>
                    <Checkbox
                      value="AUB027"
                      onChange={e => this.onChangecheckbox(e.target.value)}
                    >
                      AUB027
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="DNR920"
                      onChange={e => this.onChangecheckbox(e.target.value)}
                    >
                      DNR920
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="HOL479"
                      onChange={e => this.onChangecheckbox(e.target.value)}
                    >
                      HOL479
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="YHM340"
                      onChange={e => this.onChangecheckbox(e.target.value)}
                    >
                      YHM340
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="YMX767"
                      onChange={e => this.onChangecheckbox(e.target.value)}
                    >
                      YMX767
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
          </Col>
          <Col xs={24} md={16} lg={18}>
            <div className="inset-box-shadowed-effect p-4 cal-max-height right-calender">
              <FullCalendar
                id="bigcalender"
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                selectable={true}
                initialView="dayGridMonth"
                events={this.formatEvents()}
                dateClick={this.showModal}
                editable={true}
                eventClick={this.handleEventClick}
                eventDrop={this.handleEventDrop}
                ref={this.calendarComponentRef}
              />
            </div>
          </Col>
        </Row>
        <Modal
          title="Confirm"
          visible={this.state.isDeleteModalVisible}
          cancelText="No"
          okText="Yes"
          onOk={this.deleteHandleOk}
          onCancel={this.deleteHandleCancel}
        >
          Are you sure you want to delete this booking?
        </Modal>
        <Modal
          className="bookvehicle_modal"
          title="Book vehicle"
          visible={this.state.setIsModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          centered
        >
          {this.state.eventId !== "" ? (
            <Button className="deletebtn" onClick={this.showdeleteEvent}>
              <DeleteOutlined />
            </Button>
          ) : (
            ""
          )}
          <Form
            name="Booking_form"
            layout="vertical"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
          >
            <Form.Item
              label="From to"
              rules={
                this.state.fromDate || this.state.fromDate === ""
                  ? [
                      {
                        required: true,
                        message: "Please Enter Date"
                      }
                    ]
                  : []
              }
              name="bookedDate"
            >
              <RangePicker
                size="small"
                onChange={this.onChangeDate}
                name="bookedDate"
                format="YYYY-MM-DD"
                disabledDate={current => {
                  return current && current < moment().startOf("day");
                }}
              />
            </Form.Item>
            <Form.Item label="Select companies" name="company">
              <Select
                size="small"
                showSearch
                placeholder="Select a companies"
                optionFilterProp="children"
                onChange={e => this.onchangeselect(e, "company")}
                name="company"
                onSearch={this.onSearch}
                value={this.state.company}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Bilpool">Bilpool</Option>
                <Option value="Filial Nord test">Filial Nord test</Option>
                <Option value="Filial Syd">Filial Syd</Option>
                <Option value="Fiskeback">Fiskeback</Option>
                <Option value="Granna">Granna</Option>
                <Option value="JIRICOM AB">JIRICOM AB</Option>
                <Option value="JIRICOM AF Kund Mall">
                  JIRICOM AF Kund Mall
                </Option>
                <Option value="JIRICOM AF Mall">JIRICOM AF Mall</Option>
                <Option value="Kontor Nord">Kontor Nord</Option>
                <Option value="L Fiskeback">L Fiskeback</Option>
                <Option value="Region Syd">Region Syd</Option>
                <Option value="Syd Privet">Syd Privet</Option>
                <Option value="Syd tjanst">Syd tjanst</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Select vehicle"
              rules={[
                {
                  required: true,
                  message: "Please Enter Vehicle"
                }
              ]}
              name="vehicle"
            >
              <Select
                size="small"
                showSearc
                placeholder="Select a Vehicle"
                optionFilterProp="children"
                onChange={e => this.onchangeselect(e, "vehicle")}
                name="vehicle"
                value={this.state.vehicle}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="AUB027 - Volvo 245-883 GL 1990">
                  AUB027 - Volvo 245-883 GL 1990
                </Option>
                <Option value="DNR920 - Volkswagen Passat Alltrack 2.0 TDi 4Motion 2012">
                  DNR920 - Volkswagen Passat Alltrack 2.0 TDi 4Motion 2012
                </Option>
                <Option value="HOL479 - Audi A4 Sedan 2.0 TDI 2008">
                  HOL479 - Audi A4 Sedan 2.0 TDI 2008
                </Option>
                <Option value="YHM340 - Audi A1 Sportback 1.0 TFSI 2016">
                  YHM340 - Audi A1 Sportback 1.0 TFSI 2016
                </Option>
                <Option value="YMX767 - Porsche Macan 2017">
                  YMX767 - Porsche Macan 2017
                </Option>
              </Select>
            </Form.Item>
            <Form.Item label="Select User" name="user">
              <Select
                size="small"
                showSearch
                initialValues="Narola Dev"
                placeholder="Select a User"
                optionFilterProp="children"
                onChange={e => this.onchangeselect(e, "user")}
                value={this.state.user}
                name="user"
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Edit Dahl">Edit Dahl</Option>
                <Option value="Gunnar Dahl">Gunnar Dahl</Option>
                <Option value="JIBS Student account">
                  JIBS Student account
                </Option>
                <Option value="Jimmy Thornlof">Jimmy Thornlof</Option>
                <Option value="Jimmy EM">Jimmy EM</Option>
                <Option value="Joakim Nodfelt">Joakim Nodfelt</Option>
                <Option value="Kund AB">Kund AB</Option>
                <Option value="Linus Test">Linus Test</Option>
                <Option value="Linus Testar">Linus Testar</Option>
                <Option value="Linus Hacker">Linus Hacker</Option>
                <Option value="Linus RFID">Linus RFID</Option>
                <Option value="Narola Dev">Narola Dev</Option>
                <Option value="Rick">Rick </Option>
                <Option value="Rickard F">Rickard F</Option>
                <Option value="Test Drive">Test Drive</Option>
                <Option value="Test Test">Test Test</Option>
                <Option value="Test Testar">Test Testar</Option>
                <Option value="Test4 4">Test4 4</Option>
                <Option value="Testar exchange">Testar exchange</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Travel purpose" name="travel">
              <Select
                size="small"
                showSearc
                initialValues="Business"
                placeholder="Travel purpose"
                optionFilterProp="children"
                onChange={e => this.onchangeselect(e, "travel")}
                value={this.state.travel}
                name="travel"
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Business">Business</Option>
                <Option value="Private">Private</Option>
                <Option value="refuelling">refuelling</Option>
                <Option value="Client">Client</Option>
              </Select>
            </Form.Item>
            <Form.Item name="remider" label="Reminder" style={{ margin: "0" }}>
              <Input.Group className="cstm_padding-row">
                <Form.Item style={{ width: "33.33%", padding: "0 10px" }}>
                  <Select
                    size="small"
                    initialValues="Email"
                    onChange={e => this.onchangeselect(e, "reminderemail")}
                    value={this.state.reminderemail}
                    style={{ width: "100%" }}
                    name="reminderemail"
                  >
                    <Option value="None">None</Option>
                    <Option value="Email">Email</Option>
                  </Select>
                </Form.Item>
                <Form.Item style={{ width: "66.66%", padding: "0 10px" }}>
                  <Input.Group className="cstm_padding-row_nongutter">
                    <Input
                      size="small"
                      value={this.state.reminderinput}
                      style={{ flex: "1", marginRight: "20px" }}
                      name="reminderinput"
                      onChange={e => this.onchange(e, "reminderinput")}
                    />
                    <Select
                      size="small"
                      initialValues="minutes"
                      onChange={e => this.onchangeselect(e, "remindertime")}
                      value={this.state.remindertime}
                      style={{ flex: "1" }}
                      name="remindertime"
                    >
                      <Option value="minutes">minutes</Option>
                      <Option value="hours">hours</Option>
                      <Option value="days">days</Option>
                      <Option value="weeks">weeks</Option>
                    </Select>
                  </Input.Group>
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item label="Notes" name="notes">
              <TextArea
                rows={3}
                name="notes"
                value={this.state.notes}
                onChange={e => this.onchange(e, "notes")}
              />
            </Form.Item>
            <Form.Item label="Public notification" style={{ margin: "0" }}>
              <Radio.Group
                onChange={this.onChangeRadio}
                name="pubNotifiVal"
                value={this.state.pubNotifiVal && this.state.pubNotifiVal}
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item style={{ textAlign: "center", marginBottom: "0" }}>
              <Button type="primary" htmlType="submit">
                {this.state.eventId !== "" ? "Save" : "Review"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Review vehicle booking"
          visible={this.state.setIsSecModalVisible}
          onOk={this.handleOkSec}
          onCancel={this.handleCancelSec}
          cancelText="Change"
          okText="Confirm"
        >
          <div className="review-table">
            <Row gutter={30}>
              <Col span={9}>
                <label>From</label>
              </Col>
              <Col span={15}>
                <span>{fromDate}</span>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col span={9}>
                <label>Till</label>
              </Col>
              <Col span={15}>
                <span>{toDate}</span>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col span={9}>
                <label>Vehicle</label>
              </Col>
              <Col span={15}>
                <span>{vehicle}</span>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col span={9}>
                <label>Notes</label>
              </Col>
              <Col span={15}>
                <span>{notes}</span>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Calender;
