import React from "react";
import {Alert} from 'antd';

class AlertMessage extends React.Component {
    render() {
        return <>
            <Alert style={{ marginBottom: "10px" }} message={this.props.message} type={this.props.type} closable/>
        </>
    }
}

export default AlertMessage;