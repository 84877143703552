import React, {Component, useState} from 'react';
import Header from '../../components/breadcrumbheader/Header';
import {Button, Card, Col, DatePicker, Form, Input, Row, Select} from "antd";
import {Option} from "antd/es/mentions";
import Checkbox from "antd/es/checkbox/Checkbox";
import { Chart, registerables } from "chart.js";
import {Bar} from "react-chartjs-2";
import "../../assets/css/DegreeOfUtilization.css";
import jsonData from "../../json/utilization.json";

Chart.register(...registerables);

class DegreeOfUtilization extends Component {
    formRef = React.createRef();

    state = {
        redraw: true,
        data: {
            labels: jsonData.Data.LP,
            datasets: [
                {
                    label: 'Hours',
                    data: jsonData.Data.Hours,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Travels',
                    data: jsonData.Data.Travels,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Km',
                    data: jsonData.Data.Km,
                    backgroundColor: 'rgba(255, 159, 64, 0.2)',
                    borderColor: 'rgba(255, 159, 64, 1)',
                    borderWidth: 1,
                },
            ],
        }
    }

    componentDidMount() {
        console.log(this.chartReference);
    }

    routes = [
        {
            path: '',
            breadcrumbName: 'Analysis',
        },
        {
            path: '',
            breadcrumbName: 'Degree of Utilization',
        },
    ];


    options = {
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: 'Average usage on vehicles',
            },
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';

                    if (label) {
                        label += ': ';
                    }
                    label += Math.round(tooltipItem.yLabel * 100) / 100;
                    return label;
                }
            }
        }
    };

    onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    }
    onFinish = (values) => {
        console.log("values", values);
        const index = [];
        const label = [];
        const hours = [];
        const travels = [];
        const km = [];
        if (values.vehicles.length > 0) {
            values.vehicles.map((k, i) => {
                var key = jsonData.Data.LP.findIndex(label => label === k);
                index.push(key);
            })

            index.sort(function (a, b) {
                return a - b;
            });
            index.map((k, i) => {
                label.push(jsonData.Data.LP[k]);
                hours.push(jsonData.Data.Hours[k]);
                travels.push(jsonData.Data.Travels[k]);
                km.push(jsonData.Data.Km[k]);
            })

            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    labels: label,
                    datasets: [
                        {
                            label: 'Hours',
                            data: hours,
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 1,
                        },
                        {
                            label: 'Travels',
                            data: travels,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                            borderWidth: 1,
                        },
                        {
                            label: 'Km',
                            data: km,
                            backgroundColor: 'rgba(255, 159, 64, 0.2)',
                            borderColor: 'rgba(255, 159, 64, 1)',
                            borderWidth: 1,
                        }
                    ]
                }
            }));
        } else {
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    labels: jsonData.Data.LP,
                    datasets: [
                        {
                            label: 'Hours',
                            data: jsonData.Data.Hours,
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 1,
                        },
                        {
                            label: 'Travels',
                            data: jsonData.Data.Travels,
                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                            borderWidth: 1,
                        },
                        {
                            label: 'Km',
                            data: jsonData.Data.Km,
                            backgroundColor: 'rgba(255, 159, 64, 0.2)',
                            borderColor: 'rgba(255, 159, 64, 1)',
                            borderWidth: 1,
                        },
                    ]
                }
            }));
        }
    }

    render() {
        const {RangePicker} = DatePicker;
        console.log("jsonData", jsonData);
        return (
            <div className="functionPagediv">
                <Header routes={this.routes} filter={false} floating={true} form_id={"degreeOfUtilization"}>
                    <Form
                        name="degreeOfUtilization"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        id={"degreeOfUtilization"}
                    >
                        <Row gutter={[16, 20]}>

                            <Col className="gutter-row label-div" span={24}>
                                <label style={{marginRight: "7px"}}>From and To: </label>
                                <RangePicker showTime/>
                            </Col>
                            <Col className="gutter-row label-div" span={24}>
                                <label style={{marginRight: "7px"}}>Days: </label>
                                <Checkbox onChange={this.onChange}>Monday</Checkbox>
                                <Checkbox onChange={this.onChange}>Tuesday</Checkbox>
                                <Checkbox onChange={this.onChange}>Wednesday</Checkbox>
                                <Checkbox onChange={this.onChange}>Thursday</Checkbox>
                                <Checkbox onChange={this.onChange}>Friday</Checkbox>
                                <Checkbox onChange={this.onChange}>Saturday</Checkbox>
                                <Checkbox onChange={this.onChange}>Sunday</Checkbox>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Form.Item name="vehicles">

                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: "100%"}}
                                        showSearch
                                        placeholder="Select vehicles"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="AU2TRACK">AU2TRACK</Option>
                                        <Option value="BRJ181">BRJ181</Option>
                                        <Option value="DNR920">DNR920</Option>
                                        <Option value="YHM340">YHM340</Option>
                                        <Option value="YMX767">YMX767</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    showSearch
                                    placeholder="Responsibility number"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="ABC123">ABC123</Option>
                                    <Option value="ABC456">ABC456</Option>
                                    <Option value="ABC789">ABC789</Option>
                                </Select>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    showSearch
                                    placeholder="Select companies"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="All Companies">All Companies</Option>
                                    <Option value="Bilpool">Bilpool</Option>
                                    <Option value="Filial Nord Test">Filial Nord Test</Option>
                                    <Option value="Filial Syd">Filial Syd</Option>
                                </Select>
                            </Col>
                        </Row>
                    </Form>
                </Header>

                <div className="main-wrapper">
                    <Card title="Degree of Utilization">
                        <div className="site-card-wrapper">
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Card bordered={false} className='card_body travel-card'>
                                        <div>Number of travels</div>
                                        <div className={'card_header'}>{jsonData.TotTravels}</div>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card className='card_body hours-card' bordered={false}>
                                        <div>Average hours</div>
                                        <div className={'card_header'}>{jsonData.MeanHr}</div>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card className='card_body distance-card' bordered={false}>
                                        <div>Average distance (km)</div>
                                        <div className={'card_header'}>{jsonData.AvgDist}km</div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <Bar data={this.state.data} options={this.options} key={Math.random()}/>
                    </Card>
                </div>

            </div>
        );
    }
}

export default DegreeOfUtilization;
