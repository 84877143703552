import React from "react";
import { ThunderboltOutlined,EnvironmentOutlined  } from "@ant-design/icons";
/* CHECKED */
export const vehicleStatusData = [ 																																																																																									
    {key: "1",licensePlate: "ABB123", brand: "Lynx", model: "Boondocker 3900 600", year: "2014", km: "0", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "2",licensePlate: "AU2FIX", brand: "Jiricom", model: "au2fix", year: "2020", km: "2274", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "98", statusConnection:"connected"	},
    {key: "3",licensePlate: "AU2TRACK", brand: "Jiricom", model: "au2track", year: "2020", km: "0", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "9000", statusConnection:"not connected"	},
    {key: "4",licensePlate: "AUB027", brand: "Volvo", model: "245-883 GL", year: "1990", km: "89752", company: "Bilpool", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "5",licensePlate: "BMA350", brand: "Volkswagen", model: "Caddy 2.0 TDi", year: "2013", km: "124254", company: "Test", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "6",licensePlate: "BRJ181", brand: "Saab", model: "9-3", year: "2011", km: "17703", company: "Gränna", respNum: "asdf", lastDriver: "Test Testar", deviceID: "10116", statusConnection:"connected"},
    {key: "7",licensePlate: "CNN527", brand: "BMW", model: "525d Touring", year: "2006", km: "11089", company: "Test", respNum: "", lastDriver: "", deviceID: "8885", statusConnection:"not connected"	},
    {key: "8",licensePlate: "CYN543", brand: "Volkswagen", model: "Golf Variant 1.6 TDi BlueMotion", year: "2015", km: "84421", company: "JIRICOM AB", respNum: "fasdf", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "9",licensePlate: "DNR920", brand: "Volkswagen", model: "Passat Alltrack 2.0 TDi 4Motion", year: "2012", km: "126119", company: "Gränna", respNum: "", lastDriver: "", deviceID: "8886", statusConnection:"not connected"	},
    {key: "10",licensePlate: "DNR921", brand: "Mercedes-Benz", model: "C 220 d", year: "2015", km: "63663", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "11",licensePlate: "GCE800", brand: "BMW", model: "M5 Sedan", year: "2018", km: "0", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "12",licensePlate: "GCZ612", brand: "Opel", model: "Vectra GT 872D3", year: "1992", km: "270546", company: "L Fiskebäck", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "13",licensePlate: "GRANDTOTAL", brand: "", model: "", year: "0", km: "1486", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "3369", statusConnection:"connected"	},
    {key: "14",licensePlate: "HEJ124", brand: "Saab", model: "9-5", year: "2009", km: "125", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "15",licensePlate: "HOL479", brand: "Audi", model: "A4 Sedan 2.0 TDI", year: "2008", km: "51307", company: "Bilpool", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "16",licensePlate: "JCN555", brand: "Volvo", model: "V70 D5", year: "2011", km: "102894", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "17",licensePlate: "KMB959", brand: "Opel", model: "Astra 1.6", year: "2008", km: "10003", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "18",licensePlate: "KYJ680", brand: "Volkswagen", model: "Golf GTD 2.0", year: "2010", km: "140000", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "19",licensePlate: "LBD581", brand: "Volvo", model: "S80 1.6 D DRIVe", year: "2010", km: "221150", company: "Kontor Nord", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "20",licensePlate: "LCM382", brand: "Hyundai", model: "IX35 1.6", year: "2010", km: "110777", company: "JIRICOM AB", respNum: "1234", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "21",licensePlate: "LCM383", brand: "Hyundai", model: "IX35 1.6", year: "2010", km: "2703", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "99", statusConnection:"not connected"	},
    {key: "22",licensePlate: "LCM384", brand: "Hyundai", model: "IX35 1.6", year: "2010", km: "1192", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "23",licensePlate: "LCM390", brand: "Brenderup", model: "CER", year: "2016", km: "30025", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},																																		                  
    {key: "24",licensePlate: "LSR567", brand: "Volvo", model: "V70 D4", year: "2015", km: "5434550", company: "Filial Nord test", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "25",licensePlate: "MSA297", brand: "BMW", model: "520d Touring", year: "2016", km: "1980", company: "Region Syd", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "26",licensePlate: "NPX910", brand: "Volkswagen", model: "Golf Variant 1.2 TSI", year: "2016", km: "30001", company: "Region Syd", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "27",licensePlate: "OYE884", brand: "BMW", model: "X3 xDrive30d", year: "2015", km: "653453", company: "Filial Syd", respNum: "", lastDriver: "Linus Test", deviceID: "64", statusConnection:"not connected"	},
    {key: "28",licensePlate: "PGF849", brand: "Seat", model: "Leon ST 1.2 TSI", year: "2016", km: "45742", company: "JIRICOM AB", respNum: "", lastDriver: "Rick", deviceID: "80", statusConnection:""	},
    {key: "29",licensePlate: "PRG510", brand: "Audi", model: "A7 Sportback 3.0 TDI quattro", year: "2013", km: "47611", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:"not connected"	},
    {key: "30",licensePlate: "PRX972", brand: "Volkswagen", model: "Crafter 2.0 TDi", year: "2014", km: "7138", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "31",licensePlate: "RRF213", brand: "Volvo", model: "V40 2.0 T", year: "2000", km: "199240", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "32",licensePlate: "RRF364", brand: "Kawasaki", model: "EX 500 A", year: "1987", km: "2422", company: "Fiskebäck", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "33",licensePlate: "RRF365", brand: "Mazda", model: "626 2.0", year: "2000", km: "900", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "34",licensePlate: "STC344", brand: "Opel", model: "Astra 1.8", year: "2000", km: "0", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "35",licensePlate: "TES123", brand: "Tgab", model: "X1000", year: "2002", km: "2", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "36",licensePlate: "TZC014", brand: "BMW", model: "520d Touring", year: "2016", km: "0", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    																														
    {key: "37",licensePlate: "XZX160", brand: "Audi", model: "A4 Avant 2.0 TDI quattro", year: "2006", km: "12987", company: "JIRICOM AB", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "38",licensePlate: "YHM340", brand: "Audi", model: "A1 Sportback 1.0 TFSI", year: "2016", km: "60227", company: "Fiskebäck", respNum: "1234", lastDriver: "", deviceID: "10149", statusConnection:"connected"	},
    {key: "39",licensePlate: "YMX006", brand: "Mazda", model: "CX-5 2.2 D AWD", year: "2017", km: "0", company: "Test", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "40",licensePlate: "YMX007", brand: "Mazda", model: "CX-5 2.2 D AWD", year: "2017", km: "0", company: "Test", respNum: "", lastDriver: "", deviceID: "", statusConnection:""	},
    {key: "41",licensePlate: "YMX767", brand: "Porsche", model: "Macan", year: "2017", km: "49216", company: "Fiskebäck", respNum: "", lastDriver: "8887", deviceID: "", statusConnection:"connected"},
    {key: "42",licensePlate: "YRX950", brand: "Audi", model: "Q2 1.4 TFSI", year: "2017", km: "41679", company: "JIRICOM AB", respNum: "2562", lastDriver: "", deviceID: "", statusConnection:"not connected"}]


export const selectTags = [
    {id: "1", content: "License plate" },
    {id: "2", content: "Brand" },
    {id: "3", content: "Model" },
    {id: "4", content: "Year" },
    {id: "5", content: "km" },
    {id: "6", content: "Odometer this year" },
    {id: "7", content: "Vehicle company" },
    {id: "8", content: "Responsibility number" },
    {id: "9", content: "Company 1" },
    {id: "10", content: "Company 2" },
    {id: "11", content: "Company 3" },
    {id: "12", content: "Last inspection" },
    {id: "13", content: "Last maintenance" },
    {id: "14", content: "Sale price" },
    {id: "15", content: "Sales date" },
    {id: "16", content: "Device" },
    {id: "17", content: "Total driving time (days, hours)" },
    {id: "18", content: "Number of travels" },
    {id: "19", content: "Total traveltime (h)" },
    {id: "20", content: "First name" },
    {id: "21", content: "Last name" },
    {id: "22", content: "Title" },
    {id: "23", content: "Reference code" },
    {id: "24", content: "Phone" },
    {id: "25", content: "Mobile phone" },
    {id: "26", content: "Email" },
    {id: "27", content: "DriverID (RFID)" },
    {id: "28", content: "Vehicle manager First name" },
    {id: "29", content: "Vehicle manager Last name" },
    {id: "30", content: "Vehicle manager Title" },
    {id: "31", content: "Vehicle manager Reference code" },
    {id: "32", content: "Vehicle manager Phone" },
    {id: "33", content: "Vehicle manager Mobile phone" },
    {id: "34", content: "Vehicle manager Email" },
    {id: "35", content: "Vehicle manager DriverID (RFID)" },
    {id: "36", content: "Tillverkningsdatum" },
    {id: "37", content: "Först registrerad" },
    {id: "38", content: "Trafik i Sverige" },
    {id: "39", content: "Skattemånad" },
    {id: "40", content: "Motoreffekt" },
    {id: "41", content: "Max hastighet (km/h)" },
    {id: "42", content: "Drivmedel" },
    {id: "43", content: "Motorvolym" },
    {id: "44", content: "Bränsleförbrukning, blandad körning (l)" },
    {id: "45", content: "Koldioxid, CO2 blandad körning" },
    {id: "46", content: "Kväveoxider, NOx blandad körning" },
    {id: "47", content: "Kolväten och kväveoxider, THC + NOx" },
    {id: "48", content: "Växellåda" },
    {id: "49", content: "Fyrhjulsdrift" },
    {id: "50", content: "Ljudnivå dB, vid körning" },
    {id: "51", content: "Antal passagerare, max" },
    {id: "52", content: "Färg" },
    {id: "53", content: "Kaross" },
    {id: "54", content: "Längd" },
    {id: "55", content: "Bredd" },
    {id: "56", content: "Höjd" },
    {id: "57", content: "Tjänstevikt (faktisk vikt)" },
    {id: "58", content: "Totalvikt" },
    {id: "59", content: "Lastvikt" },
    {id: "60", content: "Släpvagnsvikt" },
    {id: "61", content: "Däckdimension fram" },
    {id: "62", content: "Däckdimension bak" },
    {id: "63", content: "Senast besiktigad" },
    {id: "64", content: "Senaste ägarbyte" },
    {id: "65", content: "Årlig skatt" },
    {id: "66", content: "Fälgdimension fram" },
    {id: "67", content: "Fälgdimension fram" },
    {id: "68", content: "Max axelavstånd axel 1-2" },
    {id: "69", content: "Nästa besiktning" },
    {id: "70", content: "Besiktning Mätars. (km)" },
    {id: "71", content: "CO2 WTW" },
    {id: "72", content: "Total inköpspris, kr" },
    {id: "73", content: "Egen insats, kr" },
    {id: "74", content: "Lån, kr" },
    {id: "75", content: "Ränta, %" },
    {id: "76", content: "Återbetalningstid, månader" },
    {id: "77", content: "Månadskostnad, kr" },
    {id: "78", content: "Värdeminskning, kr/månad" },
    {id: "79", content: "Total inköpspris, kr" },
    {id: "80", content: "Förhöjd första avgift, kr" },
    {id: "81", content: "Ränta, %" },
    {id: "82", content: "Leasingtid, månader" },
    {id: "83", content: "Månadskostnad, kr" },
    {id: "84", content: "Restvärde, kr" },
    {id: "85", content: "Försäkringsbolag" },
    {id: "86", content: "Försäkringstyp" },
    {id: "87", content: "Maskinförsäkring, km" },
    {id: "88", content: "Vagnskadeförsäkring" },
    {id: "89", content: "Självrisk" },
    {id: "90", content: "Försäkringskostnad, kr/månad" },
    {id: "91", content: "Avtalsperiod, månader" },
    {id: "92", content: "Serviceintervall, km" },
    {id: "93", content: "Serviceintervall, månader" },
    {id: "94", content: "Månadskostnad, kr" },
    {id: "95", content: "Fordonskattepliktigt" },
    {id: "96", content: "Årsskatt, kr" },
    {id: "97", content: "Nybilsgaranti, månader" },
    {id: "98", content: "Vagnskadegaranti, månader" },
    {id: "99", content: "Drivemedelkostnad per liter, kr" },
    {id: "100", content: "CO2 WTW per liter, kg" },
    {id: "101", content: "Mätarställning, km" },
    {id: "102", content: "Månader" },
    {id: "103", content: "Över hastighetsgräns (%)" },
]
