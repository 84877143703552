import React, { useState, useEffect, useMemo } from "react";
import {
    Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
} from "antd";
import * as Apis from "../../../utils/api";
import * as organisationActions from "../../../actions/organisations/organisations";
import { findOrganisationNamePath } from "../../../reducer/organisations/organisationReducer";
import { useDispatch, useSelector, connect } from "react-redux";
import { openNotificationWithIcon } from "../../common/function";

const UserForm = (props) => {
    const formRef = React.createRef();
    const dispatch = useDispatch();

    const [accessTemplatelist, setAccessTemplateslist] = useState([]); //can be recivsers
    const { Option } = Select;
    const { TreeNode } = TreeSelect;
    const [treevalue, setTreeValue] = useState(undefined);
    const [organisationId, setOrganisationId] = useState(0);
    const [companys, setCompanys] = useState([]);

    const onTreeChange = (newValue) => {
        setTreeValue(newValue);
    };

    const availableOrganisations = useSelector(
        (state) => state.organisations.antdCascaderOptionsOrganisations
    );
    const [currentOrgsPath, setCurrentOrgsPath] = useState([]);

    //FASTER WAY TO DO APICALLS!
    useEffect(() => {
        resetForm();
        console.log(
            "useeffect formRef.current",
            formRef.current,
            "props.id ",
            props.id
        );

        if (props.id && formRef?.current) {
            console.log(props.id);
            Apis.getUserData(props.id)
                .then((res) => {
                    if (res.Status == "NOK") {
                        console.log("error");
                    } else {
                        console.log("useeffect userdata", res.Userdata[0]);
                        formRef.current.setFieldsValue({
                            firstName: res.Userdata[0].FirstName,
                            lastName: res.Userdata[0].LastName,
                            title: res.Userdata[0].Title,
                            accessTemplateId: res.Userdata[0].AccessTemplateId,
                            phone: res.Userdata[0].Phone,
                            cellPhone: res.Userdata[0].CellPhone,
                            rfid: res.Userdata[0].Rfid,
                            reference: res.Userdata[0].Reference,
                            language: res.Userdata[0].Language,
                            organisationIdTree: res.Userdata[0].CompanyID,
                            email: res.Userdata[0].Email,
                        });
                        setOrganisationId(Number(res.Userdata[0].CompanyID));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            console.log("create");
            formRef?.current &&
                formRef.current.setFieldsValue({
                    firstName: props.firstName ? props.firstName : "",
                    lastName: props.lastName ? props.lastName : "",
                    rfid: props.rfid ? props.rfid : "",
                });
        }

        Apis.GetOrgs()
            .then((res) => {
                console.log("API OCOM ", res);
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    setCompanys(res.Companies);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        Apis.GetAccessTemplates()
            .then((res) => {
                if (res.Status == "NOK") {
                    console.log("error");
                } else {
                    console.log(res);
                    const users = [];
                    for (var key in res.Templates) {
                        users.push(
                            <Option key={res.Templates[key]["id"]}>
                                {res.Templates[key]["name"]}
                            </Option>
                        );
                    }
                    setAccessTemplateslist(users);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [props.id, formRef?.current]);

    useEffect(() => {
        if (!availableOrganisations.length) {
            organisationActions.getOrganisations()(dispatch);
        }
        if (!!availableOrganisations.length && organisationId) {
            const currentOrganisationPath =
                findOrganisationNamePath(organisationId, availableOrganisations)
                    .length > 0
                    ? findOrganisationNamePath(
                          organisationId,
                          availableOrganisations
                      ).reverse()
                    : ["User is not connected to any organisation"];
            setCurrentOrgsPath(currentOrganisationPath);
        }
    }, [availableOrganisations.length, organisationId]);

    const onChange = (newValue) => {
        console.log("cascader onchange", newValue);
        setCurrentOrgsPath(newValue);
    };

    const onFormLayoutChange = ({ size }) => {
        //setComponentSize(size);
    };
    const onFinish = (values) => {
        console.log("Received values of form: ", values);
        const newData = {
            cmd: props.id ? "Update" : "Create",
            userId: props.id ? props.id : "",
            firstName: values.firstName ? values.firstName : "",
            lastName: values.lastName ? values.lastName : "",
            title: values.title ? values.title : "",
            accessTemplateId: values.accessTemplateId
                ? values.accessTemplateId
                : "",
            phone: values.phone ? values.phone : "",
            cellPhone: values.cellPhone ? values.cellPhone : "",
            rfid: values.rfid ? values.rfid : "",
            reference: values.reference ? values.reference : "",
            email: values.email ? values.email : "",
            language: values.language ? values.language : "",
            organisationIdTree: currentOrgsPath ? currentOrgsPath : [],
        };
        console.log("userForm will ", props.id ? "Update" : "Create");

        //Updates user
        if (props.id) {
            Apis.updateUser(newData)
                .then((res) => {
                    console.log("from API: ", res);
                    openNotificationWithIcon(
                        "success",
                        `Succesfully updated user ${values.firstName}` +
                            ` ${values.lastName}`,
                        "",
                        "top",
                        5
                    );
                })
                .catch((error) => {
                    console.log(error);
                    openNotificationWithIcon(
                        "error",
                        `Failed to update ${values.firstName}` +
                            ` ${values.lastName}`,
                        "",
                        "top",
                        5
                    );
                });
        }
        //Creates user
        else {
            Apis.createUser()
                .then((res) => {
                    console.log("from API: ", res);
                    openNotificationWithIcon(
                        "success",
                        `Succesfully created user ${values.firstName}` +
                            ` ${values.lastName}`,
                        "",
                        "top",
                        5
                    );
                })
                .catch((error) => {
                    console.log(error);
                    openNotificationWithIcon(
                        "error",
                        `Failed to update ${values.firstName}` +
                            ` ${values.lastName}`,
                        "",
                        "top",
                        5
                    );
                });
        }

        console.log("Received formdata to update/create API: ", newData);
    };
    const resetForm = () => {
        console.log("resetform");
        formRef.current.resetFields();
    };

    console.log(accessTemplatelist);

    return (
        <Form
            ref={formRef}
            name={props.formName ? props.formName : "userForm"}
            id={props.formId ? props.formId : "userForm"}
            labelCol={{
                span: 7,
            }}
            wrapperCol={{
                span: 14,
            }}
            layout="horizontal"
            size={"default"}
            onFinish={onFinish}
        >
            <Form.Item
                label="Firstname"
                name="firstName"
                rules={[
                    { required: true, message: "Please input your Firstname!" },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Lastname"
                name="lastName"
                rules={[
                    { required: true, message: "Please input your Lastname!" },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Title" name="title">
                <Input />
            </Form.Item>
            <Form.Item
                label="Accesstemplate"
                name="accessTemplateId"
                rules={[
                    {
                        required: true,
                        message: "Please Select an accesstemplate!",
                    },
                ]}
            >
                <Select>{accessTemplatelist}</Select>
            </Form.Item>
            <Form.Item label="Telephone" name="phone">
                <Input />
            </Form.Item>
            <Form.Item label="Cellphone" name="cellPhone">
                <Input />
            </Form.Item>
            <Form.Item label="RFID" name="rfid">
                <Input />
            </Form.Item>
            <Form.Item label="Referencecode" name="reference">
                <Input />
            </Form.Item>
            <Form.Item label="Language" name="language">
                <Select defaultValue="0">
                    <Option value="0" selected="">
                        Swedish
                    </Option>
                    <Option value="1">English</Option>
                    <Option value="2">Japanese</Option>
                    <Option value="3">Finnish</Option>
                    <Option value="4">Norwegian</Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Organisation"
                name="organisationIdTree"
                // rules={[
                //     {
                //         type: 'array',
                //         required: true,
                //         message: "Please select an organisation!",
                //     },
                // ]}
            >
                {console.log(
                    "currentOrgsPath ",
                    currentOrgsPath,
                    "availableOrganisations ",
                    availableOrganisations
                )}
                <Cascader
                    defaultValue={currentOrgsPath}
                    options={companys}
                    changeOnSelect
                    value={currentOrgsPath}
                    onChange={onChange}
                />
            </Form.Item>
            {props.id ? (
                <>
                    <Form.Item label="Email" name="email">
                        <Input />
                    </Form.Item>
                </>
            ) : (
                <>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </>
            )}
        </Form>
    );
};

export default UserForm;
