/*
Checked
*/

export const ADDED_WIDGETS = "ADDED_WIDGETS";
export const ALL_WIDGETS = "ALL_WIDGETS";
export const ACTIVED_KEY = "ACTIVED_KEY";
export const ADDED_WIDGETS1 = "ADDED_WIDGETS1";
export const ADDED_WIDGETS2 = "ADDED_WIDGETS2";
export const ADDED_WIDGETS3 = "ADDED_WIDGETS3";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const SELECTED_MENU = "SELECTED_MENU";
export const ALL_FUNC_MENU = "ALL_FUNC_MENU";
export const CHANGE_LANG = "CHANGE_LANG";
export const ENG_LANG_DATA = "ENG_LANG_DATA";
export const SWE_LANG_DATA = "SWE_LANG_DATA";
export const JAP_LANG_DATA = "JAP_LANG_DATA";
export const SEARCHED_VEHI_DATA = "SEARCHED_VEHI_DATA";
export const LOGIN_USER_REQUESTED = "LOGIN_USER_REQUESTED";
export const LOGIN_USER_SUCCEEDED = "LOGIN_USER_SUCCEEDED";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";