import { ENG_LANG_DATA, SWE_LANG_DATA, JAP_LANG_DATA } from "../actionTypes";

let initialState = {
  engLangData: [
    {
      languagekey: "welcomeText",
      languagevalue: "Welcome Linus"
    },
    {
      languagekey: "todaysDateText",
      languagevalue: "Today's Date"
    },
    {
      languagekey: "totaNumOfTravels",
      languagevalue: "Total Number of Travels"
    },
    {
      languagekey: "totalTravelsTodayText",
      languagevalue: "Total Travels Today"
    },
    {
      languagekey: "totalDrivingDisText",
      languagevalue: "Total Driving Distance"
    },
    {
      languagekey: "totalDrivingDistodayText",
      languagevalue: "Total Driving Distance Today (Km)"
    },
    {
      languagekey: "numOfActiveVehiTodayText",
      languagevalue: "Number of Active Vehicles Today"
    },
    {
      languagekey: "numOfTravelsText",
      languagevalue: "Number of Travels"
    },
    {
      languagekey: "totalkmText",
      languagevalue: "Total (km)"
    },
    {
      languagekey: "activeVehiText",
      languagevalue: "Active Vehicles"
    },
    {
      languagekey: "currentAlramText",
      languagevalue: "Current Alarms"
    },
    {
      languagekey: "ongoingTravelsText",
      languagevalue: "Ongoing Travels"
    },
    { languagekey: "table.CurrentAlarm.Level", languagevalue: "Level" },
    { languagekey: "table.CurrentAlarm.Time", languagevalue: "Time" },
    { languagekey: "table.CurrentAlarm.Device", languagevalue: "Device" },
    { languagekey: "table.CurrentAlarm.Vehicle", languagevalue: "Vehicle" },
    { languagekey: "table.CurrentAlarm.Type", languagevalue: "Type" },
    {
      languagekey: "table.OngoingTravels.Travelid",
      languagevalue: "Travel id"
    },
    {
      languagekey: "table.OngoingTravels.Licenseplate",
      languagevalue: "License plate"
    },
    { languagekey: "table.OngoingTravels.Driver", languagevalue: "Driver" },
    { languagekey: "table.OngoingTravels.Start", languagevalue: "Start" },
    {
      languagekey: "table.OngoingTravels.Traveltime",
      languagevalue: "Traveltime"
    },
    { languagekey: "table.OngoingTravels.Distance", languagevalue: "Distance" },
    { languagekey: "bookedVehiText", languagevalue: "Booked Vehicles" },
    {
      languagekey: "addWidgetToDashText",
      languagevalue: "Add Widgets to Dashboard"
    },
    { languagekey: "widgetsText", languagevalue: "Widgets" },
    {
      languagekey: "bookedVehicleDes",
      languagevalue: "Display booked vehicles"
    },
    {
      languagekey: "tableCurrentAlarmDes",
      languagevalue: "Table of current alarms"
    },
    {
      languagekey: "tableOngoingTravelsDes",
      languagevalue: "Table of ongoing travels"
    },
    {
      languagekey: "chartNumOfTravelsDes",
      languagevalue: "Bar chart for number of travels"
    },
    {
      languagekey: "chartTotalKmDes",
      languagevalue: "Bar chart for total(km)"
    },
    {
      languagekey: "chartActiveVehicleDes",
      languagevalue: "Bar chart for active vehicles"
    },
    {
      languagekey: "staticTodaysDateDes",
      languagevalue: "Display today's date"
    },
    {
      languagekey: "staticTotalNumOfTravelsDes",
      languagevalue: "Display total number of travels"
    },
    {
      languagekey: "staticTotalTravelsTodayDes",
      languagevalue: "Display total travels today"
    },
    {
      languagekey: "staticTotalDrivingDisDes",
      languagevalue: "Display total driving distance"
    },
    {
      languagekey: "staticTotalDrivingDisTodayDes",
      languagevalue: "Display total driving distance today (Km)"
    },
    {
      languagekey: "staticNumOfActiveVehiTodayDes",
      languagevalue: "Display number of active vehicles today"
    },
    { languagekey: "addWidgetsBtn", languagevalue: "Add Widgets" },
    { languagekey: "changeLayoutBtn", languagevalue: "Change Layout" },
    { languagekey: "changeDoneBtn", languagevalue: "Changes Done" },
    { languagekey: "menu.Dashboard", languagevalue: "Dashboard" },
    { languagekey: "menu.vehicle", languagevalue: "Vehicle" },
    {
      languagekey: "menu.vehicle.vehicleStatus",
      languagevalue: "Vehicle status"
    },
    {
      languagekey: "menu.vehicle.searchVehicles",
      languagevalue: "Search vehicles"
    },
    {
      languagekey: "menu.vehicle.Currentmeterreadings",
      languagevalue: "Current meter readings"
    },
    {
      languagekey: "menu.vehicle.Batterystatus",
      languagevalue: "Battery status"
    },
    { languagekey: "menu.vehicle.Refueling", languagevalue: "Refueling" },
    { languagekey: "menu.vehicle.Inspections", languagevalue: "Inspections" },
    {
      languagekey: "menu.vehicle.Vehicleservice",
      languagevalue: "Vehicle service"
    },
    {
      languagekey: "menu.vehicle.Vehiclereplacement",
      languagevalue: "Vehicle replacement"
    },
    {
      languagekey: "menu.vehicle.Vehicleconfiguration",
      languagevalue: "Vehicle configuration"
    },
    {
      languagekey: "menu.vehicle.CO2emissions",
      languagevalue: "CO2 emissions"
    },
    { languagekey: "menu.vehiclecard", languagevalue: "Vehicle card" },
    {
      languagekey: "menu.vehiclecard.Vehicleoverview",
      languagevalue: "Vehicle overview"
    },
    { languagekey: "menu.vehiclecard.Basicdata", languagevalue: "Basic data" },
    { languagekey: "menu.vehiclecard.Economy", languagevalue: "Economy" },
    {
      languagekey: "menu.vehiclecard.Maintenance",
      languagevalue: "Maintenance"
    },
    { languagekey: "menu.vehiclecard.System", languagevalue: "System" },
    { languagekey: "menu.vehiclecard.Notes", languagevalue: "Notes" },
    {
      languagekey: "menu.vehiclecard.Certificatetravel",
      languagevalue: "Certificate travel"
    },
    { languagekey: "menu.driver", languagevalue: "Driver" },
    {
      languagekey: "menu.driver.Latestidentifications",
      languagevalue: "Latest identifications"
    },
    {
      languagekey: "menu.driver.Driveroverview",
      languagevalue: "Driver overview"
    },
    {
      languagekey: "menu.driver.Driveranalysis",
      languagevalue: "Driver analysis"
    },
    { languagekey: "menu.positions", languagevalue: "Positions" },
    {
      languagekey: "menu.positions.Currentpositions",
      languagevalue: "Current positions"
    },
    {
      languagekey: "menu.positions.Findthenearestvehicle",
      languagevalue: "Find the nearest vehicle"
    },
    {
      languagekey: "menu.positions.Create/editzones",
      languagevalue: "Create / edit zones"
    },
    {
      languagekey: "menu.positions.Listofzones",
      languagevalue: "List of zones"
    },
    { languagekey: "menu.travel", languagevalue: "Travel" },
    {
      languagekey: "menu.travel.Ongoingtravel",
      languagevalue: "Ongoing travel"
    },
    {
      languagekey: "menu.travel.Completedtrips",
      languagevalue: "Completed trips"
    },
    { languagekey: "menu.travel.Travellog", languagevalue: "Travel log" },
    {
      languagekey: "menu.travel.Congestiontax",
      languagevalue: "Congestion tax"
    },
    { languagekey: "menu.travel.Travelstop", languagevalue: "Travel stop" },
    {
      languagekey: "menu.travel.Travelpurposerules",
      languagevalue: "Travel purpose rules"
    },
    {
      languagekey: "menu.travel.Autoexportdrivingrecords",
      languagevalue: "Autoexport driving records"
    },
    {
      languagekey: "menu.travel.Manuallogbook",
      languagevalue: "Manual logbook"
    },
    { languagekey: "menu.travel.Changeuser", languagevalue: "Change user" },
    {
      languagekey: "menu.travel.Certificatetravel",
      languagevalue: "Certificate travel"
    },
    { languagekey: "menu.reserve", languagevalue: "Reserve" },
    { languagekey: "menu.reserve.Vehicle", languagevalue: "Vehicle" },
    {
      languagekey: "menu.reserve.Vehicleservice",
      languagevalue: "Vehicle service"
    },
    {
      languagekey: "menu.reserve.Bookotherusers",
      languagevalue: "Book other users"
    },
    { languagekey: "menu.alarm", languagevalue: "Alarm" },
    { languagekey: "menu.alarm.Devicealarm", languagevalue: "Device alarm" },
    { languagekey: "menu.alarm.Driveralarm", languagevalue: "Driver alarm" },
    { languagekey: "menu.alarm.Servicealarm", languagevalue: "Service alarm" },
    {
      languagekey: "menu.alarm.Exchangealarm",
      languagevalue: "Exchange alarm"
    },
    { languagekey: "menu.alarm.Zonlarm", languagevalue: "Zonlarm" },
    { languagekey: "menu.alarm.Speed​​alarm", languagevalue: "Speed ​​alarm" },
    {
      languagekey: "menu.alarm.Receiverofalarms",
      languagevalue: "Receiver of alarms"
    },
    { languagekey: "menu.maintenance", languagevalue: "Maintenance" },
    {
      languagekey: "menu.maintenance.Completedmaintenance",
      languagevalue: "Completed maintenance"
    },
    {
      languagekey: "menu.maintenance.Maintenancework",
      languagevalue: "Maintenance work"
    },
    { languagekey: "menu.economy", languagevalue: "Economy" },
    {
      languagekey: "menu.economy.Totalcostofownership(TCO)",
      languagevalue: "Total cost of ownership (TCO)"
    },
    {
      languagekey: "menu.economy.Currentmonthlycost",
      languagevalue: "Current monthly cost"
    },
    { languagekey: "menu.economy.Leasing", languagevalue: "Leasing" },
    { languagekey: "menu.economy.Fuel", languagevalue: "Fuel" },
    { languagekey: "menu.analyzes", languagevalue: "Analyzes" },
    {
      languagekey: "menu.analyzes.Degreeofutilization",
      languagevalue: "Degree of utilization"
    },
    {
      languagekey: "menu.analyzes.Exportanalyzes",
      languagevalue: "Export analyzes"
    },
    { languagekey: "menu.analyzes.Speeds(ISA)", languagevalue: "Speeds (ISA)" },
    { languagekey: "menu.analyzes.Fuel", languagevalue: "Fuel" },
    { languagekey: "menu.analyzes.Fuel-import", languagevalue: "Fuel-import" },
    {
      languagekey: "menu.analyzes.Drivingbehavior",
      languagevalue: "Driving behavior"
    },
    { languagekey: "menu.reportTemplates", languagevalue: "Report templates" },
    {
      languagekey: "menu.reportTemplates.Createyourownreports",
      languagevalue: "Create your own reports"
    },
    { languagekey: "menu.reportTemplates.Reports", languagevalue: "Reports" },
    {
      languagekey: "menu.reportTemplates.Autoexportreports",
      languagevalue: "Autoexport reports"
    },
    { languagekey: "menu.system", languagevalue: "System" },
    { languagekey: "menu.system.Overview", languagevalue: "Overview" },
    { languagekey: "menu.system.Details", languagevalue: "Details" },
    { languagekey: "menu.system.Activate", languagevalue: "Activate" },
    { languagekey: "menu.system.Boxalarm", languagevalue: "Box alarm" },
    {
      languagekey: "menu.system.Feedback/Support",
      languagevalue: "Feedback / Support"
    },
    {
      languagekey: "menu.system.Autoexportreports",
      languagevalue: "Autoexport reports"
    },
    { languagekey: "menu.show", languagevalue: "Show" },
    { languagekey: "menu.show.HideMapView", languagevalue: "Hide Map View" },
    {
      languagekey: "menu.show.Timelimitontravel",
      languagevalue: "Time limit on travel"
    },
    {
      languagekey: "menu.show.Showonlyunlockedtrips",
      languagevalue: "Show only unlocked trips"
    },
    {
      languagekey: "menu.show.Showonlyowntrips",
      languagevalue: "Show only own trips"
    },
    { languagekey: "menu.show.Showspeed", languagevalue: "Show speed" },
    { languagekey: "menu.admin", languagevalue: "Admin" },
    { languagekey: "menu.admin.Organisation", languagevalue: "Organisation" },
    {
      languagekey: "menu.admin.Organisation.Organizationalstructure",
      languagevalue: "Organizational structure"
    },
    {
      languagekey: "menu.admin.Organisation.Listorganization",
      languagevalue: "List organization"
    },
    {
      languagekey: "menu.admin.Organisation.Createaneworganization",
      languagevalue: "Create a new organization"
    },
    { languagekey: "menu.admin.Permissions", languagevalue: "Permissions" },
    {
      languagekey: "menu.admin.Permissions.Listofpermissiontemplates",
      languagevalue: "List of permission templates"
    },
    {
      languagekey: "menu.admin.Permissions.Createpermissiontemplates",
      languagevalue: "Create permission templates"
    },
    { languagekey: "menu.admin.User", languagevalue: "User" },
    { languagekey: "menu.admin.User.Listusers", languagevalue: "List users" },
    {
      languagekey: "menu.admin.User.Createusers",
      languagevalue: "Create users"
    },
    { languagekey: "menu.admin.User.Userlog", languagevalue: "User log" },
    { languagekey: "menu.admin.Data", languagevalue: "Data" },
    {
      languagekey: "menu.admin.Data.Importvehicles",
      languagevalue: "Import vehicles"
    },
    {
      languagekey: "menu.admin.Data.Importusers",
      languagevalue: "Import users"
    },
    {
      languagekey: "menu.admin.Data.Importtravelpurposes",
      languagevalue: "Import travel purposes"
    },
    { languagekey: "menu.admin.Data.Importfuel", languagevalue: "Import fuel" },
    {
      languagekey: "menu.admin.Data.Exportvehicles",
      languagevalue: "Export vehicles"
    },
    { languagekey: "menu.admin.Backupofdata", languagevalue: "Backup of data" },
    { languagekey: "menu.admin.Templates", languagevalue: "Templates" },
    {
      languagekey: "menu.admin.Templates.Listofvehicletemplates",
      languagevalue: "List of vehicle templates"
    },
    {
      languagekey: "menu.admin.Templates.Createvehicletemplate",
      languagevalue: "Create vehicle template"
    },
    {
      languagekey: "menu.admin.Templates.Listofcosttemplates",
      languagevalue: "List of cost templates"
    },
    {
      languagekey: "menu.admin.Templates.Createcosttemplate",
      languagevalue: "Create cost template"
    },
    {
      languagekey: "menu.admin.Templates.Listservicetemplate",
      languagevalue: "List service template"
    },
    {
      languagekey: "menu.admin.Templates.Createservicetemplate",
      languagevalue: "Create service template"
    },
    {
      languagekey: "menu.admin.Templates.Listvehiclereplacementtemplate",
      languagevalue: "List vehicle replacement template"
    },
    {
      languagekey: "menu.admin.Templates.Createvehiclereplacementtemplate",
      languagevalue: "Create vehicle replacement template"
    },
    {
      languagekey: "menu.admin.Registervehicles",
      languagevalue: "Register vehicles"
    },
    { languagekey: "menu.admin.Userlog", languagevalue: "User log" },
    { languagekey: "menu.mySetting", languagevalue: "My Setting" },
    { languagekey: "menu.logOut", languagevalue: "Log Out" }
  ],
  sweLangData: [
    {
      languagekey: "welcomeText",
      languagevalue: "Välkommen Linus"
    },
    {
      languagekey: "todaysDateText",
      languagevalue: "Dagens datum"
    },
    {
      languagekey: "totaNumOfTravels",
      languagevalue: "Totalt antal resor"
    },
    {
      languagekey: "totalTravelsTodayText",
      languagevalue: "Total antal resor idag"
    },
    {
      languagekey: "totalDrivingDisText",
      languagevalue: "Total körsträcka"
    },
    {
      languagekey: "totalDrivingDistodayText",
      languagevalue: "Total körsträcka idag (km)"
    },
    {
      languagekey: "numOfActiveVehiTodayText",
      languagevalue: "Antal aktiva fordon idag"
    },
    {
      languagekey: "numOfTravelsText",
      languagevalue: "Antal resor"
    },
    {
      languagekey: "totalkmText",
      languagevalue: "Total (km)"
    },
    {
      languagekey: "activeVehiText",
      languagevalue: "Aktiva fordon"
    },
    {
      languagekey: "currentAlramText",
      languagevalue: "Aktuella larm"
    },
    {
      languagekey: "ongoingTravelsText",
      languagevalue: "Pågående resor"
    },
    { languagekey: "table.CurrentAlarm.Level", languagevalue: "Nivå" },
    { languagekey: "table.CurrentAlarm.Time", languagevalue: "Tid" },
    { languagekey: "table.CurrentAlarm.Device", languagevalue: "Enhet" },
    { languagekey: "table.CurrentAlarm.Vehicle", languagevalue: "Fordon" },
    { languagekey: "table.CurrentAlarm.Type", languagevalue: "Typ" },
    {
      languagekey: "table.OngoingTravels.Travelid",
      languagevalue: "Rese-id"
    },
    {
      languagekey: "table.OngoingTravels.Licenseplate",
      languagevalue: "Registreringsskylt"
    },
    { languagekey: "table.OngoingTravels.Driver", languagevalue: "Förare" },
    { languagekey: "table.OngoingTravels.Start", languagevalue: "Start" },
    {
      languagekey: "table.OngoingTravels.Traveltime",
      languagevalue: "Restid"
    },
    { languagekey: "table.OngoingTravels.Distance", languagevalue: "Distans" },
    { languagekey: "bookedVehiText", languagevalue: "Bokade fordon" },
    {
      languagekey: "addWidgetToDashText",
      languagevalue: "Lägg till widgets i instrumentpanelen"
    },
    { languagekey: "widgetsText", languagevalue: "Widgets" },
    {
      languagekey: "bookedVehicleDes",
      languagevalue: "Visa bokade fordon"
    },
    {
      languagekey: "tableCurrentAlarmDes",
      languagevalue: "Tabell över aktuella larm"
    },
    {
      languagekey: "tableOngoingTravelsDes",
      languagevalue: "Tabell över pågående resor"
    },
    {
      languagekey: "chartNumOfTravelsDes",
      languagevalue: "Stapeldiagram för antal resor"
    },
    {
      languagekey: "chartTotalKmDes",
      languagevalue: "Stapeldiagram för totalt (km)"
    },
    {
      languagekey: "chartActiveVehicleDes",
      languagevalue: "Stapeldiagram för aktiva fordon"
    },
    {
      languagekey: "staticTodaysDateDes",
      languagevalue: "Visa dagens datum"
    },
    {
      languagekey: "staticTotalNumOfTravelsDes",
      languagevalue: "Visa totalt antal resor"
    },
    {
      languagekey: "staticTotalTravelsTodayDes",
      languagevalue: "Visa totala resor idag"
    },
    {
      languagekey: "staticTotalDrivingDisDes",
      languagevalue: "Visa total körsträcka"
    },
    {
      languagekey: "staticTotalDrivingDisTodayDes",
      languagevalue: "Visa total körsträcka idag (Km)"
    },
    {
      languagekey: "staticNumOfActiveVehiTodayDes",
      languagevalue: "Visa antalet aktiva fordon idag"
    },
    { languagekey: "addWidgetsBtn", languagevalue: "Lägg till widgets" },
    { languagekey: "changeLayoutBtn", languagevalue: "Ändra layout" },
    { languagekey: "changeDoneBtn", languagevalue: "Ändringar klara" },
    { languagekey: "menu.Dashboard", languagevalue: "instrumentbräda" },
    { languagekey: "menu.vehicle", languagevalue: "Fordon" },
    {
      languagekey: "menu.vehicle.vehicleStatus",
      languagevalue: "Fordonstatus"
    },
    {
      languagekey: "menu.vehicle.searchVehicles",
      languagevalue: "Besiktningar"
    },
    {
      languagekey: "menu.vehicle.Currentmeterreadings",
      languagevalue: "Aktuella mätaravläsningar"
    },
    {
      languagekey: "menu.vehicle.Batterystatus",
      languagevalue: "Batteri status"
    },
    { languagekey: "menu.vehicle.Refueling", languagevalue: "Tankning" },
    { languagekey: "menu.vehicle.Inspections", languagevalue: "Inspektioner" },
    {
      languagekey: "menu.vehicle.Vehicleservice",
      languagevalue: "Fordonsservice"
    },
    {
      languagekey: "menu.vehicle.Vehiclereplacement",
      languagevalue: "Fordonsbyte"
    },
    {
      languagekey: "menu.vehicle.Vehicleconfiguration",
      languagevalue: "Fordonskonfiguration"
    },
    {
      languagekey: "menu.vehicle.CO2emissions",
      languagevalue: "CO2-utsläpp"
    },
    { languagekey: "menu.vehiclecard", languagevalue: "Fordonskort" },
    {
      languagekey: "menu.vehiclecard.Vehicleoverview",
      languagevalue: "Fordonsöversikt"
    },
    {
      languagekey: "menu.vehiclecard.Basicdata",
      languagevalue: "Grundläggande information"
    },
    { languagekey: "menu.vehiclecard.Economy", languagevalue: "Ekonomi" },
    {
      languagekey: "menu.vehiclecard.Maintenance",
      languagevalue: "Underhåll"
    },
    { languagekey: "menu.vehiclecard.System", languagevalue: "Systemet" },
    { languagekey: "menu.vehiclecard.Notes", languagevalue: "Anteckningar" },
    {
      languagekey: "menu.vehiclecard.Certificatetravel",
      languagevalue: "Certifikatresor"
    },
    { languagekey: "menu.driver", languagevalue: "Förare" },
    {
      languagekey: "menu.driver.Latestidentifications",
      languagevalue: "Senaste identifieringar"
    },
    {
      languagekey: "menu.driver.Driveroverview",
      languagevalue: "Föraröversikt"
    },
    {
      languagekey: "menu.driver.Driveranalysis",
      languagevalue: "Föraranalys"
    },
    { languagekey: "menu.positions", languagevalue: "Positioner" },
    {
      languagekey: "menu.positions.Currentpositions",
      languagevalue: "Nuvarande positioner"
    },
    {
      languagekey: "menu.positions.Findthenearestvehicle",
      languagevalue: "Hitta närmaste fordon"
    },
    {
      languagekey: "menu.positions.Create/editzones",
      languagevalue: "Skapa / redigera zoner"
    },
    {
      languagekey: "menu.positions.Listofzones",
      languagevalue: "Lista över zoner"
    },
    { languagekey: "menu.travel", languagevalue: "Resa" },
    {
      languagekey: "menu.travel.Ongoingtravel",
      languagevalue: "Pågående resor"
    },
    {
      languagekey: "menu.travel.Completedtrips",
      languagevalue: "Slutförda resor"
    },
    { languagekey: "menu.travel.Travellog", languagevalue: "Reselogg" },
    {
      languagekey: "menu.travel.Congestiontax",
      languagevalue: "Trängselskatt"
    },
    { languagekey: "menu.travel.Travelstop", languagevalue: "Resestopp" },
    {
      languagekey: "menu.travel.Travelpurposerules",
      languagevalue: "Regler för resändamål"
    },
    {
      languagekey: "menu.travel.Autoexportdrivingrecords",
      languagevalue: "Autoexport körrekord"
    },
    {
      languagekey: "menu.travel.Manuallogbook",
      languagevalue: "Manuell loggbok"
    },
    { languagekey: "menu.travel.Changeuser", languagevalue: "Växla användare" },
    {
      languagekey: "menu.travel.Certificatetravel",
      languagevalue: "Certifikatresor"
    },
    { languagekey: "menu.reserve", languagevalue: "Boka" },
    { languagekey: "menu.reserve.Vehicle", languagevalue: "Fordon" },
    {
      languagekey: "menu.reserve.Vehicleservice",
      languagevalue: "Fordonsservice"
    },
    {
      languagekey: "menu.reserve.Bookotherusers",
      languagevalue: "Boka andra användare"
    },
    { languagekey: "menu.alarm", languagevalue: "Larm" },
    { languagekey: "menu.alarm.Devicealarm", languagevalue: "Enhetslarm" },
    { languagekey: "menu.alarm.Driveralarm", languagevalue: "Förarlarm" },
    { languagekey: "menu.alarm.Servicealarm", languagevalue: "Servicelarm" },
    {
      languagekey: "menu.alarm.Exchangealarm",
      languagevalue: "Byt larm"
    },
    { languagekey: "menu.alarm.Zonlarm", languagevalue: "Mina zoner" },
    { languagekey: "menu.alarm.Speed​​alarm", languagevalue: "Hastighetslarm" },
    {
      languagekey: "menu.alarm.Receiverofalarms",
      languagevalue: "Mottagare av larm"
    },
    { languagekey: "menu.maintenance", languagevalue: "Underhåll" },
    {
      languagekey: "menu.maintenance.Completedmaintenance",
      languagevalue: "Slutfört underhåll"
    },
    {
      languagekey: "menu.maintenance.Maintenancework",
      languagevalue: "underhållsarbete"
    },
    { languagekey: "menu.economy", languagevalue: "Ekonomi" },
    {
      languagekey: "menu.economy.Totalcostofownership(TCO)",
      languagevalue: "Total ägandekostnad (TCO)"
    },
    {
      languagekey: "menu.economy.Currentmonthlycost",
      languagevalue: "Aktuell månatlig kostnad"
    },
    { languagekey: "menu.economy.Leasing", languagevalue: "Leasing" },
    { languagekey: "menu.economy.Fuel", languagevalue: "Bränsle" },
    { languagekey: "menu.analyzes", languagevalue: "Analyser" },
    {
      languagekey: "menu.analyzes.Degreeofutilization",
      languagevalue: "Användningsgrad"
    },
    {
      languagekey: "menu.analyzes.Exportanalyzes",
      languagevalue: "Exportanalyser"
    },
    {
      languagekey: "menu.analyzes.Speeds(ISA)",
      languagevalue: "Hastigheter (ISA)"
    },
    { languagekey: "menu.analyzes.Fuel", languagevalue: "Bränsle" },
    {
      languagekey: "menu.analyzes.Fuel-import",
      languagevalue: "Bränsleimport"
    },
    {
      languagekey: "menu.analyzes.Drivingbehavior",
      languagevalue: "Körbeteende"
    },
    { languagekey: "menu.reportTemplates", languagevalue: "Rapportera mallar" },
    {
      languagekey: "menu.reportTemplates.Createyourownreports",
      languagevalue: "Skapa egna rapporter"
    },
    { languagekey: "menu.reportTemplates.Reports", languagevalue: "Rapporter" },
    {
      languagekey: "menu.reportTemplates.Autoexportreports",
      languagevalue: "Autoexport rapporterar"
    },
    { languagekey: "menu.system", languagevalue: "Systemet" },
    { languagekey: "menu.system.Overview", languagevalue: "Översikt" },
    { languagekey: "menu.system.Details", languagevalue: "Detaljer" },
    { languagekey: "menu.system.Activate", languagevalue: "Aktivera" },
    { languagekey: "menu.system.Boxalarm", languagevalue: "Boxlarm" },
    {
      languagekey: "menu.system.Feedback/Support",
      languagevalue: "Feedback / support"
    },
    {
      languagekey: "menu.system.Autoexportreports",
      languagevalue: "Autoexport rapporterar"
    },
    { languagekey: "menu.show", languagevalue: "Visa" },
    { languagekey: "menu.show.HideMapView", languagevalue: "Dölj kartvy" },
    {
      languagekey: "menu.show.Timelimitontravel",
      languagevalue: "Tidsgräns för resor"
    },
    {
      languagekey: "menu.show.Showonlyunlockedtrips",
      languagevalue: "Visa endast olåsta resor"
    },
    {
      languagekey: "menu.show.Showonlyowntrips",
      languagevalue: "Visa bara egna resor"
    },
    { languagekey: "menu.show.Showspeed", languagevalue: "Visa hastighet" },
    { languagekey: "menu.admin", languagevalue: "Administration" },
    { languagekey: "menu.admin.Organisation", languagevalue: "Organisation" },
    {
      languagekey: "menu.admin.Organisation.Organizationalstructure",
      languagevalue: "Organisationsstruktur"
    },
    {
      languagekey: "menu.admin.Organisation.Listorganization",
      languagevalue: "Lista organisation"
    },
    {
      languagekey: "menu.admin.Organisation.Createaneworganization",
      languagevalue: "Skapa en ny organisation"
    },
    { languagekey: "menu.admin.Permissions", languagevalue: "Behörigheter" },
    {
      languagekey: "menu.admin.Permissions.Listofpermissiontemplates",
      languagevalue: "Lista över behörighetsmallar"
    },
    {
      languagekey: "menu.admin.Permissions.Createpermissiontemplates",
      languagevalue: "Skapa behörighetsmallar"
    },
    { languagekey: "menu.admin.User", languagevalue: "Användare" },
    {
      languagekey: "menu.admin.User.Listusers",
      languagevalue: "Lista användare"
    },
    {
      languagekey: "menu.admin.User.Createusers",
      languagevalue: "Skapa användare"
    },
    { languagekey: "menu.admin.User.Userlog", languagevalue: "Användarlogg" },
    { languagekey: "menu.admin.Data", languagevalue: "Data" },
    {
      languagekey: "menu.admin.Data.Importvehicles",
      languagevalue: "Importera fordon"
    },
    {
      languagekey: "menu.admin.Data.Importusers",
      languagevalue: "Importera användare"
    },
    {
      languagekey: "menu.admin.Data.Importtravelpurposes",
      languagevalue: "Importera resesyften"
    },
    {
      languagekey: "menu.admin.Data.Importfuel",
      languagevalue: "Importera drivmedel"
    },
    {
      languagekey: "menu.admin.Data.Exportvehicles",
      languagevalue: "Exportera fordon"
    },
    {
      languagekey: "menu.admin.Backupofdata",
      languagevalue: "Back-up av data"
    },
    { languagekey: "menu.admin.Templates", languagevalue: "Mallar" },
    {
      languagekey: "menu.admin.Templates.Listofvehicletemplates",
      languagevalue: "Lista fordonsmall"
    },
    {
      languagekey: "menu.admin.Templates.Createvehicletemplate",
      languagevalue: "Skapa fordonsmall"
    },
    {
      languagekey: "menu.admin.Templates.Listofcosttemplates",
      languagevalue: "Lista kostnadsmallar"
    },
    {
      languagekey: "menu.admin.Templates.Createcosttemplate",
      languagevalue: "Skapa kostnadsmall"
    },
    {
      languagekey: "menu.admin.Templates.Listservicetemplate",
      languagevalue: "Lista servicemall"
    },
    {
      languagekey: "menu.admin.Templates.Createservicetemplate",
      languagevalue: "Skapa servicemall"
    },
    {
      languagekey: "menu.admin.Templates.Listvehiclereplacementtemplate",
      languagevalue: "Lista utbytesmall för fordon"
    },
    {
      languagekey: "menu.admin.Templates.Createvehiclereplacementtemplate",
      languagevalue: "Skapa bilbytesmall"
    },
    {
      languagekey: "menu.admin.Registervehicles",
      languagevalue: "Registrera fordon"
    },
    { languagekey: "menu.admin.Userlog", languagevalue: "Användarlogg" },
    { languagekey: "menu.mySetting", languagevalue: "Min inställning" },
    { languagekey: "menu.logOut", languagevalue: "Logga ut" }
  ],
  japLangData: [
    {
      languagekey: "welcomeText",
      languagevalue: "ようこそ ライナス"
    },
    {
      languagekey: "todaysDateText",
      languagevalue: "今日の日付"
    },
    {
      languagekey: "totaNumOfTravels",
      languagevalue: "累積走行数"
    },
    {
      languagekey: "totalTravelsTodayText",
      languagevalue: "今日の走行数"
    },
    {
      languagekey: "totalDrivingDisText",
      languagevalue: "累積走行距離"
    },
    {
      languagekey: "totalDrivingDistodayText",
      languagevalue: "今日の走行距離"
    },
    {
      languagekey: "numOfActiveVehiTodayText",
      languagevalue: "今日の稼働車両"
    },
    {
      languagekey: "numOfTravelsText",
      languagevalue: "走行数"
    },
    {
      languagekey: "totalkmText",
      languagevalue: "合計距離"
    },
    {
      languagekey: "activeVehiText",
      languagevalue: "稼働車両数"
    },
    {
      languagekey: "currentAlramText",
      languagevalue: "現在の警告"
    },
    {
      languagekey: "ongoingTravelsText",
      languagevalue: "継続中の走行"
    },
    { languagekey: "table.CurrentAlarm.Level", languagevalue: "レベル" },
    { languagekey: "table.CurrentAlarm.Time", languagevalue: "時間" },
    { languagekey: "table.CurrentAlarm.Device", languagevalue: "端末" },
    { languagekey: "table.CurrentAlarm.Vehicle", languagevalue: "車両" },
    { languagekey: "table.CurrentAlarm.Type", languagevalue: "タイプ" },
    {
      languagekey: "table.OngoingTravels.Travelid",
      languagevalue: "旅行ID"
    },
    {
      languagekey: "table.OngoingTravels.Licenseplate",
      languagevalue: "ナンバープレート"
    },
    { languagekey: "table.OngoingTravels.Driver", languagevalue: "運転者" },
    { languagekey: "table.OngoingTravels.Start", languagevalue: "開始" },
    {
      languagekey: "table.OngoingTravels.Traveltime",
      languagevalue: "移動時間"
    },
    { languagekey: "table.OngoingTravels.Distance", languagevalue: "距離" },
    { languagekey: "bookedVehiText", languagevalue: "予約済みの車両" },
    {
      languagekey: "addWidgetToDashText",
      languagevalue: "ダッシュボードにウィジェットを追加する"
    },
    { languagekey: "widgetsText", languagevalue: "ウィジェット" },
    {
      languagekey: "bookedVehicleDes",
      languagevalue: "予約した車両を表示する"
    },
    {
      languagekey: "tableCurrentAlarmDes",
      languagevalue: "現在のアラームの表"
    },
    {
      languagekey: "tableOngoingTravelsDes",
      languagevalue: "進行中の旅行の表"
    },
    {
      languagekey: "chartNumOfTravelsDes",
      languagevalue: "旅行数の棒グラフ"
    },
    {
      languagekey: "chartTotalKmDes",
      languagevalue: "合計（km）の棒グラフ"
    },
    {
      languagekey: "chartActiveVehicleDes",
      languagevalue: "アクティブな車両の棒グラフ"
    },
    {
      languagekey: "staticTodaysDateDes",
      languagevalue: "今日の日付を表示する"
    },
    {
      languagekey: "staticTotalNumOfTravelsDes",
      languagevalue: "旅行の総数を表示する"
    },
    {
      languagekey: "staticTotalTravelsTodayDes",
      languagevalue: "今日の総旅行数を表示する"
    },
    {
      languagekey: "staticTotalDrivingDisDes",
      languagevalue: "総走行距離を表示"
    },
    {
      languagekey: "staticTotalDrivingDisTodayDes",
      languagevalue: "今日の総走行距離を表示（Km）"
    },
    {
      languagekey: "staticNumOfActiveVehiTodayDes",
      languagevalue: "今日のアクティブな車両の数を表示します"
    },
    { languagekey: "addWidgetsBtn", languagevalue: "ウィジェットを追加する" },
    { languagekey: "changeLayoutBtn", languagevalue: "レイアウトの変更" },
    { languagekey: "changeDoneBtn", languagevalue: "変更が完了しました" },
    { languagekey: "menu.Dashboard", languagevalue: "ダッシュボード" },
    { languagekey: "menu.vehicle", languagevalue: "車両" },
    {
      languagekey: "menu.vehicle.vehicleStatus",
      languagevalue: "車両状態"
    },
    {
      languagekey: "menu.vehicle.searchVehicles",
      languagevalue: "車両検索"
    },
    {
      languagekey: "menu.vehicle.Currentmeterreadings",
      languagevalue: "現在のメーターの読み"
    },
    {
      languagekey: "menu.vehicle.Batterystatus",
      languagevalue: "バッテリー状態"
    },
    { languagekey: "menu.vehicle.Refueling", languagevalue: "給油" },
    { languagekey: "menu.vehicle.Inspections", languagevalue: "検査" },
    {
      languagekey: "menu.vehicle.Vehicleservice",
      languagevalue: "車両サービス"
    },
    {
      languagekey: "menu.vehicle.Vehiclereplacement",
      languagevalue: "車両交換"
    },
    {
      languagekey: "menu.vehicle.Vehicleconfiguration",
      languagevalue: "車両構成"
    },
    {
      languagekey: "menu.vehicle.CO2emissions",
      languagevalue: "CO2排出量"
    },
    { languagekey: "menu.vehiclecard", languagevalue: "車両カード" },
    {
      languagekey: "menu.vehiclecard.Vehicleoverview",
      languagevalue: "車両概要"
    },
    { languagekey: "menu.vehiclecard.Basicdata", languagevalue: "基本データ" },
    { languagekey: "menu.vehiclecard.Economy", languagevalue: "経済" },
    {
      languagekey: "menu.vehiclecard.Maintenance",
      languagevalue: "メンテナンス"
    },
    { languagekey: "menu.vehiclecard.System", languagevalue: "システム" },
    { languagekey: "menu.vehiclecard.Notes", languagevalue: "ノート" },
    {
      languagekey: "menu.vehiclecard.Certificatetravel",
      languagevalue: "証明書旅行"
    },
    { languagekey: "menu.driver", languagevalue: "運転手" },
    {
      languagekey: "menu.driver.Latestidentifications",
      languagevalue: "最新の識別"
    },
    {
      languagekey: "menu.driver.Driveroverview",
      languagevalue: "ドライバーの概要"
    },
    {
      languagekey: "menu.driver.Driveranalysis",
      languagevalue: "ドライバー分析"
    },
    { languagekey: "menu.positions", languagevalue: "ポジション" },
    {
      languagekey: "menu.positions.Currentpositions",
      languagevalue: "現在の位置"
    },
    {
      languagekey: "menu.positions.Findthenearestvehicle",
      languagevalue: "最寄りの車両を探す"
    },
    {
      languagekey: "menu.positions.Create/editzones",
      languagevalue: "ゾーンの作成/編集"
    },
    {
      languagekey: "menu.positions.Listofzones",
      languagevalue: "ゾーンのリスト"
    },
    { languagekey: "menu.travel", languagevalue: "トラベル" },
    {
      languagekey: "menu.travel.Ongoingtravel",
      languagevalue: "進行中の旅行"
    },
    {
      languagekey: "menu.travel.Completedtrips",
      languagevalue: "完了した旅行"
    },
    { languagekey: "menu.travel.Travellog", languagevalue: "旅行ログ" },
    {
      languagekey: "menu.travel.Congestiontax",
      languagevalue: "混雑税"
    },
    {
      languagekey: "menu.travel.Travelstop",
      languagevalue: "トラベルストップ"
    },
    {
      languagekey: "menu.travel.Travelpurposerules",
      languagevalue: "旅行目的のルール"
    },
    {
      languagekey: "menu.travel.Autoexportdrivingrecords",
      languagevalue: "運転記録の自動エクスポート"
    },
    {
      languagekey: "menu.travel.Manuallogbook",
      languagevalue: "手動航海日誌"
    },
    {
      languagekey: "menu.travel.Changeuser",
      languagevalue: "ユーザーを変更する"
    },
    {
      languagekey: "menu.travel.Certificatetravel",
      languagevalue: "証明書旅行"
    },
    { languagekey: "menu.reserve", languagevalue: "予約する" },
    { languagekey: "menu.reserve.Vehicle", languagevalue: "車両" },
    {
      languagekey: "menu.reserve.Vehicleservice",
      languagevalue: "車両サービス"
    },
    {
      languagekey: "menu.reserve.Bookotherusers",
      languagevalue: "他のユーザーを予約する"
    },
    { languagekey: "menu.alarm", languagevalue: "警報" },
    {
      languagekey: "menu.alarm.Devicealarm",
      languagevalue: "デバイスアラーム"
    },
    {
      languagekey: "menu.alarm.Driveralarm",
      languagevalue: "ドライバーアラーム"
    },
    {
      languagekey: "menu.alarm.Servicealarm",
      languagevalue: "サービスアラーム"
    },
    {
      languagekey: "menu.alarm.Exchangealarm",
      languagevalue: "交換アラーム"
    },
    { languagekey: "menu.alarm.Zonlarm", languagevalue: "私のゾーン" },
    {
      languagekey: "menu.alarm.Speed​​alarm",
      languagevalue: "スピードアラーム"
    },
    {
      languagekey: "menu.alarm.Receiverofalarms",
      languagevalue: "アラームの受信者"
    },
    { languagekey: "menu.maintenance", languagevalue: "メンテナンス" },
    {
      languagekey: "menu.maintenance.Completedmaintenance",
      languagevalue: "メンテナンス完了"
    },
    {
      languagekey: "menu.maintenance.Maintenancework",
      languagevalue: "保守作業"
    },
    { languagekey: "menu.economy", languagevalue: "経済" },
    {
      languagekey: "menu.economy.Totalcostofownership(TCO)",
      languagevalue: "総所有コスト（TCO）"
    },
    {
      languagekey: "menu.economy.Currentmonthlycost",
      languagevalue: "現在の月額費用"
    },
    { languagekey: "menu.economy.Leasing", languagevalue: "リース" },
    { languagekey: "menu.economy.Fuel", languagevalue: "燃料" },
    { languagekey: "menu.analyzes", languagevalue: "分析" },
    {
      languagekey: "menu.analyzes.Degreeofutilization",
      languagevalue: "利用度"
    },
    {
      languagekey: "menu.analyzes.Exportanalyzes",
      languagevalue: "輸出分析"
    },
    {
      languagekey: "menu.analyzes.Speeds(ISA)",
      languagevalue: "スピード（ISA）"
    },
    { languagekey: "menu.analyzes.Fuel", languagevalue: "燃料" },
    { languagekey: "menu.analyzes.Fuel-import", languagevalue: "燃料輸入" },
    {
      languagekey: "menu.analyzes.Drivingbehavior",
      languagevalue: "運転行動"
    },
    {
      languagekey: "menu.reportTemplates",
      languagevalue: "レポートテンプレート"
    },
    {
      languagekey: "menu.reportTemplates.Createyourownreports",
      languagevalue: "独自のレポートを作成する"
    },
    { languagekey: "menu.reportTemplates.Reports", languagevalue: "レポート" },
    {
      languagekey: "menu.reportTemplates.Autoexportreports",
      languagevalue: "自動エクスポートレポート"
    },
    { languagekey: "menu.system", languagevalue: "システム" },
    { languagekey: "menu.system.Overview", languagevalue: "概要概要" },
    { languagekey: "menu.system.Details", languagevalue: "詳細" },
    { languagekey: "menu.system.Activate", languagevalue: "アクティベート" },
    { languagekey: "menu.system.Boxalarm", languagevalue: "ボックスアラーム" },
    {
      languagekey: "menu.system.Feedback/Support",
      languagevalue: "フィードバック/サポート"
    },
    {
      languagekey: "menu.system.Autoexportreports",
      languagevalue: "自動エクスポートレポート"
    },
    { languagekey: "menu.show", languagevalue: "公演" },
    {
      languagekey: "menu.show.HideMapView",
      languagevalue: "マップビューを非表示"
    },
    {
      languagekey: "menu.show.Timelimitontravel",
      languagevalue: "旅行の時間制限"
    },
    {
      languagekey: "menu.show.Showonlyunlockedtrips",
      languagevalue: "ロックされていない旅行のみを表示する"
    },
    {
      languagekey: "menu.show.Showonlyowntrips",
      languagevalue: "自分の旅行のみを表示する"
    },
    { languagekey: "menu.show.Showspeed", languagevalue: "速度を表示" },
    { languagekey: "menu.admin", languagevalue: "管理者" },
    { languagekey: "menu.admin.Organisation", languagevalue: "組織" },
    {
      languagekey: "menu.admin.Organisation.Organizationalstructure",
      languagevalue: "組織構造"
    },
    {
      languagekey: "menu.admin.Organisation.Listorganization",
      languagevalue: "リスト編成"
    },
    {
      languagekey: "menu.admin.Organisation.Createaneworganization",
      languagevalue: "新しい組織を作成する"
    },
    { languagekey: "menu.admin.Permissions", languagevalue: "権限" },
    {
      languagekey: "menu.admin.Permissions.Listofpermissiontemplates",
      languagevalue: "権限テンプレートのリスト"
    },
    {
      languagekey: "menu.admin.Permissions.Createpermissiontemplates",
      languagevalue: "権限テンプレートを作成する"
    },
    { languagekey: "menu.admin.User", languagevalue: "ユーザー" },
    {
      languagekey: "menu.admin.User.Listusers",
      languagevalue: "ユーザーを一覧表示する"
    },
    {
      languagekey: "menu.admin.User.Createusers",
      languagevalue: "ユーザーを作成する"
    },
    { languagekey: "menu.admin.User.Userlog", languagevalue: "ユーザーログ" },
    { languagekey: "menu.admin.Data", languagevalue: "データ" },
    {
      languagekey: "menu.admin.Data.Importvehicles",
      languagevalue: "輸入車"
    },
    {
      languagekey: "menu.admin.Data.Importusers",
      languagevalue: "ユーザーのインポート"
    },
    {
      languagekey: "menu.admin.Data.Importtravelpurposes",
      languagevalue: "旅行目的の輸入"
    },
    { languagekey: "menu.admin.Data.Importfuel", languagevalue: "燃料の輸入" },
    {
      languagekey: "menu.admin.Data.Exportvehicles",
      languagevalue: "輸出車両"
    },
    {
      languagekey: "menu.admin.Backupofdata",
      languagevalue: "データのバックアップ"
    },
    { languagekey: "menu.admin.Templates", languagevalue: "テンプレート" },
    {
      languagekey: "menu.admin.Templates.Listofvehicletemplates",
      languagevalue: "車両テンプレートのリスト"
    },
    {
      languagekey: "menu.admin.Templates.Createvehicletemplate",
      languagevalue: "車両テンプレートを作成する"
    },
    {
      languagekey: "menu.admin.Templates.Listofcosttemplates",
      languagevalue: "コストテンプレートのリスト"
    },
    {
      languagekey: "menu.admin.Templates.Createcosttemplate",
      languagevalue: "コストテンプレートを作成する"
    },
    {
      languagekey: "menu.admin.Templates.Listservicetemplate",
      languagevalue: "リストサービステンプレート"
    },
    {
      languagekey: "menu.admin.Templates.Createservicetemplate",
      languagevalue: "サービステンプレートを作成する"
    },
    {
      languagekey: "menu.admin.Templates.Listvehiclereplacementtemplate",
      languagevalue: "車両交換​​テンプレートのリスト"
    },
    {
      languagekey: "menu.admin.Templates.Createvehiclereplacementtemplate",
      languagevalue: "車両交換​​テンプレートを作成する"
    },
    {
      languagekey: "menu.admin.Registervehicles",
      languagevalue: "車両登録"
    },
    { languagekey: "menu.admin.Userlog", languagevalue: "ユーザーログ" },
    { languagekey: "menu.mySetting", languagevalue: "私の設定" },
    { languagekey: "menu.logOut", languagevalue: "ログアウト" }
  ]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENG_LANG_DATA:
      return {
        ...state,
        engLangData: action.payload
      };
    case SWE_LANG_DATA:
      return {
        ...state,
        sweLangData: action.payload
      };
    case JAP_LANG_DATA:
      return {
        ...state,
        japLangData: action.payload
      };
    default:
      return state;
  }
};
