import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Row, Col, DatePicker, Select, Drawer, Input, Affix, Form, AutoComplete } from "antd";
import moment from "moment";
import filterSearch from '../search/filterSearch'

//TODO why cant I search on the FAQ company in search drawer?
//TODO driver select display replicas
const VehicleSearchDrawer = (props) => {
    const [open, setOpen] = useState(false);
    const [advancedSearchEnable, setAdvancedSearchEnable] = useState(false)
    const [formName, setFormName] = useState("vehicleSearch")
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    useEffect(() => {
        if (props.Open) {
            showDrawer()
        }
      }, [props.Open]);

    const showDrawer = () => {
        setOpen(!open);
        props.pageSetOpen(!open)
    };
    const parseVehicleStatusFormItem = (vehicleStatusItem) => {
        let vehicleStatusInt = null
        switch (vehicleStatusItem) {
            case "Inactive": 
            vehicleStatusInt = 0
            break
            case "Active":
            vehicleStatusInt = 1
            break
            case "Sold":
            vehicleStatusInt = 2
            break
            case "Terminated":
            vehicleStatusInt = 3
            break
        }
        return vehicleStatusInt
    }

    const onFinish = (values) => {
      console.log("onfinish input ", values)
        const data = {
            'LicensePlate': !values.LicensePlate ? null : values.LicensePlate.trim(),
            'Miles': {
              'milesFrom': !values.milesFrom ? null : values.milesFrom.trim(),
              'milesTo': !values.milesTo ? null : values.milesTo.trim(),
            },
            'Year': {
              'yearFrom': !values.Year ? null : moment(values.Year[0]).format("Y").trim(),
              'yearTo': !values.Year ? null : moment(values.Year[1]).format("Y").trim(),
            },
            'Brand': !values.Brand ? null : values.Brand.trim(),
            'Model': !values.Model ? null : values.Model.trim(),
            'Driver' : !values.Driver ? null : values.Driver.trim(),
            'Group' : !values.Group ? null :values.responsibilitynumber.trim(),
            "Company" : !values.Company ? null : values.Company.trim(),
            "Status" : parseVehicleStatusFormItem(values.VehicleStatus),
        }
        console.log("data in onfinish to filter generaldrawer: ", data)
        //props.setVehicles(filterVehicles(data))
        props.setVehicles(filterSearch(data, props.constantAllVehicles))
        showDrawer()
    }
    const onFinishFailed = (errorInfo) => {
        console.log('onfininshFailed:', errorInfo);
    };
    const advancedSearchclick = () => {
        setAdvancedSearchEnable(!advancedSearchEnable)
    }
    //Returns 2d array with key-value pair objects in inner array.
    const companies_arr = props.Companies
    
    return (
        <Drawer
            title="Search"
            placement="right"
            onClose={showDrawer}
            open={open}
            size="large"
            form={formName}
            footer={
              <div
                  style={{
                      textAlign: "right",
                  }}
              >
                  <Button
                      onClick={() => {
                        showDrawer();
                      }}
                      form={formName}
                      type="primary"
                      key="submit"
                      htmlType="submit"
                  >
                      Search
                  </Button>
              </div>
          }
          >
            <Form
              name={props.formName ? props.formName : formName}
              id={props.formId ? props.formId : formName}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              
            > 
              <Row gutter={[16, 20]} className="search-form-row">
                {/* <Col className="gutter-row" span={20}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">Search</Button>
                  </Form.Item>
                </Col> */}
                <Col className="gutter-row" span={12}>
                  <Form.Item name="LicensePlate" label="License plate">
                    <AutoComplete
                      placeholder="ABC123"
                    />
                  </Form.Item>
                </Col>
                {props.AdvancedSearchBtnEnable ?
                  <>
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="vehicles">
                            <Button className="advancedSearch-btn" onClick={advancedSearchclick}>Advanced Search</Button>
                        </Form.Item>
                    </Col>
                    {advancedSearchEnable ?
                    <>
                    {props.brands ? <>
                    <Col className="gutter-row" span={12}>
                        <Form.Item name="Brand" label="Brand">
                        <Select
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            placeholder="Brand"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {props.brands.map((v) => <Option value={v}>{v}</Option>)}
                        </Select>
                        </Form.Item>
                    </Col>
                    </> : null}
                    {props.models ? <>
                    <Col className="gutter-row" span={12}>
                      <Form.Item name="Model" label="Model">
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Model"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {props.models.map((v) => <Option value={v}>{v}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    </> : null }

                    <Col className="gutter-row" span={12}>
                      <Form.Item name="Year" label="Year">
                        <RangePicker picker="year" />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={12}>
                      <Input.Group>
                        <Row gutter={8}>
                          <Col span={8}>
                            <Form.Item name="milesFrom" label="Odometer From">
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item name="milesTo" label="Odometer to">
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Col>

                    {companies_arr[0] ? <>
                    <Col className="gutter-row" span={12}>
                      <Form.Item name="Company" label="Company">
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select companies"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {/* companies.map((v) => <Option value={v.Id}>{v.Name}</Option>) */}
                          {companies_arr[0] ? companies_arr[0].map((v) => (<Option value={v.Name}> {v.Name} </Option>)) : <Option value="not available">No Available Companies Exists</Option>  }
                        </Select>
                      </Form.Item>
                    </Col>
                    </> : null }

                    { props.users ? <>
                    <Col className="gutter-row" span={12}>
                      <Form.Item name="Driver" label="Driver">
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Driver"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {props.users.map((v) => <Option value={v.Name}>{v.Name}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    </> : null}

                    {props.group ? <>
                    <Col className="gutter-row" span={12}>
                      <Form.Item name="responsibilitynumber" label="Responsibility number">
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select Responsibility number"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {props.group.map((v) => <Option value={v}>{v}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    </> : null }

                    <Col className="gutter-row" span={12}>
                      <Form.Item name="VehicleStatus" label="Vehicle status">
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          showSearch
                          placeholder=""
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                          <Option value="Sold">Sold</Option>
                          <Option value="Terminated">Terminated</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    </> : null}
                </> : null}
              </Row>
            </Form>
          </Drawer>
    )
}

export default VehicleSearchDrawer