import React, { useState, useEffect, useRef } from "react";
import { Checkbox, Col, Row, Form } from "antd";

const CustomCheckboxGroup = ({
    values,
    groupState,
    mainTitle,
    pid,
    checkedRecord,
    checkeAll,
}) => {
    const [state, setState] = useState({
        values: [],
        checkedList: [],
        indeterminate: false,
        checkAll: false,
        parentId: pid,
        parenTitle: mainTitle,
    });

    const [allValuesChecked, setAllValuesChecked] = useState([]);

    const { current: checkedRecordRef } = useRef(checkedRecord);
    const { current: valuesRef } = useRef(values);

    // console.log('allValuesChecked',allValuesChecked);
    //TODO reopen modal do not show new data
    useEffect(() => {
        console.log(
            "CustomCheckboxGroup useEffect props values: checkedRecord: ",
            values,
            checkedRecord
        );

        if (values) {
            const formattedValues = values.map((data) => {
                return { label: data.name, value: data.value };
            });
            console.log("CustomCheckboxGroup checkedRecord ", checkedRecord);

            let myarr = checkedRecord ? checkedRecord : [];

            values.map((data) => {
                if (data.checked === true) {
                    myarr.push(data.value);
                }
            });
            //  console.log(values);
            setAllValuesChecked(() => {
                return values.map((value) => {
                    return value.value;
                });
            });
            if (myarr != "") {
                setState({
                    values: formattedValues,
                    checkedList: myarr,
                    indeterminate: !!myarr,
                    checkAll: false,
                });
            } else {
                setState({
                    values: formattedValues,
                    checkedList: myarr,
                    indeterminate: false,
                    checkAll: false,
                });
            }
        }

       
    }, [valuesRef, checkedRecordRef, checkedRecord?.length, mainTitle]);

    let allChekcedList = [];

    useEffect(() => {
        if (groupState) {
            groupState({ ...state });
        }
    }, [state]);

    function onChange(checkedList) {
        setState({
            ...state,
            checkedList,
            indeterminate:
                !!checkedList.length && checkedList.length < values.length,
            checkAll: checkedList.length === values.length,
            parentId: pid,
            parenTitle: mainTitle,
        });
        return state;
    }
    checkeAll(state.checkAll, mainTitle);

    function onCheckAllChange(e) {
        setAllValuesChecked(() => {
            return values.map((value) => {
                return value.value;
            });
        });

        checkeAll(e.target.checked, mainTitle);
        return setState({
            ...state,
            checkedList: e.target.checked ? allValuesChecked : [],
            indeterminate: false,
            checkAll: e.target.checked,
            parentId: pid,
            parenTitle: mainTitle,
        });
    }

    const mock = ["1.1", "1.2"];

    return (
        <>
            <Col span={8} className="outer-border">
                <div className="access-list-wrapper">
                    {/* <Form.Item 
            //name="checkbox-group"
            name={mainTitle}
            valuePropName="checked"
            > */}
                    <div className="ant-checkbox-group-item access-list-head">
                        <Checkbox
                            className="checkbox-header"
                            indeterminate={state.indeterminate}
                            onChange={onCheckAllChange}
                            checked={state.checkAll}
                        >
                            {mainTitle}
                        </Checkbox>
                        {/* </Form.Item> */}
                    </div>
                    <Form.Item
                        //name="checkbox-group"
                        name={mainTitle}
                        valuePropName="checked"
                    >
                        <Checkbox.Group
                            name={mainTitle}
                            className="checkbox-group"
                            options={state.values}
                            value={state.checkedList}
                            // value={mock}
                            onChange={onChange}
                        />
                    </Form.Item>
                </div>
            </Col>
        </>
    );
};

export default CustomCheckboxGroup;
