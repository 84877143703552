import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { notification } from "antd";

import * as Apis from "../../utils/api";

import * as XLSX from "xlsx";
//the new excel packages!
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import logoBig from "../../assets/Images/logoJiricom.png";
import dayjs from "dayjs";

export const renderFormatMsg = (id, msg) => (
    <FormattedMessage id={id} defaultMessage={msg} />
);

export const ForgotPassword = (email) => {
    if (email.length == 0) {
        return "Please enter email address";
    } else {
        //console.log(email)
        Apis.forgotPassword(email).then((res) => {
            if (res.Status === "OK") {
                NotisCheck("success", "top", res.Message);
            } else {
                NotisCheck("error", "top", res.Message);
            }
        });
    }
};


//iconType: success, info, warning, error
export const openNotificationWithIcon = (
    iconType,
    message,
    description,
    placement = "top",
    duration = 3
) => {
    notification[iconType]({
        message: message,
        description: description,
        placement: placement,
        duration: duration,
    });
};



export const nNotis = (
    type = "Notification",
    placement = "top",
    text = "",
    duration = 0,
    title = "Notification"
) => {
    const args = {
        type: type,
        message: title,
        description: text,
        placement: placement,
        duration: duration,
    };
    notification.open(args);
};

export const NotisCheck = (type, place, msg) => {
    nNotis(type, place, msg, 3);
    // setInterval(() => {
    //   console.log("VI kan kolla notiser :D");
    //   nNotis('info', "top","Notification",3)
    // }, 15000);
};
export const CheckAccess = () => {
    // get user access bits (dynamic?)
};

/**
 * @function getPreviousWeekDate
 * @return {Date} Date
 */
export const getPreviousWeekDate = () => {
    let newDate = new Date();
    const prevWeek = newDate.getDate() - 7;
    return newDate.setDate(prevWeek);
};

/**
 * 
 * @returns Object; DateTimeObject, DateTimeString, DateTimeCompactString
 */
export const getCurrentDateTimeString = () => {
    const currentDate = dayjs()
    return {DateTimeObject: currentDate, DateTimeString: currentDate.format('YYYY-MM-DD HH:mm:ss'), DateTimeCompactString: currentDate.format('YYYYMMDDHHmmss')}
}

/**
 * 
 * @returns Object; DateTimeObject, DateTimeString, DateTimeCompactString
 */
export const getTodaysMidnightDate = () => {
    const todaysDateMidnight = dayjs().startOf('date')
    return {DateTimeObject: todaysDateMidnight, DateTimeString: todaysDateMidnight.format('YYYY-MM-DD HH:mm:ss'), DateTimeCompactString: todaysDateMidnight.format('YYYYMMDDHHmmss')}
}

/**
 * 
 * @returns Object; DateTimeObject, DateTimeString, DateTimeCompactString
 */
export const getCurrentDateTime = () => {
    const currentDateTimeObject = dayjs()
    return {DateTimeObject: currentDateTimeObject, DateTimeString: currentDateTimeObject.format('YYYY-MM-DD HH:mm:ss'), DateTimeCompactString: currentDateTimeObject.format('YYYYMMDDHHmmss')}
}

export const getFirstDayThisWeekDate = (separator = "", padStart = false) => {
    let today = new Date();
    let day = today.getDay() || 7; // Get current day number, converting Sun. to 7
    if (day !== 1)
        // Only manipulate the date if it isn't Mon.
        today.setHours(-24 * (day - 1)); // Set the hours to day number minus 1
    //   multiplied by negative 24
    let firstDay = today.getDate();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    if (padStart) {
        firstDay = firstDay.toString().padStart(2, "0");
        return `${currentYear}${separator}${
            currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`
        }${separator}${firstDay}`;
    } else {
        return `${currentYear}${separator}${
            currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`
        }${separator}${firstDay.toString()}`;
    }
};

export const getLastDayThisWeekDate = (separator = "", padStart = false) => {
  let curr = new Date();
  let first = curr.getDate() - curr.getDay() +1; // First day is the day of the month - the day of the week 
  let last = first +6;
  let lastDay = new Date(curr.setDate(last))
  lastDay = lastDay.getDate()
  const currentMonth = curr.getMonth() + 1;
  const currentYear = curr.getFullYear();

  if (padStart) {
    lastDay = lastDay.toString().padStart(2, "0");
      return `${currentYear}${separator}${
          currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`
      }${separator}${lastDay}`;
  } else {
      return `${currentYear}${separator}${
          currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`
      }${separator}${lastDay.toString()}`;
  }
};

export const getFirstDayPreviousMonthDate = (
    separator = "",
    padStart = false
) => {
    const newDate = new Date();
    let year = newDate.getFullYear();
    const prevMonth = newDate.getMonth() + 1 - 1;
    let firstDay = "1";
    if (padStart) {
        firstDay = firstDay.padStart(2, "0");
        return `${year}${separator}${
            prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`
        }${separator}${firstDay}`;
    } else {
        return `${year}${separator}${
            prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`
        }${separator}${firstDay}`;
    }
};

export const getLastDayPreviousMonthDate = (
    separator = "",
    padStart = false
) => {
    const currentDate = new Date(getCurrentDate("-", true));
    const lastDayPrevMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
    );
    console.log("lastDayPrevMonth:::::: ", lastDayPrevMonth);
    let year = lastDayPrevMonth.getFullYear();
    let lastDay = lastDayPrevMonth.getDate();
    console.log("lastDay: ", lastDay);
    const prevMonth = currentDate.getMonth() + 1 - 1;
    console.log("prevMonth : ", prevMonth);
    if (padStart) {
        lastDay = String(lastDay).padStart(2, "0");
        return `${year}${separator}${
            prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`
        }${separator}${lastDay}`;
    } else {
        return `${year}${separator}${
            prevMonth < 10 ? `0${prevMonth}` : `${prevMonth}`
        }${separator}${lastDay}`;
    }
};

export const getFirstDayThisMonthDate = (
    separator = "",
    padStart = false,
    format = ""
) => {
    const newDate = new Date();
    const currentMonth = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let firstDay = "1";

    if (padStart) {
        firstDay = firstDay.padStart(2, "0");
        return `${year}${separator}${
            currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`
        }${separator}${firstDay}`;
    } else {
        return `${year}${separator}${
            currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`
        }${separator}${firstDay}`;
    }
};

export function getCurrentDate(separator = "", padStart = false) {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    if (padStart) {
        var dd = String(newDate.getDate()).padStart(2, "0");
        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${dd}`;
    } else {
        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`;
    }
}

export function formatTimestampToReadableDate(connMsg) {
    connMsg = connMsg.split(":");
    const timestamp = connMsg[1].trim();
    const [year, month, day, hh, ss, ms] = [
        timestamp.slice(0, 4),
        timestamp.slice(4, 6),
        timestamp.slice(6, 8),
        timestamp.slice(8, 10),
        timestamp.slice(10, 12),
        timestamp.slice(12, 14),
    ];
    const readableDate =
        year + "-" + month + "-" + day + " " + hh + ":" + ss + ":" + ms;
    return connMsg[0] + ": " + readableDate;
    /*
  //TODO auto conversion doesnt work because of auto choose timezones
  const timestampNr = Number(timestamp)
  console.log("timstamp in data formatter: ", timestampNr)
  let date = new Date(timestampNr);
  console.log("direct date : ", date)
  console.log("Date: "+date.getDate()+
          "/"+(date.getMonth()+1)+
          "/"+date.getFullYear()+
          " "+date.getHours()+
          ":"+date.getMinutes()+
          ":"+date.getSeconds());
          */
}

export const CreateRouteExport = (
    data,
    createdOfUser = "",
    dateFrom = "",
    dateTo = "",
    summaryData = null
) => {
    var date = new Date();

    const title = "book" + date.toLocaleString();

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Körjournal");
    header(workbook, worksheet, createdOfUser, "Körjournal", dateFrom, dateTo);

    if (summaryData) {
        worksheet.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Summary",
            "Nr of Trips",
            "Traveltime (dd:HH:mm)",
            "Distance (km)",
        ]);
        worksheet.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Private",
            summaryData.totalPrivateRoutes,
            summaryData.totalPrivateTraveltime,
            summaryData.totalPrivateDistance,
        ]);
        worksheet.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Business",
            summaryData.totalBusinessRoutes,
            summaryData.totalBusinessTraveltime,
            summaryData.totalBusinessDistance,
        ]);
        worksheet.addRow([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Total",
            summaryData.totalRoutes,
            summaryData.totalTraveltime,
            summaryData.totalDistance,
        ]);
        worksheet.addRow([]);
    }

    worksheet.columns = [
        { key: "LicensePlate" },
        { key: "Driver" },
        { key: "RouteID" },
        { key: "StartTimeStamp" },
        { key: "EndTimeStamp" },
        { key: "StartAddress" },
        { key: "StopAddress" },
        { key: "RouteType" },
        { key: "TravelTime" },
        { key: "Distance" },
        { key: "Consumption" },
        { key: "obstart" },
        { key: "obstop" },
        { key: "Comment" },
    ];

    worksheet.addRow([
        "Vehicle",
        "Driver",
        "Route ID",
        "Start Time",
        "Stop Time",
        "Start Address",
        "Stop Address",
        "Route type",
        "Traveltime",
        "Distance",
        "Consumption",
        "Odometer Start",
        "Odometer Stop",
        "Comment",
    ]);

    data.map((x) =>
        worksheet.addRow([
            x.LicensePlate,
            x.Driver,
            x.RouteID,
            x.StartTime,
            x.EndTime,
            x.StartAddress,
            x.StopAddress,
            x.RouteType,
            x.TravelTime,
            x.Distance,
            x.Consumption,
            x.odometerStart,
            x.odometerStop,
            x.Purpose,
        ])
    );

    //style row number 3
    worksheet.getRow(3).font = { family: 4, size: 12, bold: true };

    worksheet.columns.forEach(function (column, i) {
        var maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
            var columnLength = cell.value ? cell.value.toString().length : 15;
            if (columnLength > maxLength) {
                maxLength = columnLength;
            }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
    });
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(blob, title + ".xlsx");
    });
};

export const CreateCongestiontaxExport = (
    data,
    createdOfUser = "",
    totalCostBusiness,
    totalCostPrivate,
    dateIntervalSearch,
    callback
) => {
    var date = new Date();
    const title = "Congestiontax" + date.toLocaleString();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Congestiontax");
    header(workbook, worksheet, createdOfUser);
    worksheet.addRow([
        "Tollpassings for the interval:",
        dateIntervalSearch.dateFrom,
        dateIntervalSearch.dateTo,
    ]);

    worksheet.columns = [
        { key: "Date" },
        { key: "Driver" },
        { key: "LicensePlate" },
        { key: "TollZone" },
        { key: "CostBusiness" },
        { key: "CostPrivate" },
    ];
    worksheet.addRow([
        "Date",
        "Driver",
        "License Plate",
        "Toll Zone",
        "Cost Business",
        "Cost Private",
    ]);
    data.map((x) =>
        worksheet.addRow([
            x.DateTime,
            x.Driver,
            x.LicensePlate,
            x.TollZone,
            // x.CostBusiness,
            callback(x, "Business"),
            // x.CostPrivate,
            callback(x, "Private"),
            //x.Type
        ])
    );
    worksheet.addRow([
        "Total cost",
        "",
        "",
        "",
        totalCostBusiness,
        totalCostPrivate,
    ]);
    download(workbook, worksheet, title);
};

export const CreateUserlogExport = (data) => {
    //console.log("DATTA to function "+data);
    var date = new Date();
    //title
    const title = "Userlog" + date.toLocaleString();

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Userlog");
    header(workbook, worksheet);

    worksheet.columns = [
        { key: "DateTime" },
        { key: "UserName" },
        { key: "Status" },
        { key: "IPAddress" },
    ];

    worksheet.addRow(["DateTime", "UserName", "Status", "IPAddress"]);
    data.map((x) =>
        //console.log(x)
        worksheet.addRow([x.date, x.user, x.Status, x.ip])
    );
    download(workbook, worksheet, title);
};

export const createVehicleExport = (data, createdOfUser = "") => {
    var date = new Date();
    const title = "VehicleList_" + date.toLocaleString();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Fordonslista");

    header(workbook, worksheet, createdOfUser);
    worksheet.columns = [
        { key: "LicensePlate" },
        { key: "Brand" },
        { key: "Model" },
        { key: "Miles" },
        { key: "Owner" },
        { key: "Driver" },
    ];

    worksheet.addRow([
        "LicensePlate",
        "Brand",
        "Model",
        "Miles",
        "Owner",
        "Driver",
    ]);
    data.map((x) => {
        worksheet.addRow([
            x.LicensePlate,
            x.Brand,
            x.Model,
            x.Miles,
            x.Owner,
            x.Driver,
        ]);
    });
    download(workbook, worksheet, title);
};

const header = async (
    workbook,
    worksheet,
    createdOfUser = "",
    typeOfList = "Fordonslista",
    dateFrom = "",
    dateTo = ""
) => {
    if (dateFrom && dateTo) {
        worksheet.addRow(["JIRICOM", dateFrom + " - " + dateTo]);
    } else {
        worksheet.addRow(["JIRICOM"]);
    }

    if (createdOfUser) {
        worksheet.addRow([typeOfList, "Hämtad av: " + createdOfUser]);
    }

    // add image to workbook by buffer
    /*const imageId2 = await workbook.addImage({
    //filename: "../../assets/Images/logoJiricom.png",
    buffer: fs.readFileSync("../../assets/Images/logoJiricom.png"),
    extension: 'png',
  });
  worksheet.addImage(imageId2, 'B2:D6');
  //worksheet.addImage(imageId2);
 /* worksheet.addImage(imageId2, {
    tl: { col: 0, row: 0 },
    ext: { width: 500, height: 200 }
  });*/

    //worksheet.addRow(["Fordonslista", "Hämtad av: Anders Andersson"]);

    // for the graduate graphic designers...
    /*worksheet.getCell("A1").font = {
    name: "Arial Black",
    color: { argb: "FF00FF00" },
    family: 2,
    size: 16,
    italic: true,
  };*/
    //headers will help to keep good standard to en rows"
};
const download = (workbook, worksheet, title) => {
    //style row number 3
    worksheet.getRow(3).font = { family: 4, size: 12, bold: true };
    worksheet.columns.forEach(function (column, i) {
        var maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
            var columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
                maxLength = columnLength;
            }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
    });

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(blob, title + ".xlsx");
    });
    /*workbook.csv.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, title + '.csv');
  })*/
};

export const XOR = (a,b) => {
    return ( a || b ) && !( a && b );
}
