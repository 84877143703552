import isEmpty from "../../utils/is-empty";
import getStorageValue from "../../utils/getStorageValue";

const initialState = {
  isAuthenticated: getStorageValue("Access-token") !== null,
  user: {},
  token: getStorageValue("Access-token"),
  loading: false,
  checkLoginLoading: false
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_LOADER":
      return {
        ...state,
        loading: payload,
      };
    case "SET_CHECK_LOGIN_LOADER":
      return {
        ...state,
        checkLoginLoading: payload,
      };
    case "LOGIN_DETAILS":
      return {
        ...state,
        token: payload,
        isAuthenticated: !isEmpty(payload),
      };
    case "SET_LOGIN_DETAILS":
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
