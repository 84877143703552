import React from "react"
import {Route, Switch} from "react-router-dom";
// import Dashboard from "./dashboard/dashboard.tsx";
import TRASDemoDashboard from "./dashboard/TRASDemoDashboard.tsx";
//import Vehiclestatus_old from "./vehicle/vehiclestatus";
import Vehiclestatus from "./vehicle/vehiclestatus";
import Vehiclesearch from "./vehicle/vehiclesearch";
import Vehiclecurrentmeterreadings from "./vehicle/currentmeterreadings";
import Vehiclebatterystatus from "./vehicle/batterystatus";
import Vehiclerefueling from "./vehicle/refueling";
import Vehicleinspections from "./vehicle/inspections";
import Vehiclevervice from "./vehicle/vehicleservice";
import Vehiclereplacement from "./vehicle/vehiclereplacement";
import Vehicleconfiguration from "./vehicle/vehicleconfiguration";
import Vehicleco2 from "./vehicle/co2";
import VehicleNotes from "./vehicle/notes";
import Vehicleoverview from "./vehiclecard/vehicleoverview";
import Vehiclecardbasic from "./vehiclecard/basic";
import Vehiclecardeconomy from "./vehiclecard/economy";
import Vehiclecardmaintenance from "./vehiclecard/maintenance";
import Vehiclecardsystem from "./vehiclecard/system";
import Vehiclecardnotes from "./vehiclecard/notes";
import Vehiclecardupdate from "../components/vehiclecard/update"
import Vehiclecardcertificatetravel from "./vehiclecard/certificatetravel";
import Driverlatesidentifications from "./driver/latestidentifications";
import Driveroverview from "./driver/driveroverview";
import Useroverview from "./driver/view";
import Driveranalysis from "./driver/driveranalysis";
import CurrentPosition from "./positions/CurrentPosition";
import Positionsfindnearest from "./positions/findnearest";
import Zones from "./positions/zones";
import Travelongoing from "./travels/ongoing";
import CompletedTrips from "./travels/CompletedTrips/DetailsView/completedTrips";
import completedTripsStandard from "./travels/CompletedTrips/StandardView/completedTripsStandard";
import completedTripsStandard2 from "./travels/CompletedTrips/StandardView/completedTripsStandard2";
import Travellog from "./travels/travellog";
import Congestiontax from "./travels/congestiontax";
import Travelstop from "./travels/travelstop";
import Purposerules from "./travels/purposerules";
import Autoexport from "./travels/autoexport";
import Manuallog from "./travels/manuallogbook";
import Changeuser from "./travels/changeuser";
import Travelcertificatetravel from "./travels/changeuser";
import Calender from "./reserve/calender";
import Vehicleservice from "./reserve/vehicleservice";
import Bookotheruser from "./reserve/bookotheruser";
import Devicealarm from "./alarm/devicealarm";
import Driveralarm from "./alarm/driveralarm";
import Servicealarm from "./alarm/servicealarm";
import Exchangealarm from "./alarm/exchangealarm";
import Zonalarm from "./alarm/zonalarm";
import Speedlarm from "./alarm/speedlarm";
import Receiverofalarm from "./alarm/receiverofalarm";
import Maintenancecompleted from "./alarm/devicealarm";
import Maintenancework from "./alarm/driveralarm";
import TCO from "./economy/tco";
import Currentmonthlycost from "./economy/currentmonthlycost";
import Leasing from "./economy/leasing";
import Fuel from "./economy/fuel";
import Utilization from "./analyzes/DegreeOfUtilization";
import Analyzesexport from "./analyzes/export";
import ISA from "./analyzes/ISA";
import Analyzesfuel from "./analyzes/fuel";
import Fuelimport from "./analyzes/fuelimport";
import Drivingbehavior from "./analyzes/drivingbehavior";
import Createownreport from "./reports/createownreport";
import Reports from "./reports/reports";
import AutoexportReports from "./reports/autoexport";
import Systemactivate from "./system/activate";
import SystemAutoexport from "./system/autoexportreports";
import Boxalarm from "./system/boxalarm";
import Systemdetails from "./system/details";
import Feedback from "./system/feedback";
import Systemoverview from "./system/overview";
import functionPage from "./functionPage/functionPage";
import login from "./login/login";
import EnglishLanSetting from "../components/languagesetting/english";
import SwidishLanSetting from "../components/languagesetting/swidish";
import JapaneseLanSetting from "../components/languagesetting/japanese";
import AuthRoute from "../utils/AuthRoute";
import NotFoundPage from "./error/NotFound";
import UserList from "./admin/users/listusers";
import UserLog from "./admin/users/userlog";
import Organisations from "./admin/organisation/organisation";
import AccessTemplates from "./admin/access/access";
import RegisterVehicle from "./admin/vehicle/registerVehicle"
import SetNewPassword from "../components/forgotpassword/SetNewPasswordFormContent";
import QrScanEntry from "./qrScan/qrScanEntry.tsx"

class Routes extends React.Component {
    render() {

        return (
            <Switch>
                {/* <AuthRoute path="/" exact component={Dashboard}/> */}
                <AuthRoute path="/" exact component={TRASDemoDashboard}/>
                {/* Vehicle */}
                <AuthRoute path="/vehicle/status" exact component={Vehiclestatus}/>
                <AuthRoute path="/vehicle/search" exact component={Vehiclesearch}/>
                <AuthRoute path="/vehicle/currentmeterreadings" exact component={Vehiclecurrentmeterreadings}/>
                <AuthRoute path="/vehicle/batterystatus" exact component={Vehiclebatterystatus}/>
                <AuthRoute path="/vehicle/refueling" exact component={Vehiclerefueling}/>
                <AuthRoute path="/vehicle/inspections" exact component={Vehicleinspections}/>
                <AuthRoute path="/vehicle/service" exact component={Vehiclevervice}/>
                <AuthRoute path="/vehicle/replacement" exact component={Vehiclereplacement}/>
                <AuthRoute path="/vehicle/configuration" exact component={Vehicleconfiguration}/>
                <AuthRoute path="/vehicle/co2" exact component={Vehicleco2}/>
                <AuthRoute path="/vehicle/notes" exact component={VehicleNotes}/>
                {/* Vehicle Card */}
                <AuthRoute path="/vehiclecard/overview/:vehicleIdFromParams"  component={Vehicleoverview}/>
                <AuthRoute path="/vehiclecard/basic" exact component={Vehiclecardbasic}/>
                <AuthRoute path="/vehiclecard/economy" component={Vehiclecardeconomy}/>
                <AuthRoute path="/vehiclecard/maintenance" exact component={Vehiclecardmaintenance}/>
                <AuthRoute path="/vehiclecard/system" exact component={Vehiclecardsystem}/>
                <AuthRoute path="/vehiclecard/notes" exact component={Vehiclecardnotes}/>
                <AuthRoute path="/vehiclecard/certificatetravel" exact component={Vehiclecardcertificatetravel}/>
                <AuthRoute path="/vehiclecard/update/:vehicleIdFromParams"  component={Vehiclecardupdate}/>
                {/*Driver*/}
                <AuthRoute path="/driver/lastestIdentifications" exact component={Driverlatesidentifications}/>
                <AuthRoute path="/driver/overview" exact component={Driveroverview}/>
                <AuthRoute path="/driver/analysis" exact component={Driveranalysis}/>
                {/*Positions*/}
                <AuthRoute path="/positions/current" exact component={CurrentPosition}/>
                <AuthRoute path="/positions/findnearest" exact component={Positionsfindnearest}/>
                <AuthRoute path="/positions/zones" exact component={Zones}/>
                {/*Travel*/}
                <AuthRoute path="/travels/ongoing" exact component={Travelongoing}/>
                <AuthRoute path="/travels/completedtrips/detailview" exact component={CompletedTrips}/>
                <AuthRoute path="/travels/completedtrips/standardview" exact component={completedTripsStandard}/>
                <AuthRoute path="/travels/completedtrips/standardview_v2" exact component={completedTripsStandard2}/>
                <AuthRoute path="/travels/travellog" exact component={Travellog}/>
                <AuthRoute path="/travels/congestiontax" exact component={Congestiontax}/>
                <AuthRoute path="/travels/stop" exact component={Travelstop}/>
                <AuthRoute path="/travels/purposerules" exact component={Purposerules}/>
                <AuthRoute path="/travels/autoexport" exact component={Autoexport}/>
                <AuthRoute path="/travels/manuellog" exact component={Manuallog}/>
                <AuthRoute path="/travels/changeuser" exact component={Changeuser}/>
                <AuthRoute path="/travels/certificatetravel" exact component={Travelcertificatetravel}/>
                {/*Reserve*/}
                <AuthRoute path="/reserve/vehicle" exact component={Calender}/>
                <AuthRoute path="/reserve/vehicleservice" exact component={Vehicleservice}/>
                <AuthRoute path="/reserve/bookotheruser" exact component={Bookotheruser}/>
                {/*Alarm*/}
                <AuthRoute path="/alarm/device" exact component={Devicealarm}/>
                <AuthRoute path="/alarm/driver" exact component={Driveralarm}/>
                <AuthRoute path="/alarm/service" exact component={Servicealarm}/>
                <AuthRoute path="/alarm/exchange" exact component={Exchangealarm}/>
                <AuthRoute path="/alarm/zon" exact component={Zonalarm}/>
                <AuthRoute path="/alarm/speed" exact component={Speedlarm}/>
                <AuthRoute path="/alarm/receiver" exact component={Receiverofalarm}/>
                {/*Maintenance*/}
                <AuthRoute path="/maintenance/completed" exact component={Maintenancecompleted}/>
                <AuthRoute path="/maintenance/work" exact component={Maintenancework}/>
                {/*Economy*/}
                <AuthRoute path="/economy/tco" exact component={TCO}/>
                <AuthRoute path="/economy/currentmonthlycost" exact component={Currentmonthlycost}/>
                <AuthRoute path="/economy/leasing" exact component={Leasing}/>
                <AuthRoute path="/economy/fuel" exact component={Fuel}/>
                {/*Analyzes*/}
                <AuthRoute path="/analyzes/utilization" exact component={Utilization}/>
                <AuthRoute path="/analyzes/export" exact component={Analyzesexport}/>
                <AuthRoute path="/analyzes/isa" exact component={ISA}/>
                <AuthRoute path="/analyzes/fuel" exact component={Analyzesfuel}/>
                <AuthRoute path="/analyzes/fuelimport" exact component={Fuelimport}/>
                <AuthRoute path="/analyzes/drivingbehavior" exact component={Drivingbehavior}/>
                {/*Report*/}
                <AuthRoute path="/reports/createownreport" exact component={Createownreport}/>
                <AuthRoute path="/reports/reports" exact component={Reports}/>
                <AuthRoute path="/reports/autoexport" exact component={AutoexportReports}/>
                {/*System*/}
                <AuthRoute path="/system/activate" exact component={Systemactivate}/>
                <AuthRoute path="/system/autoexport" exact component={SystemAutoexport}/>
                <AuthRoute path="/system/boxalarm" exact component={Boxalarm}/>
                <AuthRoute path="/system/details" exact component={Systemdetails}/>
                <AuthRoute path="/system/feedback" exact component={Feedback}/>
                <AuthRoute path="/system/overview" exact component={Systemoverview}/>

                {/*USER/DRIVERS*/}
                <AuthRoute path="/user/view/:UID"  component={Useroverview}/>
                
                {/* ADMIN */}
                <AuthRoute path="/admin/organisation" exact component={Organisations}/>
                <AuthRoute path="/admin/accesstemplate" exact component={AccessTemplates}/>
                <AuthRoute path="/admin/registervehicle" exact component={RegisterVehicle}/>
                <AuthRoute path="/admin/users" exact component={UserList}/>
                <AuthRoute path="/admin/userlog" exact component={UserLog}/>

                {/*EXTRA FUNCTION PAGE*/}
                <AuthRoute path="/functionpage" exact component={functionPage}/>
                <Route path="/login" exact component={login}/>
                <Route path="/forgot/:id" exact component={SetNewPassword} />

                <AuthRoute
                    path="/englishlangsetting"
                    exact
                    component={EnglishLanSetting}
                />
                <AuthRoute
                    path="/swedishlangsetting"
                    exact
                    component={SwidishLanSetting}
                />
                <AuthRoute
                    path="/japaneselangsetting"
                    exact
                    component={JapaneseLanSetting}
                />

                {/* OTHERS */}
                <AuthRoute path="/mobile/rfid/:rfid" exact component={QrScanEntry}/>

                <Route path="*" component={NotFoundPage} />
            </Switch>
        )
    }
}


export default Routes;