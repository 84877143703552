import React, { Component } from "react";
import { Form, Select, Radio, Checkbox } from "antd";
import Header from "../../components/breadcrumbheader/Header";
import { Option } from "antd/es/mentions";
import {
    InfoWindow,
    GoogleMap,
    LoadScript,
    Marker,
} from "@react-google-maps/api";
import redmarkerImage from "../../assets/Images/carred.png";
import yellowmarkerImage from "../../assets/Images/caryellow.png";
import greenmarkerImage from "../../assets/Images/cargreen.png";
import * as Apis from "../../utils/api";
import { formItemLayout } from "../../components/forms/formLayout";
class CurrentPosition extends Component {
    state = {
        centers: [],
        openInfoWindowMarkerId: [],
        positions: [],
        centerpoint: "",
        open: false,
    };

    componentDidMount() {
        this.getData();
    }

    getData() {
        Apis.getPositions()
            .then((res) => {
                console.log("getPositions res", res);
                if (res.Status == "NOK") {
                    console.log("error request positions");
                } else {
                    let arr = [];
                    console.log("API", res.Results);
                    console.log("will", this.state.centers);
                    for (var key in res.Results) {
                        if (res.Results.hasOwnProperty(key)) {
                            arr.push({
                                Id: res.Results[key]["Id"],
                                lat: res.Results[key]["Latitude"],
                                lng: res.Results[key]["Langitude"],
                                LicensePlate: res.Results[key]["LicensePlate"],
                                vid: res.Results[key]["VId"],
                                Kor: res.Results[key]["Kor"],
                                Hast: res.Results[key]["Velocity"],
                                TravelTime: res.Results[key]["TravelTime"],
                                UnixTime: res.Results[key]["UnixTime"],
                                DateTime: res.Results[key]["DateTime"],
                            });
                        }
                    }
                    this.setState({ centers: arr });
                    this.setState({ centerpoint: this.state.centers[0] });
                }
                setInterval(() => this.refreshData(), 10000);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    refreshData() {
        Apis.getPositions()
            .then((res) => {
                if (res.Status == "NOK") {
                    console.log("error updating positions");
                } else {
                    let arr = [];
                    console.log("API", res.Results);
                    for (var key in res.Results) {
                        if (res.Results.hasOwnProperty(key)) {
                            //see if you selected specific vehile to show just show them,

                            arr.push({
                                Id: res.Results[key]["Id"],
                                lat: res.Results[key]["Latitude"],
                                lng: res.Results[key]["Langitude"],
                                LicensePlate: res.Results[key]["LicensePlate"],
                                vid: res.Results[key]["VId"],
                                Kor: res.Results[key]["Kor"],
                                Hast: res.Results[key]["Velocity"],
                                TravelTime: res.Results[key]["TravelTime"],
                                UnixTime: res.Results[key]["UnixTime"],
                                DateTime: res.Results[key]["DateTime"],
                            });
                        }
                    }
                    this.setState({ centers: arr });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onLoad = (infoWindow) => {};

    onFinish = (values) => {
        console.log("values", values);
        console.log("STATE ", this.state.centers);
        let markerArr = [];
        let infoWindow = [];
        if (values.vehicles !== undefined && values.vehicles.length > 0) {
            values.vehicles.map((k, i) => {
                console.log("loop i loop ", i);
                console.log("loop i loop ", k);
                //let obj = jsonData.Positioner.find(o => o.RegNr === k);
                let obj = this.state.centers.find((o) => o.RegNr === k);
                console.log("hittad ", obj);
                markerArr.push({ lat: obj.lat, lng: obj.lng, info: obj.RegNr });
                if (values.infoWindowAction !== "hide") {
                    console.log("helloa :D");
                    infoWindow.push(i);
                }
            });
        } else {
            //jsonData.Positioner.map((k, i) => {
            this.state.centers.map((k, i) => {
                console.log("loop i loop ", i);
                console.log("loop i loop ", k);
                markerArr.push({ lat: k.lat, lng: k.lng, info: k.RegNr });
                if (values.infoWindowAction !== "hide") {
                    infoWindow.push(i);
                }
            });
        }
        this.setState({
            centers: markerArr,
            openInfoWindowMarkerId: infoWindow,
        });
    };

    handleToggleInfoWindow = (markerId) => {
        if (!this.isSelected(markerId)) {
            this.setState({
                openInfoWindowMarkerId: [
                    ...this.state.openInfoWindowMarkerId,
                    markerId,
                ],
            });
        }
    };

    handleToggleClose = (markerId) => {
        const index = this.getIndex(markerId);
        const newMarker = [...this.state.openInfoWindowMarkerId];
        newMarker.splice(index, 1);
        this.setState({
            openInfoWindowMarkerId: newMarker,
        });
    };

    showDrawer = () => {
        console.log("showDrawer");
        this.setState({
            open: true,
        });
    };

    isSelected = (key) => {
        const index = this.getIndex(key);
        return index != -1;
    };

    getIndex = (key) => {
        return this.state.openInfoWindowMarkerId.indexOf(key);
    };

    // SET MARKER ICON ACCORDING TO THE STATUS
    getMarkerIcon = (id) => {
        const standing = redmarkerImage;
        const moving = yellowmarkerImage;
        const reached = greenmarkerImage;
        switch (id) {
            case 0:
                return standing;
            case 1:
                return moving;
            case 2:
                return reached;
        }
    };

    render() {
        const mapContainerStyle = {
            height: "100vh",
            width: "100%",
        };
        const divStyle = {
            background: "white",
            cursor: "pointer",
        };

        return (
            <div className="functionPagediv">
                <Header
                    filter={true}
                    floating={true}
                    form_id={"currentPositionForm"}
                >
                    <Form
                        name="currentPositionForm"
                        //ref={formRef}
                        id={"currentPositionForm"}
                        onFinish={this.onFinish}
                        {...formItemLayout}
                    >
                        <Form.Item name="vehicles">
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                placeholder="Select vehicles"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {this.state.positions.map((k, i) => (
                                    <Option value={k.unit}>{k.info}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="companies">
                            <Select
                                allowClear
                                showSearch
                                placeholder="Select companies"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="All Companies">
                                    All Companies
                                </Option>
                                <Option value="Bilpool">Bilpool</Option>
                                <Option value="Filial Nord Test">
                                    Filial Nord Test
                                </Option>
                                <Option value="Filial Syd">Filial Syd</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="infoWindowAction">
                            <Radio.Group defaultValue="a" buttonStyle="solid">
                                <Radio.Button value="show_moving">
                                    Show info window on moving vehicles
                                </Radio.Button>
                                <Radio.Button value="show">
                                    Show all info windows
                                </Radio.Button>
                                <Radio.Button value="hide">
                                    Hide all info windows
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="Check-box">
                            <Checkbox value={"show-only-moving"}>
                                Show only moving vehicles
                            </Checkbox>
                            <Checkbox value={"follow-vehicles"}>
                                Follow Vehicles
                            </Checkbox>
                            <Checkbox value={"show-only-moving"}>
                                Show detailed info windows
                            </Checkbox>
                        </Form.Item>
                    </Form>
                </Header>
                <LoadScript
                    googleMapsApiKey="AIzaSyA58-rBlwfGOHKU3X99wwccgjC8o0kw7uQ"
                    language="EN"
                >
                    <GoogleMap
                        id="marker-example"
                        mapContainerStyle={mapContainerStyle}
                        zoom={6}
                        center={this.state.centerpoint}
                    >
                        {this.state.centers.map((k, i) => (
                            <Marker
                                key={i}
                                icon={this.getMarkerIcon(k.Kor)}
                                position={k}
                                onClick={() => this.handleToggleInfoWindow(i)}
                            >
                                {this.isSelected(i) && (
                                    <InfoWindow
                                        onLoad={this.onLoad}
                                        onCloseClick={() =>
                                            this.handleToggleClose(i)
                                        }
                                    >
                                        <div style={divStyle}>
                                            <h1 className={"infowindow-text"}>
                                                {k.LicensePlate}
                                            </h1>
                                            <p>
                                                {k.Id ? (
                                                    <>
                                                        Device Id: {" " + k.Id}{" "}
                                                    </>
                                                ) : (
                                                    " "
                                                )}

                                                {k.DateTime ? (
                                                    <>
                                                        <br /> {k.DateTime}{" "}
                                                    </>
                                                ) : (
                                                    " "
                                                )}

                                                {k.TravelTime ? (
                                                    <>
                                                        <br /> Travel Time:{" "}
                                                        {" " + k.TravelTime}{" "}
                                                    </>
                                                ) : (
                                                    " "
                                                )}
                                            </p>
                                        </div>
                                    </InfoWindow>
                                )}
                            </Marker>
                        ))}
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    }
}

export default CurrentPosition;
