import React, { Component } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import {
    MinusSquareOutlined,
    UserOutlined,
    ProfileOutlined,
    BellOutlined,
    PoweroffOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    TableOutlined,
    PlusCircleOutlined,
    BarChartOutlined,
    CheckSquareOutlined,
    DownOutlined,
    CustomerServiceOutlined,
    ProjectOutlined,
    TranslationOutlined,
    SettingOutlined,
    ClusterOutlined,
    DownloadOutlined,
} from "@ant-design/icons";

import Leftmenu from "../leftmenu/leftmenu";
import logo from "../../assets/Images/logo.png";
import logoBig from "../../assets/Images/logoJiricom.png";
import "../../assets/css/sidebar.css";
import {
    addedWidgets,
    allWidgets,
    addedWidgets1,
    addedWidgets2,
    addedWidgets3,
    changeLayout,
    changeLang,
} from "../../actions/index";
import { connect } from "react-redux";
import { Layout, Drawer, message, Menu, Select, Button, Modal } from "antd";
import { renderFormatMsg, nNotis, NotisCheck } from "../common/function";
import Routes from "../../pages/Routes";
import * as Apis from "../../utils/api";

const { Option } = Select;
const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        name: "NONE",
        profileData: [],
        logoShow: true,
        collapsed: false,
        widgetsDrawerShow: false,

        mySettingsModalVisible: false,

        // isDraggable: false,
        addedwidgets: [],
        addedwidgets1: [],
        addedwidgets2: [],
        addedwidgets3: [],
        widgetsArr: [
            {
                icon: <CheckSquareOutlined />,
                title: renderFormatMsg("bookedVehiText", "Booked Vehicles"),
                description: renderFormatMsg(
                    "bookedVehicleDes",
                    "Display booked vehicles"
                ),
            },
            {
                icon: <TableOutlined />,
                title: renderFormatMsg("currentAlramText", "Current Alarms"),
                description: renderFormatMsg(
                    "tableCurrentAlarmDes",
                    "Table of current alarms"
                ),
            },
            {
                icon: <TableOutlined />,
                title: renderFormatMsg("ongoingTravelsText", "Ongoing Travels"),
                description: renderFormatMsg(
                    "tableOngoingTravelsDes",
                    "Table of ongoing travels"
                ),
            },
            {
                icon: <BarChartOutlined />,
                title: renderFormatMsg("numOfTravelsText", "Number of Travels"),
                description: renderFormatMsg(
                    "chartNumOfTravelsDes",
                    "Bar chart for number of travels"
                ),
            },
            {
                icon: <BarChartOutlined />,
                title: renderFormatMsg("totalkmText", "Total (km)"),
                description: renderFormatMsg(
                    "chartTotalKmDes",
                    "Bar chart for total(km)"
                ),
            },
            {
                icon: <BarChartOutlined />,
                title: renderFormatMsg("activeVehiText", "Active Vehicles"),
                description: renderFormatMsg(
                    "chartActiveVehicleDes",
                    "Bar chart for active vehicles"
                ),
            },
            {
                icon: <MinusSquareOutlined />,
                title: renderFormatMsg("todaysDateText", "Today's Date"),
                description: renderFormatMsg(
                    "staticTodaysDateDes",
                    "Display today's date"
                ),
            },
            {
                icon: <MinusSquareOutlined />,
                title: renderFormatMsg(
                    "totaNumOfTravels",
                    "Total Number of Travels"
                ),
                description: renderFormatMsg(
                    "staticTotalNumOfTravelsDes",
                    "Display total number of travels"
                ),
            },
            {
                icon: <MinusSquareOutlined />,
                title: renderFormatMsg(
                    "totalTravelsTodayText",
                    "Total Travels Today"
                ),
                description: renderFormatMsg(
                    "staticTotalTravelsTodayDes",
                    "Display total travels today"
                ),
            },
            {
                icon: <MinusSquareOutlined />,
                title: renderFormatMsg(
                    "totalDrivingDisText",
                    "Total Driving Distance"
                ),
                description: renderFormatMsg(
                    "staticTotalDrivingDisDes",
                    "Display total driving distance"
                ),
            },
            {
                icon: <MinusSquareOutlined />,
                title: renderFormatMsg(
                    "totalDrivingDistodayText",
                    "Total Driving Distance Today (Km)"
                ),
                description: renderFormatMsg(
                    "staticTotalDrivingDisTodayDes",
                    "Display total driving distance today (Km)"
                ),
            },
            {
                icon: <MinusSquareOutlined />,
                title: renderFormatMsg(
                    "numOfActiveVehiTodayText",
                    "Number of Active Vehicles Today"
                ),
                description: renderFormatMsg(
                    "staticNumOfActiveVehiTodayDes",
                    "Display number of active vehicles today"
                ),
            },
        ],
    };

    componentDidMount() {
        this.props.dispatch(allWidgets(this.state.widgetsArr));
        Apis.getUserProfile()
            .then((res) => {
                if (res.Status == "NOK") {
                    console.log("error");
                } else {
                    this.setState({
                        name: res.Data.Name,
                        profileData: res.Data,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handlelangChange = (langAbbrevation) => {
        this.props.dispatch(changeLang(langAbbrevation));
    };


    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
            logoShow: !this.state.logoShow,
        });
    };

    addWidgets = () => {
        this.setState({ widgetsDrawerShow: true });
    };

    changeLayout = () => {
        this.props.dispatch(
            changeLayout(this.props.changeLayout === true ? false : true)
        );
    };

    onClose = () => {
        this.setState({ widgetsDrawerShow: false });
    };
    pluswidgets = (e, title) => {
        if (this.props.activedKey === "newTab0") {
            this.setState(
                {
                    addedwidgets1: [
                        ...this.state.addedwidgets1,
                        { title: title },
                    ],
                },
                () =>
                    // console.log("addedwidgets", this.state.addedwidgets)
                    this.props.dispatch(addedWidgets1(this.state.addedwidgets1))
            );
            message.success(`Widgets added successfully`);
        }
        if (this.props.activedKey === "newTab1") {
            this.setState(
                {
                    addedwidgets2: [
                        ...this.state.addedwidgets2,
                        { title: title },
                    ],
                },
                () =>
                    // console.log("addedwidgets", this.state.addedwidgets)
                    this.props.dispatch(addedWidgets2(this.state.addedwidgets2))
            );
            message.success(`Widgets added successfully`);
        }
        if (this.props.activedKey === "newTab2") {
            this.setState(
                {
                    addedwidgets3: [
                        ...this.state.addedwidgets3,
                        { title: title },
                    ],
                },
                () =>
                    this.props.dispatch(addedWidgets3(this.state.addedwidgets3))
            );
            message.success(`Widgets added successfully`);
        }
        if (this.props.activedKey === "1") {
            this.setState(
                {
                    addedwidgets: [
                        ...this.state.addedwidgets,
                        { title: title },
                    ],
                },
                () => this.props.dispatch(addedWidgets(this.state.addedwidgets))
            );
            message.success(`Widgets added successfully`);
        }
    };
    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    handlelangChange = (e) => {
        console.log("e change lang", e);
        this.props.dispatch(changeLang(e));
    };

    handleLogOut = () => {
        var myItem = localStorage.getItem("pathHistory");
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("pathHistory", myItem);
        window.location.replace("/");
    };

    showModal = () => {
        this.setState({
            mySettingsModalVisible: !this.state.mySettingsModalVisible,
        });
    };

    handleOk = () => {
        this.setState({
            mySettingsModalVisible: !this.state.mySettingsModalVisible,
        });
        nNotis(
            "success",
            "top",
            "You have now pressed the ok button and closed this window"
        );
    };

    handleCancel = () => {
        this.setState({
            mySettingsModalVisible: !this.state.mySettingsModalVisible,
        });
        nNotis(
            "error",
            "top",
            "You have now pressed the cancel button and closed this window"
        );
    };

    render() {
        const menu = (
            <Menu>
                {/* <SubMenu key="sub14" title="Admin" icon={<UserOutlined />}> */}
                <SubMenu
                    key="sub15"
                    title={renderFormatMsg(
                        "menu.admin.Organisation",
                        "Organisation"
                    )}
                >
                    <Menu.Item key="79">
                        {renderFormatMsg(
                            "menu.admin.Organisation.Organizationalstructure",
                            "Organizational structure"
                        )}
                    </Menu.Item>
                    <Menu.Item key="80">
                        {renderFormatMsg(
                            "menu.admin.Organisation.Listorganization",
                            "List organization"
                        )}
                    </Menu.Item>
                    <Menu.Item key="81">
                        {renderFormatMsg(
                            "menu.admin.Organisation.Createaneworganization",
                            "Create a new organization"
                        )}
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="sub16"
                    title={renderFormatMsg(
                        "menu.admin.Permissions",
                        "Permissions"
                    )}
                >
                    <Menu.Item key="82">
                        {renderFormatMsg(
                            "menu.admin.Permissions.Listofpermissiontemplates",
                            "List of permission templates"
                        )}
                    </Menu.Item>
                    <Menu.Item key="83">
                        {renderFormatMsg(
                            "menu.admin.Permissions.Createpermissiontemplates",
                            "Create permission templates"
                        )}
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="sub17"
                    title={renderFormatMsg("menu.admin.User", "User")}
                >
                    <Menu.Item key="84">
                        <NavLink to="/user/list">
                            {renderFormatMsg(
                                "menu.admin.User.Listusers",
                                "List users"
                            )}
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="85">
                        <NavLink to="">
                            {renderFormatMsg(
                                "menu.admin.User.Createusers",
                                "Create users"
                            )}
                        </NavLink>
                    </Menu.Item>
                    <Menu.Item key="86">
                        <NavLink to="">
                            {renderFormatMsg(
                                "menu.admin.User.Userlog",
                                "User log"
                            )}
                        </NavLink>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="sub18"
                    title={renderFormatMsg("menu.admin.Data", "Data")}
                >
                    <Menu.Item key="87">
                        {renderFormatMsg(
                            "menu.admin.Data.Importvehicles",
                            "Import vehicles"
                        )}
                    </Menu.Item>
                    <Menu.Item key="88">
                        {renderFormatMsg(
                            "menu.admin.Data.Importusers",
                            "Import users"
                        )}
                    </Menu.Item>
                    <Menu.Item key="89">
                        {renderFormatMsg(
                            "menu.admin.Data.Importtravelpurposes",
                            "Import travel purposes"
                        )}
                    </Menu.Item>
                    <Menu.Item key="90">
                        {renderFormatMsg(
                            "menu.admin.Data.Importfuel",
                            "Import fuel"
                        )}
                    </Menu.Item>
                    <Menu.Item key="91">
                        {renderFormatMsg(
                            "menu.admin.Data.Exportvehicles",
                            "Export vehicles"
                        )}
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="92">
                    {renderFormatMsg(
                        "menu.admin.Backupofdata",
                        "Backup of data"
                    )}
                </Menu.Item>
                <SubMenu
                    key="sub19"
                    title={renderFormatMsg("menu.admin.Templates", "Templates")}
                >
                    <Menu.Item key="93">
                        {renderFormatMsg(
                            "menu.admin.Templates.Listofvehicletemplates",
                            "List of vehicle templates"
                        )}
                    </Menu.Item>
                    <Menu.Item key="94">
                        {renderFormatMsg(
                            "menu.admin.Templates.Createvehicletemplate",
                            "Create vehicle template"
                        )}
                    </Menu.Item>
                    <Menu.Item key="95">
                        {renderFormatMsg(
                            "menu.admin.Templates.Listofcosttemplates",
                            "List of cost templates"
                        )}
                    </Menu.Item>
                    <Menu.Item key="96">
                        {renderFormatMsg(
                            "menu.admin.Templates.Createcosttemplate",
                            "Create cost template"
                        )}
                    </Menu.Item>
                    <Menu.Item key="97">
                        {renderFormatMsg(
                            "menu.admin.Templates.Listservicetemplate",
                            "List service template"
                        )}
                    </Menu.Item>
                    <Menu.Item key="98">
                        {renderFormatMsg(
                            "menu.admin.Templates.Createservicetemplate",
                            "Create service template"
                        )}
                    </Menu.Item>
                    <Menu.Item key="99">
                        {renderFormatMsg(
                            ("menu.admin.Templates.Listvehiclereplacementtemplate",
                            "List vehicle replacement template")
                        )}
                    </Menu.Item>
                    <Menu.Item key="100">
                        {renderFormatMsg(
                            ("menu.admin.Templates.Createvehiclereplacementtemplate",
                            "Create vehicle replacement template")
                        )}
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="101">
                    {renderFormatMsg(
                        "menu.admin.Registervehicles",
                        "Register vehicles"
                    )}
                </Menu.Item>
                <Menu.Item key="102">
                    {renderFormatMsg("menu.admin.Userlog", "User log")}
                </Menu.Item>
                {/* </SubMenu> */}
            </Menu>
        );

        return (
            <Layout>
                <Sider
                    style={{ height: "100vh", position: "inherit" }}
                    trigger={null}
                    collapsible
                    collapsed={this.state.collapsed}
                >
                    <div className="logo">
                        <NavLink to="/">
                            <img
                                className="logo"
                                src={this.state.logoShow ? logoBig : logo}
                                alt="logo"
                            />
                        </NavLink>
                    </div>
                    <Leftmenu />
                </Sider>
                <Layout
                    className="site-layout collapassed-sec"
                    style={{ position: "static", height: "100vh" }}
                >
                    <Header>
                        {React.createElement(
                            this.state.collapsed
                                ? MenuFoldOutlined
                                : MenuUnfoldOutlined,
                            {
                                className: "trigger",
                                onClick: this.toggle,
                            }
                        )}
                        <Menu mode="horizontal">
                            {/* <Menu.Item
                                onClick={this.addWidgets}
                                className=" addwidgetbtn"
                                key="3989">
                                <span>{renderFormatMsg("addWidgetsBtn", "Add Widgets")}</span>
                                <PlusCircleOutlined className="addWidgetsIcon"/>
                            </Menu.Item> */}
                            {/* <Menu.Item
                                onClick={this.changeLayout}
                                className=" changelayoutbtn"
                                key="398"
                            >
                                {
                                    <>
                                        <span>
                                            {this.props.changeLayout === true
                                                ? renderFormatMsg(
                                                      "changeDoneBtn",
                                                      "Changes Done"
                                                  )
                                                : renderFormatMsg(
                                                      "changeLayoutBtn",
                                                      "Change Layout"
                                                  )}{" "}
                                        </span>
                                        <ProjectOutlined className="changeLayoutIcon" />
                                    </>
                                }
                            </Menu.Item> */}
                            <SubMenu
                                key="sub1"
                                title={
                                    <>
                                        <span>{this.state.name}</span>{" "}
                                        <DownOutlined />
                                    </>
                                }
                            >
                                <Menu.ItemGroup
                                    key="sub14"
                                    icon={<TranslationOutlined />}
                                    title="Language Settings"
                                >
                                    <Menu.Item key="105">
                                        <NavLink to="#" onClick={() => this.handlelangChange("en")}>
                                            English
                                        </NavLink>
                                    </Menu.Item>
                                    {/* <Menu.Item key="106">
                                        <NavLink to="#" onClick={() => this.handlelangChange("sv")}>
                                            Swedish
                                        </NavLink>
                                    </Menu.Item> */}
                                    <Menu.Item key="107">
                                        <NavLink to="#" onClick={() => this.handlelangChange("jp")}>
                                            Japanese / 日本語
                                        </NavLink>
                                    </Menu.Item>
                                </Menu.ItemGroup>
                                {/* <Menu.ItemGroup title="Item 2">
                                    <Menu.Item key="3">Support</Menu.Item>
                                </Menu.ItemGroup> */}
                                {/* <Menu.Item
                                    key="77"
                                    onClick={this.showModal}
                                    icon={<SettingOutlined />}
                                > */}
                                    {/* <NavLink to="#"> */}
                                    {/* {renderFormatMsg(
                                        "menu.mySetting",
                                        "My Setting"
                                    )} */}
                                    {/* </NavLink> */}
                                {/* </Menu.Item> */}
                                <Menu.Item
                                    key="104"
                                    icon={<PoweroffOutlined />}
                                >
                                    <NavLink to="#" onClick={this.handleLogOut}>
                                        {renderFormatMsg(
                                            "menu.logOut",
                                            "Log Out"
                                        )}
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>
                        </Menu>

                        {/* <Drawer
                            className="widgets-drawer"
                            title={renderFormatMsg(
                                "addWidgetToDashText",
                                "Add Widgets to Dashboard"
                            )}
                            placement="right"
                            closable={true}
                            onClose={this.onClose}
                            visible={this.state.widgetsDrawerShow}
                            width={400}
                        >
                            <h3>
                                {this.state.widgetsArr.length}{" "}
                                {renderFormatMsg("widgetsText", "Widgets")}
                            </h3>
                            {this.state.widgetsArr.map(widgets => (
                                <div className="widgetsblock">
                                    <div class="widgetsleft">
                                        <span>{widgets.icon}</span>
                                        <div className="widgetsdetails">
                                            <h4>{widgets.title}</h4>
                                            <p>{widgets.description}</p>
                                        </div>
                                    </div>
                                    <PlusCircleOutlined
                                        onClick={e => this.pluswidgets(e, widgets.title)}
                                        className="plus-addwidgets"
                                    />
                                </div>
                            ))}
                        </Drawer> */}

                        <Modal
                            title="Update your settings"
                            visible={this.state.mySettingsModalVisible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                        >
                            <p>Some contents...</p>
                        </Modal>
                    </Header>
                    <Routes />
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    // console.log("state mapstatetoprops", state);
    return {
        activedKey: state.widgets.activedKey,
        changeLayout: state.widgets.changeLayout,
        selectedLang: state.widgets.changeLang,
    };
};

export default connect(mapStateToProps)(Sidebar);

// export default Sidebar;
