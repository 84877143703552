const getStorageValue = (key) => {
  if (sessionStorage.getItem(key)) {
    return sessionStorage.getItem(key);
  } else if (localStorage.getItem(key)) {
    sessionStorage.setItem(key, localStorage.getItem(key));
    return localStorage.getItem(key);
  } else {
    return null;
  }
};

export default getStorageValue;
