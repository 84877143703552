import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as TRASDemoMock from "../../utils/TRASDemoDashboardMockData";
import { left } from "@antv/x6/lib/registry/port-layout/line";
import * as antdFormats from "../../utils/antdFormats";
import * as moment from "moment";
import { useDispatch } from "react-redux";

dayjs.extend(utc);

const initialState = {
    isHistoricalTravelsLoading: false,
    getTravels: [],
    getTravelsByUserId: [],
    ongoingTravels: [],
    amountOfOngoingTravels: 0,
    ongoingTravelsDriverNamesUniqueAntd: [],
    ongoingTravelsLicensePlatesUniqueAntd: [],
    travel: {},
    editTravel: {},
    fromDate: {},
    toDate: {},
    VehicleTravels: {},
    historicalTravels: [],
    historicalTravelsStatistics: [],
    travelByIdCoordinates: [],
    historicalTravelsLicensePlatesUniqueAntd: [],
    historicalTravelsDriverNamesUniqueAntd: [],
    travelByTravelId: {}
};

const travelsReducer = (state = initialState, { type, payload }, callback) => {
    switch (type) {
        case "GET_TRAVELS":
            // console.log("GET_TRAVELS REDUCER payload ", payload);

            payload.data.Routes = payload.data.Routes.map((route) => {
                return (route = {
                    ...route,
                    StartTime: route.Time.StartTime,
                    EndTime: route.Time.EndTime,
                });
            });


            return {
                ...state,
                getTravels: payload.data.Routes,
                fromDate: payload.fromDate,
                toDate: payload.toDate,
                callback,
            };
        case "GET_HISTORICAL_TRAVELS":
            if (payload.Count === 0) {
                payload.Routes = []
            }
            const sourceObject= {};
            const sources = payload.sources;
            
            const parsedTravels = payload.Routes.map((obj) => {
                return {
                    DriverInfo: obj.DriverInfo,
                    CompanyName: obj.CompanyName,
                    VehicleCompanyId: obj.VehicleCompanyId,
                    Key: obj.RouteID,
                    FirstLat: obj.Coords.StartLat,
                    FirstLon: obj.Coords.StartLng,
                    LastLat: obj.Coords.StopLat,
                    LastLon: obj.Coords.StopLng,
                    Attest: obj.Attest,
                    TravelId: obj.RouteID,
                    LicensePlate: obj.LicensePlate,
                    DriverName: obj.Driver,
                    DriverId: obj.DriverId,
                    StartAddress: obj.StartAddress,
                    StopAddress: obj.StopAddress,
                    Time:
                        obj.Time.StartTime + "<br>" + obj.Time.EndTime,
                    StartTime: obj.Time.StartTime,
                    EndTime: obj.Time.EndTime,
                    StartDateTime: obj.Time.StartTimeStamp,
                    EndDateTime: obj.Time.EndTimeStamp,
                    DateUnix: moment(obj.Time.StartTimeStamp).valueOf(),
                    TravelTime: obj.TravelTime,
                    TravelTimeSeconds: obj.TravelTimeSeconds,
                    Distance: obj.Distance,
                    DistanceKilometers: obj.DistanceKilometers,
                    Consumption: obj.Consumption,
                    Comment: obj.Comment,
                    TravelType: obj.RouteType,
                    Purpose: obj.Purpose,
                    OdometerStop: obj.odometerStop,
                    OdometerStart: obj.odometerStart,
                    VehicleId: obj.Vid,
                    Rfid: obj.Rfid,
                }
            })

            sources.forEach((source) => {
                sourceObject[source] = parsedTravels;
            });

            // console.log("GET_HISTORICAL_TRAVELS sourceObject in travels reducer: .historicalTravelsStatistics ", sourceObject.historicalTravelsStatistics)

            const historicalTravelsLicensePlatesUniqueAntd = antdFormats.makeUniqueAntd("LicensePlate", parsedTravels)
            // console.log("GET_HISTORICAL_TRAVELS historicalTravelsLicensePlatesUniqueAntd ", historicalTravelsLicensePlatesUniqueAntd)
            const historicalTravelsDriverNamesUniqueAntd = antdFormats.makeUniqueAntd("DriverName", parsedTravels)
            // console.log("GET_HISTORICAL_TRAVELS historicalTravelsDriverNamesUniqueAntd ", historicalTravelsDriverNamesUniqueAntd)
            
            return {
                ...state,
                ...sourceObject,
                isLoading: false,
                historicalTravelsLicensePlatesUniqueAntd: historicalTravelsLicensePlatesUniqueAntd,
                historicalTravelsDriverNamesUniqueAntd: historicalTravelsDriverNamesUniqueAntd,
                callback
            }

        case "GET_USERID_TRAVELS":
            if (payload?.Routes?.length >= 1) {
                payload.Routes = payload.Routes.map((route) => {
                    return (route = {
                        ...route,
                        StartTime: route.Time.StartTime,
                        EndTime: route.Time.EndTime,
                        TravelId: route.RouteID,
                        VehicleId: route.Vid,
                        DriverName: route.Driver,

                    });
                });
            } else {
                payload.Routes = []
            }


            return {
                ...state,
                getTravelsByUserId: payload.Routes,
                fromDate: payload.fromDate,
                toDate: payload.toDate,
                callback,
            };
        case "GET_TRAVEL_COORDINATES":
            // const latLngs = payload.Route.Coords.Poly.Coords.map((coordObject) => {
            //     return {
            //         lat: parseFloat(coordObject.latitude),
            //         lng: parseFloat(coordObject.longitude),
            //     }
            // })

            return {
                ...state,
                travelByTravelId: payload?.Route
                // travelByIdCoords: latLngs,
            }

        case "GET_VEHICLE_TRAVELS":
            //If api response is not a list with biggest date last, then reverse the list.
            const additionalTravelsDateObjects =
                payload.Results.VehicleTravelsWeekGraphData.map((travel) => {
                    return {
                        ...travel,
                        DayMonthName: dayjs
                            .utc(travel.Date)
                            .toString()
                            .slice(0, 11),
                    };
                });
            if (
                additionalTravelsDateObjects[0].Date >
                additionalTravelsDateObjects[1].Date
            ) {
                additionalTravelsDateObjects.reverse();
            }

            //If api response is not a list with biggest date last, then reverse the list.
            const additionalOdometerDateObjects =
                payload.Results.VehicleOdometerWeekGraphData.map(
                    (OdometerObj) => {
                        return {
                            ...OdometerObj,
                            DayMonthName: dayjs
                                .utc(OdometerObj.Date)
                                .toString()
                                .slice(0, 11),
                        };
                    }
                ).reverse();
            if (
                additionalOdometerDateObjects[0].Date >
                additionalOdometerDateObjects[1].Date
            ) {
                additionalOdometerDateObjects.reverse();
            }

            return {
                ...state,
                VehicleTravels: {
                    ...payload.Results,
                    VehicleTravelsWeekGraphData: additionalTravelsDateObjects,
                    VehicleOdometerWeekGraphData: additionalOdometerDateObjects,
                },
                callback,
            };
        case "GET_ONGOING_TRAVELS":
            
            const parseOngoingTravels = payload.Results.map((ongoingtravel) => {
                return {
                    travelId: ongoingtravel.TravelId,
                    licensePlate: ongoingtravel.LicensePlate,
                    driver: ongoingtravel.Driver,
                    startTime: ongoingtravel.StartTime,
                    travelTime: ongoingtravel.TravelTime,
                    distance: ongoingtravel.Distance.Value,
                };
            });
           
            return {
                ...state,
                ongoingTravels: parseOngoingTravels,
                callback,
            };
        case "GET_ONGOING_TRAVELS_TRAS_DEMO":
            const amountOfOngoingTravels = payload?.Result?.length;
            const ongoingTravelsDriverNamesUniqueAntd =
                antdFormats.makeUniqueAntd("DriverName", payload.Result);
            const ongoingTravelsLicensePlatesUniqueAntd =
                antdFormats.makeUniqueAntd("LicensePlate", payload.Result);

            //TODO check if position is correct. 

            return {
                ...state,
                ongoingTravels: payload.Result,
                amountOfOngoingTravels: amountOfOngoingTravels,
                ongoingTravelsDriverNamesUniqueAntd:
                    ongoingTravelsDriverNamesUniqueAntd,
                ongoingTravelsLicensePlatesUniqueAntd:
                    ongoingTravelsLicensePlatesUniqueAntd,
                callback,
            };

        case "SINGLE_TRAVEL":
            return {
                ...state,
                travel: payload,
                callback,
            };
        case "EDIT_TRAVEL":
            
            const routeId = payload.RouteID;
            const driverId = payload.DriverID;
            const routeType = payload.RouteType;
            const travelpurpose = payload.Purpose;
            const driverName = payload.DriverName;

            const newTravelList = state.getTravels.Routes.map((route) => {
                if (route.RouteID === routeId) {
                    
                    const updatedRoute = {
                        ...route,
                        DriverId:
                            driverId && driverId != " "
                                ? driverId
                                : route.DriverId,
                        RouteType:
                            routeType && routeType != " "
                                ? routeType
                                : route.RouteType,
                        Purpose:
                            travelpurpose && travelpurpose != " "
                                ? travelpurpose
                                : route.Purpose,
                        Driver:
                            driverName && driverName != " "
                                ? driverName
                                : route.Driver,
                    };
                    return updatedRoute;
                }
                return route;
            });
            return {
                ...state,
                getTravels: {
                    Count: state.getTravels.Count,
                    Status: state.getTravels.Status,
                    Routes: newTravelList,
                },
                editTravel: payload,
                callback,
            };
        case "SET_LOADER_HISTORICAL_TRAVELS":
            return {
                ...state,
                isHistoricalTravelsLoading: payload
            }
        default:
            return state;
    }
};
export default travelsReducer;
