import React, {Component} from "react";
import {InfoWindow, GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
//import "../../assets/css/CurrentPosition.css";
import redmarkerImage from "../../assets/Images/carred.png";
import yellowmarkerImage from "../../assets/Images/caryellow.png";
import greenmarkerImage from "../../assets/Images/cargreen.png";
import * as Apis from '../../utils/api';
class Map extends Component {
    state = {
        centers: [],
        openInfoWindowMarkerId: [],
        positions: [],
        centerpoint: ""
    }
    componentDidMount() {
        this.getData();
    }
    
    getData(){
        Apis.getPositions().then((res) => {
            console.log("Map MapsCurrent response: ", res)
            if (res.Status == "NOK") {
                console.log("error");
            } else {
                let arr = [];
                console.log("API",res.Positioner);
                console.log("will",this.state.centers);
                for (var key in res.Positioner) {
                    if (res.Positioner.hasOwnProperty(key)) {
                        arr.push({
                            lat: res.Positioner[key]['Lat'], 
                            lng: res.Positioner[key]['Lng'], 
                            RegNr:res.Positioner[key]['RegNr'],
                            vid:res.Positioner[key]['vID'],
                            Kor:res.Positioner[key]['Kor'],
                            Hast:res.Positioner[key]['Hast'],
                            ResTid:res.Positioner[key]['ResTid'],
                            TidStamp:res.Positioner[key]['TidStamp'],
                        });
                    }
                }
                this.setState({centers: arr});
                this.setState({centerpoint: this.state.centers[0]})
            }
            setInterval(() =>this.refreshData(), 10000);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    refreshData() {
        Apis.getPositions().then((res) => {
            if (res.Status == "NOK") {
                console.log("error");
            } else {
                let arr = [];
                console.log("API",res.Positioner);
                for (var key in res.Positioner) {
                    if (res.Positioner.hasOwnProperty(key)) {

                        //see if you selected specific vehile to show just show them,
                        arr.push({
                            lat: res.Positioner[key]['Lat'], 
                            lng: res.Positioner[key]['Lng'], 
                            RegNr:res.Positioner[key]['RegNr'],
                            vid:res.Positioner[key]['vID'],
                            Kor:res.Positioner[key]['Kor'],
                            Hast:res.Positioner[key]['Hast'],
                            ResTid:res.Positioner[key]['ResTid'],
                            TidStamp:res.Positioner[key]['TidStamp'],
                        });
                    }
                }
                this.setState({centers: arr});
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    onFinish = values => {
        console.log("values", values);
        console.log("STATE ", this.state.centers);
        let markerArr = [];
        let infoWindow = [];
        if (values.vehicles !== undefined && values.vehicles.length > 0) {
            values.vehicles.map((k, i) => {
                console.log("loop i loop ",i);
                console.log("loop k loop ",k);
                //let obj = jsonData.Positioner.find(o => o.RegNr === k);
                let obj = this.state.centers.find(o => o.RegNr === k);
                console.log("hittad ", obj);
                markerArr.push({lat: obj.lat, lng: obj.lng, info: obj.RegNr});
                if (values.infoWindowAction !== "hide") {
                    console.log("helloa :D");
                    infoWindow.push(i);
                }
            });
        } else {
            //jsonData.Positioner.map((k, i) => {
            this.state.centers.map((k, i) => {
                console.log("loop i loop ",i);
                console.log("loop i loop ",k);
                markerArr.push({lat: k.lat, lng: k.lng, info: k.RegNr})
                if (values.infoWindowAction !== "hide") {
                    infoWindow.push(i);
                }
            })
        }
        this.setState({centers: markerArr, openInfoWindowMarkerId: infoWindow});
    }

    handleToggleInfoWindow = (markerId) => {
        if (!this.isSelected(markerId)) {
            this.setState({
                openInfoWindowMarkerId: [...this.state.openInfoWindowMarkerId, markerId],
            });
        }
    }

    handleToggleClose = (markerId) => {
        const index = this.getIndex(markerId);
        const newMarker = [...this.state.openInfoWindowMarkerId];
        newMarker.splice(index, 1);
        this.setState({
            openInfoWindowMarkerId: newMarker
        });
    }

    isSelected = (key) => {
        const index = this.getIndex(key);
        return index != -1;
    };

    getIndex = (key) => {
        return this.state.openInfoWindowMarkerId.indexOf(key);
    };

    // SET MARKER ICON ACCORDING TO THE STATUS
    getMarkerIcon = (id) => {
        const standing = redmarkerImage;
        const moving = yellowmarkerImage;
        const reached = greenmarkerImage;
        switch (id) {
            case 0:
                return standing;
            case 1:
                return moving;
            case 2:
                return reached;
        }
    };

    //TODO make width height from parent container.
    render() {        
        const mapContainerStyle = {
            //height: window.innerHeight,
            height: "100%",
            width: "100%",
            position: "static"
        }
        const divStyle = {
            background: "white",
            cursor: "pointer",
        }

        console.log("RENDER GOOGLE MAP")

        return <>
            {/* <h1>helloa, {this.props.id} form maps</h1> */}
            <LoadScript
                googleMapsApiKey="AIzaSyA58-rBlwfGOHKU3X99wwccgjC8o0kw7uQ"
                language="EN"
            >
                <GoogleMap
                    id="marker-example"
                    mapContainerStyle={mapContainerStyle}
                    zoom={6}
                    center={this.state.centerpoint}
                >{
                    this.state.centers.map((k, i) => (
                        <Marker
                            key={i}
                            icon={this.getMarkerIcon(k.Kor)}
                            position={k}
                            onClick={() => this.handleToggleInfoWindow(i)}
                        >
                            {
                                this.isSelected(i) &&
                                <InfoWindow
                                    onLoad={this.onLoad}
                                    onCloseClick={() => this.handleToggleClose(i)}
                                >
                                    <div style={divStyle}>
                                        <h1 className={"infowindow-text"}>{k.RegNr}</h1>
                                        <p>
                                            Kor: {k.Kor}<br/>
                                            TidStamp: {k.TidStamp}<br/>
                                            Hast: {k.Hast}<br/>
                                            ResTid: {k.ResTid}
                                        </p>
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    ))
                }
                </GoogleMap>
            </LoadScript>
        </>;
      }
    }
export default Map;