const IntervalObjects =  {
  'Year': {'from': 'yearFrom', 'to': 'yearTo'},
  'Miles': {'from': 'milesFrom', 'to': 'milesTo'},
  'DateUnix' : {'from': 'dateFrom', 'to': 'dateTo'}
} as const

export const isValueInInterval = (valueFrom: any, valueTo: any, targetValue: any) => {
  [valueFrom, valueTo, targetValue] = [parseFloat(valueFrom),  parseFloat(valueTo),  parseFloat(targetValue)]

  if (valueTo == undefined || valueTo == null || isNaN(valueTo)  ) {
    return valueFrom <= targetValue
  } else if (valueFrom == undefined || valueFrom == null || isNaN(valueFrom)  ) {
    return targetValue <= valueTo 
  } else {
      return valueFrom <= targetValue && targetValue <= valueTo 
  }
}

const filterSearch = (filterData: any, dataToFilter: any) => {
    console.log("filterData ", filterData)
    console.log("dataToFilter ", dataToFilter)
    let attributesToMatch = []
    for (const [key, value] of Object.entries(filterData)) {
      if(value) {
          if(typeof value !== 'object') {
            attributesToMatch.push(key)
          } else if(Array.isArray(value)) {
            attributesToMatch.push(key)
          } else {
            //Looking at children if they have any values, then push only once to attributesToMatch
            for (const [keyChild, valueChild] of Object.entries(value)) {
              if(valueChild) {
                attributesToMatch.push(keyChild)
                break
              }
            }
          }
      }
    }

    console.log("attributesToMatch", attributesToMatch)
    let loop = Reflect.ownKeys(filterData);
    const nrOfTotalMatchesNeeded = attributesToMatch.length
    let filteredVehicles = []

    //TODO chjnage variable name of vehicle to smth general
    for (let vehicle of dataToFilter) {
      let validAttributes = loop.filter(field => {
        let value = filterData[field];
        let fieldString = field.toString()
        //console.log("fieldString ", fieldString)
        if (!value) {
          return;
        }
        //filterdata and datatofilter must have the same keyname in order to be filtered.
        if (!vehicle[field]) {
          return;
        }
        //field -> 'Year'
        //IntervalObjects[field] -> {'from': 'yearFrom', 'to': 'yearTo'}
        //IntervalObjects[field]['from'] -> 'yearFrom'
        if (Object.keys(IntervalObjects).includes(fieldString) ) {
          //console.log("value ", value)
          let valueFrom = filterData[field][IntervalObjects[fieldString as keyof typeof IntervalObjects].from]
          let valueTo = filterData[field][IntervalObjects[fieldString as keyof typeof IntervalObjects].to]
          //console.log("valueFrom valueTo", valueFrom, valueTo)
          return isValueInInterval(valueFrom, valueTo, vehicle[field])
        } else {
          //Array of values to search on vehicle with:
          if (Array.isArray(value) && value.length > 0) {
            //console.log("compare value[0]", value[0])
            //value -> ["1638790663066125",...]
            //console.log("compare vehicle[field]", vehicle[field].toString())
            //vehicle[field] -> 1638790663066125
            return value.some((subValue) => { 
              return vehicle[field].toString().includes(subValue)
            })
           
          //Else single value to search vehicle with:
          } else {
            return vehicle[field].toString().toLowerCase().includes(value.toLowerCase())
          }

          
        }
      });
      console.log("validAttributes ", validAttributes)
      if (validAttributes.length >= nrOfTotalMatchesNeeded) {
        filteredVehicles.push(vehicle)
      }
    }

    console.log("filteredVehicles ", filteredVehicles)
    return filteredVehicles
  }

  export default filterSearch