
export const TRASDemoMockData = 7;
export const getCurrentAddressTRASDemo = "Drottningsgatan 11A";
//Move 1: står stilla, 2 rör sig
export const getOngoingTravelsTRASDemo = [
    {
        Id: "123456789",
        TrackerId: "9013",
        DeviceId: "1090",
        TravelId: "7952_230627n12",
        VehicleId: "123456",
        DriverId: "1634329474513853",
        DriverName: "Linus Frost", //TODO klicka på namn, visa sub-table under raden om dagens historiska alkoholmätningar för den föraren.
        Rfid: "jk4356h",
        Move: 1,
        LicensePlate: "ABC458",
        Latitude: "57.7837130",
        Longitude: "14.2313664",
        TravelTime: "00:21:54",
    },
    {
        Id: "123456788",
        TrackerId: "9014",
        DeviceId: "1091",
        TravelId: "7952_444444444",
        VehicleId: "123456",
        DriverId: "1634329474513852",
        DriverName: "Daeiel Windahl",
        Rfid: "",
        Move: 2,
        LicensePlate: "CJH458",
        Latitude: "57.7837142",
        Longitude: "14.2378671",
        TravelTime: "00:21:53",
    },
    {
        Id: "123456787",
        TrackerId: "9015",
        DeviceId: "1092",
        TravelId: "7952_555555555",
        VehicleId: "123456",
        DriverId: "1634329474513854",
        DriverName: "Dabniel Windahl",
        Rfid: "jk4356h",
        Move: 1,
        LicensePlate: "CJH458",
        Latitude: "57.7837121",
        Longitude: "14.2313612",
        TravelTime: "00:31:29",
    },
    {
        Id: "123456785",
        TrackerId: "9016",
        DeviceId: "1094",
        TravelId: "7952_33333333",
        VehicleId: "123456",
        DriverId: "789789",
        DriverName: "Lianus Frost",
        Rfid: "jk4356h",
        Move: 2,
        LicensePlate: "CJH458",
        Latitude: "57.7837142",
        Longitude: "14.2313671",
        TravelTime: "01:23:54",
    },
    {
        Id: "123456786",
        TrackerId: "9017",
        DeviceId: "1093",
        TravelId: "7952_666666666",
        VehicleId: "123456",
        DriverId: "1634329474513855",
        DriverName: "Simon Vasquez",
        Rfid: "jk4356h",
        Move: 2,
        LicensePlate: "AAA123",
        Latitude: "57.7834355",
        Longitude: "14.2313655",
        TravelTime: "11:23:54",
    },
];

//List of todays AC 
//TODO in real, fetch whole table of ACs from today and map to routeIds? -> map ACs to TrackerId. always gonna happen if end-user follow rules.
export const getOngoingAlcoholMetersTRASDemo = [
    {
        AlcoholDeviceId: "9068",
        TrackerId: "9014",
        TravelId: "7952_444444444", //får inte alltid, om de blåser AC på kontoret.
        DriverId: "1634329474513852",
        StatusColor: "Blue",
        TravelStatus: "Stop",
        ActivationTimestamp: "2023-05-26 08:45:32",
        ActivationResult: "Approved",
        ActivationRemedy: "Accepted", //TODO admin skickar ut en action som föraren måste göra.
    },
    {
        AlcoholDeviceId: "9067",
        TrackerId: "9013",
        TravelId: "7952_230627n12",
        DriverId: "1634329474513853",
        StatusColor: "Yellow",
        TravelStatus: "Start",
        ActivationTimestamp: "2023-05-26 08:45:31",
        ActivationResult: "Warning",
        ActivationRemedy: "Ignored",
    },
    {
        AlcoholDeviceId: "9069",
        TrackerId: "9015",
        TravelId: "7952_555555555",
        DriverId: "1634329474513854",
        StatusColor: "Red",
        TravelStatus: "Finished",
        ActivationTimestamp: "2023-05-26 09:04:32",
        ActivationResult: "Invalid",
        ActivationRemedy: "Cleaned",
    },
    {
        AlcoholDeviceId: "9070",
        TrackerId: "9017",
        TravelId: "7952_666666666",
        DriverId: "1634329474513855",
        StatusColor: "BlinkRed",
        TravelStatus: "Start",
        ActivationTimestamp: "2023-05-25 08:04:32",
        ActivationResult: "NotApproved",
        ActivationRemedy: "Ignored",
    },
    {
        AlcoholDeviceId: "9071",
        TrackerId: "9018",
        TravelId: "",
        DriverId: "1634329474513856",
        StatusColor: "Yellow",
        TravelStatus: "Finish",
        ActivationTimestamp: "",
        ActivationResult: "Warning",
        ActivationRemedy: "Ignored",
    },
];

export const coordinatesToAddressTRASDemoResponse = {
    Status: "OK",
    Result: "Gatan 1, 42626 Staden"
}






