import { setLoader } from "../auth";
import instance from "../../instance";
import { callFail, callSuccess } from "../../utils/callback";
import getStorageValue from "../../utils/getStorageValue";
import * as Apis from "../../utils/api";

import * as TRASDemoMock from "../../utils/TRASDemoDashboardMockData";
import _ from "lodash";

// message: "Alc;routeId"
interface INotification {
    message: string;
    isBlinking?: boolean;
    isBeeping?: boolean;
}

export const requestAlcBlowToDeviceId = (deviceId: any, mock?: boolean) => {
    if (!mock) {
        return Apis.requestAlcBlow(deviceId)
            .then((response: any) => {
                // console.log("requestAlcBlowToDeviceId response ", response);
                return new Promise<any>((resolve, reject) => {
                    if (response.Status === "OK") {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                });
            })
            .catch((error: any) => {
                console.log(
                    "error in requestAlcBlowToDeviceId actions api call",
                    error
                );
                return new Promise((reject) => {
                    reject(error);
                });
            });
    } else {
        return new Promise<any>((resolve) => {
            resolve("Mock not yet implemented for this action.");
        });
    }
};

/**
 *
 * @param dateFrom e.g., 20230822103256
 * @param dateTo e.g., 20230822103220
 * @param callback
 * @param mock
 * @returns
 */
export const pullNotificationsByDate =
    (dateFrom?: any, dateTo?: any, callback?: any, mock?: boolean) =>
    (dispatch: any) => {
        if (callback === _) {
            callback = null;
        }
        dispatch(setLoader(true));

        if (!mock) {
            if (!dateFrom) dateFrom = "20231005103220"; //TODO yesterdays date
            Apis.pullNotifications(dateFrom, dateTo)
                .then((response) => {
                    if (response.Status === "OK") {
                        dispatch(
                            {
                                type: "GET_NOTIFICATIONS",
                                payload: response,
                            },
                            callback && callback(response)
                        );
                    } else {
                        dispatch({
                            type: "SET_ERRORS",
                            payload: response,
                        });
                    }
                })
                .catch((error) => {
                    console.log(
                        "error in pullNotifications actions api call",
                        error
                    );
                    if (error) {
                        dispatch({
                            type: "SET_ERRORS",
                            payload: error,
                        });
                    }
                    dispatch(setLoader(false));
                });
        } else {
            console.log("no mock data available");
        }
    };

export const pullNotificationsByDeviceIdDate =
    (
        deviceId: any,
        dateFrom?: any,
        dateTo?: any,
        callback?: any,
        mock?: boolean
    ) =>
    (dispatch: any) => {
        if (callback === _) {
            callback = null;
        }
        dispatch(setLoader(true));

        if (!mock) {
            if (!dateFrom) dateFrom = "2023-08-21 10:31:02"; //TODO yesterdays date
            const request = {
                deviceId: deviceId,
                dateFrom: dateFrom,
                dateTo: dateTo,
            };
            Apis.pullNotificationsByDeviceIdDate(request)
                .then((response) => {
                    // console.log("pullNotificationsByDeviceIdDate res ", response)
                    if (response.Status === "OK") {
                        dispatch(
                            {
                                type: "GET_NOTIFICATIONS_BY_DEVICE_ID",
                                payload: response,
                            },
                            callback && callback(response)
                        );
                    } else {
                        dispatch({
                            type: "SET_ERRORS",
                            payload: response,
                        });
                    }
                })
                .catch((error) => {
                    console.log(
                        "error in pullNotificationsByUserIdDate actions api call",
                        error
                    );
                    if (error) {
                        dispatch({
                            type: "SET_ERRORS",
                            payload: error,
                        });
                    }
                    dispatch(setLoader(false));
                });
        } else {
            console.log("no mock data available");
        }
    };

export const deleteNotificationByNotificationId = (notificationId: any) => {
    return Apis.deleteNotificationByNotificationId(notificationId)
        .then((response: any) => {
            // console.log(
            //     "deleteNotificationByNotificationId response heeeelooooo ",
            //     response
            // );
            return new Promise<any>((resolve, reject) => {
                if (response.Status === "OK") {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
        })
        .catch((error: any) => {
            console.log(
                "error in deleteNotificationByNotificationId actions api call",
                error
            );
            return new Promise((reject) => {
                reject(error);
            });
        });
};


export const archiveNotificationsByDeviceId = (deviceId: any) => {
    return Apis.archiveNotificationsByDeviceId(deviceId)
        .then((response: any) => {
            // console.log(
            //     "archiveNotificationsByDeviceId response ",
            //     response
            // );
            return new Promise<any>((resolve, reject) => {
                if (response.Status === "OK") {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
        })
        .catch((error: any) => {
            console.log(
                "error in archiveNotificationsByDeviceId actions api call",
                error
            );
            return new Promise((reject) => {
                reject(error);
            });
        });
};