import React, { useEffect, useState } from "react";
import {
    Card,
    Col,
    Row,
    Image,
    Input,
    Button,
    Form,
    Space,
    message,
    Select,
    Layout,
} from "antd";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import instance from "../../instance";
import getStorageValue from "../../utils/getStorageValue";
import VehicleForm from "../forms/vehicle/vehicle.tsx";
import { useDispatch, useSelector, connect } from "react-redux";
import * as vehicleActions from "../../actions/vehicles/vehicles";
import VehicleCardWrapper from "../../pages/vehiclecard/vehicleCardWrapper.js";

//TODO make styles global, with themes  https://ant.design/docs/react/customize-theme
const VehicleUpdate = (props) => {
    const [form] = Form.useForm();
    let vehicleId = null;
    if (props.location.state) {
        vehicleId = props.location.state;
    }

    const { vehicleIdFromParams } = useParams();
    console.log("VehicleUpdate location vehicleId: ", vehicleId);

    return (
        <VehicleCardWrapper
            Content = {{width: '50%'}}
            isCard={true}
        >
            <div
                style={{
                    padding: 24,
                    minHeight: 360,
                    background: "white",
                    // border: "2px solid green",
                }}
            >
                {/* TODO organisation cascader tree dont aslways show up initially */}
                {vehicleId || vehicleIdFromParams ? (
                    <VehicleForm
                        form={form}
                        vehicleId={vehicleId || vehicleIdFromParams}
                        formName="updateTab"
                        hasFormButtons
                    />
                ) : null}
            </div>
        </VehicleCardWrapper>
    );
};

export default VehicleUpdate;
