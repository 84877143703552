import React, {Component, useEffect, useState, useCallback, useRef} from "react";
import {GoogleMap, Polyline as GooglePolyline,
  Marker as GoogleMarker, useLoadScript, useJsApiLoader, InfoWindow} from '@react-google-maps/api'
import carred from '../../assets/Images/carred.png'
import caryellow from  '../../assets/Images/caryellow.png'
import cargreen from '../../assets/Images/cargreen.png'

//OBS
/*
These settings must atleast be set in parent component in order for RePlay to work.
  const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA58-rBlwfGOHKU3X99wwccgjC8o0kw7uQ",
        libraries: ["geometry", "drawing", "places"]})
*/
const RePlay = (props) => {
  console.log("replay props.path.Geometrics.Polyline", props.path.Geometrics.Polyline)
  console.log("parentDrawerVisible", props.parentDrawerVisible)
  console.log("props.replaySpeed ", props.replaySpeed)
  console.log("infoWindowContent LP: ", props.infoWindowContent)

  const [state, setState] = useState({
    progress: [],
    isProgressFinished: false,
    velocity: 0
  })
  const [moveCount, setMoveCount] = useState(0)
  const [infoWindow,setInfoWindow] = useState(true)
  
  const [path, setPath] = useState({
    coordinates: null
  })

  const [routeCoordsDists, setRouteCoordsDists] = useState(null)

  const loadAnimation = useCallback(async () => {
    try {
      
      let coordsAndDistances = path.coordinates.map((coord, i, array) => {
        if(i === 0) {
          return {lat: coord.Latitude, lng: coord.Longitude, distance: 0, velocity: coord.Velocity}
        } 

        const { Latitude: lat1, Longitude: lng1 } = coord;
        const latLong1 = new window.google.maps.LatLng(lat1, lng1);
        console.log("latLong1", latLong1)
        
        const { latitude: lat2, longitude: lng2 } = array[0];
        const latLong2 = new window.google.maps.LatLng(lat2, lng2);
        console.log("latLong2", latLong2)

        // in meters:
        // distance is calculated birdway from pos 0 and pos x
        const distance = global.google.maps.geometry.spherical.computeDistanceBetween(
          latLong1,
          latLong2
        ); 
        //console.log("loaddistance", distance)
        return {lat: coord.Latitude, lng: coord.Longitude, distance: distance, velocity: coord.Velocity}
      });
      console.log("coordsAndDistances", coordsAndDistances)

      //const interpolatedCoordsAndDistances = interpolatePoints(coordsAndDistances)
      setRouteCoordsDists(coordsAndDistances)

      //setPath({coordsAndDistances : coordsAndDistances})
    } catch (e) {console.log("eeeeeeeee",e)}
  })
  
  useEffect(() => {
    const pathCoords = props.path.Geometrics.Polyline
    console.log("useffect pathCoords", pathCoords)
    setPath({coordinates: pathCoords})
  }, [props.path]); 

  useEffect(() => {
    console.log("useffect state path.coordinates ", path.coordinates)
    if (path.coordinates != null) {
      loadAnimation()
    }
  }, [path.coordinates]);

  //TODO movecounter and 250 should be states one can update in the meantime
  useEffect(() => {
    console.log("useffect routeCoordsDists", routeCoordsDists)
    let moveCounter = 0
    
    //TODO set subcoordinates in between real coordinates inside  routeCoordsDists

    const interval = window.setInterval(() => {
      //moveObject(moveCounter)
      moveObject(moveCount)
      console.log("progress state: ", state.progress)
      //moveCounter += 1
    }, props.replaySpeed) 

    if(state.isProgressFinished) {
      console.log("should stop moving object now. isprogress state: ", state.isProgressFinished)
      window.clearInterval(interval);
    }

    if(!props.parentDrawerVisible) {
      console.log("should stop moving object now. props.parentDrawerVisible state: ", props.parentDrawerVisible)
      window.clearInterval(interval);
    }
    
    return () => {
      // Anything in here is fired on component unmount.
      window.clearInterval(interval);
    }
  }, [routeCoordsDists, state.isProgressFinished, props.parentDrawerVisible, moveCount, props.replaySpeed]);

  /*
  const velocity = 10;
  const initialDate = new Date();
  const getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - initialDate) / 1000; 
    return differentInTime * velocity; 
  };
  */

  const moveObject = (moveCounter) => {
    console.log("routeCoordsDists", routeCoordsDists)

    //const distance = getDistance();
    //console.log("distance: ", distance)
    /*
    if (!distance) {
      return;
    }
    */

    let _progress = routeCoordsDists.slice(0, moveCounter+1)
    console.log("moveCounter", moveCounter)
    setMoveCount(moveCounter+1)
    console.log("init _progress : ", _progress)
    /*
    //Cuz sometimes the car get closer to the start pos...
    let _progress = routeCoordsDists.filter(
      moment => moment.distance < distance
    );
    */

    const nextLine = routeCoordsDists[_progress.length]
    
    /*
    const nextLine = routeCoordsDists.find(
      moment => moment.distance > distance
    );
    */
    console.log("nextline", nextLine)
    
    
    // it's the end!
    if (!nextLine) {
      console.log("REPLAY ROUTE IS FINISHED")
      setState({ 
        progress: _progress, 
        isProgressFinished: true,
        velocity: 0
      });
      return; 
    }

    /*
    const lastLine = _progress[_progress.length - 1];
    console.log("lastLine " , lastLine)
    const lastLineLatLng = new global.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );
    */
    
    const nextLineLatLng = new global.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    /*
    const totalDistance = Math.abs( nextLine.distance - lastLine.distance);
    const percentage = (distance - lastLine.distance) / totalDistance
    */
    
    
    //TODO set progress with subcoordinates in between real coordinates
    /*
    const position = global.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    */

    _progress = _progress.concat(nextLineLatLng);
    //_progress = _progress.concat(position);
    setState({ progress:_progress, velocity: routeCoordsDists[_progress.length-1]['Velocity'] });
    

  };

  const onMarkerLoad = () => {
    setInfoWindow(true)
  }

  const onMarkerClicked = () => {
    setInfoWindow(true)
  }

  const onInfoWindowCloseClick = () => {
    setInfoWindow(false)
  }

  const handleParentMapCenterState = (position) => {
    if(position) {
      props.handleMapCenterStateCallback(position)
    }
  }

  const iconByVelocity = (velocity) => {
    //TODO find the true unit on the velocity so it becomes correctly converted to kmh
    let kmh = velocity * 10
    if (kmh == 0 || kmh < 0) {
      return carred
    } else if ( kmh > 0 && kmh < 10) {
      return caryellow
    } else {
      return cargreen
    }
  }

  return (
  <>
      {state.progress && (
        <>
          <GooglePolyline
            path={state.progress}
            options={{ strokeColor: "#FF0000 " }}
          />
          <GoogleMarker
            position={state.progress[state.progress.length - 1]}
            onLoad={onMarkerLoad}
            onClick={onMarkerClicked}
            onPositionChanged={handleParentMapCenterState(state.progress[state.progress.length - 1])}
            icon={iconByVelocity(state.velocity)}
          >
            {props.infoWindowContent && infoWindow ? 
                <InfoWindow onCloseClick={onInfoWindowCloseClick} options={{disableAutoPan: true}}>
                  <div style={{textAlign:"center"}}>
                    <div> {props.infoWindowContent} </div> 
                  </div>
                </InfoWindow>
              : 
              <> </>
            }
          </GoogleMarker>
        </>
      )}
  </>
  );
}
    
 export default RePlay  
