import React, { useEffect, useRef, useState } from 'react';
import { Comment, Avatar, Form, Button, List, Input } from 'antd';


class VehicleMaintenance extends React.Component {

  render() {

    return (
      <>
        <div>maintenance</div>
      </>
    );
  }
}

export default VehicleMaintenance;