import type { IRootState } from "../../global-types";
import type { CollapseProps } from "antd";
import React, { FC, useEffect, useState, Suspense, useRef } from "react";
import {
    Button,
    Col,
    Collapse,
    Card,
    Drawer,
    Layout,
    Row,
    Statistic,
    Typography,
} from "antd";
import { Loading3QuartersOutlined, SettingOutlined } from "@ant-design/icons";
import { GridContent } from "@ant-design/pro-layout";
import { useDispatch, useSelector, connect } from "react-redux";
import BreadcrumbHeader from "../../components/breadcrumbheader/Header";
import { useParams } from "react-router-dom";
import PageLoading from "../../components/common/pageLoading";
import UserForm from "../../components/forms/users/user";
import * as usersActions from "../../actions/users/users";
import { nNotis } from "../../components/common/function";

interface IQrScanEntryProps {}
const { Title, Text } = Typography;
const { Panel } = Collapse;

const QrScanEntry: FC<IQrScanEntryProps> = (props) => {
    const { Header, Content, Footer, Sider } = Layout;
    const { rfid } = useParams();
    const loginUser = useSelector((state: IRootState) => state.login.loginUser);
    const dispatch = useDispatch();
    const [loginFirstName, setLoginFirstName] = useState(null);
    const [loginLastName, setLoginLastName] = useState(null);

    useEffect(() => {
        if (loginUser?.Name) {
            const [firstname, lastname] = loginUser?.Name?.replace(
                /\s+/g,
                " "
            ).split(" ");
            setLoginFirstName(firstname);
            setLoginLastName(lastname);
        }
    }, [loginUser?.Name]);

    const rowResponsiveProps = {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 12,
        xl: 12,
        style: { marginBottom: 24 },
    };

    //TODO make these notises to an hook.
    const onCreateSuccess = () => {
        return nNotis(
            "success",
            "top",
            `Successfully created user`,
            3,
            "Created User"
        );
    };

    const onUpdateSuccess = () => {
        return nNotis(
            "success",
            "top",
            `Successfully updated user with RFID`,
            3,
            "Updated User"
        );
    };

    const onCreateFail = () => {
        return nNotis(
            "error",
            "top",
            `Could not create user; try again later or else contact support`,
            6,
            "Something went wrong"
        );
    };

    const onUpdateFail = () => {
        return nNotis(
            "error",
            "top",
            `Could not update user; try again later or else contact support`,
            6,
            "Something went wrong"
        );
    };

    //TODO get form values from children's form
    //TODO logic in buttons
    //TODO hide left menu
    return (
        <>
            <div className="functionPagediv">
                <Layout
                    style={{
                        background: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Content>
                        <GridContent>
                            <Suspense fallback={<PageLoading />}>
                                <Row gutter={24} style={{ marginTop: 20 }}>
                                    <Col {...rowResponsiveProps}>
                                        <Card
                                            style={{
                                                boxShadow:
                                                    "5px 5px 10px 5px lightgray",
                                                height: 95,
                                                textAlign: "center",
                                            }}
                                        >
                                            <Text type="secondary">
                                                Scanned RFID
                                            </Text>{" "}
                                            <Title
                                                style={{ marginTop: 0 }}
                                                level={3}
                                                copyable
                                            >
                                                {rfid}
                                            </Title>
                                        </Card>
                                    </Col>
                                    <Col {...rowResponsiveProps}>
                                        <Card
                                            style={{
                                                boxShadow:
                                                    "5px 5px 10px 5px lightgray",
                                                height: 95,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    usersActions.updateCreateUser(
                                                        {},
                                                        onUpdateSuccess,
                                                        onUpdateFail
                                                    )(dispatch);
                                                }}
                                            >
                                                Add RFID to my account
                                            </Button>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col {...rowResponsiveProps}>
                                        <Collapse
                                            bordered={false}
                                            style={{ minWidth: 500 }}
                                        >
                                            <Panel
                                                header={
                                                    <Text>Create User</Text>
                                                }
                                                key="1"
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                            >
                                                <Card
                                                    title={"Create User"}
                                                    bordered={true}
                                                    style={{
                                                        boxShadow:
                                                            "5px 5px 10px 5px lightgray",
                                                        minWidth: 500,
                                                    }}
                                                    extra={
                                                        <Button
                                                            onClick={() => {
                                                                usersActions.updateCreateUser(
                                                                    {},
                                                                    onCreateSuccess,
                                                                    onCreateFail
                                                                )(dispatch);
                                                            }}
                                                            form={
                                                                "createUserForm"
                                                            }
                                                            type="primary"
                                                            key="submit"
                                                            htmlType="submit"
                                                        >
                                                            Create
                                                        </Button>
                                                    }
                                                >
                                                    {loginFirstName &&
                                                        loginLastName && (
                                                            <UserForm
                                                                formName={
                                                                    "createUserForm"
                                                                }
                                                                formId={
                                                                    "createUserForm"
                                                                }
                                                                rfid={rfid}
                                                                firstName={
                                                                    loginFirstName
                                                                }
                                                                lastName={
                                                                    loginLastName
                                                                }
                                                            />
                                                        )}
                                                </Card>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Suspense>
                        </GridContent>
                    </Content>
                </Layout>
            </div>
        </>
    );
};

export default QrScanEntry;
