import React from "react";
import { connect } from "react-redux";
import { List, Typography, Card, Form, DatePicker, Select, Row, Col, Button, Drawer } from "antd";
import "../../assets/css/functionPage.css";
import Header from "../../components/breadcrumbheader/Header";
import { CSVLink } from "react-csv";
import * as moment from "moment";
import jsonData from "../../json/speedIAS.json";
import { Column } from '@ant-design/charts';
import { FilterOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

class ISA extends React.Component {

  state = {
    selectedWidgetComp: [],
    selectedFunctions: [],
    selectedFuncTitle: "",
    formData: {},
    startDate: "",
    endDate: "",
    data: [],
    config: null,
    display: false
  };

  filterArray = (start, end) => {
    var filteredData = [];
    var filteredDate = []
    var chartstartDate = start;
    var ChartendDate = end;

    Object.keys(jsonData) && Object.keys(jsonData).length > 0 && Object.keys(jsonData).filter(function (a) {
      if (a >= chartstartDate && a <= ChartendDate) {
        filteredData.push({ [a]: jsonData[a] });
        filteredDate.push(a)
      }
    });

    var finalArr = []

    filteredDate.map(date => (
      finalArr.push({
        name: '0-10 km/h',
        Month: date,
        Speed: jsonData[date][0],
      }, {
        name: '10-20 km/h',
        Month: date,
        Speed: jsonData[date][1],
      }, {
        name: '20-30 km/h',
        Month: date,
        Speed: jsonData[date][2],
      }, {
        name: '> 30 km/h',
        Month: date,
        Speed: jsonData[date][3],
      })
    ))

    this.setState({ data: finalArr, }, () => this.setState({
      config: {
        data: this.state.data,
        isGroup: true,
        xField: 'Month',
        yField: 'Speed',
        seriesField: 'name',
        color: ['#43BC84', '#FDD97E', '#E5723E', "#EF568F"],
        label: {
          position: 'bottom',
          offsetY: 21,
          // layout: "limitInShape",
          style: {
            fontSize: 10
          },
        },
      }
    }))
  }

  CSVdata = [
    { "": "0-10 km/h", "07/10": 3, "08/10": 2, "09/10": 5, "10/10": 3, "11/10": 3, "12/10": 10, "13/10": 13, "Total": 39 },
    { "": "10-20 km/h", "07/10": 4, "08/10": 7, "09/10": 8, "10/10": 7, "11/10": 8, "12/10": 22, "13/10": 30, "Total": 86 },
    { "": "20-30 km/h", "07/10": 4, "08/10": 3, "09/10": 8, "10/10": 4, "11/10": 0, "12/10": 7, "13/10": 11, "Total": 37 },
    { "": ">30 km/h", "07/10": 3, "08/10": 5, "09/10": 7, "10/10": 4, "11/10": 7, "12/10": 10, "13/10": 11, "Total": 47 }
  ]

  componentDidMount() {
    this.filterArray("211007", "211013")
  }



  routes = [
    {
      path: '',
      breadcrumbName: 'Analyzes',
    },
    {
      path: '',
      breadcrumbName: 'Speeds (ISA)',
    },
  ];

  csvDownloadHandler = () => {
    console.log("Csv data", this.state.data)
  }

  formSubmitClick = (values) => {

    values.startDate = moment(this.state.startDate).format("YYMMDD")
    values.endDate = moment(this.state.endDate).format("YYMMDD")
    this.setState({ formData: values, display: false })
    this.filterArray(values.startDate, values.endDate);
  }

  onchangeDates = (dates, dateStrings) => {
    this.setState({ startDate: dateStrings[0], endDate: dateStrings[1] });
  }

  render() {
    return (
      <>
        <Header routes={this.routes} filter={false} >

        </Header>
        <Card title="Speeds (ISA)">
          <Button className="filter-btn-float" icon={<FilterOutlined />} onClick={() => this.setState({ display: true })} />
          <Drawer title="Search filters"
            width={720}
            onClose={() => {
              this.setState({ display: false })
            }}
            visible={this.state.display}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: 'right',
                }}
              >

                <Button style={{ marginRight: 8 }}>
                  <CSVLink className="btn btn-primary exportBtn" data={this.CSVdata} filename={"IAS.csv"} target="_blank">
                    Create CSV
                  </CSVLink>
                </Button>
                <Button onClick={() => this.formSubmitClick} form={"speedIASForm"} type="primary" key="submit" htmlType="submit">
                  Search
                </Button>
              </div>
            }>
            <Form
              name="speedIASForm"
              // ref={formRef}
              id={"speedIASForm"}
              onFinish={this.formSubmitClick}
              layout="vertical"
            >
              <Row gutter={[16, 20]} className="search-form-row">
                <Col className="gutter-row" span={24} >
                  <Form.Item name="date" label="Date">
                    <RangePicker format="YYYY-MM-DD" onChange={this.onchangeDates} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} >
                  <Form.Item name="speedZone" label="Speed Zone">
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Speed Zone"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="195an">195an</Option>
                      <Option value="E4 A6">E4 A6</Option>
                      <Option value="E4 Huskvarna">E4 Huskvarna</Option>
                      <Option value="Gränna 40km/h">Gränna 40km/h</Option>
                      <Option value="Jkpg tätort">Jkpg tätort</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={12} >
                  <Form.Item name="speedLimit" label="Speed limit">
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Speed limit"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="30">30</Option>
                      <Option value="40">40</Option>
                      <Option value="50">50</Option>
                      <Option value="60">60</Option>
                      <Option value="70">70</Option>
                      <Option value="80">80</Option>
                      <Option value="90">90</Option>
                      <Option value="100">100</Option>
                      <Option value="110">110</Option>
                      <Option value="120">120</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} >
                  <Form.Item name="Company" label="Company">
                    <Select
                      allowClear
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Select companies"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Bilpool">Bilpool</Option>
                      <Option value="Filial Nord test">Filial Nord test</Option>
                      <Option value="Filial Syd">Filial Syd</Option>
                      <Option value="Fiskeback">Fiskeback</Option>
                      <Option value="Granna">Granna</Option>
                      <Option value="JIRICOM AB">JIRICOM AB</Option>
                      <Option value="JIRICOM AF Kund Mall">
                        JIRICOM AF Kund Mall
                      </Option>
                      <Option value="JIRICOM AF Mall">JIRICOM AF Mall</Option>
                      <Option value="Kontor Nord">Kontor Nord</Option>
                      <Option value="L Fiskeback">L Fiskeback</Option>
                      <Option value="Region Syd">Region Syd</Option>
                      <Option value="Syd Privet">Syd Privet</Option>
                      <Option value="Syd tjanst">Syd tjanst</Option>
                    </Select>
                  </Form.Item>
                </Col>

              </Row>
            </Form>
          </Drawer>

          {this.state.config !== null && <Column {...this.state.config} />}
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle
    // allWidgetsdata: state.widgets.allWidgets
  };
};

export default connect(mapStateToProps)(ISA);
