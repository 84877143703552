export const setUser = (user) => (dispatch) => {
  dispatch({
    type: "SET_LOGIN_DETAILS",
    payload: user,
  });
};

export const setLoader = (value) => (dispatch) => {
  dispatch({
    type: "SET_LOADER",
    payload: value,
  });
};

export const setCheckLoginLoader = (value) => (dispatch) => {
  dispatch({
    type: "SET_CHECK_LOGIN_LOADER",
    payload: value,
  });
};

