import React, { useEffect, useState, memo, useCallback } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import "../../../../assets/css/completedTrips1.css";
import {
    Empty,
    Row,
    Col,
    Drawer,
    Form,
    Input,
    DatePicker,
    TimePicker,
    Select,
    Button,
    Dropdown,
    Skeleton,
    Affix,
    Space,
} from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import Header from "../../../../components/breadcrumbheader/Header";
import RenderGM from "../../../../components/Map/Google";
//import RenderGM from '../Map/Google';
import { Content } from "antd/es/layout/layout";
import ListRoutes from "./Route";
import { Option } from "antd/es/mentions";
import { useHistory } from "react-router-dom";
import {
    getTravels,
    singleTravel,
    editTravel,
} from "../../../../actions/travels/travels";
import * as vehicleActions from "../../../../actions/vehicles/vehicles";
import * as organisationActions from "../../../../actions/organisations/organisations";
import * as userActions from "../../../../actions/users/users";

import filterSearch from "../../../../components/search/filterSearch";
import {
    CreateRouteExport,
    getPreviousWeekDate,
} from "../../../../components/common/function";
import summaryData from "../../../../components/travel/travelExport";
import * as moment from "moment";

import * as Apis from "../../../../utils/api";

const CompletedTripsStandard = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.auth.loading);
    const travelList = useSelector((state) => state.travels.getTravels);
    const fromDate = useSelector((state) => state.travels.fromDate);
    const toDate = useSelector((state) => state.travels.toDate);
    const vehicles = useSelector(
        (state) => state.vehicles.getVehiclesFormatted
    );
    const respNumbers = useSelector(
        (state) => state.vehicles.getRespNumbersFormatted
    );
    const travel = useSelector((state) => state.travels.travel);
    const organisations = useSelector(
        (state) => state.organisations.getOrganisations
    );
    const users = useSelector((state) => state.users.getUsers);
    const editedTravel = useSelector((state) => state.travels.editTravel);

    const [routeData, setRouteData] = useState();
    const [state, setState] = useState({
        visible: false,
        Route: undefined,
    });
    const [searchVisible, setSearchVisible] = useState(false);
    const [latestRouteId, setLatestRouteId] = useState({});
    //const [vehicles, setVehicles] = useState([]);

    const routes = [
        {
            path: "",
            breadcrumbName: "Travel",
        },
        {
            path: "",
            breadcrumbName: "Completed Trips",
        },
    ];

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    useEffect(() => {
        console.log(
            "preparing dispatching in useEffect CompletedTripsStandard..."
        );
        dispatch(vehicleActions.getVehiclesAPI());
        dispatch(organisationActions.getOrganisations());
        dispatch(userActions.getUsers());
    }, []);

    //TODO if no previous week date have no routes, then?
    useEffect(() => {
        console.log(
            "prepare dispatching in useEffect CompletedTripsStandard..."
        );
        const data = {
            fromDate: moment(getPreviousWeekDate()),
            toDate: moment(),
        };
        dispatch(getTravels(data, setLatestRouteId));
    }, [history.location.pathname]);

    useEffect(() => {
        console.log("ShowRoute initially...");
        if (latestRouteId) {
            console.log("ShowRoute triggered...");
            ShowRoute(latestRouteId);
        }
    }, [latestRouteId]);

    const onTableChange = (pagination, filters, sorter, extra) => {
        console.log("params", pagination, filters, sorter, extra);
    };

    const onCloseSearch = () => {
        setSearchVisible(false);
    };

    const RouteExport = () => {
        console.log("travels state: ", travelList);
        console.log("props.loggedInUser.Name", props.loggedInUser.Name);
        const summaryDataObj = summaryData(travelList);
        console.log("fromDate toDate", fromDate, toDate);
        let dateFromFriendly = moment(fromDate).format("ll");
        let dateToFriendly = moment(toDate).format("ll");
        CreateRouteExport(
            travelList,
            props.loggedInUser.Name,
            dateFromFriendly,
            dateToFriendly,
            summaryDataObj
        );
    };

    //TODO get utloggad error message from server.
    //Cuz I send api call twice fast. Cuz it is in strictmode in index.js?
    const ShowRoute = (routeId) => {
        console.log("callback ShowRoute routeId:", routeId);
        if (!routeId) return;

        Apis.getRouteById(routeId)
            .then((response) => {
                console.log(
                    "CompletedTripsStandard Apis.getRouteById API RES",
                    response
                );
                if (response.Status == "NOK") {
                } else {
                    let Coords = [];
                    response.Results.Geometrics.Polyline.map((x) =>
                        Coords.push({
                            lat: parseFloat(x.Latitude),
                            lng: parseFloat(x.Longitude),
                        })
                    );
                    setState({ Route: { Coords: Coords } });
                    setRouteData(response.Results);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const { RangePicker } = DatePicker;
    const rangeConfig = {
        rules: [
            {
                type: "array",
                required: false,
                message: "Please select time!",
            },
        ],
    };

    const [formSearch] = Form.useForm();
    formSearch.setFieldsValue({
        Picker: [moment().subtract(7, "d"), moment()],
    });

    //TODO add local search, use filter component. Can not do now, because the local search can not be built up with periods of different data in a optimal way
    const onFinishSearchForm = (values, isLocalSearch = false) => {
        const rangeTimeValue = values["Picker"];
        values = {
            ...values,
            Picker: [
                rangeTimeValue[0].format("YYYY-MM-DD HH:mm:ss"),
                rangeTimeValue[1].format("YYYY-MM-DD HH:mm:ss"),
            ],
        };

        console.log("onfinish input ", values);
        if (isLocalSearch) {
            const localFormatData = {};
        } else {
            const apiFormatData = {
                licenseplate: !values.LicensePlate
                    ? null
                    : values.LicensePlate.trim(),
                toDate: values.Picker[1],
                fromDate: values.Picker[0],
                travelid: !values.travelId ? null : values.travelId.trim(),
                driver: Number(values.driver),
                startadress: !values.startAddress
                    ? ""
                    : values.startAddress.trim(),
                stopadress: !values.stopAddress
                    ? ""
                    : values.stopAddress.trim(),
                org: values.organisation,
                attest: !values.attest ? "" : values.attest,
                respNumber: !values.respNumber
                    ? null
                    : values.respNumber.trim(),
                routeType: !values.routeType ? "" : values.routeType,
                travelpurpose: values.travelPurpose,
            };
            console.log("onfinish apiFormatData ", apiFormatData);

            dispatch(getTravels(apiFormatData));
        }
    };

    const updateRoute = useCallback((routeId, data) => {
        //console.log("write to db, value inparameter value, routeId, driver: ", routeTypeId, routeId, driver)
        console.log("updateRoute routeId, data: ", data);

        //Passing getTravels as callback to get all travels once edited the specific travel.
        //dispatch(editTravel({'routeId': routeId, 'routeTypeId': data.routeTypeId, 'driverId' : data.driverId, 'travelPurpose': data.travelPurpose}, getTravels()))
        dispatch(
            editTravel({
                routeId: routeId ? routeId : "",
                routeTypeId: data.routeTypeId ? data.routeTypeId : "",
                driverId: data.driverId ? data.driverId : "",
                travelPurpose: data.travelPurpose ? data.travelPurpose : "",
                driverName: data.driverName ? data.driverName : "",
            })
        );
    }, []);

    //TODO map container is small if 1 card is shown only. Make it big in any situation.
    return (
        <div className="functionPagediv">
            <Affix
                offsetTop={150}
                onChange={(affixed) => console.log(affixed)}
                style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
            >
                <div>
                    <p>
                        <Button
                            size="large"
                            type="primary"
                            onClick={() => setSearchVisible(true)}
                            icon={<SearchOutlined />}
                        />
                    </p>
                    <p>
                        <Button
                            size="large"
                            type="primary"
                            onClick={RouteExport}
                            icon={<DownloadOutlined />}
                        />
                    </p>
                </div>
            </Affix>
            {travelList && !!travelList.length ? (
                <>
                    <Row justify="space-evenly">
                        <Col span={11} className="site-layout-background">
                            {loading ? (
                                <Skeleton active />
                            ) : (
                                <ListRoutes
                                    data={travelList}
                                    updateRoute={updateRoute}
                                    onClick={ShowRoute}
                                    vehicles={vehicles}
                                    editedTravel={editedTravel}
                                />
                            )}
                        </Col>

                        <Col span={12} className="site-layout-background">
                            {console.log(
                                "routeData ",
                                routeData,
                                "state.Route ",
                                state.Route
                            )}
                            {routeData ? (
                                <>
                                    <RenderGM
                                        center={
                                            state.Route !== undefined
                                                ? {
                                                      lat: state.Route.Coords[0]
                                                          .lat,
                                                      lng: state.Route.Coords[0]
                                                          .lng,
                                                  }
                                                : {
                                                      lat: 57.783113,
                                                      lng: 14.161727,
                                                  }
                                        }
                                        zoom={13}
                                        type="Route"
                                        data={routeData}
                                        parentDrawerVisible={true}
                                        //data={state.Route}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                {/* //TODO fix responsive button */}
                    <Empty
                        style={{}}
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                            height: 60,
                        }}
                        description={
                            <p>
                                No travels made last week. Try search for a
                                specific date interval to display travels.
                            </p>
                        }
                    >
                        <Button
                            style={{
                                marginRight: "46.5%",
                            }}
                            type="primary"
                            onClick={() => {
                                setSearchVisible(true);
                            }}
                        >
                            Search Travels
                        </Button>
                    </Empty>
                </>
            )}

            <Drawer
                title="Search"
                placement="right"
                onClose={onCloseSearch}
                visible={searchVisible}
                size="large"
            >
                <Form
                    form={formSearch}
                    name="searchTravelForm"
                    onFinish={onFinishSearchForm}
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                >
                    <Form.Item
                        name="Picker"
                        label="Time interval"
                        {...rangeConfig}
                    >
                        <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>

                    <Form.Item label="License Plate" name="licensePlate">
                        <Select showSearch allowClear>
                            {loading ? (
                                <Skeleton active />
                            ) : (
                                <>
                                    {vehicles.map((v) => (
                                        <Select.Option value={v.LicensePlate}>
                                            {v.LicensePlate}
                                        </Select.Option>
                                    ))}
                                </>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Travel ID" name="travelId">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Start Address" name="startAddress">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Stop Address" name="stopAddress">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Driver" name="driver">
                        <Select
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            placeholder="Driver"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {users.map((v) => (
                                <Select.Option value={v.UserID}>
                                    {v.Name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Organisation" name="organisation">
                        <Select
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            placeholder="Select companies"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {organisations[0] ? (
                                organisations[0].map((v) => (
                                    <Select.Option value={v.Id}>
                                        {v.Name}
                                    </Select.Option>
                                ))
                            ) : (
                                <Select.Option value="not available">
                                    No Available Companies Exists
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Attest" name="attest">
                        <Select allowClear>
                            <Select.Option value="">All</Select.Option>
                            <Select.Option value="0">
                                Not attested
                            </Select.Option>
                            <Select.Option value="1">
                                Only attested
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Routetype" name="routeType">
                        <Select>
                            <Select.Option value="">Alla</Select.Option>
                            <Select.Option value="0">Tjänsteresa</Select.Option>
                            <Select.Option value="1">Privatresa</Select.Option>
                            <Select.Option value="6">Kundresa</Select.Option>
                            <Select.Option value="3">Tankning</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Travelpurpose" name="travelPurpose">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Responsibility" name="respNumber">
                        <Select
                            allowClear
                            style={{ width: "100%" }}
                            showSearch
                            placeholder="Driver"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {respNumbers.map((v) => (
                                <Select.Option value={v}>{v}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Search
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        loggedInUser: state.login.loginUser,
    };
};

export default connect(mapStateToProps)(CompletedTripsStandard);
