import React, { useEffect, useState, useMemo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
    Tree,
    Row,
    Card,
    Col,
    Drawer,
    Affix,
    Form,
    Input,
    Button,
    Space,
} from "antd";
import { useHistory } from "react-router-dom";
import Header from "../../../components/breadcrumbheader/Header";
import AddTreeForm from "../../../components/forms/tree/otree";
//import * as Apis from '../../utils/api';
import { UserAddOutlined } from "@ant-design/icons";
import { nNotis } from "../../../components/common/function";
import * as organisationsActions from "../../../actions/organisations/organisations";
import OrganisationForm from "../../../components/forms/tree/organisation";
import { useCascaderOrganisations } from "../../../hooks/useCascaderOrganisations";
import BreadcrumbHeader from "../../../components/breadcrumbheader/Header.js";

const { Search } = Input;

const Organisations = (props) => {
    const dispatch = useDispatch();
    const routes = [
        {
            path: "",
            breadcrumbName: "Organisation",
        },
        {
            path: "",
            breadcrumbName: "Organisation Tree",
        },
    ];

    const [visible, setVisible] = useState(false);
    const [Id, setId] = useState([]);
    const [organisations] = useSelector(
        (state) => state.organisations.antdOrganisationTree
    );
    const [selectedOrganisationId, setSelectedOrganisationId] = useState(null);
    const [organisationCascaderOptions, currentOrganisationPath] =
        useCascaderOrganisations(selectedOrganisationId);

    const dataList = [];
    useEffect(() => {
        organisationsActions.getOrganisationTree()(dispatch);
    }, []);

    useEffect(() => {
        if (organisations && Object.keys(organisations).length > 0)
            setTreeData(organisations);
    }, [organisations?.length]);

    const openDrawer = (id, newId) => {
        setId({ pId: id[0], newId: newId });
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const [treeData, setTreeData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [visibleOrganisationDrawer, setVisibleOrganisationDrawer] =
        useState(false);

    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    const generateList = (data) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const { key } = node;
            dataList.push({
                key,
                title: node.title,
            });

            if (node.children) {
                generateList(node.children);
            }
        }
    };

    generateList(treeData);

    const getParentKey = (key, tree) => {
        let parentKey;

        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];

            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    //TODO make uppercase intolerant
    const onSearchChange = (e) => {
        const { value } = e.target;
        const newExpandedKeys = dataList
            .map((item) => {
                if (
                    item.title.toLowerCase().indexOf(value.toLowerCase()) !== -1
                ) {
                    return getParentKey(item.key, treeData);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);

        setExpandedKeys(newExpandedKeys);
        setSearchValue(value);
        setAutoExpandParent(true);
    };

    const streeData = useMemo(() => {
        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.title;
                const index = strTitle
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase());
                const beforeStr = strTitle.substring(0, index);
                const afterStr = strTitle.slice(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className="site-tree-search-value">
                                {searchValue}
                            </span>
                            {afterStr}
                        </span>
                    ) : (
                        <span>{strTitle}</span>
                    );

                if (item.children) {
                    return {
                        title,
                        key: item.key,
                        children: loop(item.children),
                        icon: (selected) => (
                            <>
                                <Button
                                    type="link"
                                    icon={<UserAddOutlined />}
                                    size={"small"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        let newId = 0;
                                        if (selected.data.children) {
                                            const totalChildren =
                                                selected.data.children;
                                            newId = totalChildren.length;
                                        }
                                        openDrawer([selected.data.key], newId);
                                    }}
                                />
                            </>
                        ),
                    };
                }

                return {
                    title,
                    key: item.key,
                    icon: (selected) => (
                        <Button
                            style={{ zIndex: 2 }}
                            type="link"
                            icon={<UserAddOutlined />}
                            size={"small"}
                            onClick={(e) => {
                                e.stopPropagation();
                                let newId = 0;
                                if (selected.data.children) {
                                    const totalChildren =
                                        selected.data.children;
                                    newId = totalChildren.length;
                                }
                                openDrawer([selected.data.key], newId);
                            }}
                        />
                    ),
                };
            });
        return loop(treeData);
    }, [searchValue, treeData]);

    const onSelect = (selectedKeys, info) => {
        console.log("stree onSelect selectedKeys info ", selectedKeys, info);
        setSelectedOrganisationId(info.node.key);
        setVisibleOrganisationDrawer(true);
    };

    const onCloseOrganisationDrawer = () => {
        setVisibleOrganisationDrawer(false);
    };

    const updateTreeData = (list, key, children) => {
        // debugger
        const updatedTree = list.map((node) => {
            if (node.key === key) {
                const nodeC = node.children || [];
                nodeC?.push(children);
                return { ...node, children: nodeC };
            }

            if (node.children) {
                return {
                    ...node,
                    children: updateTreeData(node.children, key, children),
                };
            }

            return node;
        });
        return updatedTree;
    };

    const insertDataHandler = (formData) => {
        const organisationIds = organisationsActions.findOrganisationIdPath(
            formData.OrganisationId,
            organisationCascaderOptions
        );

        console.log(
            "insertDataHandler formData organisationIds organisationCascaderOptions ",
            formData,
            organisationIds,
            organisationCascaderOptions
        );

        //TODO remove +1 because of mock data
        let element = {
            title: formData.OrganisationName,
            key: formData.OrganisationId + 1,
            icon: <UserAddOutlined />,
        };
        const result = updateTreeData(treeData, organisationIds[1], element);
        setTreeData(result);
        setVisible(false);
    };

    const removeTreeNodeHandler = () => {
        const organisationIds = organisationsActions.findOrganisationIdPath(
            selectedOrganisationId,
            organisationCascaderOptions
        );
        console.log("removeTreeNodeHandler organisationIds ", organisationIds);

        organisationsActions.deleteOrganisation(
            selectedOrganisationId,
            organisationIds,
            () => {
                nNotis(
                    "success",
                    "top",
                    `Successfully removed organisation`,
                    3,
                    "Removed organisation"
                );
                organisationsActions.getOrganisationTree()(dispatch);
            },
            () => {
                nNotis(
                    "error",
                    "top",
                    `Could not remove organisation; try again later or contact support`,
                    3,
                    "Something went wrong when trying to remove organisation"
                );
            }
        )(dispatch);

        //TODO remove org in UI
        //TODO all children to node will be moved under headorgnode
    };

    const OrganisationUpdateDrawerHeader = (formName) => {
        return (
            <>
                <Space>
                    <Button
                        onClick={() => {
                            setVisibleOrganisationDrawer(false);
                        }}
                        form={formName}
                        type="primary"
                        key="submit"
                        htmlType="submit"
                    >
                        Update
                    </Button>

                    <Button
                        onClick={() => {
                            setVisibleOrganisationDrawer(false);
                            removeTreeNodeHandler();
                        }}
                        danger
                    >
                        Remove
                    </Button>
                </Space>
            </>
        );
    };

    const OrganisationCreateDrawerHeader = (formName) => {
        return (
            <>
                <Space>
                    <Button
                        onClick={() => {
                            setVisibleOrganisationDrawer(false);
                        }}
                        form={formName}
                        type="primary"
                        key="submit"
                        htmlType="submit"
                    >
                        Create
                    </Button>
                </Space>
            </>
        );
    };

    return (
        <div className="functionPagediv">
            <BreadcrumbHeader routes={routes} filter={false} />
            <Row justify="space-around" align="middle">
                <Col xl={6} style={{ marginTop: 8 }}>
                    <Card
                        bordered={true}
                        style={{
                            boxShadow: "5px 5px 10px 5px lightgray",
                        }}
                    >
                        <Search
                            style={{
                                marginBottom: 8,
                            }}
                            placeholder="Search"
                            onChange={onSearchChange}
                        />
                        {streeData.length ? (
                            <Tree
                                // onExpand={onExpand}
                                // expandedKeys={expandedKeys}
                                // autoExpandParent={autoExpandParent}
                                showIcon
                                showLine
                                treeData={streeData}
                                defaultExpandAll={true}
                                onSelect={onSelect}
                            />
                        ) : (
                            "Loading organisations..."
                        )}
                    </Card>
                </Col>
            </Row>

            <Drawer
                title={"Create Organisation"}
                placement="right"
                size="large"
                onClose={onClose}
                open={visible}
                extra={OrganisationCreateDrawerHeader("organisationForm")}
            >
                <Row type="flex" justify="center" align="middle">
                    <>
                        <OrganisationForm
                            formName="createOrganisationForm"
                            formId="createOrganisationForm"
                            insertDataHandlerCallback={insertDataHandler}
                        />
                    </>
                </Row>
            </Drawer>

            {/*  TODO MEGA make this drawer and form generic for CRUD */}
            <Drawer
                title={"Update Organisation"}
                placement="right"
                size="large"
                onClose={onCloseOrganisationDrawer}
                open={visibleOrganisationDrawer}
                extra={OrganisationUpdateDrawerHeader("organisationForm")}
            >
                <>
                    <OrganisationForm
                        formName="updateOrganisationForm"
                        formId="updateOrganisationForm"
                        organisationId={selectedOrganisationId}
                    />
                </>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => {
    console.log("selected menu mapstatetoprops", state);
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        // allWidgetsdata: state.widgets.allWidgets
    };
};

export default connect(mapStateToProps)(Organisations);
