import React from "react";
import { connect } from "react-redux";
import { List, Typography } from "antd";
import "../../assets/css/functionPage.css";

class Manuallog extends React.Component {
  state = {
    selectedWidgetComp: [],
    selectedFunctions: [],
    selectedFuncTitle: ""
  };

  render() {
    return (
      <div className="functionPagediv">
        <p className="functioncontent">manualloggin
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle
    // allWidgetsdata: state.widgets.allWidgets
  };
};

export default connect(mapStateToProps)(Manuallog);
