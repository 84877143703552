import React, { useEffect, useRef, useState } from 'react';
import { Comment, Avatar, Form, Button, List, Input } from 'antd';


class VehicleLarm extends React.Component {

  render() {

    return (
      <>
        <div>LAMR</div>
      </>
    );
  }
}

export default VehicleLarm;