import { ITemplate } from "../../global-types";
import { DefaultOptionType } from "rc-select/lib/Select";

const initialState = {
    accessTemplates: [],
    accessTemplatesAntdSelectFormatted: undefined,
};

const templatesReducer = (
    state = initialState,
    { type, payload }: any,
    callback: any
) => {
    switch (type) {
        case "GET_ACCESS_TEMPLATES":
            console.log("GET_ACCESS_TEMPLATES REDUCER ", payload);
            const accessTemplatesAntdSelectFormatted: DefaultOptionType[] | undefined = payload.length > 0 ?
                payload.map((template: ITemplate) => ({
                    label: template.Name,
                    value: template.TemplateId,
                })) : undefined;

            console.log(
                "GET_ACCESS_TEMPLATES accessTemplatesAntdSelectFormatted",
                accessTemplatesAntdSelectFormatted
            );
            return {
                ...state,
                accessTemplatesAntdSelectFormatted:
                    accessTemplatesAntdSelectFormatted,
                accessTemplates: payload,
                callback,
            };

        case "SET_LOADER":
            return {
                ...state,
                loading: payload,
            };
        default:
            return state;
    }
};

export default templatesReducer;
