import instance from "../instance";
import getStorageValue from "./getStorageValue";
import * as Common from "../components/common/function"

const MOCK_API = true;

const options = {
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
};

export function setTokenHeader(token) {
    // console.log("Token in setTokenHeader", token);
    if (token !== undefined) {
        instance.defaults.headers.common["Access-Token"] = token;
    }
}

export function setUseMockHeader(str = MOCK_API) {
    //str = GUI2.0-dev om du vill ha mock data.
    instance.defaults.headers.common["Use-Mock"] = str;
}

//Login
export function login() {
    const url = "/login";
    return instance.post(url).then((response) => response.data);
}

//check login status
export function checkLogin() {
    setTokenHeader(getStorageValue("Access-token"));
    const url = "login/check";
    return instance
        .get(url, { crossdomain: true })
        .then((response) => response.data);
}

export function forgotPassword(email) {
    const url = "login/forgot";
    const data = {
        Email: email,
    };
    return instance.post(url, data).then((response) => response.data);
}

export function forgotPassSendEmailToUser(emailAddress) {
    const url = "login/forgotPass";
    //api/app/
    const data = {
        Email: emailAddress,
        //BaseUrl: instance.baseURL.slice(0, -8) + '#/'
        BaseUrl: instance.getUri().slice(0, -8) + "#/" + "forgot/",
    };
    console.log("forgotPassSendEmailToUser data ", data);
    return instance.post(url, data).then((response) => response.data);
}

export function forgotPassCheckToken(token) {
    const url = "login/forgotPass" + token;
    return instance.get(url).then((response) => response.data);
}

export function forgotPassUpdatePassword(password, token) {
    const url = "login/forgotPass";
    const data = {
        Password: password,
        Token: token,
        BaseUrl: instance.getUri().slice(0, -8) + "#/" + "login/",
    };
    return instance.put(url, data).then((response) => response.data);
}

//Gets profile data
export function getUserProfile() {
    setTokenHeader(getStorageValue("Access-token"));
    const url = "/profile";
    return instance.get(url).then((response) => response.data);
}
//gets all vehiclenotes
export function getAllVehicleNotes() {
    setTokenHeader(getStorageValue("Access-token"));
    const url = "/all_notes";
    return instance.get(url).then((response) => response.data);
}
//adds a vehicle note
export function postVehicleNote(data) {
    setTokenHeader(getStorageValue("Access-token"));
    const url = "/vehicle/note/add";
    instance.post(url, data).then((response) => response.data);
}

//gets all postions
export function getPositions() {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/positions";
    return instance.get(url).then((response) => response.data);
}

//get vehicle postions ordered latest first index
export function getVehiclePositions(id) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/positions/" + id;
    return instance.get(url).then((response) => response.data);
}

/**
 * @function getAllVehicles
 * @param {Object} data 
 *  @member {String} lp
 * vID 
 *  $milesFrom = sqlesc($con, $POST->milesFrom);
    $milesTo = sqlesc($con, $POST->milesTo);
    $brand = sqlesc($con, $POST->Brand);
    $model = sqlesc($con, $POST->Model);
    $yearTo = sqlesc($con, $POST->yearTo);
    $yearFrom = sqlesc($con, $POST->yearFrom);
    $userID = sqlesc($con, $POST->UserID);
    $c = sqlesc($con, $POST->Company);
    $respnumer = sqlesc($con, $POST->RespNumer);
    $gtrack = sqlesc($con, $POST->GTrack);
    $vStatus = sqlesc($con, $POST->VStatus);
 * @returns 
 */
export function getAllVehicles(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/all_vehicles";
    return instance.post(url, data).then((response) => response.data);
}

/**
 * 
 * @param {*} vehicleId
 * @returns
    {
    "Status": "NOK",
    "Count": 1,
    "Results": {
        "VehicleId": "1638790663066125",
        "LicensePlate": "ABC123",
        "ChassiNumber": "U5YH7814GKL039015",
        "BrandLogo": "https://au2box.com/img/car-brands/toyota",
        "Brand": "Toyota",
        "Model": "Ceed 1.4 T-GDI",
        "Year": "1989",
        "Kilometers": 23837,
        "DriverDefault": "Martin Den Store Lundberg",
        "DriverDefaultId": 123456789,
        "DriverCurrent": "Ulf Göransson",
        "DriverCurrentId": 987654321,
        "Attest": false,
        "HideTravel": false,
        "VehicleClass": "PB",
        "BuyDate": "2023-04-27",
        "KilometersAllowance": 9.5,
        "LastMaintenanceDate": "2023-09-27",
        "LastMaintenanceMiles": 23500,
        "LastInspectionDate": "2023-10-27",
        "SellPrice": 123,
        "SellDate": "",
        "Templates": {
            "TemplateCost": {
                "TemplateId": "7295873442",
                "Name": "ECR37H",
                "AddedDateTime": "2020-10-27 13:20:02",
                "Count": 0,
                "UserId": "1674262013898306",
                "ParentCompanyId": "1523418074238829",
                "Type": 4,
                "Data": "a:36:{i:0;s:0:\"\";i:1;s:0:\"\";i:2;s:0:\"\";i:3;s:0:\"\";i:4;s:0:\"\";i:5;s:2:\"12\";i:6;s:0:\"\";i:7;s:0:\"\";i:8;s:0:\"\";i:9;s:0:\"\";i:10;s:0:\"\";i:11;s:2:\"36\";i:12;s:4:\"3476\";i:13;s:0:\"\";i:14;s:0:\"\";i:15;s:1:\"1\";i:16;s:0:\"\";i:17;s:1:\"1\";i:18;s:4:\"1000\";i:19;s:6:\"241,66\";i:20;s:0:\"\";i:21;s:0:\"\";i:22;s:0:\"\";i:23;s:0:\"\";i:24;s:1:\"1\";i:25;s:3:\"360\";i:26;s:2:\"12\";i:27;s:2:\"12\";i:28;s:2:\"14\";i:29;s:0:\"\";i:30;s:0:\"\";i:31;s:0:\"\";i:32;s:0:\"\";i:33;s:0:\"\";i:34;s:0:\"\";i:35;s:0:\"\";}",
                "ValueData": "a:1:{i:0;s:6:\"290000\";}",
                "ValueNames": "a:1:{i:0;s:5:\"Inköp\";}",
                "Cost": "362500",
                "VehicleTemplateId": "123456",
                "VAT": 0.25,
                "Price": 113,
                "Discount": 0.2
            },
            "TemplateService": {
                "TemplateId": "7295873442",
                "Name": "ECR37H",
                "AddedDateTime": "2020-10-27 13:20:02",
                "Count": 0,
                "UserId": "1674262013898306",
                "ParentCompanyId": "1523418074238829",
                "Type": 4,
                "Data": "a:36:{i:0;s:0:\"\";i:1;s:0:\"\";i:2;s:0:\"\";i:3;s:0:\"\";i:4;s:0:\"\";i:5;s:2:\"12\";i:6;s:0:\"\";i:7;s:0:\"\";i:8;s:0:\"\";i:9;s:0:\"\";i:10;s:0:\"\";i:11;s:2:\"36\";i:12;s:4:\"3476\";i:13;s:0:\"\";i:14;s:0:\"\";i:15;s:1:\"1\";i:16;s:0:\"\";i:17;s:1:\"1\";i:18;s:4:\"1000\";i:19;s:6:\"241,66\";i:20;s:0:\"\";i:21;s:0:\"\";i:22;s:0:\"\";i:23;s:0:\"\";i:24;s:1:\"1\";i:25;s:3:\"360\";i:26;s:2:\"12\";i:27;s:2:\"12\";i:28;s:2:\"14\";i:29;s:0:\"\";i:30;s:0:\"\";i:31;s:0:\"\";i:32;s:0:\"\";i:33;s:0:\"\";i:34;s:0:\"\";i:35;s:0:\"\";}",
                "ValueData": "a:1:{i:0;s:6:\"290000\";}",
                "ValueNames": "a:1:{i:0;s:5:\"Inköp\";}",
                "Cost": "362500",
                "VehicleTemplateId": "123456",
                "VAT": 0.25,
                "Price": 113,
                "Discount": 0.2
            }
        },
        "Status": 1,
        "Booking": 0,
        "VehicleGroup": "PB",
        "CompanyId": "1612202680149768",
        "VehicleDataDetails": {
            "Tax": {
                "Label": "Årlig skatt",
                "Value": null
            },
            "TaxMonth": {
                "Label": "Skattemånad",
                "Value": null
            },
            "Color": {
                "Label": "Färg",
                "Value": "Ljusgrön"
            },
            "Chassi": {
                "Label": "Kaross",
                "Value": "Sedan"
            },
            "Passengers": {
                "Label": "Antal passagerare, max",
                "Value": "4 st + förare"
            },
            "BuiltDate": {
                "Label": "Tillverkningsdatum",
                "Value": "1998-12-12"
            },
            "FirstRegisterDate": {
                "Label": "Först registrerad",
                "Value": "1998-12-16"
            },
            "InTrafficDate": {
                "Label": "Trafik i Sverige",
                "Value": "1999-01-26"
            },
            "GearType": {
                "Label": "Växellåda",
                "Value": "Automatisk"
            },
            "FuelType": {
                "Label": "Drivmedel",
                "Value": "Bensin"
            },
            "SoundLevel": {
                "Label": "Ljudnivå dB, vid körning",
                "Value": "73"
            },
            "Power": {
                "Label": "Motoreffekt",
                "Value": "170hk / 125kW"
            },
            "TopSpeed": {
                "Label": "Max hastighet (km/h)",
                "Value": null
            },
            "Consumption": {
                "Label": "Bränsleförbrukning, blandad körning (l)",
                "Value": "0"
            },
            "CO2": {
                "Label": "Koldioxid, CO2 blandad körning",
                "Value": null
            },
            "NOX": {
                "Label": "Kväveoxider, NOx blandad körning",
                "Value": null
            },
            "THCNOX": {
                "Label": "Kolväten och kväveoxider, THC + NOx",
                "Value": null
            },
            "Length": {
                "Label": "Längd",
                "Value": "4950"
            },
            "Width": {
                "Label": "Bredd",
                "Value": "1840"
            },
            "Height": {
                "Label": "Höjd",
                "Value": null
            },
            "TotalWeight": {
                "Label": "Totalvikt",
                "Value": "2040"
            },
            "Weight": {
                "Label": "Tjänstevikt (faktisk vikt)",
                "Value": "1640"
            },
            "LoadWeight": {
                "Label": "Lastvikt",
                "Value": "400"
            },
            "TrailerWeight": {
                "Label": "Släpvagnsvikt",
                "Value": "500"
            },
            "FrontTires": {
                "Label": "Däckdimension fram",
                "Value": "205/65 R15 94V"
            },
            "RearTires": {
                "Label": "Däckdimension bak",
                "Value": "205/65 R15 94V"
            },
            "FrontRims": {
                "Label": "Fälgdimension fram",
                "Value": "6,5J X 15 X 43"
            },
            "RearRims": {
                "Label": "Fälgdimension fram",
                "Value": "6,5J X 15 X 43"
            },
            "AxleLength": {
                "Label": "Max axelavstånd axel 1-2",
                "Value": null
            },
            "NextVerification": {
                "Label": "Nästa besiktning",
                "Value": "2020-11-30"
            }
        },
        "Usage": {
            "Count": 1002,
            "Travels": {
                "0": 998,
                "1": 4,
                "2": 0,
                "Label": "Antal resor",
                "Legend": "Travels"
            },
            "Kilometer": {
                "0": "11587.61",
                "1": "68.56",
                "2": "0.00",
                "Label": "Antal Km",
                "Legend": "Km"
            },
            "PercentageKilometer": {
                "0": "99.41",
                "1": "0.59",
                "2": 0,
                "Label": "Antal Km %",
                "Legend": "PercentageKm"
            },
            "Cost": {
                "0": "0.00",
                "1": "0.00",
                "2": "0.00",
                "Label": "Drivmedel kr",
                "Legend": "TotalCost"
            }
        }
    }
}​
*/
export function getVehicle(vid) {
    //TODO make api handle property uri flag. So only relevant data come in response
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/vehicle/" + vid;
    return instance.get(url).then((response) => response.data);
}

//TODO not implemented in backend
export function getVehicleIdByLicensePlate(licensePlate) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/vehicle/" + licensePlate;
    return instance.get(url).then((response) => response.data);
}

/**
 *
 * @param {*} data: {
 *      LicensePlate
 * }
 * @returns
 */
export function lookupVehicleByLicensePlate(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/vehicle/lookup";
    return instance.post(url, data).then((response) => response.data);
}

/**
 *
 * @param {*} data: {vehicleId, licensePlate, brand, ...}
 * @returns
 */
export function updateVehicle(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/vehicle/update";
    return instance.put(url, data).then((response) => response.data);
}

/**
 *
 * @param {*} data : {
 * LicensePlate*
 * Miles*
 * Device
 * Driver
 * }
 * @returns
 */
export function createVehicle(data) {
    setTokenHeader(getStorageValue("Access-token"));
    const url = "vehicle";
    return instance.post(url, data).then((response) => response.data);
}

export function deleteVehicle(vehicleId) {
    setTokenHeader(getStorageValue("Access-token"));
    const url = "/vehicle/delete/" + vehicleId;
    return instance.get(url).then((response) => response.data);
}

/** 
 * @function getAllRoutes
 * @param {data} -  
    $licensePlate = sqlesc($con, $POST->licenseplate);
    $startadress = sqlesc($con, $POST->startadress);
    $stopadress = sqlesc($con, $POST->stopadress);
    $toDate = Time_StripDate($POST->toDate);
    $fromDate = Time_StripDate($POST->fromDate);
    $searchtype = sqlesc($con, $POST->routeType);
    @member {string} attest: "1" || "0" || "";
    $attest = sqlesc($con, $POST->attest);
    $routeSearch = sqlesc($con, $POST->travelid);
    $purpose = sqlesc($con, $POST->travelpurpose);
    @member {number} driver 
    $ownerID = sqlesc($con, $POST->driver);
    $group = sqlesc($con, $POST->respNumber);
    @return {Array} 
*/
export function getAllRoutes(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/travels";
    return instance.post(url, data).then((response) => response.data);
}

export function getVehicleTravels(vehicleId = null) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);

    const url = `vehicle/travels/${vehicleId}`;

    return instance.get(url).then((response) => response.data);
}

export function getOngoingTravels(filter, mock=true) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = `travels/ongoing/${filter}`;
    if(mock) {
        setUseMockHeader(MOCK_API);
    } 
    return instance.get(url).then((response) => response.data);
}

export function putEdittravel(routeId, data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = `/travels/${routeId}/edit`;
    return instance.put(url, data).then((response) => response.data);
}

//get Routes info
export function getRouteById(id, mock=true) {
    setTokenHeader(getStorageValue("Access-token"));
    if(mock) {
        setUseMockHeader(MOCK_API);
    }

    let url = "/route/" + id;
    return instance.get(url).then((response) => response.data);
}

// Get Notificatiosn
export function getNotifications() {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/notis";
    return instance.get(url).then((response) => response.data);
}

//Get all conversations
export function getInbox() {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/inbox";
    return instance.get(url).then((response) => response.data);
}

//Get conversation by id
export function getConversations(id) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/inbox/" + id;
    return instance.get(url).then((response) => response.data);
}

//post a answer in a conversation
export function postConversationAnswer(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/inbox/answer";
    return instance.post(url, data).then((response) => response.data);
}
//get alarms
export function getAlarms(types = "all", dateFrom = "", dateTo = "") {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/alarms";
    if (types !== "all") {
        url = "/alarm/" + types;
    }
    if (dateFrom !== "") {
        url = url + `/${dateFrom}`;
    }
    if (dateTo !== "") {
        url = url + `/${dateTo}`;
    }

    return instance.get(url).then((response) => response.data);
}

//get vehicle alarms
export function getVehicleAlarms(vehicleId) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    const url = `vehicle/alarms/${vehicleId}`;

    return instance.get(url).then((response) => response.data);
}

//get alarms resivers
export function getAlarmsRecivers(types = "all") {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/alarmrecivers";
    if (types !== "all") {
        url = "/alarmrecivers/" + types;
    }

    return instance.get(url).then((response) => response.data);
}
//update alarm resivers
export function updateAlarmRecivers(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/updateAlarmRecivers";
    return instance.post(url, data).then((response) => response.data);
}
//Get All Drivers
export function getDrivers(mock=true) {
    setTokenHeader(getStorageValue("Access-token"));
    if(mock) {
        setUseMockHeader(MOCK_API);
    }
    let url = "/drivers";
    return instance.get(url).then((response) => response.data);
}

//getDriversTRASDemo
export function getDriversTRASDemo() {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/driversTRASDemo";
    return instance.get(url).then((response) => response.data);
}

//Update Driver
export function updateDriver(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    const userId = data.UserId;
    let url = `/driver/${userId}`;
    return instance.put(url, data).then((response) => response.data);
}
//Create Driver
export function createDriver(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = `/driver`;
    return instance.post(url, data).then((response) => response.data);
}

//Get All Users
export function getUsers() {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/users";
    return instance.get(url).then((response) => response.data);
}
//Get All Users
export function getUserData(id) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/user/" + id;
    return instance.get(url).then((response) => response.data);
}

//PUT attest
export function putTravelAttest(id, attest) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/travel/" + id + "/attest";
    return instance
        .put(url, { attest: attest })
        .then((response) => response.data);
}

//Post for userlogs
export function userlog(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/userlog";
    return instance.post(url, data).then((response) => response.data);
}

//post after tollpassings
export function Tollpassings(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/tollPassings";
    return instance.post(url, data).then((response) => response.data);
}

//get travelpurposerules
export function GetTravelPurposeRules() {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/travelpurposerules";
    return instance.get(url).then((response) => response.data);
}

//get specefic travelpurposerule by id
export function GetPurposeRuleById(id) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/getpurposerule/" + id;
    return instance.get(url).then((response) => response.data);
}

//edit and update travelpurposerule
/*
{
    startaddress
    startcity
    startnumber
    stopcity
    stopaddress
    stopnumber
    travelpurpose
    command Create/Update
}
*/
export function UpdatePurposeRule(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/updatepurposerule";
    return instance.post(url, data).then((response) => response.data);
}

export function GetAccessTemplates() {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/accessTemplates";
    return instance.get(url).then((response) => response.data);
}

export function updateCreateUser(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/user";
    return instance.post(url, data).then((response) => response.data);
}

export function updateUser(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    const userId = data.userId;
    let url = `/user/${userId}`;
    return instance.put(url, data).then((response) => response.data);
}

export function createUser(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/user";
    return instance.post(url, data).then((response) => response.data);
}

export function GetOrgs() {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/get-orgs/";
    return instance.get(url).then((response) => response.data);
}

export function GetOrganisationTree() {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/organisationTree/";
    return instance.get(url).then((response) => response.data);
}

export function getOrganisation(organisationId) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/organisation/" + organisationId;
    return instance.get(url).then((response) => response.data);
}

export function updateOrganisation(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/organisation";
    return instance.put(url, data).then((response) => response.data);
}

export function deleteOrganisation(organisationId, organisationParentsIds) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/organisation/" + organisationId;
    return instance
        .post(url, organisationParentsIds)
        .then((response) => response.data);
}

export function createOrganisation(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/organisation";
    return instance.post(url, data).then((response) => response.data);
}

export function deleteRule(id) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/deletePurpose/" + id;
    return instance.post(url).then((response) => response.data);
}
//underuppbyggnad
export function vehicelstatus() {
    setTokenHeader(getStorageValue("Access-token"));
    let url = "/get-vehicle-status";
    return instance.get(url).then((response) => response.data);
}

export function vehiclesStatus() {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/vehiclesStatus";
    return instance.get(url).then((response) => response.data);
}

export function getAccesses() {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/accesses";
    return instance.get(url).then((response) => response.data);
}

export function createAccessTemplate(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/accessTemplate";
    return instance.post(url, data).then((response) => response.data);
}

export function updateAccessTemplate(data) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = "/accessTemplate";
    return instance.put(url, data).then((response) => response.data);
}
export function deleteAccessTemplate(accessTemplateId) {
    setTokenHeader(getStorageValue("Access-token"));
    setUseMockHeader(MOCK_API);
    let url = `/accessTemplate/${accessTemplateId}`;
    return instance.post(url).then((response) => response.data);
}

/**
 * 
 * @param {* dateFrom: "20230810235959", dateTo: "20230810235959"} data 
 * @returns 
 */
export function getMeasurements(data) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = `/measurements`;
    return instance.post(url, data).then((response) => response.data);
}

/**
 * 
 * @param {* userId} userId 
 * @param {* dateFrom: "20230810235959", dateTo: "20230810235959", userId} data 
 * @returns 
 */
// userid is used for getting measuremnts per user, not notifications.
export function getMeasurementsAndNotificationsByUserIdCompactDate(data) {
    if(!data.userId) {
        data.userId = null;
    }
    // if(!data.userId)
    //     return PromiseRejectionEvent("No userId provided for endpoint getMeasurementsAndNotificationsByUserIdCompactDate");
    if(!data.dateFrom)
        data.dateFrom = Common.getTodaysMidnightDate().DateTimeCompactString;
    setTokenHeader(getStorageValue("Access-token"));
    let url = `/alc/userId/${data.userId}`;
    // console.log("url getMeasurementsAndNotificationsByUserIdCompactDate endpoint ", url)
    return instance.post(url, data).then((response) => response.data);
} 

/**
 * 
 * @param {* deviceId} deviceId 
 * @param {* dateFrom: "20230810235959", dateTo: "20230810235959", deviceId} data 
 * @returns 
 */
export function getMeasurementsAndNotificationsByDeviceIdCompactDate(data) {
    if(!data.deviceId) {
        data.deviceId = null;
    }
    // if(!data.userId)
    //     return PromiseRejectionEvent("No userId provided for endpoint getMeasurementsAndNotificationsByUserIdCompactDate");
    if(!data.dateFrom)
        data.dateFrom = Common.getTodaysMidnightDate().DateTimeCompactString;
    setTokenHeader(getStorageValue("Access-token"));
    let url = `/getAlc/device/${data.deviceId}`;
    // console.log("url getMeasurementsAndNotificationsByUserIdCompactDate endpoint ", url)
    return instance.post(url, data).then((response) => response.data);
} 

/**
 * 
 * @param {*} trackerId 
 * @param {* dateFrom: "20230810235959"} dateFrom 
 * @returns 
 */
export function getMeasurementsByDeviceId(trackerId, dateFrom) {
    if(!dateFrom) {
        dateFrom = Common.getTodaysMidnightDate().DateTimeCompactString;
    }
    setTokenHeader(getStorageValue("Access-token"));
    let url = `/measurements/${dateFrom}/${trackerId}`;
    return instance.get(url).then((response) => response.data);
} 

/**
 * 
 * @param {* userId} userId 
 * @param {* dateFrom: "20230810235959"} dateFrom 
 * @returns 
 */
export function getMeasurementsByUserIdCompactDate(userId, dateFrom, dateTo=null) {
    if(!userId)
        return PromiseRejectionEvent("No userId provided for endpoint getMeasurementsByUserIdCompactDate");
    if(!dateFrom)
        dateFrom = Common.getTodaysMidnightDate().DateTimeCompactString;
    setTokenHeader(getStorageValue("Access-token"));
    let url = `/measurements/userId/${userId}/${dateFrom}`;
    if(dateTo) {
        url += `/${dateTo}`
    }
    return instance.get(url).then((response) => response.data);
} 



/**
 * 
 * @param {*} deviceId 
 * @returns 
 */
export function requestAlcBlow(deviceId) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = `/alc/device/${deviceId}`;
    return instance.post(url).then((response) => response.data);
}

/**
 * 
 * @param {_ DateTime! CompactString e.g., 20230822103256} dateFrom 
 * @param {_ DateTime? CompactString e.g., 20230822103320} dateTo 
 * @returns 
 */
export function pullNotifications(dateFrom, dateTo) {
    setTokenHeader(getStorageValue("Access-token"));
    let url = `/notifications/${dateFrom}`;
    if(dateTo) {
        url = `/notifications/${dateFrom}/${dateTo}`;
    }
    return instance.get(url).then((response) => response.data);
}

/**
 * 
 * @param {* deviceId, dateFrom, dateTo} request 
 * @returns 
 */
export function pullNotificationsByDeviceIdDate(request) {
    setTokenHeader(getStorageValue("Access-token"));

    let url = `/notifications/${request.deviceId}`;
    if(request.dateFrom) {
        url += `/${request.dateFrom}`;
    }
    if(request.dateTo) {
        url += `/${request.dateTo}`;
    }
    return instance.get(url).then((response) => response.data);
}


export function deleteNotificationByNotificationId(notificationId) {
    setTokenHeader(getStorageValue("Access-token"));
    console.log("deleteNotificationByNotificationId  notificationId ", notificationId)
    let url = `/delete-notification/delete/${notificationId}`;
    return instance.get(url).then((response) => response.data);
}


export function archiveNotificationsByDeviceId(deviceId) {
    setTokenHeader(getStorageValue("Access-token"));
    console.log("deleteNotificationsByDeviceId  deviceId ", deviceId)
    let url = `/archive-notifications/device/${deviceId}`;
    return instance.get(url).then((response) => response.data);
}

export function getTravelsByUserId(requestData) {
    setTokenHeader(getStorageValue("Access-token"));
    const userId = requestData.userId
    let dateFrom = requestData.dateFrom
    let dateTo = requestData.dateTo
    if(!dateFrom) dateFrom = "2023-08-20"; //TODO yesterday
    if(!dateTo) dateTo = "2023-08-24"; //TODO current date
    const payload = {
        toDate: dateTo,
        fromDate: dateFrom,
        driver: userId
    }
    let url = `/travels`;
    return instance.post(url, payload).then((response) => response.data);
}


