import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Table, Tooltip } from "antd";
import { TinyArea } from "@ant-design/charts";
import React from "react";
import { useHistory } from "react-router-dom";

import NumberInfo from "./NumberInfo";
import Trend from "./Trend";
import styles from "./style.less";

import type { DataItem } from "./data.d";

const TopSearch = ({
    title,
    loading,
    graphData,
    searchData,
    dropdownGroup,
    graphData2,
    columns,
    pageSize
}: {
    title: string;
    loading: boolean;
    graphData?: DataItem[];
    dropdownGroup: React.ReactNode;
    searchData: DataItem[];
    graphData2?: DataItem[];
    columns: DataItem[];
    pageSize?: number;
}) => {
    const history = useHistory();

    console.log("graphData ", graphData);

    const tinyAreaConfig = {
        xField: "x",
        height: 45,
        forceFit: true,
        yField: "y",
        smooth: true,
        data: graphData,
    };
    const tinyAreaConfig2 = {
        xField: "x",
        height: 45,
        forceFit: true,
        yField: "y",
        smooth: true,
        data: graphData2,
    };

    return (
        <Card
            loading={loading}
            bordered={false}
            title={title ? title : "Current Alarms"}
            extra={dropdownGroup}
            style={{
                height: "100%",
            }}
        >
            <Row gutter={68}>
                {graphData && (
                    <Col sm={12} xs={24} style={{ marginBottom: 24 }}>
                        <NumberInfo
                            subTitle={
                                <span>
                                    Alarms activated this month
                                    <Tooltip title="All type of alarms are included in the calculation">
                                        <InfoCircleOutlined
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Tooltip>
                                </span>
                            }
                            gap={8}
                            total={graphData?.reduce(
                                (accumulated, current) => accumulated + current,
                                0
                            )}
                        />
                        <TinyArea {...(tinyAreaConfig as any)} />
                    </Col>
                )}

                {graphData2 && (
                    <Col sm={12} xs={24} style={{ marginBottom: 24 }}>
                        <NumberInfo
                            subTitle={
                                <span>
                                    Alarms activated this week
                                    <Tooltip title="All type of alarms are included in the calculation">
                                        <InfoCircleOutlined
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Tooltip>
                                </span>
                            }
                            total={graphData2?.reduce(
                                (accumulated, current) => accumulated + current,
                                0
                            )}
                            gap={8}
                        />
                        <TinyArea {...(tinyAreaConfig2 as any)} />
                    </Col>
                )}
            </Row>
            <Table<any>
                rowKey={(record) => record.index}
                size="small"
                columns={columns}
                dataSource={searchData}
                pagination={{
                    style: { marginBottom: 0 },
                    pageSize: pageSize ? pageSize : 5,
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            history.push("/");
                        },
                    };
                }}
            />
        </Card>
    );
};

export default TopSearch;
