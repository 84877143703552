import React, { useState } from "react";
import { NotisCheck } from "../common/function";
import { Form, Input, Button, Modal,  Row, Col, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import validator from "validator";
import * as Apis from "../../utils/api";
import GenericPasswordForm from "./GenericPasswordForm.tsx";

const FPassword = (props) => {
    const [loading, setLoading] = useState(false)

    const onClickSendEmail = (values) => {
        console.log("onClickSendEmail values", values)
        if (values.femail.length == 0) {
            return "Please enter an email address"
        } else {
            setLoading(true)
            Apis.forgotPassSendEmailToUser(values.femail).then((res) => {
                if (res.Status === "OK") {
                    setLoading(false)
                    console.log("forgotPassSendEmailToUser res ", res)
                    NotisCheck("success", "top", res.Message)
                } else {
                    setLoading(false)
                    NotisCheck("error", "top", res.Message)
                }
            })
        }
    }

    const formContent  = () => {
        return (
            <>
                <Form.Item
                    name="femail"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input your Email Address!'
                        }
                    ]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                    />

                </Form.Item>
            </>
        )
    }

    return (
        <GenericPasswordForm loading={loading} form={{name: 'myForm', onFinish: onClickSendEmail}} formContent={formContent} formButton={{buttonText: 'Email me a recovery link'}}/>
    )
}

export default FPassword;