import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Button, Row, Col, Drawer, Affix } from "antd";
import { useHistory } from "react-router-dom";
import Header from "../../components/breadcrumbheader/Header";
import RuleForm from "../../components/forms/rules/rule";
import "../../assets/css/functionPage.css"
import * as Apis from "../../utils/api";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";

const RuleList = (props) => {
  const history = useHistory();
  const [rules, setRules] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [visible, setVisible] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("Create");
  const [id, setId] = useState(0);

  const openDrawer = (id) => {
    setId(id);
    setDrawerTitle("Update");
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const getTravelPurposeRules = () => {
    Apis.GetTravelPurposeRules()
    .then((res) => {
      console.log("GetTravelPurposeRules res ", res)
      if (res.Status === "NOK") {
        console.log("error in gettravelpurposerules page", res);
      } else {
        console.log(res);
        setTableData(res.Rules);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  useEffect(() => {
    getTravelPurposeRules()
  }, []);

  const routes = [
    {
      path: "",
      breadcrumbName: "Travel",
    },
    {
      path: "",
      breadcrumbName: "Purpose Rules",
    },
  ];
  const columns = [
    {
      title: 'Startaddress',
      dataIndex: 'startaddress',
      key: 'startaddress',
    },
    {
      title: 'startnumber',
      dataIndex: 'startnumber',
      key: 'startnumber',
    },
    {
      title: 'Startcity',
      dataIndex: 'startcity',
      key: 'startcity',
    },
    {
      title: 'Stoppaddress',
      dataIndex: 'stopaddress',
      key: 'stopaddress',
    },
    {
      title: 'stopnumber',
      dataIndex: 'stopnumber',
      key: 'stopnumber',
    },
    {
      title: 'Stopcity',
      dataIndex: 'stopcity',
      key: 'stopcity',
    },
    {
      title: 'purpose',
      dataIndex: 'purpose',
      key: 'purpose',
    },
    /*{
      title: 'Action',
      render: (_, rec) => (<Button onClick={deleteRule(rec.id)}>Delete - {rec.id}</Button>)
    },*/
  ];

  const insertDataHandler = (formData) => {
    const data = tableData;
    data.length === 0 ? formData.id = 1 : formData.id = tableData[data.length - 1].id + 1;
    //insert rule to API
    getTravelPurposeRules()
    setVisible()
    setTableData([...data, formData])
  }

  const updateDataHandler = (formData) => {
    const data = tableData;
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (element.id === formData.id) {
          let dataSwipe = [...tableData];
          dataSwipe[key] = formData;
          //Update rule to API
          //getTravelPurposeRules()
          console.log("updateDataHandler dataSwipe", dataSwipe)
          setVisible()
          setTableData(dataSwipe)
          break;
        }
      }
    }
  }
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        console.log("click on row, opens drawer: ", record, rowIndex, event);
        openDrawer(record)
      },
    };
  };
  return (
    <div className="functionPagediv">
      <Affix
        offsetTop={150}
        onChange={(affixed) => console.log(affixed)}
        style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
      >
        <p>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              setId(0);
              console.log("Create button at right, ID: ", id)
              setDrawerTitle("Create");
              setVisible(true);
            }}
            icon={<PlusCircleOutlined />}
          />
        </p>
      </Affix>
      <Row justify="space-around" align="middle">
        <Col span={23}>
          <Header routes={routes} filter={false} />
          <Table columns={columns} dataSource={tableData} onRow={onRow} uniqKey={"id"} pagination={{defaultPageSize:20 }} />
        </Col>
      </Row>
      <Drawer
        title={drawerTitle + " Resesyftes Regler"}
        placement="right"
        size="large"
        onClose={onClose}
        visible={visible}
      >
        {id ? 
          <> 
            <RuleForm data={id} upData={updateDataHandler} />
          </> 
          : 
          <>
            <RuleForm data={id} newData={insertDataHandler} />
          </>}
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle,
    // allWidgetsdata: state.widgets.allWidgets
  };
};

export default connect(mapStateToProps)(RuleList);
