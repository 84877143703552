import { defineMessages } from 'react-intl'

export default defineMessages({
    ignoreAlerts: {
        id: "TRASDemoDashboard.card.ignoreAlerts.title",
        defaultMessage: "Ignore Alerts"
    },
    violations: {
        id: "TRASDemoDashboard.card.violations.title",
        defaultMessage: "Violations"
    },
    uncheckedDrivers: {
        id: "TRASDemoDashboard.card.uncheckedDrivers.title",
        defaultMessage: "Unchecked Drivers"
    },
    checkedDrivers: {
        id: "TRASDemoDashboard.card.checkedDrivers.title",
        defaultMessage: "Checked Drivers"
    },
    activeTravels: {
        id: "TRASDemoDashboard.card.activeTravels.title",
        defaultMessage: "Active Travels"
    },
    ongoingTravels: {
        id: "TRASDemoDashboard.GenericTopSearch.ongoingTravels.title",
        defaultMessage: "Ongoing Travels"
    },
    historicalTravels: {
        id: "TRASDemoDashboard.GenericTopSearch.historicalTravels.title",
        defaultMessage: "Historical Travels"
    },
    historicalAlcoholMeasurements: {
        id: "TRASDemoDashboard.GenericTopSearch.historicalAlcoholMeasurements.title",
        defaultMessage: "Historical Alcohol Measurements"
    },
    status: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.status",
        defaultMessage: "Status"
    },
    driver: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.driver",
        defaultMessage: "Driver"
    },
    licensePlate: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.licensePlate",
        defaultMessage: "License Plate"
    },
    travelStatus: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.travelStatus",
        defaultMessage: "Travel Status"
    },
    timestampAC: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.timestampAC",
        defaultMessage: "Timestamp AC"
    },
    ACResult: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.ACResult",
        defaultMessage: "AC Result"
    },
    actionRequestStatus: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.actionRequestStatus",
        defaultMessage: "Action Request Status"
    },
    travelTime: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.travelTime",
        defaultMessage: "Travel Time"
    },
    location: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.location",
        defaultMessage: "Location"
    },
    address: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.address",
        defaultMessage: "Address"
    },
    time: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.time",
        defaultMessage: "Time"
    },
    travelDuration: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.travelDuration",
        defaultMessage: "Travel Duration"
    },
    distance: {
        id: "TRASDemoDashboard.GenericTopSearch.column.title.distance",
        defaultMessage: "Distance"
    },
    sendAction: {
        id: "TRASDemoDashboard.GenericTopSearch.row.label.sendAction",
        defaultMessage: "Send Action"
    },
    exhale: {
        id: "TRASDemoDashboard.GenericTopSearch.row.dropdown.item.label.exhale",
        defaultMessage: "Exhale!"
    },
    clean: {
        id: "TRASDemoDashboard.GenericTopSearch.row.dropdown.item.label.clean",
        defaultMessage: "Clean!"
    },
    requestAccepted: {
        id: "TRASDemoDashboard.GenericTopSearch.row.actionRequestStatus.label.requestAccepted",
        defaultMessage: "Request Accepted"
    },
    requestClearedByAdmin: {
        id: "TRASDemoDashboard.GenericTopSearch.row.actionRequestStatus.label.requestClearedByAdmin",
        defaultMessage: "Request Cleared By Admin"
    },
    exhaleRequestPending: {
        id: "TRASDemoDashboard.GenericTopSearch.row.actionRequestStatus.label.exhaleRequestPending",
        defaultMessage: "Exhale Request Pending"
    },
    cleanRequestPending: {
        id: "TRASDemoDashboard.GenericTopSearch.row.actionRequestStatus.label.cleanRequestPending",
        defaultMessage: "Clean Request Pending"
    },
    start: {
        id: "TRASDemoDashboard.common.label.start",
        defaultMessage: "Start"
    },
    stop: {
        id: "TRASDemoDashboard.common.label.stop",
        defaultMessage: "Stop"
    },
    findHistoricalTravels: {
        id: "TRASDemoDashboard.drawer.title.findHistoricalTravels",
        defaultMessage: "Find Historical Travels"
    },
    timeInterval: {
        id: "TRASDemoDashboard.drawer.form.formItem.label.timeInterval",
        defaultMessage: "Time Interval"
    },
    driverName: {
        id: "TRASDemoDashboard.drawer.form.formItem.label.driverName",
        defaultMessage: "Driver Name"
    },
    search: {
        id: "TRASDemoDashboard.common.label.search",
        defaultMessage: "Search"
    },
    requestSent: {
        id: "TRASDemoDashboard.common.feedback.title.requestSent",
        defaultMessage: "Request Sent"
    },
    successSentRequest: {
        id: "TRASDemoDashboard.common.feedback.successSentRequest",
        defaultMessage: "Successfully sent request to driver"
    },
    requestFailed: {
        id: "TRASDemoDashboard.common.feedback.title.requestFailed",
        defaultMessage: "Something went wrong"
    },
    failSentRequest: {
        id: "TRASDemoDashboard.common.feedback.failSentRequest",
        defaultMessage: "Could not send request to driver"
    },
    noHistoricalTravelsFound: {
        id: "TRASDemoDashboard.common.feedback.text.noHistoricalTravelsFound",
        defaultMessage: "No Historical Travels has been done yet."
    },
    noHistoricalMeasurementsFound: {
        id: "TRASDemoDashboard.common.feedback.text.noHistoricalMeasurementsFound",
        defaultMessage: "No Historical Measurements has been done yet."
    },
    noOngoingTravelsFound: {
        id: "TRASDemoDashboard.common.feedback.text.noOngoingTravelsFound",
        defaultMessage: "No Ongoing Travels is currently running. Wait till one of your trackers get active to see them here."
    },
    invalidInput: {
        id: "TRASDemoDashboard.common.label.invalidInput",
        defaultMessage: "Invalid Input"
    }
    
})