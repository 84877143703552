import * as moment from "moment";

/**
 * @function summaryData
 * @param travels : RouteType(string name) | TravelTimeSeconds | DistanceKilometers
 */
const summaryData = (travels) => {
    
    let nrOfPrivateRoutes = 0
    let nrOfBusinessRoutes = 0
    let nrOfTotalRoutes = 0
    let traveltimeSecondsPrivate = 0
    let traveltimeSecondsBusiness = 0
    let totalTraveltimeSeconds = 0
    let distancePrivateKm = 0
    let distanceBusinessKm = 0
    let distanceTotalKm = 0
    travels.forEach((travel) => {
      if(travel.RouteType == "Privatresa") {
        nrOfPrivateRoutes++
        if(travel.TravelTimeSeconds) traveltimeSecondsPrivate += Number( travel.TravelTimeSeconds )
        if(travel.DistanceKilometers) distancePrivateKm += Number(travel.DistanceKilometers)
      } else {
        nrOfBusinessRoutes++
        if(travel.TravelTimeSeconds) traveltimeSecondsBusiness += Number (travel.TravelTimeSeconds)
        if(travel.DistanceKilometers) distanceBusinessKm += Number(travel.DistanceKilometers)
      }
    })
    nrOfTotalRoutes = nrOfPrivateRoutes +  nrOfBusinessRoutes
    totalTraveltimeSeconds = traveltimeSecondsPrivate + traveltimeSecondsBusiness
    distanceTotalKm = distancePrivateKm + distanceBusinessKm
    const formattedTraveltimePrivate = moment().startOf('day')
      .seconds(traveltimeSecondsPrivate)
      .format('HH:mm:ss');
    const formattedTraveltimeBusiness = moment().startOf('day')
      .seconds(traveltimeSecondsBusiness)
      .format('HH:mm:ss');
    const formattedTraveltimeTotal = moment().startOf('day')
      .seconds(totalTraveltimeSeconds)
      .format('HH:mm:ss');    
    let summaryData = {
      'totalPrivateRoutes': nrOfPrivateRoutes,
      'totalBusinessRoutes' : nrOfBusinessRoutes,
      'totalRoutes': nrOfTotalRoutes,
      'totalPrivateTraveltime': formattedTraveltimePrivate,
      'totalBusinessTraveltime': formattedTraveltimeBusiness,
      'totalTraveltime': formattedTraveltimeTotal,
      'totalPrivateDistance' : distancePrivateKm,
      'totalBusinessDistance' : distanceBusinessKm, 
      'totalDistance' : distanceTotalKm,
    }

    return summaryData
}

export default summaryData