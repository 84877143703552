import {setLoader} from "../auth";
import instance from "../../instance";
import {callFail, callSuccess} from "../../utils/callback";
import getStorageValue from "../../utils/getStorageValue";
import * as Apis from "../../utils/api";
import {callback} from "chart.js/helpers";



export const getUsers = (callback: any) => (dispatch: any) => {
    dispatch(setLoader(true));

    Apis.getUsers()
      .then((res) => {
        console.log("getusers actions api res: ", res);
        if (res.Status === "NOK") {
          console.log("error", res);
          dispatch({
              type: "SET_ERRORS",
              payload: res,
            });
            callFail(callback, res);
        } else {
            dispatch({
              type: "GET_USERS",
              payload: res,
            });
            typeof callback == "function" && callback(res); 
        }
        dispatch(setLoader(false));
      })
      .catch((error) => {
        console.log("error in getusers actions api call", error)
        if (error) {
            dispatch({
                type: "SET_ERRORS",
                payload: error,
            });
        }
        dispatch(setLoader(false));
      });
}

export const updateCreateUser =
    (user: any, callbackSuccess?: any, callbackFail?: any) =>
    (dispatch: any) => {
        console.log("updateCreateUser actions api entry user: ", user);

        dispatch(setLoader(true));

        Apis.updateCreateUser(user)
            .then((res) => {
                console.log("updateCreateUser actions api res: ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "UPDATE_CREATE_USER",
                            payload: res.Results,
                        },
                        callbackSuccess && callbackSuccess(user)(res.Results)
                    );
                } else {
                    console.log("error", res);
                    dispatch(
                        {
                            type: "SET_ERRORS",
                            payload: res,
                        },
                        callbackFail && callbackFail(user)(res)
                    );
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.log(
                    "error in updateCreateUser actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

