import React, { useState } from "react";
import { Col, Row, Tabs, Button, Affix, Drawer } from "antd";
import Header from "../../components/breadcrumbheader/Header";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import "../../assets/css/header.css";
import {
  FormOutlined
} from "@ant-design/icons";

const Useroverview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { UID } = useParams();

  const loading = useSelector((state) => state.auth.loading);
  const [state, setState] = useState({
    error: "",
  });
  function callback(key) {
    console.log(key);
  }


  const routes = [
    {
      breadcrumbName: "User",
    },
    {
      breadcrumbName: "User overview",
    },
  ];
  const [visibleNote, setVisibleNote] = useState(false);
  const onCloseInfo = () => {
    setVisibleNote(false);
  };
  return (
    <div className="functionPagediv">
        <Header routes={routes} filter={false} />
        show driver {UID}
        <ul>
            <li>Hur många resor har denn användare gjort??</li>
            <li>Distance på gjorda resor</li>
            <li>Händelser som denna användare gjort</li>
            <li>Skicka meddelande direkt till denna användare?</li>
            <li>hämta rapporer för användare direkt? reseräkning, </li>
            <li>Beteende på användaren?</li>
            <li>Uppdatera profil?</li>
        </ul>
    </div>
  );
};

export default Useroverview;
