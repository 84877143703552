import {
  ADDED_WIDGETS,
  ALL_WIDGETS,
  ACTIVED_KEY,
  ADDED_WIDGETS1,
  ADDED_WIDGETS2,
  ADDED_WIDGETS3,
  CHANGE_LAYOUT,
  SELECTED_MENU,
  ALL_FUNC_MENU,
  CHANGE_LANG
} from "../actionTypes";

let initialState = {
  addedWidgets: [],
  changeLayout: false,
  selectedLang: "en"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDED_WIDGETS:
      console.log("action => ", action);
      return {
        ...state,
        addedWidgets: action.payload,
        test: action.test
      };
    case ADDED_WIDGETS1: {
      return {
        ...state,
        addedWidgets1: action.payload
      };
    }
    case ADDED_WIDGETS2: {
      return {
        ...state,
        addedWidgets2: action.payload
      };
    }
    case ADDED_WIDGETS3: {
      return {
        ...state,
        addedWidgets3: action.payload
      };
    }
    case ALL_WIDGETS:
      return {
        ...state,
        allWidgets: action.payload
      };
    case ACTIVED_KEY:
      return {
        ...state,
        activedKey: action.payload
      };
    case CHANGE_LAYOUT:
      return {
        ...state,
        changeLayout: action.payload
      };
    case SELECTED_MENU:
      console.log("action selected menu => ", action);
      return {
        ...state,
        selectedMenu: action.payload,
        submenukey: action.submenu_key,
        submenutitle: action.submenu_title,
        selectedSubmenu: action.selectedSubmenu,
        selectedFuncTitle: action.selectedFuncTitle
      };
    case ALL_FUNC_MENU:
      return {
        ...state,
        allFuncMenu: action.payload
      };
    case CHANGE_LANG:
      return {
        ...state,
        selectedLang: action.payload
      };
    default:
      return state;
  }
};
