import React, {useEffect, Component, useState, useCallback} from 'react'
import {GoogleMap, InfoWindow, LoadScript, useJsApiLoader, 
    Marker as GoogleMarker, Polyline as GooglePolyline} from '@react-google-maps/api'
import { Button, Space, Switch, Slider } from 'antd';
import { CheckOutlined, CloseOutlined, FastForwardOutlined} from '@ant-design/icons';
import Marker from './Marker'
import Polyline from './Polyline'
import RePlay from './RePlay'
import greendot from './greendot.png'

const librariesConfig = ["geometry", "drawing", "places"]

// import useWindowDimensions from './ViewPort'
const Map = (props) => {
    console.log("Map component props: ", props)
    // console.log("props.data...: ", props.data)
    // console.log("props.data.StartAddress ", props.data.StartAddress)
    // console.log("props.data.Time.StartTimeStamp ", props.data.Time.StartTimeStamp)


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA58-rBlwfGOHKU3X99wwccgjC8o0kw7uQ",
        libraries: librariesConfig
    })

    const [state, setState] = useState({
        mapsLoaded: false,
        map: null
    });

    const [mapCenter, setMapCenter] = useState(props.center)
    const [isReplay, setIsReplay] = useState(false)
    const [replaySpeed, setReplaySpeed] = useState(500);
    const [isReplayChecked, setIsReplayChecked] = useState(false)
    
    useEffect(() => {
        if (state.map) {
            const bounds = new window.google.maps.LatLngBounds();
            let Coords = [];
            props.data.Geometrics.Polyline.map((x) =>
                Coords.push({
                    lat: parseFloat(x.Latitude),
                    lng: parseFloat(x.Longitude),
                })
            );
            Coords.map(x => {
              bounds.extend({
                lat: x.lat,
                lng: x.lng,
              });
            });
            state.map.fitBounds(bounds);
            const myReplaySwitch = document.getElementById('floating-replay-switch')
            state.map.controls[global.google.maps.ControlPosition.TOP_CENTER].push(myReplaySwitch);
        }
    }, [state.map]);

    useEffect( () => {
        if (!props.parentDrawerVisible) {
            setIsReplay(false)
            setIsReplayChecked(false)
        }
    }, [props.parentDrawerVisible])

    const onMapLoaded = (data, type) => {
        if (state.map) {
            if (data !== undefined) {
                switch (type) {
                    case "Polyline":
                        return (renderPoly(data))
                    case "Marker":
                        return (renderMarker(data, state.map))
                    case "Markers":
                        return (renderMarkers(data, state.map))
                    case "Route":
                        return (renderPoly(data, true))
                    default:
                        return ""
                }
            }
        } 
    }

    const renderPoly = (data, marker = false) => {
        return (
            <Polyline
                map={state.map}
                path={data}
                marker={marker}
            />
        )
    }

    const renderMarker = (data, map) => {
        return (
            <Marker
                map={map}
                position={data}
            />
        )
    }
    const renderMarkers = (data, map) => {
        
        return data.map((k, i) => (
            <Marker
                map={map}
                position={{lat: k.lat, lng: k.lng}}
            />
        ))
    }
    
    const onLoad = React.useCallback(function callback(map) {
        setState({map: map})
        
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setState({map: map})
        setIsReplay(false)
    }, [])

    const replaySwitchHandler = () => {
        setIsReplayChecked((isReplayChecked) => !isReplayChecked)
        setIsReplay((isReplay) => !isReplay)
    }

    const handleMapCenterState = (position) => {
        //more smooth to pan to position than set center directly
        if(state.map) {
            state.map.panTo(position)
        }
        //setMapCenter(position)
    }

    const myStartPosition = {
        lat: props.data.Geometrics.StartLat, 
        lng: props.data.Geometrics.StartLng
    }

    
    //TODO when replaying, make the camera follow the car? and if user take control, let user be in control
    //TODO second time clicking the fittobound is a little off. or atleast the centering.
    return (
        <>
            {isLoaded ?
                <GoogleMap
                    center={mapCenter}
                    zoom={props.zoom}
                    onLoad={onLoad}
                    mapContainerStyle={{
                        position: props.position ? props.position : "absolute",
                        height: props.height ? props.height : "100%",
                        width: "100%"
                    }}
                    mapProp = {{streetViewControl: false}}
                    onUnmount={onUnmount}
                >
                    {state.map ? 
                        <><div id="floating-replay-switch" style={{margin: '10px'}}>
                            <Space direction='vertical' size={0}>
                                {console.log("isreplaychecked: ", isReplayChecked)}
                                <Switch onChange={replaySwitchHandler} checked={isReplayChecked} checkedChildren="Replaying" unCheckedChildren="Replay Route"/>
                                {console.log("replaySpeed", replaySpeed)}
                                <Slider 
                                    defaultValue={replaySpeed} 
                                    value={replaySpeed} 
                                    onChange={(value) => setReplaySpeed(value)} 
                                    reverse={true} 
                                    min={50} 
                                    max={500}
                                />
                            </Space>
                        </div></> 
                    : 
                        <></>
                    }
                    {isReplay ? 
                        <>  
                            {console.log("googlemap center state: ", mapCenter)}
                            <Marker info={props.data.StartAddress} info1={props.data.Time.StartTimeStamp} icon={greendot} position={myStartPosition} />
                            <RePlay handleMapCenterStateCallback={handleMapCenterState}  infoWindowContent={props.data.LicensePlate} replaySpeed={replaySpeed} path={props.data} parentDrawerVisible={props.parentDrawerVisible}/>
                        </>
                    : 
                        <> {onMapLoaded(props.data, props.type)} </>
                    }   
                </GoogleMap>
            : <><p> Could not load map </p></>}
        </> 
    )
}

export default Map



  
 