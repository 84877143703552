import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Table,
    Space,
    Button,
    Row,
    Col,
    Modal,
    Skeleton,
    Drawer,
    Affix,
} from "antd";
import { useHistory } from "react-router-dom";
import Header from "../../../components/breadcrumbheader/Header";
import UserForm from "../../../components/forms/users/user";
import JRTable from "../../../components/table/JRTable";
import "../../../assets/css/functionPage.css";
import * as Apis from "../../../utils/api";

import {
    DownOutlined,
    FilterOutlined,
    SearchOutlined,
    FormOutlined,
    AppstoreAddOutlined,
    UserAddOutlined,
} from "@ant-design/icons";

const UserList = (props) => {
    console.log("UserList props", props)
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [visible, setVisible] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState("Create");
    const [userId, setUserId] = useState(0);
    const [formName, setFormName] = useState("userForm");
    const [formId, setFormId] = useState("userForm");

    const openDrawer = (id) => {
        setUserId(id);
        setDrawerTitle("Update");
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        if(props?.history?.location?.state?.setDrawerVisible) {
            setUserId(0)
            setDrawerTitle("Create")
            setVisible(true);
        }

        Apis.getUsers()
            .then((res) => {
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    console.log(res);
                    setUsers(res.Results);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    const routes = [
        {
            path: "",
            breadcrumbName: "Users",
        },
        {
            path: "",
            breadcrumbName: "Overview",
        },
    ];
    const columns = [
        {
            title: "Namn",
            dataIndex: "Name",
            // linkrender: "/vehiclecard/overview/",
        },
        {
            title: "Title",
            dataIndex: "Title",
        },
        {
            title: "Telefon",
            dataIndex: "Phone",
        },
        {
            title: "Mobil",
            dataIndex: "Phone2",
        },
        {
            title: "E-post",
            dataIndex: "Mail",
        },
        {
            title: "Tillhör",
            dataIndex: "Company",
        },
        {
            title: "Behörighetsmall",
            dataIndex: "AccessTemplate",
        },
        {
            title: "RFID nr",
            dataIndex: "RFID",
        },
    ];
    const onRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                console.log(record, rowIndex, event);
                //alert(record.vID);
                openDrawer(record.UserID);
            },
        };
    };
    return (
        <div className="functionPagediv">
            <Affix
                offsetTop={150}
                onChange={(affixed) => console.log(affixed)}
                style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
            >
                <p>
                    <Button
                        size="large"
                        type="primary"
                        onClick={() => {
                            setUserId(0);
                            setDrawerTitle("Create");
                            setVisible(true);
                        }}
                        icon={<UserAddOutlined />}
                    />
                </p>
            </Affix>
            <Row justify="space-around" align="middle">
                <Col span={23}>
                    <Header routes={routes} filter={false} />
                    {/* {TODO when clicking row show user details} */}
                    <JRTable
                        columns={columns}
                        data={users}
                        link="/user/view/"
                        showHashLink={false}
                        // showLinkText="Visa Användare"
                        row={onRow}
                        uniqKey={"UserID"}
                    />
                </Col>
            </Row>
            <Drawer
                title={drawerTitle + " user"}
                placement="right"
                size="large"
                onClose={onClose}
                open={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={() => {
                                setVisible(false);
                            }}
                            form={"userForm"}
                            type="primary"
                            key="submit"
                            htmlType="submit"
                        >
                            {userId === 0 ? "Create" : "Update"}
                        </Button>
                    </div>
                }
            >
                {userId === 0 ? (
                    <>
                        <UserForm formName={formName} formId={formId} />
                    </>
                ) : (
                    <>
                        <UserForm
                            formName={formName}
                            formId={formId}
                            id={userId}
                        />
                    </>
                )}
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => {
    console.log("selected menu mapstatetoprops", state);
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        // allWidgetsdata: state.widgets.allWidgets
    };
};

export default connect(mapStateToProps)(UserList);
