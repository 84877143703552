import * as organisationActions from "../actions/organisations/organisations";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../global-types";
import { findOrganisationNamePath } from "../reducer/organisations/organisationReducer";

export const useCascaderOrganisations = (companyId?: string | number) => {
    const dispatch = useDispatch();
    const antdCascaderOptionsOrganisations = useSelector(
        (state: IRootState) =>
            state.organisations.antdCascaderOptionsOrganisations
    );

    const [currentOrgsPath, setCurrentOrgsPath] = useState<string[]>([]);

    useEffect(() => {
        organisationActions.getOrganisations()(dispatch);

        if (antdCascaderOptionsOrganisations.length > 0 && companyId) {
            const currentOrganisationPath =
                findOrganisationNamePath(
                    Number(companyId),
                    antdCascaderOptionsOrganisations
                ).length > 0
                    ? findOrganisationNamePath(
                          Number(companyId),
                          antdCascaderOptionsOrganisations
                      ).reverse()
                    : ["Vehicle is not connected to any organisation"];
            setCurrentOrgsPath(currentOrganisationPath);
        }
    }, [antdCascaderOptionsOrganisations.length, companyId]);

    return [
        antdCascaderOptionsOrganisations,
        currentOrgsPath,
        findOrganisationNamePath,
    ] as const;
};
