import { IDriver, ITableColumnFilter } from "../../global-types";
import * as TRASDemoMock from "../../utils/TRASDemoDashboardMockData";

const initialState = {
    ongoingAlcoholMeters: [],
    alcoholMeterStatusColorsAntd: [],
    historicalMeasurementsStatistics: [],
    loading: true,
    specificDeviceMeasurements: [],
    specificUserMeasurements: [],
    hasData: true,
    historicalMeasurements: <any>[],
    historicalMeasurementsIsolated: <any>[],
    isHistoricalMeasurementsIsolatedLoading: false,
    isHistoricalMeasurementsLoading: false,
};

const alcoholMeterStatusColors = ["Blue", "Yellow", "Red", "BlinkRed"];
const alcoholMeterActivationResultColorValues = {
    Blue: "Approved",
    Yellow: "Warning",
    Red: "Invalid",
    BlinkRed: "Vital",
};

const alcoholMeterReducer = (
    state = initialState,
    { type, payload }: any,
    callback: any
) => {
    switch (type) {
        case "GET_ONGOING_ALCOHOL_METERS_TRAS_DEMO":

            const alcoholMeterStatusColorsAntd = alcoholMeterStatusColors.map(
                (color) => {
                    let value = "Accepted";
                    switch (color) {
                        case "Blue":
                            value =
                                alcoholMeterActivationResultColorValues.Blue;
                            break;
                        case "Yellow":
                            value =
                                alcoholMeterActivationResultColorValues.Yellow;
                            break;
                        case "Red":
                            value = alcoholMeterActivationResultColorValues.Red;
                            break;
                        case "BlinkRed":
                            value =
                                alcoholMeterActivationResultColorValues.BlinkRed;
                            break;
                    }

                    return {
                        text: value,
                        value: color,
                    };
                }
            );

            // console.log("GET_ONGOING_ALCOHOL_METERS_TRAS_DEMO today ", payload.Result)

            return {
                ...state,
                ongoingAlcoholMeters: payload.Result,
                alcoholMeterStatusColors: alcoholMeterStatusColors,
                alcoholMeterStatusColorsAntd: alcoholMeterStatusColorsAntd,
                callback,
            };

        case "SET_LOADER":
            return {
                ...state,
                loading: payload,
            };
        case "GET_MEASUREMENTS_BY_DEVICE_ID":
            return {
                ...state,
                specificDeviceMeasurements: payload.Result,
                [payload.tableName]: payload.Result,
                loading: payload,
            };
        case "GET_MEASUREMENTS_BY_USER_ID":
            return {
                ...state,
                specificUserMeasurements: payload.Result,
                [payload.tableName]: payload.Result,
                loading: payload,
            };

        case "GET_HISTORICAL_MEASUREMENTS":
            // console.log("GET_HISTORICAL_MEASUREMENTS reducer ", [payload.tableName])
            const tableObject: Record<string, any[]> = {};
            const tableNames: string[] = payload.tableNames;
            tableNames.forEach((tableName) => {
                tableObject[tableName] = payload.Result;
            });
            // console.log("GET_HISTORICAL_MEASUREMENTS tableNames in reducer", tableNames, "tableObject ", tableObject)
            return {
                ...state,
                ...tableObject,
                loading: payload,
            };

        case "SET_ERRORS":
            return {
                ...state,
                hasData: false,
                ongoingAlcoholMeters: [],
            };
        case "SET_LOADER_HISTORICAL_MEASUREMENTS_ISOLATED":
            return {
                ...state,
                isHistoricalMeasurementsIsolatedLoading: payload,
            };
        case "SET_LOADER_HISTORICAL_MEASUREMENTS":
            return {
                ...state,
                isHistoricalMeasurementsLoading: payload,
            };
        default:
            return state;
    }
};

export default alcoholMeterReducer;
