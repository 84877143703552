import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Descriptions,
    Empty,
    Form,
    Select,
    Row,
    Col,
    DatePicker,
    Divider,
    notification,
    Space,
    Spin,
    Table,
    Typography,
    Affix,
    Drawer,
} from "antd";
import { connect } from "react-redux";
import "../../assets/css/functionPage.css";
import {
    nNotis,
    openNotificationWithIcon,
} from "../../components/common/function";
import moment from "moment";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import * as Apis from "../../utils/api";
import {
    CreateCongestiontaxExport,
    getCurrentDate,
    getFirstDayPreviousMonthDate,
    getFirstDayThisMonthDate,
    getLastDayPreviousMonthDate,
} from "../../components/common/function";
import { padStart } from "lodash";

export const makeCostSymbol = (parsedTollPassing, type = "Business") => {
    if (type === "Business") {
        let costSymbolString = parsedTollPassing.IsMaxPassages
            ? `(${parsedTollPassing.CostBusiness})`
            : `${parsedTollPassing.CostBusiness}`;
        if (parsedTollPassing.IsMaxCost) {
            costSymbolString = costSymbolString + "*";
        }
        return costSymbolString;
    } else {
        let costSymbolString = parsedTollPassing.IsMaxPassages
            ? `(${parsedTollPassing.CostPrivate})`
            : `${parsedTollPassing.CostPrivate}`;
        if (parsedTollPassing.IsMaxCost) {
            costSymbolString = costSymbolString + "*";
        }
        return costSymbolString;
    }
};

//class Congestiontax extends React.Component {
const Congestiontax = (props) => {
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [tollpassings, setTollPassings] = useState([]);
    const [userSearchHistory, setUserSearchHistory] = useState([]);
    const [tollpassingBusinessTotalCost, setTollPassingBusinessTotalCost] =
        useState(0);
    const [tollpassingPrivateTotalCost, setTollPassingPrivateTotalCost] =
        useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [dateIntervalSearch, setDateIntervalSearch] = useState([]);
    const [form] = Form.useForm();
    const { Option } = Select;
    const [formLayout, setFormLayout] = useState("horizontal");
    const { RangePicker } = DatePicker; //For range picker
    const dateFormat = "YYYY-MM-DD";
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    /*const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
  let today = moment();*/
    let oneMonthBack = moment(startOfMonth).subtract(1, "M");
    const [open, setOpen] = useState(false);
    const [formName, setFormName] = useState("congestionTax");

    const showDrawer = () => {
        setOpen(!open);
    };
    //load in alla data for the first time..
    useEffect(() => {
        setIsLoading(true);
        //TODO  test einar dahl  userid: '1634329474513852'
        let firstDayPreviousMonthDate = getFirstDayPreviousMonthDate("-", true);
        let lastDayPreviousMonthDate = getLastDayPreviousMonthDate("-", true);
        const data = {
            vehicleId: undefined,
            userId: props.loggedInUser.UserID,
            //userid: '1634329474513852',
            dateFrom: firstDayPreviousMonthDate,
            dateTo: lastDayPreviousMonthDate,
        };
        Apis.Tollpassings(data)
            .then((res) => {
                if (res.Status === "OK") {
                    console.log("API Tollpassings res", res);
                    const parsedRes = parseTollpassingsAPI(res);
                    console.log("parsedRes", parsedRes);
                    setTollPassings(parsedRes);
                    setDateIntervalSearch({
                        dateFrom: data.dateFrom,
                        dateTo: data.dateTo,
                    });
                    setTollPassingBusinessTotalCost(
                        res.Results.TotalCostBusiness
                    );
                    setTollPassingPrivateTotalCost(
                        res.Results.TotalCostPrivate
                    );
                    setOpen(true);
                    setIsLoading(false);
                } else {
                    setOpen(false);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        //get vehile
        Apis.getAllVehicles()
            .then((res) => {
                console.log("API", res);
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    console.log(res);
                    setVehicles(res.Vehicles);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // get driver
        Apis.getDrivers()
            .then((res) => {
                console.log("Drivers from api call: ", res);
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    //console.log(res);
                    const getUniqueDrivers = (dataArr) => {
                        const seen = new Set();
                        const uniqueDrivers = dataArr.filter((driver) => {
                            const duplicate = seen.has(driver.UserId);
                            seen.add(driver.UserId);
                            return !duplicate;
                        });
                        return uniqueDrivers;
                    };
                    if (Array.isArray(res)) {
                        // console.log("getDrivers API Res is ARRAY ", res);
                        setDrivers(getUniqueDrivers(res));
                    } else {
                        console.log(
                            "getDrivers API Res is ordinary OBJECT",
                            res
                        );
                        const driversArr = Object.keys(res).map(
                            (key) => res[key]
                        );
                        setDrivers(getUniqueDrivers(driversArr));
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const parseTollpassingsAPI = (res) => {
        try {
            let data = res.Results.TollPassings.map((tollpass) => ({
                CostBusiness: tollpass.Business ? tollpass.Business.Cost : 0,
                CostPrivate: tollpass.Private ? tollpass.Private.Cost : 0,
                DateTime: tollpass.DateTime,
                Driver: tollpass.Driver,
                Id: tollpass["Id"],
                IsMaxCost: tollpass.IsMaxCost && tollpass.IsMaxCost,
                IsMaxPassages: tollpass.IsMaxPassages && tollpass.IsMaxPassages,
                LicensePlate: tollpass.LicensePlate,
                TollId: tollpass.TollId,
                TollZone: tollpass.TollZone,
            }));
            return data;
        } catch (error) {
            //TODO handle error correctly
            console.log("Error in parseTollpassingsAPI: ", error);
            return error;
        }
    };

    const isFormInputValid = (data) => {
        return !(
            (data.vehicleId == undefined || data.vehicleId == null) &&
            (data.userId == undefined || data.userId == null)
        );
    };

    const onFinish = (values) => {
        console.log("onfinish vvalues congestiontax: :", values);
        const data = {
            vehicleId: values.Vehicle,
            userId: values.Driver,
            dateFrom: values.rangeDate[0].format(dateFormat),
            dateTo: values.rangeDate[1].format(dateFormat),
        };
        setUserSearchHistory(data);

        if (!isFormInputValid(data)) {
            openNotificationWithIcon(
                "error",
                "Invalid search",
                "Please select a Driver or a Vehicle with your desired date-interval to see Tollpassings",
                "top",
                6
            );
            return;
        }

        setDateIntervalSearch({ dateFrom: data.dateFrom, dateTo: data.dateTo });

        console.log("parsed values in onFinish: ", data);
        Apis.Tollpassings(data)
            .then((res) => {
                console.log("API tollpassings res: ", res);
                if (res.Status === "NOK") {
                    setTollPassings([]);
                    console.log("API tollpassings NOK res: ", res);
                } else {
                    const amountOfTollpassings =
                        res.Results.TollPassings.length;
                    if (amountOfTollpassings > 0) {
                        const parsedRes = parseTollpassingsAPI(res);
                        console.log("parsedRes ", parsedRes);
                        console.log(
                            "amountOfTollpassings ",
                            amountOfTollpassings
                        );
                        setTollPassings(parsedRes);
                        openNotificationWithIcon(
                            "success",
                            `Succesfully found ${amountOfTollpassings} toll passings`,
                            "",
                            "top",
                            5
                        );
                    } else {
                        console.log("TollPassings state:: ", tollpassings);
                        setTollPassings([]);
                        openNotificationWithIcon(
                            "error",
                            "No Toll passings was found",
                            "",
                            "top",
                            5
                        );
                    }

                    setTollPassingBusinessTotalCost(
                        res.Results.TotalCostBusiness
                    );
                    setTollPassingPrivateTotalCost(
                        res.Results.TotalCostPrivate
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
        showDrawer();
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const Export = () => {
        if (tollpassings.length >= 1) {
            openNotificationWithIcon(
                "info",
                "Export file will now be downloaded",
                "",
                "top",
                5
            );
            CreateCongestiontaxExport(
                tollpassings,
                props.loggedInUser.Name,
                tollpassingBusinessTotalCost,
                tollpassingPrivateTotalCost,
                dateIntervalSearch,
                makeCostSymbol
            );
        } else {
            openNotificationWithIcon(
                "error",
                "No Toll passings to export",
                "",
                "top",
                5
            );
        }
    };

    const isDateWithinSameDay = (dateMoment, targetDateMoment) => {
        let dayInSeconds = 24 * 60 * 60;
        return dateMoment.diff(targetDateMoment, "seconds") <= dayInSeconds;
    };

    const isDateWithinSameHour = (dateMoment, targetDateMoment) => {
        let hourInSeconds = 60 * 60;
        return dateMoment.diff(targetDateMoment, "seconds") <= hourInSeconds;
    };

    //Using API total sums instead
    const totalTollpassingCost = (tollpassingData, key) => {
        console.log("totalTollpassingCost tollpassingData ", tollpassingData);
        let sum = 0;

        tollpassingData
            .map((tollpassing) =>
                parseInt(tollpassing[key] ? tollpassing[key]["Cost"] : 0)
            )
            .forEach((cost) => {
                if (cost) sum += cost;
            });
        console.log("totalTollpassingCost sum ", sum);
        return sum;
    };

    const formItemLayout = formLayout === "horizontal" && {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
    };
    const buttonItemLayout = formLayout === "horizontal" && {
        wrapperCol: { span: 4, offset: 8 },
    };
    const columns = [
        {
            title: "Date",
            dataIndex: "DateTime",
            width: "20%",
        },
        {
            title: "Driver",
            dataIndex: "Driver",
            width: "20%",
        },
        {
            title: "License Plate",
            dataIndex: "LicensePlate",
        },
        {
            title: "Toll Zone",
            dataIndex: "TollZone",
        },
        {
            title: "Cost Business",
            dataIndex: "CostBusiness",
            render: (_, parsedTollPassing) =>
                makeCostSymbol(parsedTollPassing, "Business"),
        },
        {
            title: "Cost Private",
            dataIndex: "CostPrivate",
            render: (_, parsedTollPassing) =>
                makeCostSymbol(parsedTollPassing, "Private"),
        },
    ];

    return (
        <div className="functionPagediv">
            <Affix
                offsetTop={150}
                onChange={(affixed) => console.log(affixed)}
                style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
            >
                <div>
                    <p>
                        {tollpassings.length > 1 ? (
                            <Button
                                size="large"
                                type="primary"
                                onClick={Export}
                                icon={<DownloadOutlined />}
                            />
                        ) : (
                            <></>
                        )}
                    </p>
                    <p>
                        <Button
                            size="large"
                            type="primary"
                            onClick={showDrawer}
                            icon={<SearchOutlined />}
                        />
                    </p>
                </div>
            </Affix>
            {isLoading ? (
                <>
                    <div
                        style={{
                            marginTop: "25%",
                            marginRight: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Spin size="middle" />
                    </div>
                </>
            ) : (
                <>
                    {tollpassings.length > 1 ? (
                        <Row justify="space-around" align="middle">
                            <Col span={23}>
                                <>
                                    <Table
                                        title={() => (
                                            <Typography.Title
                                                editable
                                                level={5}
                                                style={{ margin: 0 }}
                                                onClick={(event) => {
                                                    showDrawer();
                                                }}
                                            >
                                                {Object.keys(
                                                    dateIntervalSearch
                                                ) &&
                                                    "Toll passings between " +
                                                        dateIntervalSearch.dateFrom +
                                                        " and " +
                                                        dateIntervalSearch.dateTo}
                                            </Typography.Title>
                                        )}
                                        columns={columns}
                                        rowKey={(record) => record.Id}
                                        dataSource={tollpassings}
                                        pagination={{
                                            defaultPageSize: 20,
                                        }}
                                        summary={() => (
                                            <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell
                                                        colSpan={4}
                                                        index={1}
                                                    >
                                                        Total Cost:{" "}
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell
                                                        index={0}
                                                    >
                                                        {tollpassingBusinessTotalCost
                                                            ? tollpassingBusinessTotalCost
                                                            : 0}
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell
                                                        index={0}
                                                    >
                                                        {tollpassingPrivateTotalCost
                                                            ? tollpassingPrivateTotalCost
                                                            : 0}
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell
                                                        colSpan={6}
                                                        index={1}
                                                    >
                                                        Costs inside parantes ()
                                                        does not count due to
                                                        many toll passages
                                                        within one hour.
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell
                                                        colSpan={6}
                                                        index={1}
                                                    >
                                                        Costs with an asterix *
                                                        have achieved max cost
                                                        for that day. In
                                                        Stockholm it is 105 kr
                                                        and for Gothenburg it is
                                                        60 kr.
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </Table.Summary>
                                        )}
                                    />
                                </>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Empty
                                style={{}}
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{
                                    height: 60,
                                }}
                                description={
                                    <p>
                                        Search on toll passings to display them
                                    </p>
                                }
                            >
                                <Button
                                    style={{
                                        marginRight: "46%",
                                    }}
                                    type="primary"
                                    onClick={() => {
                                        showDrawer();
                                    }}
                                >
                                    Find Toll Passings
                                </Button>
                            </Empty>
                        </>
                    )}
                </>
            )}

            <Drawer
                title="Search"
                size="large"
                onClose={showDrawer}
                open={open}
                footer={
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button
                            onClick={() => {}}
                            form={formName}
                            type="primary"
                            key="submit"
                            htmlType="submit"
                        >
                            Get Tollpassings
                        </Button>
                    </div>
                }
            >
                <Form
                    //style={{ width: '75%' }}
                    {...formItemLayout}
                    //layout={formLayout}
                    form={form}
                    name={props.formName ? props.formName : formName}
                    id={props.formId ? props.formId : formName}
                    initialValues={{
                        layout: formLayout,
                        rangeDate: [
                            moment(oneMonthBack, dateFormat),
                            moment(startOfMonth, dateFormat),
                        ],
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item label="Driver" name="Driver">
                        <Select
                            //showSearch
                            placeholder="Select a Driver"
                            allowClear
                            onChange={(value) => {
                                console.log("search:", value);
                            }}
                            /*onSearch={(value) => {
                console.log('search:', value);
              }}
              filterOption={(input, option) => option.children.toString().toLowerCase().includes(input.toString().toLowerCase())}*/
                        >
                            {console.log("drivers state: ", drivers)}
                            {drivers.length > 0 &&
                                drivers.map((i) => (
                                    <Option key={i.UserId} value={i.UserId}>
                                        {i.Name}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Vehicle" name="Vehicle">
                        <Select
                            showSearch
                            placeholder="Select a Vehicle"
                            allowClear
                        >
                            {vehicles.length > 0 &&
                                vehicles.map((i) => (
                                    <Option value={i.vID}>
                                        {i.LicensePlate}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Date" name="rangeDate">
                        <RangePicker format={dateFormat} />
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => {
    console.log("selected menu mapstatetoprops", state);
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        loggedInUser: state.login.loginUser,
        // allWidgetsdata: state.widgets.allWidgets
    };
};

export default connect(mapStateToProps)(Congestiontax);
