import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Table, Space, Button, Row, Col, DatePicker, Select, Drawer, Input, Affix, Form, AutoComplete, Tooltip } from "antd";
//import Highlighter from 'react-highlight-words';
import JRTable from "../../components/table/JRTable";
import "../../assets/css/functionPage.css";
import Header from "../../components/breadcrumbheader/Header";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import VehicleSearchDrawer from "../../components/vehicals/vehicleSearchDrawer_new";
import VehicleInfoDrawer from "../../components/vehicals/vehicleInfoDrawer";
import JRStatus from "../../components/vehicals/status";
import "../../assets/css/vehicelstatus.css";
import { Link, useHistory } from "react-router-dom";
import { Scatter } from "@ant-design/charts";
import * as Apis from "../../utils/api";
import { createVehicleExport, formatTimestampToReadableDate } from "../../components/common/function";
import moment from "moment";

const Vehiclestatus = (props) => {
  const [AdvanceSearchenable, setAdvanceSearchenable] = useState(false);
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const history = useHistory();
  const dispatch = useDispatch();
  const [civd, setcivd] = useState("");
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [status, setStatus] = useState([]);
  const [vehicleStatus, setVehicleStatus] = useState([]);
  const [constantAllVehicles, setConstantAllVehicles] = useState([]);
  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [users, setUsers] = useState([]);
  const [group, setGroup] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getVehicles = () => {
    Apis.vehiclesStatus()
      .then((res) => {
        console.log("API vehiclesStatus", res)
        if (res.Status === "NOK") {
          console.log("error recieved from API in Apis.vehiclesStatus", res);
        } else {
          setStatus(res.vStatus);
          const parsedData = parseVehicleStatusAPI(res)
          setVehicleStatus(parsedData)
          const brands = parsedData.map(vehicle => (vehicle.Brand))
          const respNumbers = parsedData.map(vehicle => (vehicle.Group))
          setBrands([...new Set(brands)])
          setGroup( respNumbers.filter(x => x != null && x != "" && x != undefined))
          setConstantAllVehicles(parsedData)
          console.log("parseddata: ", parsedData);
          //return true
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //TODO parse vehicle current address from api call
  const parseVehicleStatusAPI = (res) => {
    try {
      let data = res.Results.VehiclesStatus.map(
        vehicle => ({
          User: vehicle.User,
          Owner: vehicle.Owner,
          Brand: vehicle.Brand,
          LicensePlate: vehicle.LicensePlate,
          Company: vehicle.Company,
          Device: vehicle.Device,
          Group: vehicle.Group,
          Connection: vehicle.Connection,
          ConnectionMessage: vehicle.Connection.Message,
          Gsm: vehicle.Gsm,
          Battery: vehicle.Battery,
          Miles: vehicle.Miles,
          Model: vehicle.Model,
          CurrentPosition: vehicle.CurrentPosition, 
          Alarm: vehicle.Alarm,
        })
      )
      return data
    } catch(error) {
      //TODO handle error correctly
      console.log("Error in parseVehicleStatusAPI: ", error)
      return error
    }
  }

  useEffect(() => {
      getVehicles();
      Apis.getUsers()
      .then((res) => {
        console.log("API", res)
        if (res.Status === "NOK") {
          console.log("error in APi call, getVehicles", res);
        } else {
          setUsers(res.Results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
      Apis.GetOrgs()
      .then((res) => {
        console.log("API OCOM ", res)
        if (res.Status === "NOK") {
          console.log("error in API call, getOrgs: ", res);
        } else {
          setCompanies(res.ORGS);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const routes = [
    {
      path: "",
      breadcrumbName: "Vehicle",
    },
    {
      path: "",
      breadcrumbName: "Vehicle Status",
    },
  ];

  const mainColumns = [
    {
      title: "License plate",
      dataIndex: "LicensePlate",
      key: "LicensePlate",
      render: (_, parsedData) => (<Space><a onClick={(e) => history.push("/vehiclecard/overview/" + parsedData.LicensePlate)}>{parsedData.LicensePlate}</a></Space>)
    },
    {
      title: "Company",
      dataIndex: "Company",
      key: "Company",
      render: (_, parsedData) => (parsedData.Company ? parsedData.Company : "Unknown")
    },
    {
      title: "Vehicle manager",
      dataIndex: "User",
      key: "User",
    },
    {
      title: "Tracker",
      dataIndex: "Device",
      key: "Device",
      //TODO navigate to correct page
      render: (_, parsedData) => (<Space><a onClick={(e) => history.push("/travels/completedtrips/standardview")}>{parsedData.Device}</a></Space>)
    },
    {
      //TODO fix friendly date presentation
      title: "Connection",
      dataIndex: "Connection",
      key: "Connection",
      width: "2%",
      render: (_, parsedData) => (
        <JRStatus icon="bolt" msg={parsedData.ConnectionMessage} color={parsedData.Connection.Color}/>
      )
    },
    {
      title: "GSM",
      dataIndex: "Gsm",
      key: "Gsm",
      width: "5%",
      render: (_, parsedData) => (<JRStatus icon="wifi" msg={parsedData.Gsm.Message}/>)

    },
    {
      title: "Batteri",
      dataIndex: "Battery",
      key: "Battery",
      width: "5%",
      render: (_, parsedData) => (<><JRStatus icon="battery-full" msg={parsedData.Battery.Message} color={parsedData.Battery.Color}/></>)
    },
    {
      title: "Position",
      dataIndex: "CurrentPosition",
      key: "CurrentPosition",
      width: "5%",
      //TODO navigate to correct page
      render: (_, parsedData) =>  (
        <a onClick={(e) => history.push("/positions/current")}>
          <JRStatus icon="location-dot" msg={parsedData.CurrentPosition.Address ? parsedData.CurrentPosition.Address : parsedData.CurrentPosition.Coordinates  } color={parsedData.CurrentPosition.Color}/>
        </a>
      ) 
    },
    {
      title: "Larm",
      dataIndex: "Alarm",
      key: "Alarm",
      width: "5%",
      //TODO navigate to correct page
      render: (_, parsedData) => (
        <a onClick={(e) => history.push("/alarm/device")}>
          <JRStatus icon="triangle-exclamation" color={parsedData.Alarm.Color}/>
        </a>
      )
    },
  ];

  const onCloseInfo = () => {
    setVisibleInfo(false);
  };
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(!open);
  };
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        // record: row data
        // rowIndex: row index
        // event: event prototype
        console.log("click", record)
        console.log("RwoID", rowIndex)
        //alert(record.vID);
        setcivd(record.vID)
        setVisibleInfo(true);
      },
    };
  };

  const createExport = () => {
    console.log("vehicleStatus state in createExport: ", vehicleStatus)
    let exportVehicleStatus = vehicleStatus.map(vehicle => ({
      ...vehicle,
      LicensePlate: vehicle.LicensePlate,
      Driver: vehicle.User,
      Model: vehicle.Model,
      Miles: vehicle.Miles,
      Owner: vehicle.Owner,
    }))
    console.log("exportVehicleStatus createExport: ", exportVehicleStatus)
    createVehicleExport(exportVehicleStatus, props.loggedInUser.Name);
  };

  return (
    <div className="functionPagediv">
      <Affix
        offsetTop={150}
        onChange={(affixed) => console.log(affixed)}
        style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
      >
        <div>
          <p>
            <Button
              size="large"
              type="primary"
              onClick={createExport}
              icon={<DownloadOutlined />}
            />
          </p>
          <p>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                showDrawer(true);
                console.log(open);
              }}
              icon={<SearchOutlined />}
            />
          </p>
        </div>
      </Affix>
      <Row justify="space-around" align="middle">
        <Col span={23}>
          <Header routes={routes} filter={false} />
          <VehicleSearchDrawer AdvancedSearchBtnEnable={false} pageSetOpen={setOpen} constantAllVehicles={constantAllVehicles} setVehicles={setVehicleStatus} Open={open} brands={brands} companies={companies} group={group}/>  
          <Table columns={mainColumns} dataSource={vehicleStatus} onRow={onRow} pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30', '50']}} /> 
          <Drawer
            title="Vehicle Info"
            placement="right"
            onClose={onCloseInfo}
            visible={visibleInfo}
            size="large"
          >
            <VehicleInfoDrawer vid={civd} />
          </Drawer>
          
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  console.log("mapStateToProps vehiclestatus: ", state);
  return {
    loggedInUser: state.login.loginUser,
  };
};

export default connect(mapStateToProps)(Vehiclestatus);
