import React, { useState, useEffect } from "react";
import {
    Button,
    Row,
    Col,
    Drawer,
    Affix,
    Form,
    Input,
    InputNumber,
    AutoComplete,
    Tooltip,
    Select,
    Cascader,
    notification,
} from "antd";
import * as Apis from "../../../utils/api";
import { nNotis } from "../../common/function";
import { IDriverFormProps } from "../data";
import { useHistory } from "react-router-dom";

import * as driverActions from "../../../actions/drivers/drivers";
import * as vehicleActions from "../../../actions/vehicles/vehicles";
import * as organisationActions from "../../../actions/organisations/organisations";
import * as templateActions from "../../../actions/templates/templates";

import { useDispatch, useSelector } from "react-redux";
import { IDriver, IRootState } from "../../../global-types";
import { validateCreateFormInputs } from "./validate";
import { useCascaderOrganisations } from "../../../hooks/useCascaderOrganisations";
import { isArray } from "lodash";

const DriverForm = (props: IDriverFormProps) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();

    const [
        organisationOptions,
        currentOrganisationPath,
        findOrganisationNamePath,
    ] = useCascaderOrganisations(props?.driverData?.CompanyId);

    const updatedDriver = useSelector(
        (state: IRootState) => state.drivers.updatedDriver
    );
    
    const availableLicensePlatesAntdFormatted = useSelector(
        (state: IRootState) => state.vehicles.availableLicensePlatesAntdFormatted
    );
    const apiIsLoading = useSelector(
        (state: IRootState) => state.drivers.loading
    );
    const accessTemplates = useSelector(
        (state: IRootState) =>
            state.templates.accessTemplatesAntdSelectFormatted
    );

    useEffect(() => {
        resetForm();

        vehicleActions.getVehiclesAPI()(dispatch);

        if (props.driverData && !!Object.keys(props.driverData).length) {
            form.setFieldsValue({
                FirstName: props.driverData.FirstName,
                LastName: props.driverData.LastName,
                Title: props.driverData.Title,
                Email: props.driverData.Email,
                RFID: props.driverData.RFID,
                LicensePlate: props.driverData.LicensePlate,
            });
        }
    }, [
        props?.driverData?.FirstName,
        props?.driverData?.LastName,
        props?.driverData?.CompanyId,
    ]);

    useEffect(() => {
        templateActions.getAccessTemplates()(dispatch);
    }, []);

    const onFinish = (values: any) => {
        console.log("onFinish values", values);
        values.UserId = props?.rowData?.UserId;
        if(!values.CompanyId ) values.CompanyId = props?.rowData?.CompanyId
        if(Array.isArray(values.CompanyId)) {
            values.CompanyId =
            values.CompanyId && values.CompanyId[values.CompanyId.length - 1];
        } 
        values.FullName = values.FirstName + " " + values.LastName;
        values.CompanyName = findOrganisationNamePath(
            values.CompanyId,
            organisationOptions
        )[0];
        console.log("onFinish values", values);
        if (props?.driverData && !!Object.keys(props?.driverData).length) {
            onUpdate(values);
        } else {
            onCreate(values);
        }
    };

    const onUpdate = (values: IDriver) => {
        //update
        driverActions.updateDriver(
            values,
            () => {
                props.tableRowUpdate &&
                    props.rowIndex != null &&
                    props.tableRowUpdate(props.rowIndex, values);

                nNotis(
                    "success",
                    "top",
                    `Successfully updated driver`,
                    3,
                    "Updated Driver"
                );
            },
            () => {
                nNotis(
                    "error",
                    "top",
                    `Could not update driver; try again later or contact support`,
                    6,
                    "Something went wrong"
                );
            }
        )(dispatch);
    };

    const onCreateSuccess = () => {
        return nNotis(
            "success",
            "top",
            `Successfully created driver`,
            3,
            "Created Driver"
        );
    };

    const onCreateFail = () => {
        return nNotis(
            "error",
            "top",
            `Could not create driver; try again later or else contact support`,
            7,
            "Something went wrong"
        );
    };

    const onCreate = (values: IDriver) => {
        //TODO validate more within form item
        console.log("onCreate values ", values);
        const valObj = validateCreateFormInputs(values);
        if (valObj.value) {
            // create
            driverActions.createDriver(
                values,
                onCreateSuccess,
                onCreateFail
            )(dispatch);
        } else {
            const key = `open${Date.now()}`;
            const btn = (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                        history.push({
                            pathname: "/admin/users",
                            state: { setDrawerVisible: true },
                        });
                        notification.close(key);
                    }}
                >
                    Create User
                </Button>
            );
            const close = () => {};
            notification.open({
                type: "error",
                message: "Can not create driver",
                placement: "top",
                description: valObj.message,
                btn,
                key,
                onClose: close,
            });
        }
    };

    const resetForm = () => {
        form.resetFields();
        console.log("resetform");
    };

    const twoColsRowResponsiveProps = {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 12,
        xl: 12,
        style: { marginBottom: 3 },
    };

    const oneColRowResponsiveProps = {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
        style: { marginBottom: 3 },
    };

    /**
     *   CompanyId: string | number;
    UserId: string | number;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    FullName?: string;
    LicensePlate?: string | number;
    RFID?: string | number;
    Title?: string;
     */
    return (
        <>
            <Form
                form={form}
                name={props.formName ? props.formName : "driverForm"}
                id={props.formId ? props.formId : "driverForm"}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item label="First Name" name="FirstName">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item label="Last Name" name="LastName">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item label="Title" name="Title">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item label="Email" name="Email">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item label="RFID" name="RFID">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col {...twoColsRowResponsiveProps}>
                        <>
                        <Form.Item label="License Plate" name="LicensePlate">
                            <Select
                                value={props?.driverData?.LicensePlate}
                                options={availableLicensePlatesAntdFormatted}
                            />
                        </Form.Item>
                        </>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col {...oneColRowResponsiveProps}>
                        {currentOrganisationPath.length > 0 &&
                            props?.driverData &&
                            !!Object.keys(props?.driverData).length && (
                                <Form.Item
                                    label="Organisation"
                                    name="CompanyId"
                                >
                                    <Cascader
                                        options={organisationOptions}
                                        defaultValue={currentOrganisationPath}
                                        changeOnSelect
                                    />
                                </Form.Item>
                            )}
                        {props.driverData &&
                            Object.keys(props.driverData).length === 0 && (
                                <Form.Item
                                    label="Organisation"
                                    name="CompanyId"
                                >
                                    <Cascader
                                        options={organisationOptions}
                                        changeOnSelect
                                    />
                                </Form.Item>
                            )}
                    </Col>
                </Row>
                {props.driverData &&
                    Object.keys(props.driverData).length === 0 && (
                        <>
                            <Row gutter={24}>
                                <Col {...oneColRowResponsiveProps}>
                                    <Form.Item
                                        label="Access Template"
                                        name="AccessTemplate"
                                    >
                                        <Select options={accessTemplates} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
            </Form>
        </>
    );
};

export default React.memo(DriverForm);
