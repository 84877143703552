export const makeUniqueAntd = (key: any, array: any[]) => {
    // const unique = [...new Set(array.map((obj) => obj[key]))];
    const unique = array.map((obj) => obj[key]).filter((value, index, array) => array.indexOf(value) === index)
    const uniqueAntd = unique.map((obj) => {
        return {
            value: obj,
            text: obj,
        };
    });
    return uniqueAntd;
};
