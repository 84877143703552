import React, { useState, useEffect } from 'react';
import { Button, Row, Drawer, Affix, Form, Input, InputNumber, AutoComplete, Tooltip } from "antd";
import * as Apis from '../../../utils/api';
import { nNotis } from '../../common/function';

const RuleForm = (props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    resetrForm()
    if (props.data) {
      form.setFieldsValue({
        id: props.data.id,
        startaddress: props.data.startaddress,
        startnumber: props.data.startnumber,
        startcity: props.data.startcity,
        stopaddress: props.data.stopaddress,
        stopnumber: props.data.stopnumber,
        stopcity: props.data.stopcity,
        purpose: props.data.purpose,
      })
    }
  }, [props.data]);

  const onFinish = (values) => {
    const data = {
      startaddress: values.startaddress,
      startcity: values.startcity,
      startnumber: values.startnumber,
      stopcity: values.stopcity,
      stopaddress: values.stopaddress,
      stopnumber: values.stopnumber,
      travelpurpose: values.purpose,
      command: "Create"
    }
    Apis.UpdatePurposeRule(data)
      .then((res) => {
        console.log("API", res)
        if (res.Status === "NOK") {
          console.log("APIerror");
        } else {
          console.log("Svar", res);
          console.log("purpose", values)
          form.resetFields();
          props.newData(values)
          nNotis('success','top',"You have now Created a new rule",3)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onUpdate = () => {
    const formdata = form.getFieldValue();
    const data = {
      startaddress: formdata.startaddress ? formdata.startaddress : "",
      startcity: formdata.startcity ? formdata.startcity : "",
      startnumber: formdata.startnumber ? formdata.startnumber : "",
      stopcity: formdata.stopcity ? formdata.stopcity : "",
      stopaddress: formdata.stopaddress ? formdata.stopaddress : "",
      stopnumber: formdata.stopnumber ? formdata.stopnumber : "",
      travelpurpose: formdata.purpose ? formdata.purpose : "",
      id: formdata.id,
      command: "Update"
    }
    Apis.UpdatePurposeRule(data)
      .then((res) => {
        console.log("UpdatePurposeRule API res ", res)
        if (res.Status === "NOK") {
          console.log("APIerror res ", res);
        } else {
          props.upData(form.getFieldValue())
          form.resetFields();
          nNotis('success','top', `You updated the rule ${data.travelpurpose ? data.travelpurpose : ""}`,3, "Updated rule")
          
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteRule = () => {
    const formdata = form.getFieldValue();
    //alert("Delete ID:"+formdata.id);
    Apis.deleteRule(formdata.id)
      .then((res) => {
        if (res.Status === "NOK") {
          console.log("error");
        } else {
          console.log(res);
          props.upData(form.getFieldValue())
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const resetrForm = () => {
    console.log("resetform");
  }
  return (
    <Row type="flex" justify="center" align="middle" >
      <Form
        form={form}
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 24,
        }}

        onFinish={onFinish}
      >
        {/*<h1 style={{ textAlign: 'center' }}>Skapa resesyftesregel</h1>*/}

        {props.data ? (<Form.Item
          label="id"
          name="id"
          style={{
            display: 'none',
          }}
        >
          <Input />
        </Form.Item>) : null}
        <Form.Item style={{ marginBottom: 0, }} >
          <Form.Item
            label="startaddress"
            name="startaddress"
            style={{
              display: 'inline-block',
              width: 'calc(65% - 8px)',
            }}
          >
            <Input />
          </Form.Item>
          <Tooltip placement="top" title={<span>Write "-" for multiple addresses. E.g. 12-14</span>} >
            <Form.Item
              label="startnumber"
              name="startnumber"
              style={{
                display: 'inline-block',
                width: 'calc(35% - 8px)',
              }}
            >
                <Input />
            </Form.Item>
          </Tooltip>
        </Form.Item>
        <Form.Item
          label="startcity"
          name="startcity"
          style={{
            display: 'inline-block',
            width: '100%',
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0, }}>
          <Form.Item
            label="stopaddress"
            name="stopaddress"
            style={{
              display: 'inline-block',
              width: '65%',
            }}
            rules={[
              {
                required: true,
                message: 'stopaddress is required',
              },
            ]}
            >
              <Input />
          </Form.Item>
          <Tooltip placement="top" title={<span>Write "-" for multiple addresses. E.g. 12-14</span>} >
            <Form.Item
              label="stopnumber"
              name="stopnumber"
              style={{
                display: 'inline-block',
                width: '35%',
              }}
            >
              <Input />
            </Form.Item>
          </Tooltip>
        </Form.Item>
        <Form.Item
          label="stopcity"
          name="stopcity"
          style={{
            display: 'inline-block',
            width: '100%',
          }}
          rules={[
            {
              required: true,
              message: 'stopcity is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="purpose"
          name="purpose"
          style={{
            display: 'inline-block',
            width: '100%',
          }}
          rules={[
            {
              required: true,
              message: 'purpose is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item colon={false} style={{ textAlign: 'center' }}>
          {props.data 
            ? 
            <><Button onClick={onUpdate} style={{ float: 'unset' }}>Update</Button> 
            <Button danger onClick={deleteRule} style={{ float: 'unset' }}>Delete</Button></>
            : 
            <Button type="primary" htmlType="submit" style={{ float: 'unset' }}>Create</Button>
          }
        </Form.Item>
      </Form>
    </Row>
  );
};

export default RuleForm;