import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {Switch, Route, NavLink} from "react-router-dom";
import "../../assets/css/functionPage.css";
import { List,Timeline,Row,Col } from "antd";
import instance from "../../instance";
import * as Apis from '../../utils/api';
import getStorageValue from "../../utils/getStorageValue";
import { useHistory } from "react-router-dom";
import Header from "../../components/breadcrumbheader/Header";

const VehicleNotes = (props) => {
  const [messages, setMessages] = useState([]);
  const routes = [
    {
        path: '',
        breadcrumbName: 'Vehicles',
    },
    {
        path: '',
        breadcrumbName: 'Notes',
    },
  ];
  //FASTER WAY TO DO APICALLS!
  useEffect(() => {
    console.log("useEffect has been called! will now get ");
    Apis.getAllVehicleNotes().then((res) => {
        if (res.Status == "NOK") {
          console.log("error");
        } else {
          console.log(res);
          let arr = [];
          console.log("API",res.Message);
          for (var key in res.Message) {
              if (res.Message.hasOwnProperty(key)) {
                  arr.push({
                      vid: res.Message[key]['vid'], 
                      licenseplate: res.Message[key]['licenseplate'], 
                      date:res.Message[key]['date'],
                      user:res.Message[key]['user'],
                      subject:res.Message[key]['subject'],
                      message: res.Message[key]['message'],
                  });
              }
          }
          setMessages(arr)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
    <div className="functionPagediv">
        <Row justify="space-around" align="middle">
        <Col span={23}>
         <Header routes={routes} filter={false} />
         <Timeline mode="left">
        {
          messages.map(
            (k, i) => (
              <Timeline.Item label={k.date}>{k.user}<br/><b>{k.licenseplate} - {k.subject}</b><br/>{k.message}</Timeline.Item>
            )
          )
        }
      </Timeline>
        </Col></Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle,
    // allWidgetsdata: state.widgets.allWidgets
  };
};

export default connect(mapStateToProps)(VehicleNotes);
