import { setLoader } from "../auth";
import instance from "../../instance";
import { callFail, callSuccess } from "../../utils/callback";
import getStorageValue from "../../utils/getStorageValue";
import * as Apis from "../../utils/api";
import vehicle from "../../reducer/vehicle";
import * as moment from "moment";
import {
    nNotis,
    getPreviousWeekDate,
} from "../../components/common/function";
import * as Common from "../../components/common/function";

import * as TRASDemoMock from "../../utils/TRASDemoDashboardMockData";

export const getTravels = (data, callback) => (dispatch) => {
    dispatch(setLoader(true));
    instance.defaults.headers.common["Access-Token"] =
        getStorageValue("Access-token");
    const url = "/travels";
    instance
        .post(url, data)
        .then((res) => {
            if (res.data.Status === "NOK") {
                dispatch({
                    type: "SET_ERRORS",
                    payload: res.data.Message,
                });
                callFail(callback, res.data);
            } else {
                dispatch({
                    type: "GET_TRAVELS",
                    payload: {
                        data: res.data,
                        fromDate: data.fromDate,
                        toDate: data.toDate,
                    },
                });
                if (callback) callback(res.data.Routes[0].RouteID);
                //callSuccess(callback, res.data);
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("getTravels API ERROR", error);
            if (error.response) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error.response,
                });
            }
            dispatch(setLoader(false));
        });
};

export const getHistoricalTravels = (requestData, sources = ['historicalTravels'], callback = null) => (dispatch) => {
    //TODO init solution. Otherwise it will reload all the time when sending in another source.
    if (sources.find((source) => source === "historicalTravels")) {
        dispatch(
            {
                type: "SET_LOADER_HISTORICAL_TRAVELS",
                payload: true,
            },
        );
    }

    if (sources.length <= 0) {
        sources = ['historicalTravels']
    }

    if (!requestData) {
        requestData = {
            fromDate: moment(getPreviousWeekDate()),
            toDate: moment(),
            licenseplate: "",
            routeType: 0,
            RouteTypeId: 0,
            stopadress: "",
            startadress: "",
            travelpurpose: "",
            org: "",
            attest: "",
            driver: "",
            travelid: "",
            respNumber: "",
        };
    }

    if (!requestData.toDate) {
        //FYI add 1 day to prevent problems with timezones
        requestData.toDate = Common.getCurrentDateTimeString()
            .DateTimeObject.add(1, "day")
            .format("YYYY-MM-DD HH:mm:ss");
    }

    // console.log("requestData in getHistoricalTravels ", requestData, "sources ", sources)

    Apis.getAllRoutes(requestData)
        .then((response) => {
            // console.log("getHistoricalTravels ", response, "sources: ", sources)
            if (response.Status === "OK") {
                dispatch(
                    {
                        type: "GET_HISTORICAL_TRAVELS",
                        payload: { ...response, sources: sources },
                    },
                    callback && callback(response)
                );
            } else {
                console.log("error", response);
                dispatch({
                    type: "GET_HISTORICAL_TRAVELS_ERROR",
                    payload: response,
                });
            }
            dispatch(
                {
                    type: "SET_LOADER_HISTORICAL_TRAVELS",
                    payload: false,
                },
            );
        })
        .catch((error) => {
            console.log("error in getHistoricalTravels actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(
                {
                    type: "SET_LOADER_HISTORICAL_TRAVELS",
                    payload: false,
                },
            );
        });
};



export const getTravelCoordinatesById = (travelId, callback) => (dispatch) => {
    dispatch(setLoader(true));

    Apis.getRouteById(travelId, false)
        .then((response) => {
            // console.log("getTravelCoordinatesById api response: ", response);
            if (response.Status === "NOK") {
                console.log("error in getTravelCoordinatesById", response);
                dispatch({
                    type: "GET_TRAVEL_COORDINATES_ERROR",
                    payload: response,
                });
            } else {
                // console.log("getRouteById api response: ", response);
                dispatch(
                    {
                        type: "GET_TRAVEL_COORDINATES",
                        payload: response,
                    },
                    callback && callback(response)
                );
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log(error);
        });
};


export const getTravelsByUserId = (requestData, callback) => (dispatch) => {
    const userId = requestData.userId
    let dateFrom = requestData.dateFrom
    let dateTo = requestData.dateTo

    if (!dateFrom) dateFrom = "2023-08-20"; //TODO yesterday
    if (!dateTo) dateTo = "2023-08-24"; //TODO current date

    dispatch(setLoader(true));
    if (!userId) throw new Error("No userId was provided");
    if (userId === "0" || userId === 0) throw new Error("A 0 as userId is insufficient");

    Apis.getTravelsByUserId({ userId: userId, dateFrom: dateFrom, dateTo: dateTo })
        .then((response) => {
            // console.log("getTravelsByUserId actions api res: ", response);
            if (response.Status === "OK") {
                dispatch(
                    {
                        type: "GET_USERID_TRAVELS",
                        payload: response,
                    },
                    callback && callback(response)
                );
            } else {
                console.log("error", response);
                dispatch({
                    type: "GET_USERID_TRAVELS_ERROR",
                    payload: response.Message,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getTravelsByUserId actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const singleTravel = (travelId, callback) => (dispatch) => {
    instance.defaults.headers.common["Access-Token"] =
        getStorageValue("Access-token");
    const url = "route/" + travelId;
    instance
        .get(url)
        .then((res) => {
            if (res.data.Status === "NOK") {
                callFail(callback, res.data);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res.data.Message,
                });
            } else {
                typeof callback == "function" && callback(res.data);
                dispatch({
                    type: "SINGLE_TRAVEL",
                    payload: res.data,
                });
            }
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error.response,
                });
            }
        });
};

export const updateTravelToDb = async (travel) => {
    const RouteId = travel.routeId;
    travel["RouteId"] = RouteId;
    Apis.putEdittravel(RouteId, travel);
};

/**
 * @function editTravel
 * @param {data} Object inlucdes routeId(id) || driverId || travelPurpose || routeTypeId || driverName
 * @param {*} callback
 * @returns
 */
export const editTravel = (data, callback) => (dispatch) => {
    const dataToUpdate = {
        RouteId: data.routeId && data.routeId != " " ? data.routeId : "",
        DriverId: data.driverId && data.driverId != " " ? data.driverId : "",
        Purpose:
            data.travelPurpose && data.travelPurpose != " "
                ? data.travelPurpose
                : "",
        Type:
            data.routeTypeId && data.routeTypeId != " " ? data.routeTypeId : "",
        DriverName:
            data.driverName && data.driverName != " " ? data.driverName : "",
        Attest: data.Attest && data.Attest != " " ? data.Attest : "",
    };


    dispatch(setLoader(true));
    Apis.putEdittravel(dataToUpdate.RouteId, dataToUpdate)
        .then((response) => {
            if (response.Status === "NOK") {
                console.log("error api editTravel res: ", response);
                dispatch({
                    type: "SET_ERRORS",
                    payload: response,
                });
            } else {
                // console.log("api editTravel res: ", response);

                dispatch({
                    type: "EDIT_TRAVEL",
                    payload: response,
                });
                if (callback) dispatch(callback);
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getVehicleTravels = (vehicleId, callback) => (dispatch) => {
    dispatch(setLoader(true));
    if (!vehicleId) vehicleId = null;
    Apis.getVehicleTravels(vehicleId)
        .then((response) => {
            if (response.Status === "OK") {
                dispatch(
                    {
                        type: "GET_VEHICLE_TRAVELS",
                        payload: response,
                    },
                    callback && callback(response)
                );
            } else {
                console.log("error", response);
                dispatch({
                    type: "GET_VEHICLE_TRAVELS_ERROR",
                    payload: response.Message,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getVehicleTravels actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const getOngoingTravels = (callback) => (dispatch) => {
    dispatch(setLoader(true));

    Apis.getOngoingTravels()
        .then((response) => {
            // console.log("getOngoingTravels actions api res: ", response);
            if (response.Status === "OK") {
                dispatch(
                    {
                        type: "GET_ONGOING_TRAVELS",
                        payload: response,
                    },
                    callback && callback(response)
                );
            } else {
                console.log("error", response);
                dispatch({
                    type: "GET_ONGOING_TRAVELS_ERROR",
                    payload: response.Message,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getOngoingTravels actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const getOngoingTravelsTRASDemo = (callback, mock) => (dispatch) => {
    dispatch(setLoader(true));

    if (!mock) {
        Apis.getOngoingTravels("single_route", false)
            .then((response) => {
                // console.log(
                //     "response in getOngoingTravels actions api call",
                //     response
                // );
                dispatch(
                    {
                        type: "GET_ONGOING_TRAVELS_TRAS_DEMO",
                        payload: response,
                    },
                    callback && callback(response)
                );
            })
            .catch((error) => {
                console.log(
                    "error in getOngoingTravels actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    } else {
        const response = TRASDemoMock.getOngoingTravelsTRASDemo;
        dispatch(
            {
                type: "GET_ONGOING_TRAVELS_TRAS_DEMO",
                payload: response,
            },
            callback && callback(response)
        );
    }
};

export const getAddressByCoordinatesPromise = (coordinates, mock) =>
    new Promise((resolve, reject) => {
        if (!mock) {
            //TODO real data
        } else {
            const response = TRASDemoMock.coordinatesToAddressTRASDemoResponse;
            if (response.Status === "OK") {
                resolve(response.Result);
            } else {
                reject(response);
            }
        }
    });

export const getAddressByCoordinates =
    (coordinates, callback, mock) => (dispatch) => {
        dispatch(setLoader(true));

        if (!mock) {
            //TODO real data
        } else {
            const response = TRASDemoMock.coordinatesToAddressTRASDemoResponse;
            callback && callback(response);
        }
    };
