import React, { useEffect, useRef, useState } from 'react';
import { Comment, Avatar, Form, Button, List, Input } from 'antd';
import moment from 'moment';
import * as Apis from '../../utils/api';
import { AlipaySquareFilled } from '@ant-design/icons';

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'notes' : 'note'}`}
    itemLayout="horizontal"
    renderItem={props => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" onClick={onSubmit} type="primary">
        Add Note
      </Button>
    </Form.Item>
  </>
);

class VehicleNotes extends React.Component {
  state = {
    comments: [],
    submitting: false,
    value: '',
  };
  
  handleSubmit = () => {
    if (!this.state.value) {
      return;
    }
    
    const data = {
      vid: this.props.vid,
      subject: "från DRAWER I GUI",
      message: this.state.value
    }
    Apis.postVehicleNote(data).then((res) => {
      if (res.Status == "NOK") {
        console.log("error");
      } else {
        console.log(res);
        this.setState({
          value: '',
          comments: [
            ...this.state.comments,
            {
              author: 'Data',
              content: <p>{this.state.value}</p>,
              datetime: moment().fromNow(),
            },
          ],
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });

  };

  handleChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    const { comments, value } = this.state;

    return (
      <>
        {comments.length > 0 && <CommentList comments={comments} />}
        <Comment
          content={
            <Editor
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              value={value}
            />
          }
        />
      </>
    );
  }
}

export default VehicleNotes;