import {
  ADDED_WIDGETS,
  ALL_WIDGETS,
  ACTIVED_KEY,
  ADDED_WIDGETS1,
  ADDED_WIDGETS2,
  ADDED_WIDGETS3,
  CHANGE_LAYOUT,
  SELECTED_MENU,
  ALL_FUNC_MENU,
  CHANGE_LANG,
  ENG_LANG_DATA,
  SWE_LANG_DATA,
  JAP_LANG_DATA,
  SEARCHED_VEHI_DATA
} from "../actionTypes";

export const addedWidgets = data => {
  console.log("data => ", data);

  return dispatch => {
    dispatch({
      type: ADDED_WIDGETS,
      payload: data
    });
  };
};

export const addedWidgets1 = data => {
  return dispatch => {
    dispatch({
      type: ADDED_WIDGETS1,
      payload: data
    });
  };
};

export const addedWidgets2 = data => {
  return dispatch => {
    dispatch({
      type: ADDED_WIDGETS2,
      payload: data
    });
  };
};
export const addedWidgets3 = data => {
  return dispatch => {
    dispatch({
      type: ADDED_WIDGETS3,
      payload: data
    });
  };
};

export const allWidgets = data => {
  return dispatch => {
    dispatch({
      type: ALL_WIDGETS,
      payload: data
    });
  };
};

export const activedKey = key => {
  return dispatch => {
    dispatch({
      type: ACTIVED_KEY,
      payload: key
    });
  };
};

export const changeLayout = data => {
  return dispatch => {
    dispatch({
      type: CHANGE_LAYOUT,
      payload: data
    });
  };
};

export const selectedMenu = (
  data,
  submenukey,
  submenutitle,
  selectedSubmenu,
  selectedFuncTitle
) => {
  return dispatch => {
    dispatch({
      type: SELECTED_MENU,
      payload: data,
      submenu_key: submenukey,
      submenu_title: submenutitle,
      selectedSubmenu: selectedSubmenu,
      selectedFuncTitle: selectedFuncTitle
    });
  };
};

export const allFunctionMenu = data => {
  return dispatch => {
    dispatch({
      type: ALL_FUNC_MENU,
      payload: data
    });
  };
};

export const changeLang = lang => {
  return dispatch => {
    dispatch({
      type: CHANGE_LANG,
      payload: lang
    });
  };
};

export const EngLangData = data => {
  return dispatch => {
    dispatch({
      type: ENG_LANG_DATA,
      payload: data
    });
  };
};

export const SweLangData = data => {
  return dispatch => {
    dispatch({
      type: SWE_LANG_DATA,
      payload: data
    });
  };
};

export const JapLangData = data => {
  return dispatch => {
    dispatch({
      type: JAP_LANG_DATA,
      payload: data
    });
  };
};

export const SearchedVehiData = data => {
  return dispatch => {
    dispatch({
      type: SEARCHED_VEHI_DATA,
      payload: data
    });
  };
}