import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import {
    Button,
    Form,
    Input,
    Radio,
    DatePicker,
    Divider,
    notification,
    Space,
    Table,
} from "antd";
import {
    BorderBottomOutlined,
    BorderTopOutlined,
    RadiusBottomleftOutlined,
    RadiusBottomrightOutlined,
    RadiusUpleftOutlined,
    RadiusUprightOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { renderScrollShim } from "@fullcalendar/react";

const JRTable = (props) => {
    const history = useHistory();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    //const [newColums, setCols] = useState([]);
    const searchInput = useRef(null);
    //const newCol = useState();
    const fixCols = (cols) => {
        var newCol = [];
        console.log("PRO JR ",props.showHashLink);
        const showHash = (props.showHashLink === false) ? false:true;
        const showText = (props.showLinkText) ? props.showLinkText : "Visa";
        //console.log("showHash ",!props.showHash)
        showHash
            ? newCol.push({
                  title: "#",
                  render: (_, rec) => (
                      <Space>
                          <a
                              onClick={(e) =>
                                  history.push(props.link + rec[props.uniqKey])
                              }
                          >
                              {showText}
                          </a>
                      </Space>
                  ),
              })
            : newCol.push();

        cols.map((col) => {
            const so = col.dataIndex;
            const add = col.linkrender
                ? {
                      title: col.title,
                      dataIndex: col.dataIndex,
                      sorter: (a, b) => a[so].localeCompare(b[so]),
                      ...getColumnSearchProps(col.dataIndex),
                  }
                : {
                      title: col.title,
                      sorter: (a, b) => {
                          a[so].localeCompare(b[so]);
                      },
                      ...getColumnSearchProps(col.dataIndex),
                      dataIndex: col.dataIndex,
                  };
            newCol.push(add);
        });
        return newCol;
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => (searchedColumn === dataIndex ? text : text),
    });
    return (
        <>
            {props.ExportBTN ? (
                <Space style={{ marginBottom: 16 }}>
                    <Button>EXPORT</Button>
                </Space>
            ) : (
                <></>
            )}
            <Table
                columns={fixCols(props.columns)}
                rowKey={(record) => record[props.uniqKey]}
                dataSource={props.data}
                onRow={props.row}
            />
        </>
    );
};

export default JRTable;
