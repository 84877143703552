import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
} from "antd";
import { SettingOutlined } from "@ant-design/icons";
import instance from "../../instance";
import getStorageValue from "../../utils/getStorageValue";

class VehicleSystem extends React.Component {

  render() {

    return (
      <>
        <div className={"card_header"}>Connect Unit To Vehicle</div>
          <Form layout="inline">
            <Form.Item label="Unit">
              <Select
              labelInValue
              defaultValue={{ value: 'demo' }}
              >
                <Select.Option value="demo">Demo</Select.Option>
                <Select.Option value="demo1">Demo1</Select.Option>
                <Select.Option value="demo2">Demo2</Select.Option>
                <Select.Option value="demo3">Demo3</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button>Button</Button>
            </Form.Item>
          </Form>
      </>
    );
  }
}

export default VehicleSystem;