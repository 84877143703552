import { setLoader } from "../auth";
import { callFail, callSuccess } from "../../utils/callback";
import * as Apis from "../../utils/api";
import { IDriver } from "../../global-types";
import { IDriverCreateResponse, IDriverUpdateResponse } from "../actions";

import {TRASDemoMockData} from "../../utils/TRASDemoDashboardMockData"

export const getDrivers = (callback?: any, mock=false) => (dispatch: any) => {
    // console.log("getDrivers actions api entry: ");

    dispatch(setLoader(true));

    Apis.getDrivers()
        .then((res) => {
            // console.log("getDrivers actions api res: ", res);
            if (res.Status === "OK") {
                dispatch({
                    type: "GET_DRIVERS",
                    payload: res,
                });
                typeof callback == "function" && callback(res);
            } else {
                console.log("error", res);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res,
                });
                callFail(callback, res);
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getDrivers actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const updateDriver =
    (driverData: IDriver, callbackSuccess?: any, callbackFail?: any) =>
    (dispatch: any) => {
        dispatch(setLoader(true));

        Apis.updateDriver(driverData)
            .then((res: IDriverUpdateResponse) => {
                console.log("updateDriver actions api res: ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "UPDATE_DRIVER",
                            payload: res,
                        },
                        callbackSuccess(res)
                    );
                    // typeof callback == "function" && callback(res);
                } else {
                    console.log("error", res);
                    dispatch({
                        type: "SET_ERRORS",
                        payload: res,
                    });
                    callbackFail(res);
                }
                dispatch(setLoader(false));
            })
            .catch((error: any) => {
                console.log("error in updateDriver actions api call", error);
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

export const createDriver =
    (driverData: IDriver, callbackSuccess?: any, callbackFail?: any) =>
    (dispatch: any) => {
        dispatch(setLoader(true));

        Apis.createDriver(driverData)
            .then((res: IDriverCreateResponse) => {
                console.log("createDriver actions api res: ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "CREATE_DRIVER",
                            payload: res,
                        },
                        callbackSuccess(res)
                    );
                    // typeof callback == "function" && callback(res);
                } else {
                    console.log("error", res);
                    dispatch({
                        type: "SET_ERRORS",
                        payload: res,
                    });
                    callbackFail(res);
                }
                dispatch(setLoader(false));
            })
            .catch((error: any) => {
                console.log("error in createDriver actions api call", error);
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

export const getDriversTRASDemo =
    (callback: any, mock?: boolean) => (dispatch: any) => {
        // console.log("getDriversTRASDemo actions api entry: ");

        dispatch(setLoader(true));

        if (!mock) {
            Apis.getDriversTRASDemo()
                .then((res) => {
                    console.log("getDriversTRASDemo actions api res: ", res);
                    if (res.Status === "OK") {
                        dispatch({
                            type: "GET_DRIVERS_TRAS",
                            payload: res.Results,
                        });
                        typeof callback == "function" && callback(res);
                    } else {
                        console.log("error", res);
                        dispatch({
                            type: "SET_ERRORS",
                            payload: res,
                        });
                        callFail(callback, res);
                    }
                    dispatch(setLoader(false));
                })
                .catch((error) => {
                    console.log("error in getDrivers actions api call", error);
                    if (error) {
                        dispatch({
                            type: "SET_ERRORS",
                            payload: error,
                        });
                    }
                    dispatch(setLoader(false));
                });
        } else {


            dispatch({
                type: "GET_DRIVERS_TRAS_DEMO",
                payload: TRASDemoMockData,
            });
        }
    };
