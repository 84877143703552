import type { Libraries } from "@react-google-maps/api/dist";
export type {
    MarkerProps as IMarkerProps,
    GoogleMap as IGoogleMapProps,
    Marker as CMarker,
} from "@react-google-maps/api/dist";

export const GoogleMapsLibraries: Libraries = ["geometry", "drawing", "places"];

//TODO extend GoogleMapProps
export interface IMapProps {
    currentVehiclePosition?(): any;
    mapContainerHeight?: string;
    licensePlate?: string;
    formType?: "create" | "update" | "delete" | undefined;
    markers?: array<IMarkerProps>;
    showRealTimeMarkers?: boolean;
    mapProps?: IGoogleMapProps;
    onClick?: any;
}

export interface IRealTimePositionMap {
    vehicleIdFromParams: string;
}
