import React from "react";
import { connect } from "react-redux";
import { List, Modal, Card, Form, Input, Row, Col, Checkbox, Select, Radio, Space, Button, Table, message } from "antd";
import "../../assets/css/functionPage.css";
import "../../assets/css/createownreport.css"
import Header from "../../components/breadcrumbheader/Header";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlusOutlined, EditOutlined, DeleteOutlined, TagFilled,CloseOutlined,MenuOutlined } from "@ant-design/icons";
import { selectTags } from "../../components/common/commonArray";
import { CSVLink } from "react-csv";

const { Option } = Select;
const { confirm } = Modal;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;


const getListStyle = isDraggingOver => ({
  border: '1px solid #ccc',
  padding: grid,
  // width: 250,
  height: 250,
  overflow: 'auto'
});

class Createownreport extends React.Component {
  state = {
    selectedWidgetComp: [],
    selectedFunctions: [],
    selectedFuncTitle: "",
    items: selectTags,
    selected: [],
    editMode: false,
    selectedEditReport: {},
    savedRepotsCopy: [],
    savedRepots: [{
      "id": "1",
      "name": "Admin",
      "company": "",
      "reportVisible": "Everyone",
      "reportTag": [
        {
          "id": "1",
          "content": "License plate"
        }
      ]
    }, {
      "id": "2",
      "name": "test",
      "company": "",
      "reportVisible": "Everyone",
      "reportTag": [
        {
          "id": "1",
          "content": "License plate"
        },
        {
          "id": "5",
          "content": "km"
        },
        {
          "id": "13",
          "content": "Last maintenance"
        }
      ]
    }],
    csvData: []
  };

  componentDidMount() {
    this.setState({ savedRepotsCopy: this.state.savedRepots })
  }

  formRef = React.createRef();

  id2List = {
    droppable: 'items',
    droppable2: 'selected'
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    this.addRemoveTagsFun(result)
  };

  addRemoveTagsFun = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'droppable2') {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2
      });
    }
  }

  formSubmitClick = (values) => {
    if (this.state.editMode) {
      const index = this.state.savedRepotsCopy.findIndex(data => data.name == this.state.selectedEditReport.name)

      values.id = this.state.selectedEditReport.id
      this.state.savedRepotsCopy[index] = values

      this.setState({
        savedRepotsCopy: [...this.state.savedRepotsCopy],
        selectedEditReport: {},
        editMode: false,
        selected: []
      })
      message.success('Autoexport is updated successfully');
      this.formRef.current.resetFields();
    } else {
      values.reportTag = this.state.selected
      this.formRef.current.resetFields();
    }
  }

  CSVdata = [
    { Licenseplate: "ABB123", Drivers: "Linus Test", VehicleManager: "Joakim Nodfelt" },
    { Licenseplate: "AU2TRACK", Drivers: "", VehicleManager: "Rick" },
    { Licenseplate: "DNR921", Drivers: "", VehicleManager: "Jimmy Thornlof" },
    { Licenseplate: "KMB959", Drivers: "Malin Flodin", VehicleManager: "Linus Test" },
    { Licenseplate: "YRX950", Drivers: "Linus Test", VehicleManager: "Rickard F" }
  ];

  deletehandler = (record) => {
    const thiss = this
    confirm({
      title: 'Do you want to delete this item?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const updateArr = thiss.state.savedRepotsCopy.filter(data => data.id !== record.id);
        thiss.setState({ savedRepotsCopy: [...updateArr], editMode: false })
        thiss.formRef.current.resetFields();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  doubleclickHandler = (result) => {
    this.addRemoveTagsFun(result)
  }

  routes = [
    {
      path: '',
      breadcrumbName: 'Report templates',
    },
    {
      path: '',
      breadcrumbName: 'Create your own reports',
    },
  ];

  editReport = (report) => {
    this.setState({ editMode: true, selectedEditReport: report, selected: report.reportTag })
    this.formRef.current.setFieldsValue({
      name: report.name,
      company: report.company,
      reportVisible: report.reportVisible,
      reportTag: report.reportTag
    })
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',

      },
      {
        title: 'company',
        dataIndex: 'company',
      },
      {
        title: 'Report Visible',
        dataIndex: 'reportVisible',
      },
      {
        title: 'reportTag',
        dataIndex: 'Selected Tag',
      },
      {
        title: 'Action',
        key: 'action',
        render: (record) => (
          <Space size="middle">
            <a><EditOutlined onClick={() => this.editReport(record)} /></a>
            <a><DeleteOutlined onClick={() => this.deletehandler(record)} /></a>
          </Space>
        ),
      },
    ];
    return (
      <>
        <Header routes={this.routes} filter={false} >
        </Header>
        <Card title="Create your own reports">
          <Form
            name="createReportForm"
            ref={this.formRef}
            id={"createReportForm"}
            onFinish={this.formSubmitClick}
            layout="vertical"
          >
            <Row gutter={[16, 20]} className="search-form-row">
              <Col className="gutter-row" span={6} offset={4}>
                <Form.Item name="name" label="Name">
                  <Input />
                </Form.Item>
                <Form.Item name="company" label="Select companies">
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select companies"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="Bilpool">Bilpool</Option>
                    <Option value="Filial Nord test">Filial Nord test</Option>
                    <Option value="Filial Syd">Filial Syd</Option>
                    <Option value="Fiskeback">Fiskeback</Option>
                    <Option value="Granna">Granna</Option>
                    <Option value="JIRICOM AB">JIRICOM AB</Option>
                    <Option value="JIRICOM AF Kund Mall">
                      JIRICOM AF Kund Mall
                    </Option>
                    <Option value="JIRICOM AF Mall">JIRICOM AF Mall</Option>
                    <Option value="Kontor Nord">Kontor Nord</Option>
                    <Option value="L Fiskeback">L Fiskeback</Option>
                    <Option value="Region Syd">Region Syd</Option>
                    <Option value="Syd Privet">Syd Privet</Option>
                    <Option value="Syd tjanst">Syd tjanst</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                {!this.state.editMode ? <Form.Item name="saveReportCheck" valuePropName="checked">
                  <Checkbox>Save report template</Checkbox>
                </Form.Item> : ""}
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item name="reportVisible" label="Report visible for">
                  <Radio.Group>
                    <Space direction="vertical" >
                      <Radio value="Everyone">Everyone</Radio>
                      <Radio value="Company">Company</Radio>
                      <Radio value="Private">Private</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <DragDropContext onDragEnd={this.onDragEnd}>
                <Col span={6} offset={4}>
                  <Form.Item name="selectTag" label="Select fields">
                    <Droppable droppableId="droppable" >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}>
                          {this.state.items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}>
                              {(provided, snapshot) => (
                                <div
                                  onDoubleClick={() => this.doubleclickHandler({ source: { index: index, droppableId: 'droppable' }, destination: { droppableId: 'droppable2', index: index } })}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="drag-item">
                                  <MenuOutlined className="menuicon"/>
                                  <>{item.content}</> <PlusOutlined  onClick={() => this.doubleclickHandler({ source: { index: index, droppableId: 'droppable' }, destination: { droppableId: 'droppable2', index: index } })}/>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="reportTag" label="Report values">
                    <Droppable droppableId="droppable2" >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}>
                          {this.state.selected.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}>
                              {(provided, snapshot) => (
                                <div
                                  onDoubleClick={() => this.doubleclickHandler({ source: { index: index, droppableId: 'droppable2' }, destination: { droppableId: 'droppable', index: index } })}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="drag-item">
                                  <MenuOutlined className="menuicon"/>
                                  <> {item.content}</> <CloseOutlined onClick={() => this.doubleclickHandler({ source: { index: index, droppableId: 'droppable2' }, destination: { droppableId: 'droppable', index: index } })}/>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Form.Item>
                </Col>
                {/* onClick={() => this.deletehandler()} */}
                {this.state.editMode ? <Col span={16} >
                  <Button onClick={() => this.deletehandler(this.state.selectedEditReport)}>Delete report template</Button>
                  <Button>New report template</Button>
                  <Button key="submit" htmlType="submit">Update report template</Button>
                </Col> : ""}

                <Col className="gutter-row" span={3} offset={9}>
                  <Button key="submit" htmlType="submit" >
                    <CSVLink className="btn btn-primary exportBtn" data={this.CSVdata} filename={"Report.csv"} target="_blank">
                      Create CSV report
                    </CSVLink>
                  </Button>
                  {/* form={"createReportForm"} type="primary" key="submit" htmlType="submit" onClick={() => this.formSubmitClick()} */}
                </Col>
              </DragDropContext>
            </Row>
          </Form>
          <h4>Saved report templates</h4>
          <Table columns={columns} dataSource={this.state.savedRepotsCopy} onChange={this.handleChange} />
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle
    // allWidgetsdata: state.widgets.allWidgets
  };
};

export default connect(mapStateToProps)(Createownreport);
