import React from "react";
import { Tooltip, Space } from 'antd';

import '@fortawesome/fontawesome-svg-core/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const JRStatus = (props) => {
    const ico = (icon) => {
        if (icon === 'wifi') {
            return solid('wifi');
        } else if (icon === 'bolt') {
            return solid('bolt')
        } else if (icon === 'battery-full') {
            return solid('battery-full')
        } else if (icon === 'location-dot') {
            return solid('location-dot')
        } else if (icon === 'triangle-exclamation') {
            return solid('triangle-exclamation')
        } else {
            return solid('question');
        }
    }

    return (
        <>
            <Space align="center">
                <Tooltip placement="top" title={props.msg}>
                    <FontAwesomeIcon icon={ico(props.icon)} style={{ color: props.color }} />
                </Tooltip>
            </Space>
        </>
    )
}

export default JRStatus