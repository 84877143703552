import { setLoader } from "../auth";
import instance from "../../instance";
import { callFail, callSuccess } from "../../utils/callback";
import getStorageValue from "../../utils/getStorageValue";
import * as Apis from "../../utils/api";
import * as Common from "../../components/common/function";
import * as TRASDemoMock from "../../utils/TRASDemoDashboardMockData";
import _, { LoDashStatic } from "lodash";
import moment from "moment";

/**
 *
 * @param dateFrom todaysmidnight
 * @param callback
 * @param mock
 * @returns
 */
export const getOngoingAlcoholMetersTRASDemo =
    (dateFrom?: any, callback?: any, mock?: boolean) => (dispatch: any) => {
        if (callback === _) {
            callback = null;
        }
        dispatch(setLoader(true));

        if (!mock) {
            if (!dateFrom) {
                dateFrom = Common.getTodaysMidnightDate().DateTimeCompactString;
            }

            const apiData = {
                dateFrom: dateFrom,
            };
            Apis.getMeasurements(apiData)
                .then((response) => {
                    // console.log("getMeasurements action response ", response);
                    if (response.Status === "OK") {
                        dispatch(
                            {
                                type: "GET_ONGOING_ALCOHOL_METERS_TRAS_DEMO",
                                payload: response,
                            },
                            callback && callback(response)
                        );
                    } else {
                        dispatch({
                            type: "SET_ERRORS",
                            payload: response,
                        });
                    }
                })
                .catch((error) => {
                    console.log(
                        "error in getMeasurements actions api call",
                        error
                    );
                    if (error) {
                        dispatch({
                            type: "SET_ERRORS",
                            payload: error,
                        });
                    }
                    dispatch(setLoader(false));
                });
        } else {
            const response = TRASDemoMock.getOngoingAlcoholMetersTRASDemo;
            dispatch(
                {
                    type: "GET_ONGOING_ALCOHOL_METERS_TRAS_DEMO",
                    payload: response,
                },
                callback && callback(response)
            );
        }
    };

export const getMeasurementsByDeviceId =
    (trackerId: any, dateFrom?: any, callback?: any) => (dispatch: any) => {
        if (callback === _) {
            callback = null;
        }

        if (!dateFrom)
            dateFrom = Common.getTodaysMidnightDate().DateTimeCompactString;
        return Apis.getMeasurementsByDeviceId(trackerId, dateFrom)
            .then((response) => {
                dispatch(
                    {
                        type: "GET_MEASUREMENTS_BY_DEVICE_ID",
                        payload: response,
                    },
                    callback && callback(response)
                );
                return new Promise<any>((resolve) => {
                    resolve(response.Result);
                });
            })
            .catch((error) => {
                console.log(
                    "error in getMeasurementsByDeviceId actions api call",
                    error
                );
                dispatch(
                    {
                        type: "GET_MEASUREMENTS_BY_DEVICE_ID_ERROR",
                        payload: error,
                    },
                    callback && callback(error)
                );
                return new Promise((reject) => {
                    reject(error);
                });
            });
    };

export const getMeasurementsByUserIdCompactDate =
    (
        tableName: string,
        userId: any,
        dateFrom?: any,
        dateTo?: any,
        callback?: any
    ) =>
    (dispatch: any) => {
        if (callback === _) {
            callback = null;
        }

        dispatch({
            type: "SET_LOADER_HISTORICAL_MEASUREMENTS",
            payload: true,
        });

        // if (!userId) throw new Error("No userId was provided");
        if (userId === "0" || userId === 0)
            throw new Error("A 0 as userId is insufficient");

        if (!dateFrom)
            dateFrom = Common.getTodaysMidnightDate().DateTimeCompactString;

        if (!dateTo) {
            //FYI add 1 day to prevent problems with timezones
            dateTo = Common.getCurrentDateTimeString()
                .DateTimeObject.add(1, "day")
                .format("YYYYMMDDHHmmss");
        }

        const apiData = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            userId: userId,
        };

        // console.log(
        //     "getMeasurementsByUserIdCompactDate params ",
        //     userId,
        //     dateFrom,
        //     callback,
        //     tableName,
        //     "apidata: ", apiData
        // );

        return Apis.getMeasurementsAndNotificationsByUserIdCompactDate(apiData)
            .then((response: any) => {
                // console.log(
                //     "getMeasurementsByUserIdCompactDate response ",
                //     response
                // );
                dispatch(
                    {
                        type: "GET_MEASUREMENTS_BY_USER_ID",
                        payload: { ...response, tableName: tableName },
                    },
                    callback && callback(response)
                );
                dispatch({
                    type: "SET_LOADER_HISTORICAL_MEASUREMENTS",
                    payload: false,
                });
                return new Promise<any>((resolve) => {
                    resolve(response.Result);
                });
            })
            .catch((error: any) => {
                dispatch(
                    {
                        type: "GET_MEASUREMENTS_BY_USER_ID_ERROR",
                        payload: { ...error, tableName: tableName },
                    },
                    callback && callback(error)
                );
                dispatch({
                    type: "SET_LOADER_HISTORICAL_MEASUREMENTS",
                    payload: false,
                });
                return new Promise((reject) => {
                    reject(error);
                });
            });
    };

export const getMeasurementsByDeviceIdCompactDate =
    (
        tableName: string,
        deviceId: any,
        dateFrom?: any,
        dateTo?: any,
        callback?: any
    ) =>
    (dispatch: any) => {
        if (callback === _) {
            callback = null;
        }

        dispatch({
            type: "SET_LOADER_HISTORICAL_MEASUREMENTS",
            payload: true,
        });

        if (deviceId === "0" || deviceId === 0)
            throw new Error("A 0 as deviceId is insufficient");

        if (!dateFrom)
            dateFrom = Common.getTodaysMidnightDate().DateTimeCompactString;

        if (!dateTo) {
            //FYI add 1 day to prevent problems with timezones
            dateTo = Common.getCurrentDateTimeString()
                .DateTimeObject.add(1, "day")
                .format("YYYYMMDDHHmmss");
        }

        const apiData = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            deviceId: deviceId,
        };

        // console.log(
        //     "getMeasurementsByUserIdCompactDate params ",
        //     userId,
        //     dateFrom,
        //     callback,
        //     tableName,
        //     "apidata: ", apiData
        // );

        return Apis.getMeasurementsAndNotificationsByDeviceIdCompactDate(apiData)
            .then((response: any) => {
                // console.log(
                //     "getMeasurementsAndNotificationsByDeviceIdCompactDate response ",
                //     response
                // );
                dispatch(
                    {
                        type: "GET_MEASUREMENTS_BY_DEVICE_ID",
                        payload: { ...response, tableName: tableName },
                    },
                    callback && callback(response)
                );
                dispatch({
                    type: "SET_LOADER_HISTORICAL_MEASUREMENTS",
                    payload: false,
                });
                return new Promise<any>((resolve) => {
                    resolve(response.Result);
                });
            })
            .catch((error: any) => {
                dispatch(
                    {
                        type: "GET_MEASUREMENTS_BY_DEVICE_ID",
                        payload: { ...error, tableName: tableName },
                    },
                    callback && callback(error)
                );
                dispatch({
                    type: "SET_LOADER_HISTORICAL_MEASUREMENTS",
                    payload: false,
                });
                return new Promise((reject) => {
                    reject(error);
                });
            });
    };

/**
 *
 * @param dateFrom DateTimeCompactString
 * @param dateTo DateTimeCompactString
 * @param reload
 * @returns
 */
export const getHistoricalMeasurements =
    (tableNames: string[], dateFrom?: any, dateTo?: any, reload?: () => {}) =>
    (dispatch: any) => {
        dispatch({
            type: "SET_LOADER_HISTORICAL_MEASUREMENTS_ISOLATED",
            payload: reload && reload(),
        });

       

        if (!tableNames) {
            tableNames = ["historicalMeasurements"];
        }

        if (!dateFrom) {
            dateFrom = Common.getPreviousWeekDate();
            const fromDateTimeCompactString =
                moment(dateFrom).format("YYYYMMDDhhmmss");
            dateFrom = fromDateTimeCompactString;
            // console.log("getHistoricalMeasurements dateFrom param: ", dateFrom, "formatted: ", fromDateTimeCompactString)
        }

        if (!dateTo) {
            //FYI add 1 day to prevent problems with timezones
            dateTo = Common.getCurrentDateTimeString()
                .DateTimeObject.add(1, "day")
                .format("YYYYMMDDHHmmss");
        }
        // console.log(
        //     "getHistoricalMeasurements params ",
        //     dateFrom,
        //     callback,
        //     tableName
        // );

        const apiData = {
            dateFrom: dateFrom,
            dateTo: dateTo,
        };

        return Apis.getMeasurements(apiData)
            .then((response: any) => {
                // console.log(
                //     "getHistoricalMeasurements api response ",
                //     response, "tableNames: ", tableNames
                // );
                dispatch(
                    {
                        type: "GET_HISTORICAL_MEASUREMENTS",
                        payload: { ...response, tableNames: tableNames },
                    },
                    // callback && callback(response)
                );
                dispatch({
                    type: "SET_LOADER_HISTORICAL_MEASUREMENTS_ISOLATED",
                    payload: false,
                });
                return new Promise<any>((resolve) => {
                    resolve(response.Result);
                });
            })
            .catch((error: any) => {
                dispatch(
                    {
                        type: "GET_HISTORICAL_MEASUREMENTS_ERROR",
                        payload: error,
                    },
                    // callback && callback(error)
                );
                dispatch({
                    type: "SET_LOADER_HISTORICAL_MEASUREMENTS_ISOLATED",
                    payload: false,
                });
                return new Promise((reject) => {
                    reject(error);
                });
            });
    };
