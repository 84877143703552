const initialState = {
    getUsers: [],
    updatedCreatedUser: {},
};

//TODO return also respNumbers
const userReducer = (
    state = initialState,
    { type, payload }: any,
    callback: any
) => {
    switch (type) {
        case "GET_USERS":
            console.log("GET_USERS REDUCER ", payload.Results);
            return {
                ...state,
                getUsers: payload.Results,
                callback,
            };
        case "UPDATE_CREATE_USER":
            console.log("UPDATE_CREATE_USER REDUCER ", payload);
            return {
                ...state,
                updatedCreatedUser: payload,
                callback,
            };

        default:
            return state;
    }
};

export default userReducer;
