import { InfoCircleOutlined, PropertySafetyFilled } from "@ant-design/icons";
import { Card, Col, Row, Table, Tooltip } from "antd";
import { TinyArea } from "@ant-design/charts";
import React from "react";
import { useHistory } from "react-router-dom";

import NumberInfo from "./NumberInfo";
import Trend from "./Trend";
import styles from "./style.less";

import type { DataItem } from "./data.d";
import type { ColumnsType, TableProps } from "antd/es/table";

const TopSearch = ({
    title,
    loading,
    graphData,
    searchData,
    extra,
    graphData2,
    columns,
    pageSize,
    subTitle,
    subTitleTooltip,
    subTitle2,
    subTitleTooltip2,
    expandable,
    rowkey,
    rowClassNameCallback,
    rowClickCallback,
}: {
    title: string | React.ReactNode;
    loading: boolean;
    graphData?: DataItem[];
    extra?: React.ReactNode;
    searchData: DataItem[];
    graphData2?: DataItem[];
    columns: DataItem[];
    pageSize?: number;
    subTitle?: string;
    subTitleTooltip?: string;
    subTitle2?: string;
    subTitleTooltip2?: string;
    expandable?: any,
    rowkey?: React.Key,
    rowClassNameCallback?: (any: any) => any;
    rowClickCallback?: (record: any) => void;
}) => {
    const history = useHistory();

    const tinyAreaConfig = {
        xField: "x",
        height: 45,
        forceFit: true,
        yField: "y",
        smooth: true,
        data: graphData,
    };
    const tinyAreaConfig2 = {
        xField: "x",
        height: 45,
        forceFit: true,
        yField: "y",
        smooth: true,
        data: graphData2,
    };
    const onChange: TableProps<any>["onChange"] = (
        pagination,
        filters,
        sorter,
        extra
    ) => {
    };

    return (
        <Card
            loading={loading}
            bordered={false}
            title={title ? title : "Current Alarms"}
            extra={extra}
            style={{
                height: "100%",
            }}
        >
            <Row gutter={68}>
                {graphData && (
                    <Col sm={12} xs={24} style={{ marginBottom: 24 }}>
                        <NumberInfo
                            subTitle={
                                <span>
                                    {subTitle}
                                    <Tooltip title={subTitleTooltip}>
                                        <InfoCircleOutlined
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Tooltip>
                                </span>
                            }
                            gap={8}
                            total={graphData?.reduce(
                                (accumulated, current) => accumulated + current,
                                0
                            )}
                        />
                        <TinyArea {...(tinyAreaConfig as any)} />
                    </Col>
                )}

                {graphData2 && (
                    <Col sm={12} xs={24} style={{ marginBottom: 24 }}>
                        <NumberInfo
                            subTitle={
                                <span>
                                    {subTitle2}
                                    <Tooltip title={subTitleTooltip2}>
                                        <InfoCircleOutlined
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Tooltip>
                                </span>
                            }
                            total={graphData2?.reduce(
                                (accumulated, current) => accumulated + current,
                                0
                            )}
                            gap={8}
                        />
                        <TinyArea {...(tinyAreaConfig2 as any)} />
                    </Col>
                )}
            </Row>
            <Table<any>
                rowKey={(record) => record.Id}
                size="small"
                columns={columns}
                dataSource={searchData}
                pagination={{
                    style: { marginBottom: 0 },
                    pageSize: pageSize ? pageSize : 5,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            //history.push("/");
                            rowClickCallback && rowClickCallback(record)
                        },
                        style: {
                            background:
                                record.StatusColor === "BlinkRed" && "#ffa39e",
                        },
                    };
                }}
                onChange={onChange}
                expandable={expandable}
                key={rowkey}
            />
        </Card>
    );
};

export default TopSearch;
