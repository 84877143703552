import {
    SEARCHED_VEHI_DATA
  } from "../actionTypes";
import {vehicleStatusData} from "../components/common/commonArray" 
  
  let initialState = {
    serachedvehiData: vehicleStatusData,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case SEARCHED_VEHI_DATA:
        console.log("action => ", action);
        return {
          ...state,
          serachedvehiData : action.payload
        };
      default:
        return state;
    }
  };
  




