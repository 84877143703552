import {setCheckLoginLoader, setLoader} from "../auth";
import instance from "../../instance";
import {callSuccess, callFail} from "../../utils/callback"

export const adminLogin = (data, callback) => (dispatch) => {
    dispatch(setLoader(true));
    const url = 'login';
    const values = {
        Email: data.email,
        Password: data.password,
    };
    instance.defaults.headers.common['Access-Token'] = ''
    instance
        .post(url, values, {crossdomain: true})
        .then((res) => {
            if (res.data.Status === 'NOK') {
                dispatch({
                    type: "SET_ERRORS",
                    payload: res.data.Message,
                });
                callFail(callback, res.data);
            } else {
                /*localStorage.removeItem("Access-token");
                sessionStorage.removeItem("Access-token")*/
                localStorage.setItem("Access-token", res.data.User.Token);
                sessionStorage.setItem("Access-token", res.data.User.Token)

                dispatch({
                    type: "LOGIN_USER",
                    payload: res.data.User,
                });
                dispatch({
                    type: "LOGIN_DETAILS",
                    payload: res.data.User.Token,
                });

                callSuccess(callback, res.data);
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error.response,
                });
            }
            dispatch(setLoader(false));
        });
};

export const checkLogin = (token, callback) => (dispatch) => {
    dispatch(setCheckLoginLoader(true));
    instance.defaults.headers.common['Access-Token'] = token;
    const url = 'login/check';
    instance
        .get(url)
        .then((res) => {
            if (res.data.Status === 'NOK') {
                dispatch({
                    type: "SET_ERRORS",
                    payload: res.data.Message,
                });
                dispatch({
                    type: "LOGIN_DETAILS",
                    payload: "",
                });
                callFail(callback, res.data);
            } else {
              /*  localStorage.removeItem("Access-token");
                sessionStorage.removeItem("Access-token")*/
                localStorage.setItem("Access-token", res.data.User.Token);
                sessionStorage.setItem("Access-token", res.data.User.Token)

                dispatch({
                    type: "LOGIN_USER",
                    payload: res.data.User,
                });
                dispatch({
                    type: "LOGIN_DETAILS",
                    payload: res.data.User.Token,
                });

                typeof callback == "function" && callback(res.data);
            }
            dispatch(setCheckLoginLoader(false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error.response,
                });
            }
            dispatch(setCheckLoginLoader(false));
        });
}
