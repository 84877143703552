import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Drawer, Button, Affix, Form, Input, DatePicker, Select, Space } from "antd";
import "../../../assets/css/functionPage.css";
import { useHistory } from "react-router-dom";
import JRTable from "../../../components/table/JRTable";
import Header from "../../../components/breadcrumbheader/Header";
import * as Apis from "../../../utils/api";
import moment from "moment";
import {
  SearchOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import { CreateUserlogExport } from "../../../components/common/function";
const UserLog = (props) => {
  const { Option } = Select;
  const [formLayout, setFormLayout] = useState('horizontal');
  const [users, setUsers] = useState([]);
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
      }
      : null;
  const { RangePicker } = DatePicker;
  const routes = [
    {
      path: '',
      breadcrumbName: 'Log',
    },
    {
      path: '',
      breadcrumbName: 'Users Log',
    },
  ];
  const toTime = moment();
  const fromTime = moment(toTime).subtract(1, 'M');

  const [Userlog, setUserLog] = useState([]);
  const columns = [
    {
      title: 'DateTime',
      dataIndex: 'date',
      width: '20%',
      //render: (_, rec) => (<Space>{moment(rec.DateTime).format('MMMM Do YYYY, h:mm:ss')}</Space>)
    },
    {
      title: 'UserName',
      dataIndex: 'user',
      width: '20%',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
    },
    {
      title: 'IPAddress',
      dataIndex: 'ip',
    }
  ];
  const getUserLog = (data) => {
    Apis.userlog(data)
      .then((res) => {
        if (res.Status === "NOK") {
          console.log("error");
        } else {
          console.log("setting userlog with this data: ", res);
          setUserLog(res.rows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    console.log("LOADED");
    const data = {
      "fromTime": fromTime,
      "toTime": toTime,
      "text": '',
      "driver": '',
    }
    getUserLog(data);
    Apis.getUsers()
      .then((res) => {
        if (res.Status === "NOK") {
          console.log("error");
        } else {
          console.log("users", res);
          setUsers(res.Results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [open, setOpen] = useState(false);
  const createExport = () => {
    CreateUserlogExport(Userlog)
  }
  const showDrawer = () => {
    setOpen(!open);
  };
  const onFinish = (values) => {
    console.log('onfinish values in userlog page: ', values);
    const data = {
      "fromTime": values.rangeDate[0],
      "toTime": values.rangeDate[1],
      "text": values.text,
      "driver": values.driver
    }

    //TODO filter locally
    getUserLog(data);
    showDrawer()
  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  
  return (
    <div className="functionPagediv">
      <Affix
        offsetTop={150}
        onChange={(affixed) => console.log(affixed)}
        style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
      >
        <div>
          <p>
            <Button
              size="large"
              type="primary"
              onClick={createExport}
              icon={<DownloadOutlined />}
            />
          </p>
          <p>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                console.log("clicked open draweer")
                showDrawer(true);
                console.log(open);

              }}
              icon={<SearchOutlined />}
            />
          </p>

        </div>
      </Affix>
      <Row justify="space-around" align="middle">
        <Col span={23}>

          <Header routes={routes} filter={false} />
          <JRTable columns={columns} showHashLink={false} data={Userlog} uniqKey={"LogInID"} />

        </Col>
      </Row>
      <Drawer title="Search" size="large" onClose={showDrawer} visible={open}>
        <Form
          {...formItemLayout}
          initialValues={{
            layout: formLayout,
            rangeDate: [moment(fromTime), moment(toTime)]
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="Searchtext" name="text">
            <Input />
          </Form.Item>
          <Form.Item label="Standardförare" name="driver">
            <Select
              placeholder="Select a Driver"
              allowClear
            >
              {users.map(user => <Option value={user.UserID}>{user.Name}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="Timeperiod" name="rangeDate">
            <RangePicker showTime />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

const mapStateToProps = state => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle
    // allWidgetsdata: state.widgets.allWidgets
  };
};

export default connect(mapStateToProps)(UserLog);
