import React from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Select } from 'antd';
import "../../assets/css/functionPage.css";

const Receiverofalarm = (props) => {
  const { Option } = Select;
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const formRef = React.createRef();
  const onFinish = (values) => {
    console.log(values);
  };
  return (
    <div className="functionPagediv">
      <h2>Mottagare av larm</h2>
      <Form {...layout} ref={formRef} name="control-ref" onFinish={onFinish}>
        <Form.Item
          name="alarmtype"
          label="Alarmtype"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="recivers"
          label="Recivers"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
          allowClear
          mode="multiple"
            placeholder="Select receivers to this larm"
          >
            <Option value="Mail1">Mail1</Option>
            <Option value="Mail2">Mail2</Option>
            <Option value="Mail3">Mail3</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
}

const mapStateToProps = state => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle
    // allWidgetsdata: state.widgets.allWidgets
  };
};

export default connect(mapStateToProps)(Receiverofalarm);
