import { setLoader } from "../auth";
import instance from "../../instance";
import { callFail, callSuccess } from "../../utils/callback";
import getStorageValue from "../../utils/getStorageValue";
import * as Apis from "../../utils/api";
import { callback } from "chart.js/helpers";
import { IOrganisationCascaderOption } from "../../global-types";

export const getAccesses = () => (dispatch: any) => {
    dispatch(setLoader(true));
    Apis.getAccesses()
        .then((res) => {
            console.log("getAccesses res ", res);
            if (res.Status === "OK") {
                dispatch({
                    type: "GET_ACCESSES",
                    payload: res.Results,
                });
            } else {
                console.log("error", res);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getAccesses actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const createAccessTemplate =
    (accessTemplate: any, callbackSuccess?: any, callbackFail?: any) =>
    (dispatch: any) => {
        console.log("createAccessTemplate actions api entry: ");

        dispatch(setLoader(true));

        Apis.createAccessTemplate(accessTemplate)
            .then((res) => {
                console.log("createAccessTemplate actions api res: ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "CREATE_ACCESS_TEMPLATE",
                            payload: res.Results,
                        },
                        callbackSuccess(accessTemplate)(res.Results)
                    );
                } else {
                    console.log("error", res);
                    dispatch(
                        {
                            type: "SET_ERRORS",
                            payload: res,
                        },
                        callbackFail(accessTemplate)(res)
                    );
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.log(
                    "error in createAccessTemplate actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

    export const updateAccessTemplate =
    (accessTemplate: any, callbackSuccess?: any, callbackFail?: any) =>
    (dispatch: any) => {
        console.log("updateAccessTemplate actions api entry: ");

        dispatch(setLoader(true));

        Apis.updateAccessTemplate(accessTemplate)
            .then((res) => {
                console.log("updateAccessTemplate actions api res: ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "UPDATE_ACCESS_TEMPLATE",
                            payload: res.Results,
                        },
                        callbackSuccess(accessTemplate)(res.Results)
                    );
                } else {
                    console.log("error", res);
                    dispatch(
                        {
                            type: "SET_ERRORS",
                            payload: res,
                        },
                        callbackFail(accessTemplate)(res)
                    );
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.log(
                    "error in updateAccessTemplate actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

    export const deleteAccessTemplate =
    (accessTemplateId: any, callbackSuccess?: any, callbackFail?: any) =>
    (dispatch: any) => {
        console.log("deleteAccessTemplate actions api entry: ");

        dispatch(setLoader(true));

        Apis.deleteAccessTemplate(accessTemplateId)
            .then((res) => {
                console.log("deleteAccessTemplate actions api res: ", res);
                if (res.Status === "OK") {
                    dispatch(
                        {
                            type: "DELETE_ACCESS_TEMPLATE",
                            payload: res.Results,
                        },
                        callbackSuccess(accessTemplateId)(res.Results)
                    );
                } else {
                    console.log("error", res);
                    dispatch(
                        {
                            type: "SET_ERRORS",
                            payload: res,
                        },
                        callbackFail(accessTemplateId)(res)
                    );
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.log(
                    "error in deleteAccessTemplate actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };
