// GetAccessTemplates

import { setLoader } from "../auth";
import { callFail, callSuccess } from "../../utils/callback";
import * as Apis from "../../utils/api";
import {  IAccessTemplatesResponse } from "../actions";

export const getAccessTemplates = (callback?: any) => (dispatch: any) => {
    console.log("getAccessTemplates actions api entry: ");

    dispatch(setLoader(true));

    Apis.GetAccessTemplates()
        .then((res: IAccessTemplatesResponse) => {
            console.log("getAccessTemplates actions api res: ", res);
            if (res.Status === "OK") {
                dispatch({
                    type: "GET_ACCESS_TEMPLATES",
                    payload: res.Results,
                });
                typeof callback == "function" && callback(res);
            } else {
                console.log("error", res);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res,
                });
                callFail(callback, res);
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getAccessTemplates actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};