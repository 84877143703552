import dayjs from "dayjs";

const initialState = {
    alarms: [],
    parsedAlarmsTopSearch: [],
    alarmsActivatedWeekGraphData: [],
    alarmsActivatedMonthGraphData: [],
    vehicleAlarms: [],
    parsedVehicleAlarmsTopSearch: [],
    VehicleAlarmsWeekGraphData: [],
    VehicleAlarmsMonthGraphData: [],
};

/*
class AlarmList
{
    const All = 0;
    const Device = 1;
    const Driver = 2;
    const Inspection = 3;
    const Service = 4;
    const Exchange = 5;
    const TravelTime = 6;
    const LowPower = 7;
    const Help = 8;
}

class AlarmType {
    const None = 0;
    const PowerLost = 1;
    const LowPower = 2;
    const AccError = 3;
    const EEPROM = 4;
    const CAN = 5;
    const Flash = 6;
    const Temp = 7;
    const SIMTemp = 8;
    const RFIDBatt = 9;
    const RFIDTemp = 10;
    const RFIDMiss = 11;
    const GPSMiles = 12;
    const CritPower = 13;
    const Inspection = 14;
    const InspectionNow = 25;
    const Inspected = 26;
    const Service = 15;
    const Help = 16;
    const GPSPos = 17;
    const RecoverPower = 18;
    const IDError = 19;
    const GPSAddress = 21;
    const SPIFlash = 22;
    const VerifyID = 23;
    const Exchange = 24;
    const ConnError = 99;
    const VehicleError = 100;
    const DeviceError = 101;
    const NoTravel = 102;
    const TravelTime = 103;
    const NoBooking = 104;
    const Movement = 105;
    const All = 999;
}
 */

/*
 {
    level: "mock1",
    time: "mock2",
    device: "mock3",
    vehicle: "mock4",
    type: "mock5",
},
*/

const alarmsReducer = (
    state = initialState,
    { type, payload }: any,
    callback: any
) => {
    switch (type) {
        case "GET_VEHICLE_ALARMS":
            console.log("GET_VEHICLE_ALARMS vehiclealarms REDUCER ", payload);

            const vehicleAlarms = payload.Results.VehicleAlarms.map(
                (alarm: any) => {
                    const DateTimeObject = dayjs(alarm.DateTime);
                    return { ...alarm, DateTimedayjs: DateTimeObject };
                }
            );

            const parsedVehicleAlarmsTopSearch =
                payload.Results.VehicleAlarms.map((alarm: any) => {
                    return {
                        symbolColor: alarm.SymbolColor,
                        dateTime: alarm.DateTime,
                        typeText: alarm.TypeText,
                    };
                });

            return {
                ...state,
                vehicleAlarms: vehicleAlarms,
                parsedVehicleAlarmsTopSearch: parsedVehicleAlarmsTopSearch,
                VehicleAlarmsWeekGraphData:
                    payload.Results.VehicleAlarmsWeekGraphData,
                VehicleAlarmsMonthGraphData:
                    payload.Results.VehicleAlarmsMonthGraphData,
                callback,
            };
        case "GET_ALARMS":
            console.log("GET_ALARMS alarms REDUCER ", payload);
            const alarms = payload.Results.Alarms.map((alarm: any) => {
                const DateTimeObject = dayjs(alarm.DateTime);
                return { ...alarm, DateTimedayjs: DateTimeObject };
            });
            const parsedAlarmsTopSearch = payload.Results.Alarms.map((alarm: any) => {
                return {
                    symbolColor: alarm.SymbolColor,
                    dateTime: alarm.DateTime,
                    typeText: alarm.TypeText,
                    licensePlate: alarm.LicensePlate,
                    deviceIdText: alarm.TrackerIdExt
                };
            });
            return {
                ...state,
                alarms: alarms,
                parsedAlarmsTopSearch: parsedAlarmsTopSearch,
                alarmsActivatedWeekGraphData:
                    payload.Results.AlarmsActivatedWeekGraphData,
                alarmsActivatedMonthGraphData:
                    payload.Results.AlarmsActivatedMonthGraphData,
                callback,
            };

        default:
            return state;
    }
};

export default alarmsReducer;
