import React, { useState, useRef } from "react";
import { Col, Row, Button, Form } from "antd";
import BreadcrumbHeader from "../../components/breadcrumbheader/Header.js";
import { useDispatch, useSelector } from "react-redux";
import {
    useHistory,
    BrowserRouter as Router,
    Route,
    NavLink,
    Switch,
} from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import "../../assets/css/header.css";
import { FormOutlined, EditOutlined } from "@ant-design/icons";
import VehicleCardWrapper from "./vehicleCardWrapper.js";
import VehicleNotes from "../../components/vehiclecard/notes.js";
import VehicleEconomy from "../../components/vehiclecard/economy.js";
import VehicleMaintenance from "../../components/vehiclecard/maintenance.js";
import VehicleSystem from "../../components/vehiclecard/system.js";
import VehicleDashboard from "../../components/vehiclecard/dashboard.js";
import SideDrawer from "../../components/menu/sideDrawer.tsx";
import VehicleLarm from "../../components/vehiclecard/larm.js";
import Routes from "../Routes.js";
import VehicleForm from "../../components/forms/vehicle/vehicle.tsx";
import * as vehicleActions from "../../actions/vehicles/vehicles";
import Dashboard from "./dashboard.tsx";

const Vehicleoverview = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { vehicleIdFromParams } = useParams();
    let vehicleId = null;
    if (props.location.state) {
        vehicleId = props.location.state;
    }
    console.log("Vehicle overview location prop vehicleId: ", vehicleId, "vehicleIdFromParams: ", vehicleIdFromParams);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [licensePlate, setLicensePlate] = useState();
    const showDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const [form] = Form.useForm();
    const submitFormRef = useRef(form);

    const loading = useSelector((state) => state.auth.loading);
    const [state, setState] = useState({
        error: "",
    });

    function callback(key) {
        console.log(key);
    }

    const pullDashboardDataCallback = (pulledData) => {
        console.log("pulled data from dashboard: ", pulledData);
        setLicensePlate(pulledData);
    };

    const deleteVehicleCallback = () => {
        vehicleActions.deleteVehicle(vehicleIdFromParams, (res) => {
            console.log("vehicleReducer response deleteVehicle: ", res);
        })(dispatch);
    };

    
    const [visibleNote, setVisibleNote] = useState(false);
    const onCloseInfo = () => {
        setVisibleNote(false);
    };

    return (
        <VehicleCardWrapper>
            <Dashboard vehicleIdFromParams={vehicleIdFromParams}/>
        </VehicleCardWrapper>
    );
};

export default Vehicleoverview;
