import React, { useState, useRef, useEffect, useMemo } from "react";
import { Col, Row, Tabs, Button, Affix, Form, Layout } from "antd";
import BreadcrumbHeader from "../../components/breadcrumbheader/Header.js";
import { useDispatch, useSelector } from "react-redux";
import {
    useHistory,
    BrowserRouter as Router,
    Route,
    NavLink,
    Switch,
} from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import "../../assets/css/header.css";
import { FormOutlined, EditOutlined } from "@ant-design/icons";

import VehicleNotes from "../../components/vehiclecard/notes.js";
import VehicleEconomy from "../../components/vehiclecard/economy.js";
import VehicleMaintenance from "../../components/vehiclecard/maintenance.js";
import VehicleSystem from "../../components/vehiclecard/system.js";
import VehicleDashboard from "../../components/vehiclecard/dashboard.js";
import SideDrawer from "../../components/menu/sideDrawer.tsx";
import VehicleLarm from "../../components/vehiclecard/larm.js";
import Routes from "../Routes.js";
import VehicleForm from "../../components/forms/vehicle/vehicle.tsx";
import * as vehicleActions from "../../actions/vehicles/vehicles.js";

const { Header, Content, Footer, Sider } = Layout;

const VehicleCardWrapper = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { vehicleIdFromParams } = useParams();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [licensePlate, setLicensePlate] = useState();
    const showDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const [form] = Form.useForm();
    const submitFormRef = useRef(form);

    const loading = useSelector((state) => state.auth.loading);
    const [state, setState] = useState({
        error: "",
    });
    const { TabPane } = Tabs;

    function callback(key) {
        console.log(key);
    }

    const pullDashboardDataCallback = (pulledData) => {
        console.log("pulled data from dashboard: ", pulledData);
        setLicensePlate(pulledData);
    };
    const deleteVehicleCallback = () => {
        vehicleActions.deleteVehicle(vehicleIdFromParams, (res) => {
            console.log("vehicleReducer response deleteVehicle: ", res);
        })(dispatch);
    };

    const [historyRoutes, setHistoryRoutes] = useState([
        {
            path: "../../vehicle/search",
            breadcrumbName: "Vehicles",
        },
        {
            path: "/vehiclecard/overview/" + vehicleIdFromParams,
            breadcrumbName: "Vehicle Overview",
        },
    ]);
    const [test, setTest] = useState(0);
    useEffect(() => {
        setTest(1);
    }, [history]);

    const [visibleNote, setVisibleNote] = useState(false);
    const onCloseInfo = () => {
        setVisibleNote(false);
    };

    return (
        <div
            className="functionPagediv"
            style={{
                paddingLeft: 30,
                paddingRight: 30,
                // border: "2px solid pink"
            }}
        >
            {/* TODO set dynamic routes */}
            {console.log("historyRoutes wrapper", historyRoutes)}
            {console.log("historyRoutes wrapper test", test)}
            {/* TODO make linebreak of breadcrumbheader width 100% */}
            <BreadcrumbHeader routes={historyRoutes} filter={false} />

            <Route
                path="/vehiclecard/:tab?"
                render={({ match, history }) => {
                    {
                        console.log("match.params.tab: ", match.params.tab);
                    }

                    return (
                        <div className="card-container">
                            <Tabs
                                type="card"
                                defaultActiveKey={
                                    "overview/" + String(vehicleIdFromParams)
                                }
                                activeKey={
                                    match.params.tab +
                                    "/" +
                                    String(vehicleIdFromParams)
                                }
                                onChange={(key) => {
                                    console.log(
                                        "tabs onChange vehicleIdFromParams",
                                        vehicleIdFromParams
                                    );

                                    history.push({
                                        pathname: `/vehiclecard/${key}`,
                                        state: {
                                            vehicleId: vehicleIdFromParams,
                                        },
                                    });
                                }}
                            >
                                <TabPane
                                    tab="Overview"
                                    key={
                                        "overview/" +
                                        String(vehicleIdFromParams)
                                    }
                                ></TabPane>
                                <TabPane tab="Economy" key="economysdfgsdfg">
                                    <p>Hey economy</p>
                                    {/* <VehicleEconomy /> */}
                                </TabPane>
                                <TabPane tab="Maintenance" key="maintenance">
                                    <VehicleMaintenance />
                                </TabPane>
                                <TabPane tab="System" key="system">
                                    <VehicleSystem />
                                </TabPane>
                                <TabPane tab="Larm" key="larm">
                                    <VehicleLarm vData={vehicleIdFromParams} />
                                </TabPane>
                                <TabPane
                                    tab="Update"
                                    key={
                                        "update/" + String(vehicleIdFromParams)
                                    }
                                ></TabPane>
                            </Tabs>
                        </div>
                    );
                }}
            />
            <Layout
                style={{
                    background: "white",
                    // width: 800,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // border: "2px solid purple",
                }}
            >
                <Content
                    style={{
                        margin: props.isCard && "16px 16px 16px 0px",
                        background: "white",
                        boxShadow: props.isCard && "0 10px 10px 5px lightgray",
                        width: props?.Content?.width
                            ? props.Content.width
                            : "100%",
                        // minWidth: 200,
                        // border: "2px solid yellow",
                    }}
                >
                    {props?.children}

                    <Affix
                        style={{
                            zIndex: 10,
                            position: "fixed",
                            bottom: 20,
                            right: 0,
                        }}
                    >
                        <Button
                            size="large"
                            type="primary"
                            onClick={() => {
                                showDrawer();
                                console.log(
                                    "vehiclecard drawerButton clicked openDrawer state: ",
                                    openDrawer
                                );
                            }}
                            icon={<EditOutlined />}
                        />
                        {/* <p>
              <Button size="large" type="primary"  onClick={() => setVisibleNote(true)}  icon={<FormOutlined />} />
            </p> */}
                    </Affix>

                    <SideDrawer
                        form={form}
                        title={licensePlate}
                        pageSetOpenDrawer={setOpenDrawer}
                        pageOpenDrawer={openDrawer}
                        deleteObjectCallback={deleteVehicleCallback}
                    >
                        <VehicleForm
                            form={form}
                            vehicleId={vehicleIdFromParams}
                            formName="updateDrawer"
                            formType="update"
                        />
                    </SideDrawer>

                    {/* <Drawer
      title="Vehicle NOTES"
      placement="right"
      onClose={onCloseInfo}
      visible={visibleNote}
      size="large"
    > */}
                    {/* <VehicleNotes vid={vehicleIdFromParams} /> */}
                    {/* </Drawer> */}
                </Content>
            </Layout>
        </div>
    );
};

export default VehicleCardWrapper;
