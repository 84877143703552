import React,  { useState, useEffect }  from "react";
import { connect } from "react-redux";
import { List, Typography,Row,Col,Timeline ,Table } from "antd";
import "../../assets/css/functionPage.css";
import { useHistory } from "react-router-dom";
import JRTable from "../../components/table/JRTable";
import Header from "../../components/breadcrumbheader/Header";
import * as Apis from "../../utils/api";

const Driveranalysis = (props) => {
  const [alarms, setAlarms] = useState([]);
  const columns = [
    {
      title: "datetime",
      dataIndex: "datetime",
    },
    {
      title: "Larm",
      dataIndex: "typeText",
    },
    {
      title: "licenseplate",
      dataIndex: "licenseplate"
    },
    {
      title: "Tracker",
      dataIndex: "trackeridext",
    },
    {
      title: "Data",
      dataIndex: "data",
    },
  ];
  useEffect(() => {
    Apis.getAlarms("11,16")
      .then((res) => {
        if (res.Status == "NOK") {
          console.log("error");
        } else {
          console.log(res);
          setAlarms(res.Alarm);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  

    return (
      <div className="functionPagediv">
        <p className="functioncontent">Alarm Driver Alarm</p>
        <JRTable columns={columns} showHashLink={false} ExportBTN={false} data={alarms} uniqKey={"id"} />
      </div>
    );
}
const mapStateToProps = state => {
  console.log("selected menu mapstatetoprops", state);
  return {
    selectedmenu: state.widgets.selectedMenu,
    allfunctionmenu: state.widgets.allFuncMenu,
    submenukey: state.widgets.submenukey,
    submenutitle: state.widgets.submenutitle,
    selectedSubmenu: state.widgets.selectedSubmenu,
    selectedFuncTitle: state.widgets.selectedFuncTitle
    // allWidgetsdata: state.widgets.allWidgets
  };
}
export default connect(mapStateToProps)(Driveranalysis);
