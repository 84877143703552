import React from "react";
import "./assets/css/App.css";
import Sidebar from "./components/sidebar/sidebar";
//import {BrowserRouter as Router, Route} from "react-router-dom";
import { HashRouter as Router, Route } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import ColorPicker from "./components/colorpicker/index";
import { Button, Drawer, Row, Col, message, Layout, Affix } from "antd";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import translation from "./translation";
import { renderFormatMsg } from "./components/common/function";
import Routes from "./pages/Routes";
import {
    changeLang,
} from "./actions/index";

class App extends React.Component {
    constructor(props) {
        // console.log("props", props);
        super(props);
        this.props.dispatch(changeLang("jp"));
    }

    render() {
        if (this.props.auth) {
            document.body.classList.add('add-sidebar');
            document.body.classList.remove('remove-sidebar');
        } else {
            document.body.classList.add('remove-sidebar');
            document.body.classList.remove('add-sidebar');
        }


        return (

            <Layout className="hej">
                <IntlProvider
                    locale={this.props.selectedLang}
                    defaultLocale="jp"
                    messages={translation[this.props.selectedLang]}
                >
                    <Router basename="/">
                        {this.props.auth ?
                            <>
                                <Sidebar />
                            </> :
                            <><Layout style={{ height: "100vh" }}><Routes /></Layout>
                            </>
                        }
                    </Router>
                </IntlProvider>
            </Layout>

        );
    }
}

const mapStateToProps = state => {
    // console.log("state.widgets.changeLang", state.widgets.selectedLang);

    return {
        selectedLang: state.widgets.selectedLang,
        auth: state.auth.isAuthenticated
    };
};

export default connect(mapStateToProps)(App);
