import React, { FC } from "react";
import { Form, Button, Row, Col, Spin } from "antd";
import logo from "../../assets/Images/jiricom_logo.png";
import Alert from "../../utils/alert";
import { ButtonType as TButtonType} from "../../../node_modules/antd/lib/button/button";

interface IPasswordFormProps  {
    form: {cols?: number, name?: string, className?: string, initialValues?: {remember?: boolean}, onFinish?(values: any): void , onFinishFailed?(values: any): void },
    formContent : React.FC,
    formButton?: {onClick?(any: any): any, className?: string, type?: TButtonType , buttonText?: string},
    loading?: boolean,
    errorMessage: string,
}

const GenericPasswordForm: FC<IPasswordFormProps> = (props) => {
    const [form] = Form.useForm();
    return (
        <>
        {console.log("GenericPasswordForm props: ", props) }
        <Form.Item style={{marginTop: "50px", textAlign: "center"}}>
            <img src={logo} alt="logo" width="200"/><br/>
        </Form.Item>
        {props.loading ? <Form.Item style={{textAlign: "center"}}> <Spin/> </Form.Item> : null}
        <Form 
            form={form}
            name={props.form.name ? props.form.name : "genericForm"}
            className={props.form.className && props.form.className}
            initialValues={props.form.initialValues && props.form.initialValues}
            onFinish={props.form.onFinish && props.form.onFinish}
            onFinishFailed={props.form.onFinishFailed && props.form.onFinishFailed}
            labelCol={{span:9}}
            wrapperCol={ props.form.cols == 2 ? {span: 14} : undefined}
            layout="horizontal"
            labelAlign="left"
        >
            <Row style={{padding: "0", margin: "0"}}>
                <Col span={20} offset={2}>
                    {props.errorMessage ? (
                        <Alert message={props.errorMessage} type={"error"}/>
                    ) : null}

                    <props.formContent/>

                    {props.formButton && 
                        <Form.Item>
                            <Button type={props.formButton.type ? props.formButton.type :  "primary"} 
                                className={props.formButton.className ? props.formButton.className :  "login-form-button"} 
                                form={props.form.name ? props.form.name : "genericForm" }
                                key="submit" 
                                htmlType="submit"
                                onClick={props.formButton.onClick}
                            >    
                                {props.formButton.buttonText && props.formButton.buttonText}
                            </Button>
                        </Form.Item>
                    }
                </Col>
            </Row>
        </Form>
        </>
    )
}

export default GenericPasswordForm;