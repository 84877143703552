import dayjs from "dayjs";
const initialState = {
    getVehicles: [],
    fetchedVehicle: {},
    getVehicle: {},
    getVehiclesFormatted: [],
    getRespNumbersFormatted: [],
    updateVehicleResponse: {},
    vehiclePositions: [],
    licensePlatesAntdFormatted: [],
    availableLicensePlatesAntdFormatted: [],
    vehiclesStatus: {}
};

//TODO return also respNumbers
const vehiclesReducer = (state = initialState, { type, payload }, callback) => {
    switch (type) {
        case "GET_VEHICLES_FORMATTED":
            console.log("GET_VEHICLES_FORMATTED REDUCER");
            let vehicles = payload.Vehicles.map((obj) => ({
                key: obj.vID,
                LicensePlate: obj.LicensePlate,
                Driver: obj.Driver,
                DriverID: obj.DriverID,
            }));

            let respNumbers = [
                ...new Set(
                    payload.Vehicles.map((vehicle) => vehicle.Group).filter(
                        (group) => {
                            return group;
                        }
                    )
                ),
            ];

            const licensePlatesAntdFormatted = payload.Vehicles.map(
                (vehicle) => ({
                    value: vehicle.LicensePlate,
                    label: vehicle.LicensePlate,
                })
            );

            const availableLicensePlatesAntdFormatted = payload.Vehicles.filter(
                (vehicle) => {
                    return (
                        (vehicle.DriverID &&
                            vehicle.DriverID !== "" &&
                            vehicle.DriverID !== "0") ||
                        (vehicle.DriverId &&
                            vehicle.DriverId !== "" &&
                            vehicle.DriverId !== "0")
                    );
                }
            ).map((availableVehicle) => ({
                value: availableVehicle.LicensePlate,
                label: availableVehicle.LicensePlate,
            }));

            return {
                ...state,
                getVehiclesFormatted: vehicles,
                licensePlatesAntdFormatted: licensePlatesAntdFormatted,
                availableLicensePlatesAntdFormatted:
                    availableLicensePlatesAntdFormatted,
                getRespNumbersFormatted: respNumbers,
                callback,
            };

        case "GET_VEHICLES":
            return {
                ...state,
                getVehicles: payload,
                callback,
            };
        case "FETCH_VEHICLE":
            return {
                ...state,
                fetchedVehicle: payload,
                callback,
            };
        case "GET_VEHICLE":
            let parseVehicleClass = payload?.Results?.VehicleClass;
            switch (parseVehicleClass) {
                case "PB":
                    parseVehicleClass = "Private Car";
                    break;
                case "TB":
                    parseVehicleClass = "Transport Car";
                    break;
                case "LB":
                    parseVehicleClass = "Truck";
                    break;
                default:
                    parseVehicleClass = "Other";
            }

            const parseAcquisitionDate = dayjs(payload.Results.BuyDate);
            const parseLastMaintenanceDate = dayjs(
                payload.Results.LastMaintenanceDate
            );
            const parseLastBesiktningDate = dayjs(
                payload.Results.LastInspectionDate
            );
            const parseSelldateDate = dayjs(payload.Results.SellDate);

            return {
                ...state,
                getVehicle: {
                    ...payload.Results,
                    VehicleClassCode: payload.Results.VehicleClass,
                    VehicleClass: parseVehicleClass,
                    ParsedAcquisitionDate: parseAcquisitionDate,
                    ParsedLastBesiktningDate: parseLastBesiktningDate,
                    ParsedSelldateDate: parseSelldateDate,
                    ParsedLastMaintenanceDate: parseLastMaintenanceDate,
                },
                callback,
            };
        case "UPDATE_VEHICLE":
            return {
                ...state,
                updateVehicleResponse: payload,
                callback,
            };
        case "DELETE_VEHICLE":
            return {
                ...state,
                deleteVehicleResponse: payload,
                callback,
            };
        case "GET_VEHICLE_POSITIONS":
            return {
                ...state,
                vehiclePositions: payload,
                callback,
            };
        case "GET_VEHICLES_STATUS":
            console.log(" getVehiclesStatus GET_VEHICLES_STATUS REDUCER payload: ", payload);
            const additionalTravelsDateObjects = payload.Results.ActiveVehicles.ActiveVehiclesWeekGraphData.map((point) => {return {...point, DayMonthName: dayjs.utc(point.Date).toString().slice(0, 11)}})
            if (additionalTravelsDateObjects[0].Date > additionalTravelsDateObjects[1].Date) {
                additionalTravelsDateObjects.reverse()
            }
            return {
                ...state,
                vehiclesStatus: {...payload.Results, ActiveVehicles: {ActiveToday:  payload.Results.ActiveVehicles.ActiveToday, ActiveVehiclesWeekGraphData : additionalTravelsDateObjects}},
                callback,
            };

        default:
            return state;
    }
};

export default vehiclesReducer;
