import React from 'react';
import { List, Timeline, Popover, Select } from 'antd';
import { EllipsisOutlined, ClockCircleOutlined, FileOutlined, SearchOutlined } from '@ant-design/icons';
import { FaMapMarkerAlt, FaRoad, FaGasPump, FaPencilAlt } from 'react-icons/fa';

const { Option } = Select;

class ListRoutes2 extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = { loading: true, page: 0, offset: 0 };

    }

    componentDidMount() {
        console.log(this.props.drawer);
        //  
    }

    showRoute = (routeID) => {
        this.props.onClick(routeID);
    }

    toggleDrawer = () => {
        this.props.drawer();
    }

    render() {
        const IconText = ({ extlib = false, bounce = false, icon, text }) => {
            var antIcon = (extlib !== true ? '' : 'anticon');
            bounce = (bounce === true ? 'bounce' : '');
            var iconClass = ((antIcon && bounce) !== '' ? antIcon + ' ' + bounce : antIcon + bounce);
            return (
                <>
                    {React.createElement(icon, { style: { marginRight: 8 }, className: iconClass })}
                    {text}
                </>
            )
        };

        const SelectType = (type, routeID) => {
            return (
                <Select placeholder="Välj resetyp" showSearch defaultValue={type} style={{width: '100%'}}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    <Option value="1">Tjänsteresa</Option>
                    <Option value="2">Privatresa</Option>
                    <Option value="7">Kundresa</Option>
                </Select>
            )
        }


        return (
            <List
                itemLayout="vertical"
                size="large"
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 6,
                    xxl: 3,
                }}
                pagination={{
                    onChange: page => {
                        var offset = (page === 1 ? 0 : (page * 9 - 9));
                        this.setState({ page: page, offset: offset })
                    },
                    pageSize: 9,
                }}
                dataSource={this.props.data}
                renderItem={(item, index) => (
                    <List.Item
                        key={item.ID}
                        actions={[
                            <IconText extlib={true} icon={FaRoad} text={item.Distance + ' km'} key="list-vertical-star-o" />,
                            <IconText extlib={true} icon={FaGasPump} text={item.Liters + ' l'} key="list-vertical-like-o" />,
                            <Popover placement="top" trigger="click" title={'Ändra resetyp'} content={SelectType(item.Type, item.ID)}>
                                <span><IconText extlib={true} icon={FaPencilAlt} text={item.Type} key="list-vertical-message" /></span>
                            </Popover>,
                            <IconText icon={EllipsisOutlined} text={'Ändra resa'} key="settings" />,
                        ]}
                    >
                        <List.Item.Meta
                            title={<span>{item.LicensePlate}</span>}
                            description={<span>{item.ID}</span>}
                        />
                        <span className="point" style={{
                            position: "absolute",
                            top: "18px",
                            right:"30px"
                        }} onClick={() => this.showRoute(index + this.state.offset)}><IconText extlib={true} icon={FaMapMarkerAlt} bounce={true} text="Visa" key="show-route" /></span>
                        <Timeline>
                            <Timeline.Item color="green">{item.StartAddress}</Timeline.Item>
                            <Timeline.Item dot={<ClockCircleOutlined />}>
                                {item.Duration}
                            </Timeline.Item>
                            <Timeline.Item dot={<FileOutlined />}>
                                {item.Purpose}
                            </Timeline.Item>
                            <Timeline.Item color="red">{item.StopAddress}</Timeline.Item>
                        </Timeline>
                    </List.Item>
                )}
            />
        );
    }
}

export default ListRoutes2;