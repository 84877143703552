import React, { FC, useEffect, useState } from "react";
import { Drawer, Layout } from "antd";
import { Loading3QuartersOutlined, SettingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector, connect } from "react-redux";
import BreadcrumbHeader from "../../../components/breadcrumbheader/Header";

import VehicleForm from "../../../components/forms/vehicle/vehicle.tsx";

interface IRegisterVehicleProps {}

//TODO org cascader get api data
//TODO make form to work on non drawer too (lift out form)
const RegisterVehicle: FC<IRegisterVehicleProps> = (props) => {
    const { Header, Content, Footer, Sider } = Layout;
    const routes = [
        {
            path: "",
            breadcrumbName: "Admin",
        },
        {
            path: "",
            breadcrumbName: "Register Vehicle",
        },
    ];
    useEffect(() => {}, []);

    return (
        <div className="functionPagediv">
            
            <Layout
                style={{
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginLeft: 40
                }}

            >
                <BreadcrumbHeader routes={routes} filter={false} />   
                <Content
                    style={{
                        margin: "16px 16px 16px 0px",
                        background: "white",
                        boxShadow: "0 10px 10px 5px lightgray",
                        width: '50%',
                        minWidth: 200
                    }}
                    
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight: 360,
                            background: "white",
                          
                        }}
                    >
                        <VehicleForm formName="createVehicle" formType="create" hasFormButtons/>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};

const mapStateToProps = (state: {
    widgets: {
        selectedMenu: any;
        allFuncMenu: any;
        submenukey: any;
        submenutitle: any;
        selectedSubmenu: any;
        selectedFuncTitle: any;
    };
    login: { loginUser: any };
}) => {
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        loggedInUser: state.login.loginUser,
    };
};

export default connect(mapStateToProps)(RegisterVehicle);
