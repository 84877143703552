import type { FC } from "react";
import type {
    AnalysisData,
    DataItem,
    IRootState,
    IChartCardDataPoint,
    IDashboard,
} from "../../components/dashboard/data.d";
import { IMarkerProps } from "../../components/maps/data";
import { Suspense, useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import PageLoading from "../../components/common/pageLoading";
import { nNotis } from "../../components/common/function";
import * as VehicleActions from "../../actions/vehicles/vehicles";
import * as AlarmsActions from "../../actions/alarms/alarms";
import * as TravelsActions from "../../actions/travels/travels";
import * as AlcoholMeterActions from "../../actions/alcoholMeter/alcoholMeter";
import * as NotificationsActions from "../../actions/notifications/notifications";
import * as DriversActions from "../../actions/drivers/drivers";
import * as Common from "../../components/common/function";
import {
    InfoCircleOutlined,
    ArrowUpOutlined,
    UserOutlined,
    TagOutlined,
    EllipsisOutlined,
    DownOutlined,
    EnvironmentOutlined,
    SearchOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    solid,
    regular,
    brands,
    icon,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { TinyArea, TinyColumn, Progress, Bar, Item } from "@ant-design/charts";
import { Column } from "@ant-design/plots";
import {
    Col,
    Drawer,
    Empty,
    Row,
    Input,
    Space,
    Tooltip,
    Statistic,
    Card,
    Dropdown,
    Menu,
    Button,
    Table,
    Form,
    Select,
    Spin,
    DatePicker,
} from "antd";
import Yuan from "../../components/dashboard/utils/Yuan";
import { GridContent } from "@ant-design/pro-layout";
import { ChartCard, Field } from "../../components/dashboard/Charts";
// import Bar from "../../components/dashboard/Charts/Bar";
import Trend from "../../components/dashboard/Trend";
import styles from "../../components/dashboard/style.less";
import MiniBar from "../../components/dashboard/Charts/MiniBar";
// import { barConfig } from "@fullcalendar/react";
import GenericTopSearch from "../../components/dashboard/GenericTopSearch";
import JRStatus from "../../components/vehicals/status";
import Map from "../../components/maps/MapsCurrent";
import RenderGM from "../../components/Map/Google";
import PlainMap from "../../components/maps/PlainMap";
import GenericMap from "../../components/maps";
import { Marker as GoogleMarker, InfoWindow } from "@react-google-maps/api";
import { RealTimePositionMap } from "../../components/maps/variants/RealTimePositionMap";
import {
    getCurrentDate,
    getFirstDayThisMonthDate,
    getFirstDayThisWeekDate,
    getLastDayThisWeekDate,
} from "../../components/common/function";
import moment from "moment";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import carred from "../../assets/Images/carred.png";
import cargreen from "../../assets/Images/cargreen.png";

import type { ColumnsType } from "antd/es/table";
import type { MenuProps } from "antd";
import { extend } from "lodash";
import { ItemType } from "antd/lib/menu/hooks/useItems";

import type { TableProps } from "antd/lib/table/Table";
import { FormInstance } from "antd/es/form/hooks/useForm";

//TODO when tabled refresh every 10 sec. SubTable foloow another tablerow that is not connected to that subtable.
interface ISubTable extends TableProps<any> {
    record: any;
    parseStatusColor: (ACObject: any, travel: any) => any;
    parseStatusColorAC: (ACObject: any) => any;
    parseStatusSymbol: (symbolColor: string) => JSX.Element;
    parseActivationResult: (BAC: any) => any;
    parseActionRequest: (notification: any) => string | undefined;
    openMapDrawer: (positions: any) => void;
}

const SubTable: FC<ISubTable> = (props) => {
    const dispatch = useDispatch();
    const HistoricalTravels = useSelector(
        (state: IRootState) => state.travels.getTravelsByUserId
    );
    const HistoricalAlcoholMetersByDeviceId = useSelector(
        (state: IRootState) => state.alcoholMeters.specificDeviceMeasurements
    );
    // const HistoricalAlcoholMetersByUserId = useSelector(
    //     (state: IRootState) => state.alcoholMeters.specificUserMeasurements
    // );
    
    const NotificationsByDeviceId = useSelector(
        (state: IRootState) => state.notifications.notificationsByDeviceId
    );

    const SubTableColumns = [
        {
            title: <FormattedMessage {...messages.status} />,
            dataIndex: "StatusColor",
            key: "StatusColor",
            render: (symbolColor: string) => {
                return <a>{props.parseStatusSymbol(symbolColor)}</a>;
            },
        },
        // {
        //     title: "License Plate",
        //     dataIndex: "LicensePlate",
        //     key: "LicensePlate",
        //     render: (text: React.ReactNode) => <p>{text}</p>,
        // },
        // {
        //     title: "Travel Status",
        //     dataIndex: "TravelStatus",
        //     key: "TravelStatus",
        //     render: (text: React.ReactNode) => {
        //         return text ? (
        //             <>
        //                 <p>{text}</p>
        //             </>
        //         ) : (
        //             <>
        //                 <p>N/A</p>
        //             </>
        //         );
        //     },
        // },
        {
            title: <FormattedMessage {...messages.timestampAC} />,
            dataIndex: "ActivationTimestamp",
            key: "ActivationTimestamp",
            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.ACResult} />,
            dataIndex: "ActivationResult",
            key: "ActivationResult",
            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.actionRequestStatus} />,
            dataIndex: "ActivationRemedy",
            key: "ActivationRemedy",

            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        // {
        //     title: "Travel Time",
        //     dataIndex: "TravelTime",
        //     key: "TravelTime",

        //     render: (text: React.ReactNode) => {
        //         return text ? (
        //             <>
        //                 <p>{text}</p>
        //             </>
        //         ) : (
        //             <>
        //                 <p>N/A</p>
        //             </>
        //         );
        //     },
        // },
        {
            title: <FormattedMessage {...messages.location} />,
            dataIndex: "Location",
            key: "Location",
            render: (text: React.ReactNode, record: any) => {
                return (
                    <a
                        onClick={(e) => {
                            props.openMapDrawer([
                                {
                                    Latitude: record.Latitude,
                                    Longitude: record.Longitude,
                                },
                            ]);
                        }}
                    >
                        {text}
                    </a>
                );
            },
        },
    ];

    useEffect(() => {
        const DateTimeTodaysMidnightString =
            Common.getTodaysMidnightDate().DateTimeString;
        const DateTimeCurrent = Common.getCurrentDateTime().DateTimeString;

        const requestData = {
            userId: props.record.DriverId,
            dateFrom: DateTimeTodaysMidnightString,
            dateTo: DateTimeCurrent,
        };
        try {
            TravelsActions.getTravelsByUserId(requestData)(dispatch);
        } catch (e) {
            console.log("Error TravelsActions.getTravelsByUserId ", e);
        }

        try {
            AlcoholMeterActions.getMeasurementsByDeviceIdCompactDate(
                "historicalMeasurements",
                props.record.DeviceId,
                Common.getTodaysMidnightDate().DateTimeCompactString,
                null,
                undefined
            )(dispatch);
        } catch (e) {
            console.log(
                "Error AlcoholMeterActions.getMeasurementsByDeviceIdCompactDate ",
                e
            );
        }

        // AlcoholMeterActions.getMeasurementsByDeviceId(
        //     props.record.DeviceId,
        //     Common.getTodaysMidnightDate().DateTimeCompactString,
        //     undefined
        // )(dispatch);

        NotificationsActions.pullNotificationsByDeviceIdDate(
            props.record.DeviceId,
            Common.getTodaysMidnightDate().DateTimeCompactString,
            undefined,
            false
        )(dispatch);
    }, []);

    return (
        <>
            {/* {console.log(
                "HistoricalAlcoholMetersByDeviceId ",
                HistoricalAlcoholMetersByDeviceId
            )}
            { console.log("NotificationsByDeviceId ", NotificationsByDeviceId)} */}
            <Table
                rowKey={(record: any) => record.key}
                columns={SubTableColumns}
                dataSource={HistoricalAlcoholMetersByDeviceId?.flatMap(
                    (ACObject: any, index: number) => {
                        if (props.record.DeviceId !== ACObject.DeviceId) {
                            return [];
                        }
                        if (NotificationsByDeviceId?.length >= 1) {
                            // const Notification  = {HasReadNotification: ACObject?.HasReadMessage}
                            const Notification  = NotificationsByDeviceId[index]
                            // console.log("Notification ", Notification)
                            return {
                                key: index,
                                ...ACObject,
                                ActivationResult:
                                    ACObject === undefined
                                        ? "N/A"
                                        : props.parseActivationResult(
                                              ACObject.BAC
                                          ),
                                ActivationTimestamp:
                                    ACObject === undefined
                                        ? "N/A"
                                        : ACObject.ActivationTimestamp,
                                ActivationRemedy:
                                Notification?.HasReadMessage === undefined || null
                                        ? "N/A"
                                        : props.parseActionRequest(
                                            Notification
                                          ),
                                StatusColor:
                                    ACObject === undefined
                                        ? "Yellow"
                                        : props.parseStatusColorAC(ACObject)
                                              .symbolColor,
                                StatusColorPriority:
                                    ACObject === undefined
                                        ? 2
                                        : props.parseStatusColorAC(ACObject)
                                              .symbolPriority,
                                Location:
                                    String(ACObject.Latitude) +
                                    ", " +
                                    String(ACObject.Longitude),
                            };
                        } else {
                            return {
                                key: index,
                                ...ACObject,
                                ActivationResult:
                                    ACObject === undefined
                                        ? "N/A"
                                        : props.parseActivationResult(
                                              ACObject.BAC
                                          ),
                                ActivationTimestamp:
                                    ACObject === undefined
                                        ? "N/A"
                                        : ACObject.ActivationTimestamp,
                                StatusColor:
                                    ACObject === undefined
                                        ? "Yellow"
                                        : props.parseStatusColorAC(ACObject)
                                              .symbolColor,
                                StatusColorPriority:
                                    ACObject === undefined
                                        ? 2
                                        : props.parseStatusColorAC(ACObject)
                                              .symbolPriority,
                                Location:
                                    String(ACObject.Latitude) +
                                    ", " +
                                    String(ACObject.Longitude),
                            };
                        }
                    }
                ).reverse()}
                pagination={{
                    style: { marginBottom: 0 },
                    pageSize: 5,
                }}
            />
        </>
    );
};

//TODO ska vi ha behörigheter på detta med? Vilka ska kunna se dashboarden osv..
//TODO fix anys
interface IOngoingTravelRecord {
    Id: number;
    key?: React.Key;
    TravelId: string;
    DeviceId?: number | string;
    VehicleId?: number | string;
    DriverId?: number | string;
    DriverName?: string;
    Rfid?: string;
    Move?: number;
    LicensePlate?: string;
    Latitude?: string;
    Longitude?: string;
    TravelTime?: string;
    AlcoholDeviceId?: number | string;
    StatusColor?: string;
    StatusColorPriority?: number | string;
    TravelStatus?: string;
    ActivationTimestamp?: string;
    ActivationResult?: string;
    ActivationRemedy?: string;
    CurrentAddress?: string;
    BAC?: string | number;
}

const topRowResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
    xl: 6,
    style: { marginBottom: 24 },
};

const middleRowResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    style: { marginBottom: 24 },
};
const bottomRowResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 6,
    style: { marginBottom: 24 },
};

const checkedDrivers = (travels: any, alcoholMeters: any) => {
    const seenRfids = new Set(); // Create a Set to store seen Rfid values

    const taggedTravels = (travels || []).flatMap((travel: any) => {
        if (
            travel.Rfid === "" ||
            travel.Rfid === undefined ||
            travel.Rfid === null
        )
            return [];

        // Check if the Rfid has been seen before
        if (seenRfids.has(travel.Rfid)) {
            return []; // Return false if Rfid is repeated
        }

        seenRfids.add(travel.Rfid); // Add the Rfid to the set

        return travel;
    });

    const taggedTravelsWithAC = (taggedTravels || []).flatMap(
        (taggedTravel: any) => {
            if (
                alcoholMeters.some(
                    (AC: any) =>
                        taggedTravel.DriverId === AC.UserId &&
                        dayjs(taggedTravel.StartDateTime)
                            .subtract(5, "minute")
                            .unix() <= dayjs(AC.ActivationTimestamp).unix() &&
                        dayjs(AC.ActivationTimestamp).unix() <=
                            dayjs(taggedTravel.EndDateTime).unix()
                )
            ) {
                return taggedTravel;
            } else {
                return [];
            }
        }
    );
    return taggedTravelsWithAC;
};

const uncheckedDrivers = (travels: any, alcoholMeters: any) => {
    const seenRfids = new Set(); // Create a Set to store seen Rfid values

    const taggedTravelsWithoutAC = (travels || []).flatMap(
        (travel: any, index: number) => {
            if (
                travel.Rfid === "" ||
                travel.Rfid === undefined ||
                travel.Rfid === null
            )
                return [];

            // Check if the Rfid has been seen before
            if (seenRfids.has(travel.Rfid)) {
                return []; // Return false if Rfid is repeated
            }

            seenRfids.add(travel.Rfid); // Add the Rfid to the set

            const travelHasNoAC = !alcoholMeters.some((AC: any) => {
                return travel.DriverId === AC.UserId;
            });

            if (travelHasNoAC) {
                return travelHasNoAC;
            } else {
                return [];
            }
        }
    );
    return taggedTravelsWithoutAC;
};

const filterPendingRequests = (notifications: any) => {
    const pendingRequests = notifications.filter((notification: any) => {
        return Number(notification.HasReadMessage) === 0;
    });
    return pendingRequests;
};

//TODO is AC Alarmed when the results is Invalid?
const travelsWithACAlarming = (travels: any, alcoholMeters: any) => {
    const ACAlarmed = (travels || []).flatMap((travel: any) => {
        const isACAlarmed = (alcoholMeters || []).find((AC: any) => {
            if (
                travel.DriverId === AC.UserId &&
                dayjs(travel.StartDateTime).subtract(5, "minute").unix() <=
                    dayjs(AC.ActivationTimestamp).unix() &&
                dayjs(AC.ActivationTimestamp).unix() <=
                    dayjs(travel.EndDateTime).unix() &&
                Number(travel.TravelTimeSeconds) >= 10
            ) {
                if (AC.ActivationResult === "Invalid") return true;
                if (AC.ActivationResult === "NotApproved") return true;
                return false;
            } else {
                return false;
            }
        });

        if (isACAlarmed) return travel;
        return [];
    });
    return ACAlarmed;
};

const activatedVehicleIdsToday = (travelsToday: any) => {
    const seenVehicles = new Set(); // Create a Set to store seen Rfid values

    const activatedVehiclesToday = (travelsToday || []).flatMap(
        (travelToday: any) => {
            // Check if the Vehicle has been seen before
            if (seenVehicles.has(travelToday.VehicleId)) {
                return []; // Return false if Rfid is repeated
            }
            seenVehicles.add(travelToday.VehicleId);

            return travelToday.VehicleId;
        }
    );
    return activatedVehiclesToday;
};

//TODO is AC Alarmed when the results is Invalid?
const ACAlarming = (alcoholMeters: any) => {
    // console.log("ACAlarming alcoholMeters ", alcoholMeters);
    const ACAlarmed = (alcoholMeters || []).flatMap((AC: any) => {
        if (AC.ActivationResult === "NotApproved") return AC;
        if (AC.ActivationResult === "Invalid") return AC;
        return [];
    });
    return ACAlarmed;
};

const filterAwayApprovedACs = (alcoholMeters: any) => {
    // console.log("filterAwayApprovedACs alcoholMeters ", alcoholMeters);
    const ACAlarmed = (alcoholMeters || []).flatMap((AC: any) => {
        if (AC.ActivationResult === "NotApproved") return AC;
        if (AC.ActivationResult === "Invalid") return AC;
        if (AC.ActivationResult === "Warning") return AC;
        return [];
    });
    return ACAlarmed;
};

const IntroduceRow = ({
    loading,
    visitData,
    apiData,
}: {
    loading: boolean;
    visitData: DataItem[];
    apiData: any;
}) => (
    <Row gutter={24} style={{ marginTop: 8 }}>
        <Col {...topRowResponsiveProps}>
            <Card
                bordered={true}
                style={{
                    boxShadow: "5px 5px 10px 5px lightgray",
                    backgroundColor: "#5c0011",
                    opacity: 0.95,
                }}
            >
                <Statistic
                    title={
                        <h1 style={{ color: "white" }}>
                            <FormattedMessage {...messages.ignoreAlerts} />{" "}
                        </h1>
                    }
                    value={apiData?.AmountOfIgnoreAlerts}
                    precision={0}
                    valueStyle={{ color: "white", fontSize: 36 }}
                />
            </Card>
        </Col>

        <Col {...topRowResponsiveProps}>
            <Card
                bordered={true}
                style={{ boxShadow: "5px 5px 10px 5px lightgray" }}
            >
                <Statistic
                    title={<FormattedMessage {...messages.violations} />}
                    value={apiData?.AmountOfViolations}
                    precision={0}
                    valueStyle={{ color: "black", fontSize: 36 }}
                />
            </Card>
        </Col>

        <Col {...topRowResponsiveProps}>
            <Card
                bordered={true}
                style={{ boxShadow: "5px 5px 10px 5px lightgray" }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Statistic
                            title={
                                <FormattedMessage
                                    {...messages.uncheckedDrivers}
                                />
                            }
                            value={apiData.AmountOfTravelsWithTagWithoutAC}
                            precision={0}
                            valueStyle={{ color: "black", fontSize: 36 }}
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title={
                                <FormattedMessage
                                    {...messages.checkedDrivers}
                                />
                            }
                            value={apiData.AmountOfTravelsWithTagAndAC}
                            precision={0}
                            valueStyle={{ color: "black", fontSize: 36 }}
                        />
                    </Col>
                </Row>
            </Card>
        </Col>

        <Col {...topRowResponsiveProps}>
            <Card
                bordered={true}
                style={{ boxShadow: "5px 5px 10px 5px lightgray" }}
            >
                <Statistic
                    title={<FormattedMessage {...messages.activeTravels} />}
                    value={apiData.AmountOfOngoingTravels}
                    precision={0}
                    valueStyle={{ color: "black", fontSize: 36 }}
                />
            </Card>
        </Col>
    </Row>
);

//TODO could display loading bar when fetching from api
//TODO could link to driver overview when user click driver in table
const TRASDemoDashboard: FC<IDashboard> = (props) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { RangePicker } = DatePicker;
    const [historicalTravelsFormSearch] = Form.useForm();
    const [historicalMeasurementsFormSearch] = Form.useForm();
    const history = useHistory();
    const FirstDayThisWeekDate = getFirstDayThisWeekDate("-", true);
    const FirstDayThisMonthDate = getFirstDayThisMonthDate("-", true);
    const CurrentDate = getCurrentDate("-", true);
    const alarmsTableRef = useRef<HTMLDivElement>(null);
    const [ongoingTravelsTableData, setOngoingTravelsTableData] = useState([]);
    const [isMapDrawerOpen, setIsMapDrawerOpen] = useState(false);
    const [markers, setMarkers] = useState<IMarkerProps[]>([]);
    const [historicalTravelMarkers, setHistoricalTravelMarkers] = useState<
        IMarkerProps[]
    >([]);

    const [selectedTableRow, setSelectedTableRow] =
        useState<IOngoingTravelRecord>();
    const [amountOfTravelsWithTagAndAC, setAmountOfTravelsWithTagAndAC] =
        useState(0);
    const [
        amountOfTravelsWithTagWithoutAC,
        setAmountOfTravelsWithTagWithoutAC,
    ] = useState(0);
    const [amountOfACAlarming, setAmountOfACAlarming] = useState(0);
    const [amountOfACAboveZero, setAmountOfACAboveZero] = useState(0);
    const [amountOfTravelsWithACAlarming, setAmountOfTravelsWithACAlarming] =
        useState(0);
    const [amountOfActivatedVehiclesToday, setAmountOfActivatedVehiclesToday] =
        useState(0);
    const [amountOfPendingRequestsToday, setAmountOfPendingRequestsToday] =
        useState(0);
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
    const [isHistoricalMapDrawerOpen, setIsHistoricalMapDrawerOpen] =
        useState<boolean>(false);
    const [
        isHistoricalTravelsFormDrawerOpen,
        setIsHistoricalTravelsFormDrawerOpen,
    ] = useState<boolean>(false);
    const [
        isHistoricalMeasurementsFormDrawerOpen,
        setIsHistoricalMeasurementsFormDrawerOpen,
    ] = useState<boolean>(false);
    const [
        combinedHistoricalTravelsAndMeasurements,
        setCombinedHistoricalTravelsAndMeasurements,
    ] = useState<Array<any>>([]);
    const [historicalMeasurementsIsolated, setHistoricalMeasurementsIsolated] =
        useState([]);
    const [isReloadingMeasurementTable, setIsReloadingMeasurementTable] =
        useState(true);
    const HistoricalTravelCoordinates = useSelector(
        (state: IRootState) => state.travels.travelByIdCoordinates
    );
    const HistoricalTravel = useSelector(
        (state: IRootState) => state.travels.travelByTravelId
    );

    const [activeHistoricalTravelMarker, setActiveHistoricalTravelMarker] =
        useState<null | Number>(null);

    const [isHistoricalMarkerStartLoaded, setIsHistoricalMarkerStartLoaded] =
        useState<null | Boolean>(null);
    const [isHistoricalMarkerStopLoaded, setIsHistoricalMarkerStopLoaded] =
        useState<null | Boolean>(null);

    useEffect(() => {
        TravelsActions.getHistoricalTravels(null, ["historicalTravels"])(
            dispatch
        );
        AlcoholMeterActions.getHistoricalMeasurements(
            ["historicalMeasurements"],
            null,
            null,
            () => {
                return true;
            }
        )(dispatch);

        if (isReloadingMeasurementTable) {
            AlcoholMeterActions.getHistoricalMeasurements(
                ["historicalMeasurementsIsolated"],
                null,
                null,
                () => {
                    return true;
                }
            )(dispatch);
        }
        DriversActions.getDrivers()(dispatch);

        const interval = setInterval(() => {
            // console.log(
            //     "useeffect interval isReloadingMeasurementTable ",
            //     isReloadingMeasurementTable
            // );
            if (isReloadingMeasurementTable) {
                AlcoholMeterActions.getHistoricalMeasurements(
                    ["historicalMeasurementsIsolated"],
                    null,
                    null,
                    () => {
                        return false;
                    }
                )(dispatch);
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [isReloadingMeasurementTable]);

    useEffect(() => {
        TravelsActions.getOngoingTravelsTRASDemo(undefined, false)(dispatch);
        const interval = setInterval(() => {
            TravelsActions.getOngoingTravelsTRASDemo(
                undefined,
                false
            )(dispatch);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        AlcoholMeterActions.getOngoingAlcoholMetersTRASDemo(
            Common.getTodaysMidnightDate().DateTimeCompactString,
            undefined,
            false
        )(dispatch);
        const interval = setInterval(() => {
            AlcoholMeterActions.getOngoingAlcoholMetersTRASDemo(
                Common.getTodaysMidnightDate().DateTimeCompactString,
                undefined,
                false
            )(dispatch);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        NotificationsActions.pullNotificationsByDate(
            Common.getTodaysMidnightDate().DateTimeCompactString,
            undefined,
            false
        )(dispatch);
        const interval = setInterval(() => {
            NotificationsActions.pullNotificationsByDate(
                Common.getTodaysMidnightDate().DateTimeCompactString,
                undefined,
                false
            )(dispatch);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const HistoricalTravelsStatistics = useSelector(
        (state: IRootState) => state.travels.historicalTravelsStatistics
    );

    const ongoingAlcoholMeters = useSelector(
        (state: IRootState) => state.alcoholMeters.ongoingAlcoholMeters
    );

    const pulledNotifications = useSelector(
        (state: IRootState) => state.notifications.notifications
    );

    const relevantDataLength = HistoricalTravelsStatistics?.length;
    const pulledNotificationsLength = pulledNotifications?.length;

    //TODO should update
    useEffect(() => {
        const apiFormatData = {
            fromDate: Common.getTodaysMidnightDate().DateTimeString,
        };

        TravelsActions.getHistoricalTravels(apiFormatData, [
            "historicalTravelsStatistics",
        ])(dispatch);

        // setAmountOfTravelsWithACAlarming(
        //     travelsWithACAlarming(
        //         HistoricalTravelsStatistics,
        //         ongoingAlcoholMeters
        //     ).length
        // );

        setAmountOfPendingRequestsToday(
            filterPendingRequests(pulledNotifications).length
        );
        // setAmountOfACAlarming(ACAlarming(ongoingAlcoholMeters).length);
        setAmountOfACAboveZero(
            filterAwayApprovedACs(ongoingAlcoholMeters).length
        );
        setAmountOfTravelsWithTagAndAC(
            checkedDrivers(HistoricalTravelsStatistics, ongoingAlcoholMeters)
                .length
        );
        setAmountOfTravelsWithTagWithoutAC(
            uncheckedDrivers(HistoricalTravelsStatistics, ongoingAlcoholMeters)
                .length
        );
        setAmountOfActivatedVehiclesToday(
            activatedVehicleIdsToday(HistoricalTravelsStatistics).length
        );

        const interval = setInterval(() => {
            TravelsActions.getHistoricalTravels(apiFormatData, [
                "historicalTravelsStatistics",
            ])(dispatch);
            // setAmountOfTravelsWithACAlarming(
            //     travelsWithACAlarming(
            //         HistoricalTravelsStatistics,
            //         ongoingAlcoholMeters
            //     ).length
            // );
            setAmountOfPendingRequestsToday(
                filterPendingRequests(pulledNotifications).length
            );
            // setAmountOfACAlarming(ACAlarming(ongoingAlcoholMeters).length);
            setAmountOfACAboveZero(
                filterAwayApprovedACs(ongoingAlcoholMeters).length
            );
            setAmountOfTravelsWithTagAndAC(
                checkedDrivers(
                    HistoricalTravelsStatistics,
                    ongoingAlcoholMeters
                ).length
            );
            setAmountOfTravelsWithTagWithoutAC(
                uncheckedDrivers(
                    HistoricalTravelsStatistics,
                    ongoingAlcoholMeters
                ).length
            );
            setAmountOfActivatedVehiclesToday(
                activatedVehicleIdsToday(HistoricalTravelsStatistics).length
            );
        }, 10750);
        return () => clearInterval(interval);
    }, [relevantDataLength, pulledNotifications]);

    const ongoingTravels = useSelector(
        (state: IRootState) => state.travels.ongoingTravels
    );

    useEffect(() => {
        const combinedTableData = ongoingTravels.map(
            (travel: any, index: number) => {
                const NotificationObject = (pulledNotifications || []).findLast(
                    (notification: any) => {
                        return notification.DeviceId === travel.DeviceId;
                    }
                );
                // console.log(
                //     "NotificationObject notification ",
                //     NotificationObject
                // );
                const ACObject = (ongoingAlcoholMeters || []).findLast(
                    (AC: any) =>
                        // AC.TravelId === travel.TravelId ||
                        // AC.DeviceId === travel.DeviceId ||
                        AC.UserId === travel.DriverId &&
                        dayjs(travel.StartDateTime)
                            .subtract(5, "minute")
                            .unix() <= dayjs(AC.ActivationTimestamp).unix() &&
                        dayjs(AC.ActivationTimestamp).unix() <=
                            dayjs(travel.EndDateTime).unix()
                );
                // console.log("ACObject measurements", ACObject);

                return {
                    ...travel,
                    // ...ACObject,
                    key: index,
                    StatusColor:
                        ACObject === undefined
                            ? "Yellow"
                            : parseStatusColor(ACObject, travel).symbolColor,
                    StatusColorPriority:
                        ACObject === undefined
                            ? 2
                            : parseStatusColor(ACObject, travel).symbolPriority,
                    DriverName:
                        String(travel.DriverName).trim().length !== 0
                            ? travel.DriverName
                            : travel.Rfid.trim().length !== 0
                            ? travel.Rfid
                            : travel.DeviceId,
                    LicensePlate: travel.LicensePlate
                        ? travel.LicensePlate
                        : travel.DeviceId
                        ? travel.DeviceId
                        : "N/A",
                    ActivationResult:
                        ACObject === undefined
                            ? "N/A"
                            : parseActivationResult(ACObject.BAC),
                    ActivationTimestamp:
                        ACObject === undefined
                            ? "N/A"
                            : ACObject.ActivationTimestamp,
                    TravelStatus: travel.Move
                        ? parseTravelStatus(travel.Move)
                        : ACObject === undefined
                        ? "N/A"
                        : ACObject.TravelStatus,
                    ActivationRemedy:
                        NotificationObject === undefined
                            ? ""
                            : parseActionRequest(NotificationObject),
                    CurrentAddress: "",
                    Location:
                        String(travel?.Latitude) +
                        ", " +
                        String(travel?.Longitude),
                    NotificationId:
                        NotificationObject === undefined
                            ? null
                            : NotificationObject.Id,
                };
            }
        );
        // console.log("combinedTableData ongoing table ", combinedTableData);
        // console.log("combinedtable pulledNotifications ", pulledNotifications);
        setOngoingTravelsTableData(combinedTableData);
    }, [ongoingTravels, ongoingAlcoholMeters, pulledNotifications]);

    const vehicle = useSelector(
        (state: IRootState) => state.vehicles.getVehicle
    );

    const companyDrivers = useSelector(
        (state: IRootState) => state.drivers.drivers
    );

    const alarms = useSelector((state: IRootState) => state.alarms.alarms);

    const parsedAlarmsTopSearch = useSelector(
        (state: IRootState) => state.alarms.parsedAlarmsTopSearch
    );

    const alarmsActivatedWeekGraphData = useSelector(
        (state: IRootState) => state.alarms.alarmsActivatedWeekGraphData
    );

    const alarmsActivatedMonthGraphData = useSelector(
        (state: IRootState) => state.alarms.alarmsActivatedMonthGraphData
    );

    const vehiclesStatus = useSelector(
        (state: IRootState) => state.vehicles.vehiclesStatus
    );

    const VehicleTravels = useSelector(
        (state: IRootState) => state.travels.VehicleTravels
    );

    const amountOfOngoingTravels = useSelector(
        (state: IRootState) => state.travels.amountOfOngoingTravels
    );

    const alcoholMeterStatusColorsAntd = useSelector(
        (state: IRootState) => state.alcoholMeters.alcoholMeterStatusColorsAntd
    );

    const ongoingTravelsDriverNamesUniqueAntd = useSelector(
        (state: IRootState) => state.travels.ongoingTravelsDriverNamesUniqueAntd
    );

    const ongoingTravelsLicensePlatesUniqueAntd = useSelector(
        (state: IRootState) =>
            state.travels.ongoingTravelsLicensePlatesUniqueAntd
    );

    const HistoricalTravels = useSelector(
        (state: IRootState) => state.travels.historicalTravels
    );

    const HistoricalMeasurements = useSelector(
        (state: IRootState) => state.alcoholMeters.historicalMeasurements
    );

    const HistoricalMeasurementsIsolated = useSelector(
        (state: IRootState) =>
            state.alcoholMeters.historicalMeasurementsIsolated
    );

    useEffect(() => {
        // console.log(
        //     "useselectors HistoricalTravels ",
        //     HistoricalTravels,
        //     "HistoricalMeasurements ",
        //     HistoricalMeasurements
        // );
        const CombinedHistoricalTravelsAndMeasurements = (
            HistoricalTravels || []
        ).flatMap((travel: any) => {
            // console.log(
            //     "CombinedHistoricalTravelsAndMeasurements HistoricalMeasurements ",
            //     HistoricalMeasurements
            // );
            const ACObject = (HistoricalMeasurements || []).findLast(
                (AC: any) =>
                    travel.DriverId === AC.UserId &&
                    dayjs(travel.StartDateTime).subtract(5, "minute").unix() <=
                        dayjs(AC.ActivationTimestamp).unix() &&
                    dayjs(AC.ActivationTimestamp).unix() <=
                        dayjs(travel.EndDateTime).unix()
            );

            return {
                ...travel,
                ...ACObject,
                StatusColor:
                    ACObject === undefined
                        ? "Yellow"
                        : parseStatusColor(ACObject, travel).symbolColor,
                StatusColorPriority:
                    ACObject === undefined
                        ? 2
                        : parseStatusColor(ACObject, travel).symbolPriority,
                DriverName: travel.DriverName
                    ? travel.DriverName
                    : travel.Rfid
                    ? travel.Rfid
                    : "N/A",
                ActivationResult:
                    ACObject === undefined
                        ? "N/A"
                        : parseActivationResult(ACObject.BAC),
                ActivationTimestamp:
                    ACObject === undefined
                        ? "N/A"
                        : ACObject.ActivationTimestamp,
            };
        });

        // console.log(
        //     "CombinedHistoricalTravelsAndMeasurements  ",
        //     CombinedHistoricalTravelsAndMeasurements
        // );

        setCombinedHistoricalTravelsAndMeasurements(
            CombinedHistoricalTravelsAndMeasurements
        );
    }, [HistoricalTravels, HistoricalMeasurements]);

    useEffect(() => {
        // console.log(
        //     "companyDrivers ",
        //     companyDrivers,
        //     "HistoricalMeasurementsIsolated useeffect ",
        //     HistoricalMeasurementsIsolated
        // );

        const parsedHistoricalMeasurementsIsolated = (
            HistoricalMeasurementsIsolated || []
        ).flatMap((ACObject: any) => {
            const driver = (companyDrivers || []).find(
                (driver: any) => driver.UserId === ACObject.UserId
            );
            return {
                ...ACObject,
                StatusColor:
                    ACObject === undefined
                        ? "Yellow"
                        : parseStatusColorAC(ACObject).symbolColor,
                StatusColorPriority:
                    ACObject === undefined
                        ? 2
                        : parseStatusColorAC(ACObject).symbolPriority,
                DriverName:
                    driver === undefined
                        ? ACObject.RfidByMeasurementsDb
                            ? ACObject.RfidByMeasurementsDb.toUpperCase()
                            : "N/A"
                        : driver.Name,
                ActivationResult:
                    ACObject === undefined
                        ? "N/A"
                        : parseActivationResult(ACObject.BAC),
                ActivationTimestamp:
                    ACObject === undefined
                        ? "N/A"
                        : ACObject.ActivationTimestamp,
                Location:
                    String(ACObject?.Latitude).trim().length !== 0
                        ? String(ACObject?.Latitude) +
                          ", " +
                          String(ACObject?.Longitude)
                        : "",
            };
        });

        setHistoricalMeasurementsIsolated(
            parsedHistoricalMeasurementsIsolated.reverse()
        );
    }, [HistoricalMeasurementsIsolated, companyDrivers]);

    const isHistoricalTravelsLoading = useSelector(
        (state: IRootState) => state.travels.isHistoricalTravelsLoading
    );

    const isHistoricalMeasurementsIsolatedLoading = useSelector(
        (state: IRootState) =>
            state.alcoholMeters.isHistoricalMeasurementsIsolatedLoading
    );

    const HistoricalTravelsLicensePlatesUniqueAntd = useSelector(
        (state: IRootState) =>
            state.travels.historicalTravelsLicensePlatesUniqueAntd
    );

    const HistoricalTravelsDriverNamesUniqueAntd = useSelector(
        (state: IRootState) =>
            state.travels.historicalTravelsDriverNamesUniqueAntd
    );

    const openMapDrawer = (positions: any) => {
        //TODO add info window as children in Marker

        setMarkers(
            positions.map((positionObject: any, index: number) => {
                return (
                    <GoogleMarker
                        key={index}
                        position={{
                            lat: Number(positionObject.Latitude),
                            lng: Number(positionObject.Longitude),
                        }}
                    ></GoogleMarker>
                );
            })
        );
        setIsMapDrawerOpen(true);
    };

    const closeMapDrawer = () => {
        setIsMapDrawerOpen(false);
    };

    /**
     *
     * @param BAC meaning Blood Alcohol Concentration
     */
    const parseActivationResult = (BAC: any) => {
        let BACValueInMg = BAC.Value;
        if (BACValueInMg === "Invalid") {
            return formatMessage(messages.invalidInput);
        }

        if (BAC.Type === "Hundredth") {
            if (Number(BAC.Value) === 0) return "0 mg";
            BACValueInMg = Number(BAC.Value) / 100;
            BACValueInMg += " mg";
        }
        if (BAC.Type === "Thousand") {
            if (Number(BAC.Value) === 0) return "0 mg";
            BACValueInMg = Number(BAC.Value) / 1000;
            BACValueInMg += " mg";
        }

        return BACValueInMg;
    };

    const parseActionRequest = (notification: any) => {
        let actionRequestString;
        if (Number(notification.HasReadMessage) === 0) {
            actionRequestString = formatMessage(messages.exhaleRequestPending);
        } else if (Number(notification.HasReadMessage) === 1) {
            actionRequestString = formatMessage(messages.requestAccepted);
        }  else if (Number(notification.HasReadMessage) === 2) {
            actionRequestString = formatMessage(messages.requestClearedByAdmin);
        } else {
            actionRequestString = "";
        }
        return actionRequestString;
    };

    const parseStatusColorAC = (ACObject: any) => {
        let symbolPriority;
        let symbolColor;
        switch (ACObject.ActivationResult) {
            case "Approved":
                symbolPriority = 3;
                symbolColor = "Blue";
                break;
            case "Invalid":
                symbolPriority = 2;
                symbolColor = "Yellow";
                break;
            case "NotApproved":
                symbolPriority = 1;
                symbolColor = "Red";
                break;
            default:
                symbolPriority = 2;
                symbolColor = "Yellow";
                break;
        }
        return { symbolPriority: symbolPriority, symbolColor: symbolColor };
    };

    const parseStatusColor = (ACObject: any, travel: any) => {
        let symbolPriority;
        let symbolColor;
        switch (ACObject.ActivationResult) {
            case "Approved":
                symbolPriority = 3;
                symbolColor = "Blue";
                break;
            case "Invalid":
                symbolPriority = 2;
                symbolColor = "Yellow";
                break;
            case "NotApproved":
                if (travel.Move === 2) {
                    symbolPriority = 0;
                    symbolColor = "BlinkRed";
                } else {
                    symbolPriority = 1;
                    symbolColor = "Red";
                }
                break;
            default:
                symbolPriority = 2;
                symbolColor = "Yellow";
                break;
        }
        return { symbolPriority: symbolPriority, symbolColor: symbolColor };
    };

    //Move 1: står stilla, 2 rör sig
    const parseTravelStatus = (moveCode: any) => {
        let status;
        if (moveCode === 1) {
            status = <FormattedMessage {...messages.stop} />;
        } else {
            status = <FormattedMessage {...messages.start} />;
        }
        return status;
    };

    const parseStatusSymbol = (symbolColor: string) => {
        let symbol;
        switch (symbolColor) {
            case "Blue":
                symbol = (
                    <FontAwesomeIcon
                        icon={solid("face-smile")}
                        style={{ color: "#4096ff" }}
                        className="fa-2x"
                    />
                );
                break;
            case "Yellow":
                symbol = (
                    <FontAwesomeIcon
                        icon={solid("question-circle")}
                        style={{ color: "#ffa940" }}
                        className="fa-2x"
                    />
                );
                break;
            case "Red":
                symbol = (
                    <FontAwesomeIcon
                        icon={solid("face-frown")}
                        style={{ color: "#ff4d4f" }}
                        className="fa-2x"
                    />
                );
                break;
            case "BlinkRed":
                symbol = (
                    <FontAwesomeIcon
                        icon={solid("circle-xmark")}
                        style={{ color: "#f5222d" }}
                        className="fa-2x"
                    />
                );
                break;
            default:
                symbol = (
                    <FontAwesomeIcon
                        icon={solid("question-circle")}
                        style={{ color: "#ffa940" }}
                        className="fa-2x"
                    />
                );
                break;
        }
        return symbol;
    };

    const TravelStatusSorterOrder = ["Start", "Stop", "Finished", "N/A"];

    const ACResultsSorterOrder = [
        "NotApproved",
        "Invalid",
        "Warning",
        "Approved",
        "N/A",
    ];

    const ACRemedySorterOrder = [
        "Request Accepted",
        "Exhale Request Pending",
        "Clean Request Pending",
        "N/A",
    ];

    const requestActions: MenuProps["items"] = [
        {
            key: "Exhale",
            label: <FormattedMessage {...messages.exhale} />,
        },
        {
            key: "Clean",
            label: <FormattedMessage {...messages.clean} />,
        },
    ];

    const onActionRequestDropdownClick = (type: string, record: any) => {
        let isBlinking = false;
        let isBeeping = false;
        let isContinueBeeping = false;

        const blowRequestData = {
            deviceId: record.DeviceId,
        };

        // console.log("onActionRequestDropdownClick record type, ", record, type);

        switch (type) {
            case "Exhale":
                isContinueBeeping = true;
                NotificationsActions.requestAlcBlowToDeviceId(
                    blowRequestData.deviceId
                )
                    .then((response: any) => {
                        //TODO SHOULD update UI table with "Action Request Pending"
                        nNotis(
                            "success",
                            "top",
                            formatMessage(messages.successSentRequest),
                            4,
                            formatMessage(messages.requestSent)
                        );
                        // TravelsActions.getOngoingTravelsTRASDemo(undefined, false)(dispatch);
                        // AlcoholMeterActions.getOngoingAlcoholMetersTRASDemo(
                        //     Common.getTodaysMidnightDate().DateTimeCompactString,
                        //     undefined,
                        //     false
                        // )(dispatch);
                    })
                    .catch((error: any) => {
                        nNotis(
                            "error",
                            "top",
                            formatMessage(messages.failSentRequest),
                            4,
                            formatMessage(messages.requestFailed)
                        );
                    });
                break;
            case "Clean":
                isBlinking = true;
                // const notificationId = record.NotificationId
                NotificationsActions.archiveNotificationsByDeviceId(
                    record.DeviceId
                )
                    .then((response: any) => {
                        //TODO SHOULD update UI table with "Cleaned notifications"
                        nNotis(
                            "success",
                            "top",
                            formatMessage(messages.clean),
                            4,
                            formatMessage(messages.clean)
                        );
                    })
                    .catch((error: any) => {
                        nNotis(
                            "error",
                            "top",
                            formatMessage(messages.failSentRequest),
                            4,
                            formatMessage(messages.requestFailed)
                        );
                    });
                break;
        }
    };

    const OngoingTravelsOverviewTableColumns = [
        {
            title: <FormattedMessage {...messages.status} />,
            dataIndex: "StatusColor",
            key: "StatusColor",
            filters: alcoholMeterStatusColorsAntd,
            filterSearch: true,
            onFilter: (value: string, record: IOngoingTravelRecord) =>
                record?.StatusColor?.startsWith(value),
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) => {
                return (
                    Number(a?.StatusColorPriority) +
                    1 -
                    (Number(b?.StatusColorPriority) + 1)
                );
            },
            render: (symbolColor: string) => {
                return <a>{parseStatusSymbol(symbolColor)}</a>;
            },
        },
        Table.EXPAND_COLUMN,
        {
            title: <FormattedMessage {...messages.driver} />,
            dataIndex: "DriverName",
            key: "DriverName",
            filters: ongoingTravelsDriverNamesUniqueAntd,
            filterSearch: true,
            onFilter: (value: string, record: IOngoingTravelRecord) =>
                record?.DriverName?.startsWith(value),
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) =>
                a?.DriverName?.localeCompare(
                    b?.DriverName ? b?.DriverName : ""
                ),
            render: (text: React.ReactNode) => <p>{text}</p>,
        },
        {
            title: <FormattedMessage {...messages.licensePlate} />,
            dataIndex: "LicensePlate",
            key: "LicensePlate",
            filters: ongoingTravelsLicensePlatesUniqueAntd,
            filterSearch: true,
            onFilter: (value: string, record: IOngoingTravelRecord) =>
                record?.LicensePlate?.startsWith(value),
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) =>
                a?.LicensePlate?.localeCompare(
                    b?.LicensePlate ? b?.LicensePlate : ""
                ),
            render: (text: React.ReactNode) => <p>{text}</p>,
        },
        Table.SELECTION_COLUMN,
        {
            title: <FormattedMessage {...messages.travelStatus} />,
            dataIndex: "TravelStatus",
            key: "TravelStatus",
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) => {
                return (
                    TravelStatusSorterOrder.indexOf(
                        a?.TravelStatus ? a?.TravelStatus : "N/A"
                    ) -
                    TravelStatusSorterOrder.indexOf(
                        b?.TravelStatus ? b?.TravelStatus : "N/A"
                    )
                );
            },

            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.timestampAC} />,
            dataIndex: "ActivationTimestamp",
            key: "ActivationTimestamp",
            sorter: (a: any, b: any) => {
                if (a.ActivationTimestamp === "N/A") a = 0;
                if (b.ActivationTimestamp === "N/A") b = 0;
                return (
                    dayjs(a.ActivationTimestamp).unix() -
                    dayjs(b.ActivationTimestamp).unix()
                );
            },
            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.ACResult} />,
            dataIndex: "ActivationResult",
            key: "ActivationResult",
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) => {
                return (
                    ACResultsSorterOrder.indexOf(
                        a?.ActivationResult ? a?.ActivationResult : "N/A"
                    ) -
                    ACResultsSorterOrder.indexOf(
                        b?.ActivationResult ? b?.ActivationResult : "N/A"
                    )
                );
            },
            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.actionRequestStatus} />,
            dataIndex: "ActivationRemedy",
            key: "ActivationRemedy",
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) => {
                return (
                    ACRemedySorterOrder.indexOf(
                        a?.ActivationRemedy ? a?.ActivationRemedy : "N/A"
                    ) -
                    ACRemedySorterOrder.indexOf(
                        b?.ActivationRemedy ? b?.ActivationRemedy : "N/A"
                    )
                );
            },
            render: (text: React.ReactNode, record: any) => {
                return text ? (
                    <Space size="small">
                        <p>{text}</p>
                        <Dropdown
                            menu={{
                                items: requestActions,
                                onClick: ({ key }) => {
                                    onActionRequestDropdownClick(key, record);
                                },
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <p>
                                    <>
                                        <FormattedMessage
                                            {...messages.sendAction}
                                        />{" "}
                                        <DownOutlined />
                                    </>
                                </p>
                            </a>
                        </Dropdown>
                    </Space>
                ) : (
                    <>
                        <Dropdown
                            menu={{
                                items: requestActions,
                                onClick: ({ key }) => {
                                    onActionRequestDropdownClick(key, record);
                                },
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <p>
                                    <>
                                        <FormattedMessage
                                            {...messages.sendAction}
                                        />{" "}
                                        <DownOutlined />
                                    </>
                                </p>
                            </a>
                        </Dropdown>
                    </>
                );
            },
        },
        // {
        //     title: <FormattedMessage {...messages.travelTime} />,
        //     dataIndex: "TravelTime",
        //     key: "TravelTime",
        //     sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) => {
        //         let _ = "2012-12-12 ";
        //         return (
        //             dayjs(_ + a.TravelTime).unix() -
        //             dayjs(_ + b.TravelTime).unix()
        //         );
        //     },
        //     render: (text: React.ReactNode) => {
        //         return text ? (
        //             <>
        //                 <p>{text}</p>
        //             </>
        //         ) : (
        //             <>
        //                 <p>N/A</p>
        //             </>
        //         );
        //     },
        // },
        //TODO click on address cell will show vehicle on map in a drawer
        {
            title: <FormattedMessage {...messages.location} />,
            dataIndex: "Location",
            key: "Location",
            render: (text: React.ReactNode, record: IOngoingTravelRecord) => (
                <a
                    onClick={(e) => {
                        openMapDrawer([
                            {
                                Latitude: record.Latitude,
                                Longitude: record.Longitude,
                            },
                        ]);
                    }}
                >
                    {text}
                </a>
            ),
        },
    ];

    const openHistoricalMapDrawer = (travelId: any) => {
        TravelsActions.getTravelCoordinatesById(travelId)(dispatch);
        setIsHistoricalMapDrawerOpen(true);
    };

    const HistoricalTravelsOverviewTableColumns = [
        {
            title: <FormattedMessage {...messages.status} />,
            dataIndex: "StatusColor",
            key: "StatusColor",
            render: (symbolColor: string) => {
                return <a>{parseStatusSymbol(symbolColor)}</a>;
            },
        },
        {
            title: <FormattedMessage {...messages.driver} />,
            dataIndex: "DriverName",
            key: "DriverName",
            filters: HistoricalTravelsDriverNamesUniqueAntd,
            filterSearch: true,
            onFilter: (value: string, record: IOngoingTravelRecord) =>
                record?.DriverName?.startsWith(value),
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) =>
                a?.DriverName?.localeCompare(
                    b?.DriverName ? b?.DriverName : ""
                ),
            render: (text: React.ReactNode) => <p>{text}</p>,
        },
        {
            title: <FormattedMessage {...messages.licensePlate} />,
            dataIndex: "LicensePlate",
            key: "LicensePlate",
            filters: HistoricalTravelsLicensePlatesUniqueAntd,
            filterSearch: true,
            onFilter: (value: string, record: IOngoingTravelRecord) =>
                record?.LicensePlate?.startsWith(value),
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) =>
                a?.LicensePlate?.localeCompare(
                    b?.LicensePlate ? b?.LicensePlate : ""
                ),
            render: (text: React.ReactNode) => <p>{text}</p>,
        },
        {
            title: <FormattedMessage {...messages.timestampAC} />,
            dataIndex: "ActivationTimestamp",
            key: "ActivationTimestamp",
            sorter: (a: any, b: any) => {
                if (a.ActivationTimestamp === "N/A") a = 0;
                if (b.ActivationTimestamp === "N/A") b = 0;
                return (
                    dayjs(a.ActivationTimestamp).unix() -
                    dayjs(b.ActivationTimestamp).unix()
                );
            },
            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.ACResult} />,
            dataIndex: "ActivationResult",
            key: "ActivationResult",
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) => {
                return (
                    ACResultsSorterOrder.indexOf(
                        a?.ActivationResult ? a?.ActivationResult : "N/A"
                    ) -
                    ACResultsSorterOrder.indexOf(
                        b?.ActivationResult ? b?.ActivationResult : "N/A"
                    )
                );
            },
            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },

        {
            title: (
                <>
                    {" "}
                    <FormattedMessage {...messages.address} /> (
                    <FormattedMessage {...messages.start} /> /{" "}
                    <FormattedMessage {...messages.stop} />)
                </>
            ),
            dataIndex: "Address",
            key: "Address",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <div>
                        {record.StartAddress}
                        <br />
                        {record.StopAddress}
                    </div>
                </Space>
            ),
        },
        {
            title: (
                <>
                    {" "}
                    <FormattedMessage {...messages.time} /> (
                    <FormattedMessage {...messages.start} /> /{" "}
                    <FormattedMessage {...messages.stop} />)
                </>
            ),
            dataIndex: "Time",
            key: "Time",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <div>
                        {record.StartDateTime}
                        <br />
                        {record.EndDateTime}
                    </div>
                </Space>
            ),
        },
        {
            title: <FormattedMessage {...messages.travelTime} />,
            dataIndex: "TravelTime",
            key: "TravelTime",

            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.distance} />,
            dataIndex: "Distance",
            key: "Distance",

            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        // {
        //     title: "Start and Stop",
        //     dataIndex: "Location",
        //     key: "Location",
        //     render: (text: React.ReactNode, record: IOngoingTravelRecord) => (
        //         <a
        //             onClick={(e) => {
        //                 openHistoricalMapDrawer(record.TravelId);
        //             }}
        //         >
        //             <EnvironmentOutlined />
        //         </a>
        //     ),
        // },
    ];

    const HistoricalAlcoholMeasurementsOverviewTableColumns = [
        {
            title: <FormattedMessage {...messages.status} />,
            dataIndex: "StatusColor",
            key: "StatusColor",
            render: (symbolColor: string) => {
                return <a>{parseStatusSymbol(symbolColor)}</a>;
            },
        },
        {
            title: <FormattedMessage {...messages.driver} />,
            dataIndex: "DriverName",
            key: "DriverName",
            filters: HistoricalTravelsDriverNamesUniqueAntd,
            filterSearch: true,
            onFilter: (value: string, record: IOngoingTravelRecord) =>
                record?.DriverName?.startsWith(value),
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) =>
                a?.DriverName?.localeCompare(
                    b?.DriverName ? b?.DriverName : ""
                ),
            render: (text: React.ReactNode) => <p>{text}</p>,
        },

        {
            title: <FormattedMessage {...messages.timestampAC} />,
            dataIndex: "ActivationTimestamp",
            key: "ActivationTimestamp",
            sorter: (a: any, b: any) => {
                if (a.ActivationTimestamp === "N/A") a = 0;
                if (b.ActivationTimestamp === "N/A") b = 0;
                return (
                    dayjs(a.ActivationTimestamp).unix() -
                    dayjs(b.ActivationTimestamp).unix()
                );
            },
            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.ACResult} />,
            dataIndex: "ActivationResult",
            key: "ActivationResult",
            sorter: (a: IOngoingTravelRecord, b: IOngoingTravelRecord) => {
                return (
                    ACResultsSorterOrder.indexOf(
                        a?.ActivationResult ? a?.ActivationResult : "N/A"
                    ) -
                    ACResultsSorterOrder.indexOf(
                        b?.ActivationResult ? b?.ActivationResult : "N/A"
                    )
                );
            },
            render: (text: React.ReactNode) => {
                return text ? (
                    <>
                        <p>{text}</p>
                    </>
                ) : (
                    <>
                        <p>N/A</p>
                    </>
                );
            },
        },
        {
            title: <FormattedMessage {...messages.location} />,
            dataIndex: "Location",
            key: "Location",
            render: (text: React.ReactNode, record: IOngoingTravelRecord) => (
                <a
                    onClick={(e) => {
                        openMapDrawer([
                            {
                                Latitude: record.Latitude,
                                Longitude: record.Longitude,
                            },
                        ]);
                    }}
                >
                    {text}
                </a>
            ),
        },
    ];

    const menu = (
        <Menu>
            <Menu.Item>操作一</Menu.Item>
            <Menu.Item>操作二</Menu.Item>
        </Menu>
    );
    const dropdownGroup = (
        <span className={styles.iconGroup}>
            <Dropdown overlay={menu} placement="bottomRight">
                <EllipsisOutlined />
            </Dropdown>
        </span>
    );

    const openHistoricalTravelsSearchDrawer = () => {
        setIsHistoricalTravelsFormDrawerOpen(true);
        historicalTravelsFormSearch.setFieldsValue({
            Picker: [moment().subtract(7, "d"), moment()],
        });
    };
    const closeHistoricalTravelsFormDrawer = () => {
        setIsHistoricalTravelsFormDrawerOpen(false);
    };

    const closeHistoricalMapDrawer = () => {
        setIsHistoricalMapDrawerOpen(false);
    };

    const openHistoricalMeasurementsSearchDrawer = () => {
        setIsHistoricalMeasurementsFormDrawerOpen(true);
        historicalMeasurementsFormSearch.setFieldsValue({
            Picker: [moment().subtract(7, "d"), moment()],
        });
    };
    const closeHistoricalMeasurementsFormDrawer = () => {
        setIsHistoricalMeasurementsFormDrawerOpen(false);
    };

    const onFinishTravelsForm = (fieldsValue: any) => {
        const rangeTimeValue = fieldsValue["Picker"];
        const values = {
            ...fieldsValue,
            Picker: [
                rangeTimeValue[0].format("YYYY-MM-DD HH:mm:ss"),
                rangeTimeValue[1].format("YYYY-MM-DD HH:mm:ss"),
            ],
        };

        let apiFormatData = {
            toDate: values.Picker[1],
            fromDate: values.Picker[0],
            licenseplate: values.LicensePlate,
            driver: values.DriverId,
        };
        const dateFromCompactString = moment(
            apiFormatData.fromDate,
            "YYYY-MM-DD hh:mm:ss"
        ).format("YYYYMMDDhhmmss");
        const dateToCompactString = moment(
            apiFormatData.toDate,
            "YYYY-MM-DD hh:mm:ss"
        ).format("YYYYMMDDhhmmss");
        TravelsActions.getHistoricalTravels(apiFormatData, [
            "historicalTravels",
        ])(dispatch);
        AlcoholMeterActions.getHistoricalMeasurements(
            ["historicalMeasurements"],
            dateFromCompactString,
            dateToCompactString
        )(dispatch);
        setIsHistoricalTravelsFormDrawerOpen(false);
    };

    const onFinishMeasurementsForm = (fieldsValue: any) => {
        setIsReloadingMeasurementTable(false);

        const rangeTimeValue = fieldsValue["Picker"];
        const values = {
            ...fieldsValue,
            Picker: [
                rangeTimeValue[0].format("YYYY-MM-DD HH:mm:ss"),
                rangeTimeValue[1].format("YYYY-MM-DD HH:mm:ss"),
            ],
        };

        let apiFormatData = {
            toDate: values.Picker[1],
            fromDate: values.Picker[0],
            driver: values.DriverId ? values.DriverId : null,
        };

        // console.log("onfinish fromDate ", apiFormatData.fromDate, " toDate ", apiFormatData.toDate)

        const dateFromCompactString = moment(
            apiFormatData.fromDate,
            "YYYY-MM-DD HH:mm:ss"
        ).format("YYYYMMDDHHmmss");

        const dateToCompactString = moment(
            apiFormatData.toDate,
            "YYYY-MM-DD HH:mm:ss"
        ).format("YYYYMMDDHHmmss");

        // console.log("onfinish moment: ", dateFromCompactString , "dateToCompactString", dateToCompactString)
        // console.log("onFinishMeasurementsForm apiFormatData", apiFormatData)

        AlcoholMeterActions.getMeasurementsByUserIdCompactDate(
            "historicalMeasurementsIsolated",
            apiFormatData.driver,
            dateFromCompactString,
            dateToCompactString,
            undefined
        )(dispatch);
        setIsHistoricalMeasurementsFormDrawerOpen(false);
    };

    // TODO fix bg color to white, then use marginTop: 15
    return (
        <div
            className="functionPagediv"
            style={{
                paddingLeft: 30,
                paddingRight: 30,
                //marginTop: 15
                // border: "2px solid pink"
            }}
        >
            <>
                <GridContent>
                    <>
                        <Suspense fallback={<PageLoading />}>
                            <IntroduceRow
                                loading={false}
                                visitData={[]}
                                apiData={{
                                    AmountOfIgnoreAlerts:
                                        amountOfPendingRequestsToday,
                                    AmountOfViolations: amountOfACAboveZero,
                                    AmountOfTravelsWithTagWithoutAC:
                                        amountOfTravelsWithTagWithoutAC,
                                    AmountOfTravelsWithTagAndAC:
                                        amountOfTravelsWithTagAndAC,
                                    AmountOfOngoingTravels:
                                        amountOfActivatedVehiclesToday,
                                    ActiveVehiclesToday:
                                        vehiclesStatus?.ActiveVehicles
                                            ?.ActiveToday,
                                    TotalTravels: VehicleTravels.TotalTravels,
                                    TravelsToday: VehicleTravels.TravelsToday,
                                    TotalOdometer: VehicleTravels.TotalOdometer,
                                    OdometerToday: VehicleTravels.OdometerToday,
                                }}
                            />
                        </Suspense>
                        <Suspense fallback={<PageLoading />}>
                            <Row gutter={24}>
                                <Col {...middleRowResponsiveProps}>
                                    <Card
                                        bordered={true}
                                        style={{
                                            boxShadow:
                                                "5px 5px 10px 5px lightgray",
                                            // height: 650,
                                        }}
                                        ref={alarmsTableRef}
                                    >
                                        {ongoingTravelsTableData?.length >=
                                        1 ? (
                                            <>
                                                <GenericTopSearch
                                                    title={
                                                        <FormattedMessage
                                                            {...messages.ongoingTravels}
                                                        />
                                                    }
                                                    pageSize={8}
                                                    loading={
                                                        !!(
                                                            ongoingTravelsTableData.length ===
                                                            0
                                                        )
                                                    }
                                                    searchData={
                                                        ongoingTravelsTableData
                                                    }
                                                    // extra={dropdownGroup}
                                                    columns={
                                                        OngoingTravelsOverviewTableColumns
                                                    }
                                                    subTitle="Unchecked Drivers Today"
                                                    subTitleTooltip="Amount of Drivers that did not use AC"
                                                    subTitle2="Checked Drivers Today"
                                                    subTitleTooltip2="Amount of Drivers that did use AC"
                                                    rowClassNameCallback={(
                                                        record
                                                    ) =>
                                                        record.StatusColor ===
                                                        "BlinkRed"
                                                            ? "data-row active-row"
                                                            : "data-row"
                                                    }
                                                    expandable={{
                                                        expandedRowRender: (
                                                            record: IOngoingTravelRecord
                                                        ) => {
                                                            return (
                                                                <SubTable
                                                                    record={
                                                                        record
                                                                    }
                                                                    parseStatusColor={
                                                                        parseStatusColor
                                                                    }
                                                                    parseStatusSymbol={
                                                                        parseStatusSymbol
                                                                    }
                                                                    parseActivationResult={
                                                                        parseActivationResult
                                                                    }
                                                                    openMapDrawer={
                                                                        openMapDrawer
                                                                    }
                                                                    parseActionRequest={
                                                                        parseActionRequest
                                                                    }
                                                                    parseStatusColorAC={
                                                                        parseStatusColorAC
                                                                    }
                                                                />
                                                            );
                                                        },
                                                    }}
                                                    rowClickCallback={(
                                                        record: IOngoingTravelRecord
                                                    ) => {
                                                        setSelectedTableRow(
                                                            record
                                                        );
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Empty
                                                    style={{}}
                                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                    imageStyle={{
                                                        height: 60,
                                                    }}
                                                    description={
                                                        <p>
                                                            {formatMessage(
                                                                messages.noOngoingTravelsFound
                                                            )}
                                                        </p>
                                                    }
                                                ></Empty>
                                            </>
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                        </Suspense>
                        <Suspense fallback={<PageLoading />}>
                            <Row gutter={24}>
                                <Col {...middleRowResponsiveProps}>
                                    <Card
                                        bordered={true}
                                        style={{
                                            boxShadow:
                                                "5px 5px 10px 5px lightgray",
                                            height:
                                                isHistoricalMeasurementsIsolatedLoading &&
                                                350,
                                        }}
                                        ref={alarmsTableRef}
                                    >
                                        <>
                                            {isHistoricalMeasurementsIsolatedLoading ? (
                                                <>
                                                    <Spin
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform:
                                                                "translate(-50%, -50%)",
                                                        }}
                                                        tip={
                                                            "Loading Historical Alcohol Measurements..."
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {historicalMeasurementsIsolated?.length >=
                                                    1 ? (
                                                        <>
                                                            <GenericTopSearch
                                                                title={
                                                                    <FormattedMessage
                                                                        {...messages.historicalAlcoholMeasurements}
                                                                    />
                                                                }
                                                                pageSize={12}
                                                                loading={
                                                                    !!(
                                                                        historicalMeasurementsIsolated.length ===
                                                                        0
                                                                    )
                                                                }
                                                                searchData={
                                                                    historicalMeasurementsIsolated
                                                                }
                                                                extra={
                                                                    <Row
                                                                        justify={
                                                                            "space-between"
                                                                        }
                                                                    >
                                                                        <Col
                                                                            span={
                                                                                8
                                                                            }
                                                                        >
                                                                            <Button
                                                                                size="middle"
                                                                                type="default"
                                                                                icon={
                                                                                    <SearchOutlined />
                                                                                }
                                                                                onClick={() =>
                                                                                    openHistoricalMeasurementsSearchDrawer()
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            span={
                                                                                8
                                                                            }
                                                                        >
                                                                            <Button
                                                                                size="middle"
                                                                                type="default"
                                                                                icon={
                                                                                    <ReloadOutlined />
                                                                                }
                                                                                onClick={() => {
                                                                                    AlcoholMeterActions.getHistoricalMeasurements(
                                                                                        [
                                                                                            "historicalMeasurementsIsolated",
                                                                                        ],
                                                                                        null,
                                                                                        null,
                                                                                        () => {
                                                                                            return true;
                                                                                        }
                                                                                    )(
                                                                                        dispatch
                                                                                    );
                                                                                    setIsReloadingMeasurementTable(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                columns={
                                                                    HistoricalAlcoholMeasurementsOverviewTableColumns
                                                                }
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Card
                                                                extra={
                                                                    <Button
                                                                        size="middle"
                                                                        type="default"
                                                                        icon={
                                                                            <SearchOutlined />
                                                                        }
                                                                        onClick={() =>
                                                                            openHistoricalMeasurementsSearchDrawer()
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                <Empty
                                                                    style={{}}
                                                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                                    imageStyle={{
                                                                        height: 60,
                                                                    }}
                                                                    description={
                                                                        <p>
                                                                            {formatMessage(
                                                                                messages.noHistoricalMeasurementsFound
                                                                            )}
                                                                        </p>
                                                                    }
                                                                ></Empty>
                                                            </Card>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </Card>
                                </Col>
                            </Row>
                        </Suspense>
                        <Suspense fallback={<PageLoading />}>
                            <Row gutter={24}>
                                <Col {...middleRowResponsiveProps}>
                                    <Card
                                        bordered={true}
                                        style={{
                                            boxShadow:
                                                "5px 5px 10px 5px lightgray",
                                            height:
                                                isHistoricalTravelsLoading &&
                                                350,
                                        }}
                                        ref={alarmsTableRef}
                                    >
                                        <>
                                            {isHistoricalTravelsLoading ? (
                                                <>
                                                    <Spin
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform:
                                                                "translate(-50%, -50%)",
                                                        }}
                                                        tip={
                                                            "Loading Historical Travels..."
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {combinedHistoricalTravelsAndMeasurements?.length >=
                                                    1 ? (
                                                        <>
                                                            <GenericTopSearch
                                                                title={
                                                                    <FormattedMessage
                                                                        {...messages.historicalTravels}
                                                                    />
                                                                }
                                                                pageSize={12}
                                                                loading={
                                                                    !!(
                                                                        HistoricalTravels.length ===
                                                                        0
                                                                    )
                                                                }
                                                                searchData={
                                                                    //HistoricalTravels
                                                                    combinedHistoricalTravelsAndMeasurements
                                                                }
                                                                extra={
                                                                    <Button
                                                                        size="middle"
                                                                        type="default"
                                                                        icon={
                                                                            <SearchOutlined />
                                                                        }
                                                                        onClick={() =>
                                                                            openHistoricalTravelsSearchDrawer()
                                                                        }
                                                                    />
                                                                }
                                                                columns={
                                                                    HistoricalTravelsOverviewTableColumns
                                                                }
                                                                rowClickCallback={(
                                                                    record
                                                                ) => {
                                                                    openHistoricalMapDrawer(
                                                                        record.TravelId
                                                                    );
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Card
                                                                extra={
                                                                    <Button
                                                                        size="middle"
                                                                        type="default"
                                                                        icon={
                                                                            <SearchOutlined />
                                                                        }
                                                                        onClick={() =>
                                                                            openHistoricalTravelsSearchDrawer()
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                <Empty
                                                                    style={{}}
                                                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                                                    imageStyle={{
                                                                        height: 60,
                                                                    }}
                                                                    description={
                                                                        <p>
                                                                            {formatMessage(
                                                                                messages.noHistoricalTravelsFound
                                                                            )}
                                                                        </p>
                                                                    }
                                                                ></Empty>
                                                            </Card>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </Card>
                                </Col>
                            </Row>
                        </Suspense>
                    </>
                </GridContent>
                {/* TODO could make map update every 10 seconds once in it. */}
                <Drawer
                    title={
                        selectedTableRow?.LicensePlate
                            ? selectedTableRow.LicensePlate + " "
                            : " "
                    }
                    placement="right"
                    onClose={closeMapDrawer}
                    open={isMapDrawerOpen}
                    size="large"
                >
                    {markers && !!markers.length && (
                        <>
                            <GenericMap markers={[markers[0]]} />
                        </>
                    )}
                </Drawer>
                <Drawer
                    title={
                        <FormattedMessage {...messages.findHistoricalTravels} />
                    }
                    placement="right"
                    onClose={closeHistoricalTravelsFormDrawer}
                    open={isHistoricalTravelsFormDrawerOpen}
                    size="large"
                    extra={
                        <div
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    //TODO actually validate
                                    historicalTravelsFormSearch
                                        ?.validateFields()
                                        .then((values) => {
                                            historicalTravelsFormSearch?.submit();
                                        });
                                    closeHistoricalTravelsFormDrawer();
                                }}
                                type="primary"
                                key="submit"
                                htmlType="submit"
                            >
                                <FormattedMessage {...messages.search} />
                            </Button>
                        </div>
                    }
                >
                    <Form
                        form={historicalTravelsFormSearch}
                        layout="horizontal"
                        onFinish={onFinishTravelsForm}
                        labelCol={{
                            span: 5,
                        }}
                        wrapperCol={{
                            span: 18,
                        }}
                    >
                        <Form.Item
                            name="Picker"
                            label={
                                <FormattedMessage {...messages.timeInterval} />
                            }
                            rules={[
                                {
                                    type: "array",
                                    required: true,
                                    message: "Please select time!",
                                },
                            ]}
                        >
                            <RangePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <FormattedMessage {...messages.licensePlate} />
                            }
                            name="LicensePlate"
                        >
                            <Input
                                placeholder={formatMessage(
                                    messages.licensePlate
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <FormattedMessage {...messages.driverName} />
                            }
                            name="DriverId"
                        >
                            <Select
                                allowClear
                                style={{ width: "100%" }}
                                showSearch
                                placeholder={
                                    <FormattedMessage
                                        {...messages.driverName}
                                    />
                                }
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option?.children
                                        ?.toLowerCase()
                                        ?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {companyDrivers.map((v: any) => (
                                    <Select.Option value={v.UserId}>
                                        {v.Name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Drawer>
                <Drawer
                    title={
                        <FormattedMessage
                            {...messages.historicalAlcoholMeasurements}
                        />
                    }
                    placement="right"
                    onClose={closeHistoricalMeasurementsFormDrawer}
                    open={isHistoricalMeasurementsFormDrawerOpen}
                    size="large"
                    extra={
                        <div
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    //TODO actually validate
                                    historicalMeasurementsFormSearch
                                        ?.validateFields()
                                        .then((values) => {
                                            historicalMeasurementsFormSearch?.submit();
                                        });
                                    closeHistoricalMeasurementsFormDrawer();
                                }}
                                type="primary"
                                key="submit"
                                htmlType="submit"
                            >
                                <FormattedMessage {...messages.search} />
                            </Button>
                        </div>
                    }
                >
                    <Form
                        form={historicalMeasurementsFormSearch}
                        layout="horizontal"
                        onFinish={onFinishMeasurementsForm}
                        labelCol={{
                            span: 5,
                        }}
                        wrapperCol={{
                            span: 18,
                        }}
                    >
                        <Form.Item
                            name="Picker"
                            label={
                                <FormattedMessage {...messages.timeInterval} />
                            }
                            rules={[
                                {
                                    type: "array",
                                    required: true,
                                    message: "Please select time!",
                                },
                            ]}
                        >
                            <RangePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <FormattedMessage {...messages.driverName} />
                            }
                            name="DriverId"
                        >
                            <Select
                                allowClear
                                style={{ width: "100%" }}
                                showSearch
                                placeholder={
                                    <FormattedMessage
                                        {...messages.driverName}
                                    />
                                }
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option?.children
                                        ?.toLowerCase()
                                        ?.indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {companyDrivers.map((v: any) => (
                                    <Select.Option value={v.UserId}>
                                        {v.Name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Drawer>
                <Drawer
                    title="Show Travel"
                    placement="right"
                    onClose={closeHistoricalMapDrawer}
                    open={isHistoricalMapDrawerOpen}
                    size="large"
                >
                    <GenericMap
                        markers={
                            HistoricalTravel?.Coords?.StartLat &&
                            HistoricalTravel?.Coords?.StartLng &&
                            HistoricalTravel?.Coords?.StopLat &&
                            HistoricalTravel?.Coords?.StopLng
                                ? [
                                      <GoogleMarker
                                          key={1}
                                          icon={cargreen || undefined}
                                          position={{
                                              lat: HistoricalTravel?.Coords
                                                  ?.StartLat,
                                              lng: HistoricalTravel?.Coords
                                                  ?.StartLng,
                                          }}
                                          onLoad={() => {
                                              setIsHistoricalMarkerStartLoaded(
                                                  true
                                              );
                                          }}
                                          onClick={() =>
                                              setIsHistoricalMarkerStartLoaded(
                                                  true
                                              )
                                          }
                                      >
                                          {isHistoricalMarkerStartLoaded ? (
                                              <InfoWindow
                                                  onCloseClick={() =>
                                                      setIsHistoricalMarkerStartLoaded(
                                                          false
                                                      )
                                                  }
                                              >
                                                  <div
                                                      style={{
                                                          textAlign: "left",
                                                      }}
                                                  >
                                                      <div>
                                                          {" "}
                                                          {
                                                              HistoricalTravel?.LicensePlate
                                                          }{" "}
                                                      </div>
                                                      <div>
                                                          {" "}
                                                          {HistoricalTravel?.Driver
                                                              ? HistoricalTravel?.Driver
                                                              : HistoricalTravel?.RFID
                                                              ? HistoricalTravel?.RFID
                                                              : "N/A"}{" "}
                                                      </div>
                                                      <div>
                                                          {" "}
                                                          {
                                                              HistoricalTravel?.StartAddress
                                                          }
                                                      </div>
                                                  </div>
                                              </InfoWindow>
                                          ) : null}
                                      </GoogleMarker>,
                                      <GoogleMarker
                                          key={2}
                                          icon={carred || undefined}
                                          position={{
                                              lat: HistoricalTravel?.Coords
                                                  ?.StopLat,
                                              lng: HistoricalTravel?.Coords
                                                  ?.StopLng,
                                          }}
                                          onLoad={() => {
                                              setIsHistoricalMarkerStopLoaded(
                                                  true
                                              );
                                          }}
                                          onClick={() =>
                                              setIsHistoricalMarkerStopLoaded(
                                                  true
                                              )
                                          }
                                      >
                                          {isHistoricalMarkerStopLoaded ? (
                                              <InfoWindow
                                                  onCloseClick={() =>
                                                      setIsHistoricalMarkerStopLoaded(
                                                          false
                                                      )
                                                  }
                                              >
                                                  <div
                                                      style={{
                                                          textAlign: "left",
                                                      }}
                                                  >
                                                      <div>
                                                          {" "}
                                                          {
                                                              HistoricalTravel?.LicensePlate
                                                          }{" "}
                                                      </div>
                                                      <div>
                                                          {" "}
                                                          {HistoricalTravel?.Driver
                                                              ? HistoricalTravel?.Driver
                                                              : HistoricalTravel?.RFID
                                                              ? HistoricalTravel?.RFID
                                                              : "N/A"}{" "}
                                                      </div>
                                                      <div>
                                                          {" "}
                                                          {
                                                              HistoricalTravel?.StopAddress
                                                          }
                                                      </div>
                                                  </div>
                                              </InfoWindow>
                                          ) : null}
                                      </GoogleMarker>,
                                  ]
                                : undefined
                        }
                    />
                </Drawer>
            </>
        </div>
    );
};
const mapStateToProps = (state: {
    widgets: {
        selectedMenu: any;
        allFuncMenu: any;
        submenukey: any;
        submenutitle: any;
        selectedSubmenu: any;
        selectedFuncTitle: any;
    };
    login: { loginUser: any };
}) => {
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        loggedInUser: state.login.loginUser,
    };
};

export default connect(mapStateToProps)(TRASDemoDashboard);
