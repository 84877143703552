import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    Space,
    Button,
    Row,
    Col,
    DatePicker,
    Select,
    Drawer,
    Input,
    Affix,
    Form,
    AutoComplete,
} from "antd";
//import Highlighter from 'react-highlight-words';
import JRTable from "../../components/table/JRTable";
import "../../assets/css/functionPage.css";
import Header from "../../components/breadcrumbheader/Header";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import VehicleSearchDrawer from "../../components/vehicals/vehicleSearchDrawer_new";
import VehicleInfoDrawer from "../../components/vehicals/vehicleInfoDrawer";
import "../../assets/css/vehicelstatus.css";
import { Link, useHistory } from "react-router-dom";
import { Scatter } from "@ant-design/charts";
import * as Apis from "../../utils/api";
import { createVehicleExport } from "../../components/common/function";
import moment from "moment";
import UpdateVehicleCardDrawer from "../../components/menu/sideDrawer.tsx";
import * as organisationActions from "../../actions/organisations/organisations";

const Vehiclesearch = () => {
    const [AdvanceSearchenable, setAdvanceSearchenable] = useState(false);
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const history = useHistory();
    const dispatch = useDispatch();
    const [civd, setcivd] = useState("");
    const [visibleInfo, setVisibleInfo] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [constantAllVehicles, setConstantAllVehicles] = useState([]);
    const [models, setModels] = useState([]);
    const [brands, setBrands] = useState([]);
    const [users, setUsers] = useState([]);
    const [group, setGroup] = useState([]);

    const companys = useSelector((state) => state.organisations.getOrganisations)

    //generic drawer crud
    const [editDrawer, setEditDrawer] = useState({ open: false, data: {} });

    const getVehicles = (data) => {
        Apis.getAllVehicles(data)
            .then((res) => {
                console.log("API getAllVehicles: ", res);
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    setVehicles(res.Vehicles);
                    setConstantAllVehicles(res.Vehicles);
                    if (brands.length === 0 && models.length === 0) {
                        let brandsRes = [];
                        res.Vehicles.forEach(function (obj) {
                            brandsRes.findIndex((x) => x === obj.Brand) === -1
                                ? brandsRes.push(obj.Brand)
                                : console.log("object already exists");
                        });
                        setBrands(brandsRes);
                        let modelRes = [];
                        res.Vehicles.forEach(function (obj) {
                            modelRes.findIndex((x) => x === obj.Model) === -1
                                ? modelRes.push(obj.Model)
                                : console.log("object already exists");
                        });
                        setModels(modelRes);
                        let respnumRes = [];
                        res.Vehicles.forEach(function (obj) {
                            respnumRes.findIndex((x) => x === obj.Group) === -1
                                ? respnumRes.push(obj.Group)
                                : console.log(
                                      "respnumber object already exists"
                                  );
                        });
                        setGroup(respnumRes);
                    }
                    return true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getVehicles();
        Apis.getUsers()
            .then((res) => {
                console.log("API getUsers: ", res);
                if (res.Status === "NOK") {
                    console.log("error");
                } else {
                    setUsers(res.Results);
                }
            })
            .catch((error) => {
                console.log(error);
            });

            dispatch(organisationActions.getOrganisations())            
    }, []);

    const routes = [
        {
            path: "",
            breadcrumbName: "Vehicle",
        },
        {
            path: "",
            breadcrumbName: "Vehicle Search",
        },
    ];

    const showEditDrawer = (data, rowIx) => {
        setEditDrawer(!editDrawer.open);
    };

    //TODO parse api resposnse so you can easier present the data in TAble. e.g. Company.Name
    const columns1 = [
        {
            title: "License plate",
            dataIndex: "LicensePlate",
            key: "LicensePlate",
            render: (_, rec) => (
                <Space>
                    <a
                        onClick={() =>
                            history.push("/vehiclecard/overview/" + rec.vID)
                        }
                    >
                        {rec.LicensePlate}
                    </a>
                </Space>
            ),
        },
        {
            title: "Brand",
            dataIndex: "Brand",
        },
        {
            title: "Model",
            dataIndex: "Model",
        },
        {
            title: "Miles",
            dataIndex: "Miles",
        },
        {
            title: "Company",
            dataIndex: "Company",
        },
        {
            title: "Responsibility Number",
            dataIndex: "Group",
        },
        {
            title: "Last Driver",
            dataIndex: "Driver",
        },
    ];

    const onCloseInfo = () => {
        setVisibleInfo(false);
    };
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(!open);
    };
    const onRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                // record: row data
                // rowIndex: row index
                // event: event prototype
                console.log("click", record);
                console.log("RwoID", rowIndex);
                showEditDrawer(record, rowIndex);
                //alert(record.vID);
                //setcivd(record.vID)
                //setVisibleInfo(true);
            },
        };
    };

    const createExport = () => {
        createVehicleExport(vehicles);
    };

    return (
        <div className="functionPagediv">
            <Affix
                offsetTop={150}
                onChange={(affixed) => console.log(affixed)}
                style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
            >
                <div>
                    <p>
                        <Button
                            size="large"
                            type="primary"
                            onClick={createExport}
                            icon={<DownloadOutlined />}
                        />
                    </p>
                    <p>
                        <Button
                            size="large"
                            type="primary"
                            onClick={() => {
                                showDrawer(true);
                                console.log("vehiclesearch open state:", open);
                            }}
                            icon={<SearchOutlined />}
                        />
                    </p>
                </div>
            </Affix>
            <Row justify="space-around" align="middle">
                <Col span={23}>
                    <Header routes={routes} filter={false} />
                    <VehicleSearchDrawer
                        pageSetOpen={setOpen}
                        constantAllVehicles={constantAllVehicles}
                        setVehicles={setVehicles}
                        Open={open}
                        AdvancedSearchBtnEnable={true}
                        brands={brands}
                        Companies={companys}
                        group={group}
                        models={models}
                        users={users}
                    />
                    <Table
                        columns={columns1}
                        dataSource={vehicles}
                        onRow={onRow}
                    />
                    <Drawer
                        title="Vehicle Info"
                        placement="right"
                        onClose={onCloseInfo}
                        visible={visibleInfo}
                        size="large"
                    >
                        <VehicleInfoDrawer vid={civd} />
                    </Drawer>
                </Col>
            </Row>

            <UpdateVehicleCardDrawer
                pageSetOpenDrawer={setEditDrawer}
                pageOpenDrawer={editDrawer.open}
                data={editDrawer.data}
            />
        </div>
    );
};

export default Vehiclesearch;
