import React, { Component } from 'react'
import { Polyline as GooglePolyline, LoadScript, GoogleMap, InfoWindow, Marker as GoogleMarker } from '@react-google-maps/api'
import greendot from './greendot.png'
import reddot from './reddot.png'
import Marker from './Marker'

export class Markers extends Component {
  
  render () {
    const Start = this.props.Coords[0];
    const Stop = this.props.Coords[(this.props.Coords.length-1)];
    const Marks = [Start,Stop];

    const createMarkers = Marks.map((mark, index) => {
      var pointer = Marks.length === (index+1) ? reddot : greendot;
      var text = Marks.length === (index+1) ?this.props.StopAddress : this.props.StartAddress;
      var text2 = Marks.length === (index+1) ?this.props.StopTime : this.props.StartTime;
      
      return (
        <>
          <Marker key={index} info={text} 
            info1={text2} icon={pointer} 
            position={{lat: mark.lat, lng: mark.lng}}/>  
        </>
      )
    })

    return (
      <>
        {createMarkers} 
      </>
    );
  }
}

export default class PolyLine extends Component {

  render () {
    //const RoutePoly = this.props.path.Coords;
    let Coords = [];
    this.props.path.Geometrics.Polyline.map((x) =>
      Coords.push({
        lat: parseFloat(x.Latitude),
        lng: parseFloat(x.Longitude),
      })
    );
    //const RoutePoly = Coords;
    const StartAddress = this.props.path.StartAddress;
    const StartTime = this.props.path.Time.StartTimeStamp;
    const StopAddress = this.props.path.StopAddress;
    const StopTime = this.props.path.Time.EndTimeStamp;
    const options = {
      strokeColor: '#65afff',
      strokeOpacity: 1,
      strokeWeight: 3,
      fillColor: '#65afff',
      fillOpacity: 1,
      visible: true,
      radius: 30000,
      zIndex: 1
    }

    if(this.props.marker === true) {
      return (
        <>
          <GooglePolyline
            path={Coords}
            //path={RoutePoly}
            options={options}
          />
          <Markers
            //Coords={RoutePoly}
            Coords={Coords}
            StartAddress={StartAddress}
            StopAddress={StopAddress}
            StartTime={StartTime}
            StopTime={StopTime}
          />
        </>
      )
    } else {
      
      return (
        <GooglePolyline
          //path={RoutePoly}
          path={Coords}
          options={options}
        />
      )
    }
  }
}

