import React, {Component} from 'react';
import Header from '../../components/breadcrumbheader/Header';
import {
    Card,
    Button,
    Form,
    Checkbox,
    Row,
    Col,
    Input,
    DatePicker,
    Select,
    InputNumber,
    message,
    List,
    Modal, Table, Space
} from 'antd';
import {EditOutlined, DeleteOutlined, DownOutlined} from "@ant-design/icons";

import * as moment from "moment";
import "../../assets/css/autoexport.css";

const {confirm} = Modal;
const {Option} = Select;
const VDOArr = ["Vehicle", "Driver", "Company"];


const VDOdetails = {
    vehicle: ["Alla_fordon", "ABB123", "AU2FIX", "AU2TRACK", "AUB027", "BMA350", "BRJ181", "CNN527", "CYN543", "DNR920", "DNR921", "GCE800", "GCZ612", "GRANDTOTAL", "HEJ124", "HOL479", "JCN555", "KMB959", "KYJ680", "LBD581", "LCM382", "LCM383", "LCM384", "LCM390", "LSR567", "MSA297", "NPX910", "OYE884", "PGF849", "PRG510", "PRX972", "RRF213", "RRF364", "RRF365", "STC344", "TES123", "TZC014", "XZX160", "YHM340", "YMX006", "YMX007", "YMX767", "YRX950"],
    driver: ["Edit Dahl", "Gunnar Dahl", "JIBS Student account", "Jimmy Thornlof", "Jimmy EM", "Joakim Nodfelt", "Kund AB", "Linus Test", "Linus Testar", "Linus Hacker", "Linus RFID", "Malin Flodin", "Narola Dev", "Rick", "Rickard F", "Robin Kardell", "Test Drive", "Test Test", "Test Testar", "Test4 4", "Testar exchange"],
    company: ["All_companies", "Bilpool", "Filial Nord test", "Filial Syd", "Fiskebäck", "Gränna", "JIRICOM AB", "JIRICOM ÅF Kund Mall", "JIRICOM ÅF Mall", "Kontor Nord", "L Fiskebäck", "Product Name", "Region Syd", "Syd privat", "Syd tjänst", "Test"]
}

const receiverArr = ["Edit Dahl (ylvadahl@yahoo.se)", "Gunnar Dahl (faq1@au2box.com)", "JIBS Student account (jibs@au2box.com)", "Jimmy Thornlof (testar@au2box.om)", "Jimmy EM (jimmy1@au2box.com)", "Joakim Nodfelt (joakim.nodfelt@hotmail.com)", "Kund AB (kund@au2box.com)", "Linus Test (linusask.ask@gmail.com)", "Linus Testar (linus@jiricom.se)", "Linus Hacker (linus_hacker@au2box.com)", "Linus RFID (linusrfids@au2box.com)", "Malin Flodin (rickard.flodin81@gmail.com)", "Narola Dev (narola@au2box.com)", "Rick (rthorstensson@hotmail.com)", "Rickard F (sheldon@bigbang.com)", "Robin Kardell (robin@jiricom.se)", "Test Drive (awefadfasdf@au2box.com)", "Test Test (test@jiricom.se)", "Test Testar (test@au2box.com)", "Test4 4 (Test4@jiricom.se)", "Testar exchange (info@au2server.com)"]

class Autoexport extends Component {
    formRef = React.createRef();
    state = {
        selecteddropdown: "Vehicle",
        selectedDetails: VDOdetails["vehicle"],
        formdatatable: [],
        isEdit: false,
        selectedEditData: {},
        intervaltypeVal: "day",
        sortedInfo: null
    }

    routes = [
        {
            path: '',
            breadcrumbName: 'Travel',
        },
        {
            path: '',
            breadcrumbName: 'Autoexport Driving',
        },
    ];

    onchange = (value) => {
        this.setState({intervaltypeVal: value})
    }

    handlechange = (value) => {
        this.setState({selecteddropdown: value, selectedDetails: VDOdetails[value]})
    }

    onFinish = (values) => {
        console.log("submit values", values)
        if (this.state.isEdit) {
            console.log("values.id", values.id)

            const index = this.state.formdatatable.findIndex(data => data.id === this.state.selectedEditData.id)
            console.log("updateArr1", index)
            values.id = this.state.selectedEditData.id
            this.state.formdatatable[index] = values
            console.log("this.state.formdatatable 1 ==>", this.state.formdatatable)
            // console.log("updateArr", updateArr)
            this.setState({
                formdatatable: [...this.state.formdatatable],
                data: {},
                isEdit: false
            }, () => console.log("update formata arr", this.state.formdatatable))
            message.success('Autoexport is updated successfully');
        } else {
            values.id = this.state.formdatatable.length + 1
            this.setState({formdatatable: [...this.state.formdatatable, values],}, () => {
                console.log("this.state.formdatatable", this.state.formdatatable)
            })
            message.success('Autoexport is added successfully');
        }
        this.formRef.current.resetFields();
        this.setState({selecteddropdown: "Vehicle"})
    }

    onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(err => {
            message.error(err.errors[0]);
        })
    }

    clicklihandler = (data) => {
        this.setState({isEdit: true, selectedEditData: data, selecteddropdown: data.selectVDO})
        this.formRef.current.setFieldsValue({
            intervaltime: data.intervaltime,
            intervaltype: data.intervaltype,
            name: data.name,
            receivers: data.receivers,
            selectVDO: data.selectVDO,
            selectVehicledetail: data.selectVehicledetail,
            selectdriverdetail: data.selectdriverdetail,
            selectcompanydetail: data.selectcompanydetail,
            selectVDOdetail: data.selectVDOdetail,
            startdate: data.startdate,
            status: data.status,
            traveltype: data.traveltype
        })
    }

    deletehandler = () => {
        const thiss = this
        confirm({
            title: 'Do you want to delete this item?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                console.log('OK');
                const updateArr = thiss.state.formdatatable.filter(data => data.id !== thiss.state.selectedEditData.id);
                console.log("updateArr", updateArr)
                // values.id = this.state.selectedEditData.id
                thiss.setState({formdatatable: [...updateArr], data: {}, isEdit: false, selecteddropdown: "Vehicle"})
                thiss.formRef.current.resetFields();
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    }
    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            sortedInfo: sorter,
        });
    };

    data = [
        {
            status: 'Active',
            name: 'John',
            start_date: '2014-12-24 23:12:00',
            travel_type: "Business",
            vehicle: 'ABB123',
        },
        {
            status: 'Active',
            name: 'Smith',
            start_date: '2014-12-24 23:12:00',
            travel_type: "Business",
            vehicle: 'ABB123',
        },
        {
            status: 'Active',
            name: 'Loren',
            start_date: '2014-12-24 23:12:00',
            travel_type: "Business",
            vehicle: 'ABB123',
        },
    ];
    render() {
        const {isEdit} = this.state;
        let {sortedInfo} = this.state;
        sortedInfo = sortedInfo || {};
        const columns = [
            {
                title: 'Status',
                dataIndex: 'status',

            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                sortOrder: sortedInfo.field === 'name' && sortedInfo.order,
            },
            {
                title: 'Start date',
                dataIndex: 'start_date',
                sorter: (a, b) => a.start_date.localeCompare(b.start_date),
                sortOrder: sortedInfo.field === 'start_date' && sortedInfo.order,
            },
            {
                title: 'Travel type',
                dataIndex: 'travel_type',
                sorter: (a, b) => a.travel_type.localeCompare(b.travel_type),
                sortOrder: sortedInfo.field === 'travel_type' && sortedInfo.order,
            },
            {
                title: 'Vehicle',
                dataIndex: 'vehicle',
                sorter: (a, b) => a.vehicle.localeCompare(b.vehicle),
                sortOrder: sortedInfo.field === 'vehicle' && sortedInfo.order,
            },
            {
                title: 'Action',
                key: 'action',
                render: () => (
                    <Space size="middle">
                        <a><EditOutlined/></a>
                        <a><DeleteOutlined/></a>
                    </Space>
                ),
            },
        ];
        return (
            <div>
                <div className="functionPagediv">
                    <Header routes={this.routes} filter={false}/>

                    <div className="main-wrapper">
                        <Card title="Autoexport Driving Journal">
                            <div className="autoexport-wrap">
                                <div className="form-bg">
                                    <Form
                                        layout="vertical"
                                        className="autoexport-form"
                                        onFinish={this.onFinish}
                                        onFinishFailed={this.onFinishFailed}
                                        // initialValues={{
                                        //     intervaltime: 1,
                                        //     intervaltype: "day",
                                        //     traveltype: "business",
                                        //     selectVDO: "Vehicle",
                                        //     startdate: moment()
                                        // }}
                                        ref={this.formRef}>

                                        <Row gutter={16}>

                                            <Col span={8}>
                                                <Form.Item name="name" rules={[{
                                                    required: true,
                                                    message: 'Please enter your name'
                                                }]}>
                                                    <Input placeholder={"Name"}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="startdate">
                                                    <DatePicker style={{width: "100%"}} showTime
                                                                format="YYYY-MM-DD HH:mm:ss"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="traveltype">
                                                    <Select
                                                        showSearch
                                                        placeholder={"Select travel type"}
                                                    >
                                                        <Option value="business">Business</Option>
                                                        <Option value="privet">Privet</Option>
                                                        <Option value="client">Client</Option>
                                                        <Option value="all">All</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} className="intervale-div">
                                            <Col span={8}>
                                                <Form.Item  name="intervaltime">
                                                    <InputNumber min={1}
                                                                 placeholder={"Select interval"}
                                                        // onChange={(e) => this.onchange(e)}
                                                                 style={{width: "100%"}}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="intervaltype">
                                                    <Select
                                                        // value={isEdit && this.state.selectedEditData.intervaltype}
                                                        onChange={(e) => this.onchange(e)}
                                                        placeholder={"Interval type"}
                                                    >
                                                        <Option value="day">Day</Option>
                                                        <Option value="week">Week</Option>
                                                        <Option value="month">Month</Option>
                                                    </Select>
                                                    {this.state.intervaltypeVal == "day" &&
                                                    <small>Select number of days with start date as start, ex 10 =
                                                        report every 10 day.
                                                    </small>}
                                                    {this.state.intervaltypeVal == "week" &&
                                                    <small>Select number of weeks with start date as start, ex 5 =
                                                        report every 5 week.</small>}
                                                    {this.state.intervaltypeVal == "month" &&
                                                    <small style={{textAlign: "center"}}>Select a date per month and how
                                                        many months until next report, for example the 12th every 3
                                                        month.
                                                    </small>}
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item
                                                    name="selectVDO">
                                                    <Select
                                                        showSearch
                                                        placeholder={"Select vehicle, driver or organisation"}

                                                        // style={{ width: 200 }}
                                                        onChange={(e) => this.handlechange(e)}
                                                    >
                                                        {VDOArr.map(data => (
                                                            <Option value={data}>{data}</Option>
                                                        ))}
                                                    </Select>
                                                    <small className="selectVDO-p">Select a value above and a new field
                                                        will be visible underneath</small>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={8}>
                                                {this.state.selecteddropdown === "Vehicle" &&
                                                <Form.Item name="selectVehicledetail">
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={"Select" + " " + this.state.selecteddropdown}
                                                    >
                                                        {VDOdetails["vehicle"].map(data => (
                                                            <Option value={data}>{data}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>}
                                                {this.state.selecteddropdown === "Driver" &&
                                                <Form.Item name="selectdriverdetail">
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={"Select" + " " + this.state.selecteddropdown}
                                                    >
                                                        {VDOdetails["driver"].map(data => (
                                                            <Option value={data}>{data}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>}
                                                {this.state.selecteddropdown === "Company" &&
                                                <Form.Item
                                                    name="selectcompanydetail">

                                                    <Select
                                                        mode="multiple"
                                                        placeholder={"Select" + " " + this.state.selecteddropdown}
                                                    >
                                                        {VDOdetails["company"].map(data => (
                                                            <Option value={data}>{data}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>}
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name="receivers">
                                                    <Select
                                                        placeholder="Select receivers"
                                                        mode="multiple"
                                                    >
                                                        {receiverArr.map(recevier => (
                                                            <Option value={recevier}>{recevier}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item name="status" valuePropName="checked">
                                                    <Checkbox>Active</Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item className="button-div" style={{ textAlign: "right" }}>
                                                    <Button type="primary" htmlType="submit"
                                                            style={{backgroundColor: "#459C72", border: "1px solid #459C72"}}>
                                                        {isEdit ? "Update" : "Save"}
                                                    </Button>
                                                    {isEdit && <><Button type="primary" style={{
                                                        backgroundColor: "#FBB800",
                                                        borderColor: "#FBB800"
                                                    }}>Update Template report</Button>
                                                        <Button type="primary" onClick={this.deletehandler}
                                                                style={{backgroundColor: "#cb016d", borderColor: "#cb016d"}}>Delete
                                                            Template report</Button></>}
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Form>
                                </div>
                                {/*<div className="list-autoexport">*/}
                                {/*    <List*/}
                                {/*        header={<div className="autoexport-listitem-heading">Saved report*/}
                                {/*            templates</div>}*/}
                                {/*        pagination={this.state.formdatatable.length > 0 && {*/}
                                {/*            onChange: page => {*/}
                                {/*                console.log(page);*/}
                                {/*            },*/}
                                {/*            pageSize: 5,*/}
                                {/*        }}*/}
                                {/*        dataSource={this.state.formdatatable && this.state.formdatatable}*/}
                                {/*        renderItem={item => <List.Item*/}
                                {/*            actions={[<a onClick={() => this.clicklihandler(item)}*/}
                                {/*                         key="list-loadmore-edit"><EditOutlined/></a>,*/}
                                {/*                <a key="list-loadmore-more"*/}
                                {/*                   onClick={this.deletehandler}><DeleteOutlined/></a>]}*/}
                                {/*            className="autoexport-listitem">{item.name}</List.Item>}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>


                            <Table columns={columns} dataSource={this.data} onChange={this.handleChange}/>

                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default Autoexport;
