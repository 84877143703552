import type { DataNode, TreeProps } from "antd/es/tree";
import moment from "moment";

const initialState = {
    getOrganisations: [],
    antdCascaderOptionsOrganisations: <IOrganisationCascaderOption[]>[],
    antdOrganisationTree: <DataNode[]>[],
    organisation: {},
    createdOrganisation: {}
};

interface IOrganisationCascaderOption {
    value: string | number;
    label: string;
    children?: IOrganisationCascaderOption[];
}

export const findOrganisationNamePath = (
    orgId: number,
    orgs: IOrganisationCascaderOption[]
): string[] => {
    let foundOrgPathNames: string[] = [];
    const findCurrentOrganisation = (
        orgId: number,
        orgs: IOrganisationCascaderOption[]
    ) => {
        let found = orgs.find((org) => {
            if (org.value == orgId) {
                return true;
            } else if (org.children)
                if (org.children.length != 0)
                    return findCurrentOrganisation(orgId, org.children);
        });
        if (found) {
            foundOrgPathNames.push(found.label);
            return foundOrgPathNames;
        }
    };
    const orgsPath = findCurrentOrganisation(orgId, orgs);
    return orgsPath ? orgsPath : [];
};

//TODO return also respNumbers
const organisationReducer = (
    state = initialState,
    { type, payload }: any,
    callback: any
) => {
    switch (type) {
        case "GET_ORGANISATIONS":
            const companys_arr = Object.keys(payload.ORGS).map((key) => {
                return payload.ORGS[key];
            });
            return {
                ...state,
                getOrganisations: companys_arr,
                antdCascaderOptionsOrganisations: <
                    IOrganisationCascaderOption[]
                >payload.Companies,
                callback,
            };
        case "GET_ORGANISATION_TREE":
            return {
                ...state,
                antdOrganisationTree: [payload],
                callback,
            };
        case "GET_ORGANISATION":
            console.log("GET_ORGANISATION reducer organisation ", payload);
            //TODO parse dates
            const momentInvoiceDateFrom = moment(
                payload.InvoiceInformation.FinancialYearDate[0]
            );
            const momentInvoiceDateTo = moment(
                payload.InvoiceInformation.FinancialYearDate[1]
            );

            return {
                ...state,
                organisation: {
                    ...payload,
                    InvoiceInformation: {
                        ...payload.InvoiceInformation,
                        FinancialYearDate: [
                            momentInvoiceDateFrom,
                            momentInvoiceDateTo,
                        ],
                    },
                },
                callback,
            };
        case "UPDATE_ORGANISATION":
            return {
                ...state,
                organisation: payload,
                callback,
            };
        case "DELETE_ORGANISATION":
            return {
                ...state,
                callback,
            };
        case "CREATE_ORGANISATION":
            return {
                ...state,
                createdOrganisation: payload,
                callback,
            };
        default:
            return state;
    }
};

export default organisationReducer;
