import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { NotisCheck } from "../common/function";
import { Form, Input, Button, Modal, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import validator from "validator";
import * as Apis from "../../utils/api";
import GenericPasswordForm from "./GenericPasswordForm.tsx";
import { useParams } from "react-router-dom";
// import BgImage from "../../assets/Images/backgroundimage.png";
import logoJiricom from "../../assets/Images/logoJiricom.png"
import { adminLogin } from "../../actions/login/login";
import getStorageValue from "../../utils/getStorageValue";
import { useHistory, useLocation } from "react-router-dom";

const SetNewPassword = (props) => {
    const [FPassword, setFPassword] = useState('')
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    let from = getStorageValue('pathHistory') !== null ? getStorageValue('pathHistory') : '/';
    const [loading, setLoading] = useState(false)

    console.log("SetNewPassword props ", props)

    const formContent = () => {
        return (
            <>

                <Form.Item
                    label="New Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                    ]}

                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your new password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    setFPassword(value)
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className="login-form-button" form="myForm" key="submit" htmlType="submit"
                        onClick={() => {
                            console.log("FPassword state", FPassword)
                            setLoading(true)
                            Apis.forgotPassUpdatePassword(FPassword, params.id).then((res) => {
                                console.log("forgotPassUpdatePassword res", res)
                                if (res.Status === "OK") {
                                    NotisCheck("success", "top", res.Message ? res.Message : 'Successfully updated password')
                                    let values = { email: res.Email, password: FPassword }
                                    dispatch(adminLogin(values, (response) => {
                                        setLoading(false)
                                        if (response.Status === 'NOK') {
                                            NotisCheck("error", "top", 'Could not login automatically with your new password')
                                        } else {
                                            console.log("adminLogin response ", response)
                                            history.push(from);
                                        }
                                    }))
                                } else {
                                    setLoading(false)
                                    NotisCheck("error", "top", res.Message ? res.Message : 'Could not update password')
                                }
                            });
                        }
                        }>
                        Create New Password
                    </Button>
                </Form.Item>
            </>
        )
    }

    return (
        <Row>
            <Col sm={18} md={18} lg={18} xs={0}>
                {/* <img src={BgImage} style={{width: "100%", height: "100vh", objectFit: "cover"}}/> */}
                <div style={{ width: "100%", height: "100vh", objectFit: "cover", backgroundColor: "#001529" }}>
                    <img src={logoJiricom} style={{ marginTop: 24, marginLeft: 62 }} />
                </div>
            </Col>
            <Col sm={6} md={6} lg={6} xs={24}
                style={{ background: "#FFF" }}
            >
                <GenericPasswordForm
                    form={{ cols: 2, name: 'myForm', className: 'login-form', onFinish: props.onFinish, onFinishFailed: props.onFinishFailed }}
                    formContent={formContent}
                    loading={loading}
                />
            </Col>
        </Row>
    )


}

export default SetNewPassword;