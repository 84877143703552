import moment from "moment";

const initialState = {
    accesses: {},
    createdAccessTemplateId: null,
    checkBoxAccesses: [],
};

interface IAccess {
    Name: string;
    Description?: string;
    Level?: string;
    Id?: string;
    Bits: number;
}

const parseAccesses = (accesses: any) => {
    const parsed = Object.entries(accesses).map((AccessGroup: any) => {
        return {
            name: AccessGroup[0],
            value: AccessGroup[1][0].Id,
            checked: false,
            underbits: AccessGroup[1].flatMap(
                (access: IAccess, index: number) => {
                    if (index > 0) {
                        console.log("access ", access)
                        return {
                            name: access.Name,
                            value: access.Id,
                            checked: false,
                        };
                    } else {
                        return [];
                    }
                }
            ),
        };
    });
    console.log("accessReducer GET_ACCESSES parsedToCheckBoxData ", parsed);
    return parsed;
};

/**
 *
 * @param state
 * @param param1
 * @param callback
 * @returns
 */
const accessReducer = (
    state = initialState,
    { type, payload }: any,
    callback: any
) => {
    switch (type) {
        case "GET_ACCESSES":
            console.log("accessReducer GET_ACCESSES payload ", payload);
            const parsedToCheckBoxData = parseAccesses(payload);

            return {
                ...state,
                accesses: payload,
                checkBoxAccesses: parsedToCheckBoxData,
                callback,
            };
        case "CREATE_ACCESS_TEMPLATE":
            return {
                ...state,
                createdAccessTemplateId: payload,
                callback,
            };
        case "UPDATE_ACCESS_TEMPLATE":
            return {
                ...state,
                callback,
            };
        case "DELETE_ACCESS_TEMPLATE":
            return {
                ...state,
                callback,
            };
        default:
            return state;
    }
};

export default accessReducer;
