import React, {
    FC,
    useEffect,
    useState,
    useMemo,
    useReducer,
    useRef,
} from "react";
import {
    Table,
    Button,
    Row,
    Col,
    Drawer,
    Affix,
    Form,
    AutoComplete,
    Input,
    Select,
    DatePicker,
    Checkbox,
    InputNumber,
    Cascader,
    CheckboxOptionType,
    FormProps,
} from "antd";
import { Loading3QuartersOutlined, SettingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector, connect } from "react-redux";
import * as vehicleActions from "../../../actions/vehicles/vehicles";
import * as organisationActions from "../../../actions/organisations/organisations";

import rootReducer from "../../../reducer/rootReducer";
import { findOrganisationNamePath } from "../../../reducer/organisations/organisationReducer";
import { ColProps as IColProps } from "antd/lib/col";
import { RowProps as IRowProps } from "antd/lib/row";
import { FormItemProps as IFormItemProps } from "antd/lib/form/FormItem";
import { useParams } from "react-router-dom";
import { FormInstance } from "antd/es/form/hooks/useForm";

import type { CheckboxValueType } from "antd/es/checkbox/Group";
import type { Dayjs } from "dayjs";
import { useCascaderOrganisations } from "../../../hooks/useCascaderOrganisations";

//TODO show the info from the specific vehicle
export type IRootState = ReturnType<typeof rootReducer>;

interface IVehicleFormProps {
    vehicleId?: number;
    licensePlate?: string;
    vehicle?: any;
    formName?: string;
    formType?: "create" | "update" | "delete" | undefined;
    form?: FormInstance;
    hasFormButtons?: boolean;
}

interface IOrganisationCascaderOption {
    value: string;
    label: string;
    children?: IOrganisationCascaderOption[];
}

type FormValues = {
    licensePlate: string;
    chassiNumber: string | number;
    brand: string;
    model: string;
    yearModel: string | number;
    fuelType: string;
    vehicleClass: string;
    acquisitionDate: Dayjs;
    odometer: string | number;
    mileageAllowance: string;
    fuelConsumption: string;
    carbonDioxideEmissions: string;
    companyId: string[] | number[];
};

// type Form.useForm = (): [FormInstance]
// type Form.useFormInstance = (): FormInstance

const VehicleForm: FC<IVehicleFormProps> = (props) => {
    const { Option } = Select;
    const updatedVehicleStateResponse = useSelector(
        (state: IRootState) => state.vehicles.updateVehicleResponse
    );
    const vehicle = useSelector(
        (state: IRootState) => state.vehicles.getVehicle
    );
    const [organisationOptions, currentOrganisationPath] =
        useCascaderOrganisations(vehicle?.CompanyId);

    const acquisitionDate = useMemo(() => vehicle?.ParsedAcquisitionDate, []);
    const latestService = useMemo(() => vehicle?.ParsedLastMaintenanceDate, []);
    const sellDate = useMemo(() => vehicle?.ParsedSelldateDate, []);
    const latestInspection = useMemo(
        () => vehicle?.ParsedLastBesiktningDate,
        []
    );

    //TODO fill in form items with fetchvehicle info
    const fetchedVehicle = useSelector(
        (state: IRootState) => state.vehicles.fetchedVehicle
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.formType !== "create") {
            if (
                Object.keys(vehicle).length === 0 ||
                vehicle.vID !== props.vehicleId
            ) {
                vehicleActions.getVehicle(props.vehicleId)(dispatch);
            }

            props.form?.setFieldsValue({
                chassiNumber: vehicle?.Vin,
                licensePlate: vehicle?.LicensePlate,
                brand: vehicle?.Brand,
                model: vehicle?.Model,
                yearModel: vehicle?.Year,
                fuelType: vehicle?.Data?.FuelType?.Value,
                odometer: vehicle?.Miles,
                mileageAllowance: vehicle?.MileageAllowance,
                fuelConsumption: vehicle?.Data?.Consumption?.Value,
                carbonDioxideEmissions: vehicle?.Data?.CO2?.Value,
                sellPrice: vehicle?.Sellprice,
                costTemplate: vehicle?.CostTemplate?.Name,
                serviceTemplate: vehicle?.ServiceTemplate?.Name,
                bookable: vehicle?.Booking,
                defaultDriver: vehicle?.DriverID,
                responsibilityNumber: vehicle?.VehicleGroup,
                vehicleClass: vehicle?.VehicleClass,
                acquisitionDate: vehicle?.ParsedAcquisitionDate,
                latestService: vehicle?.ParsedLastMaintenanceDate,
                sellDate: vehicle?.ParsedSelldateDate,
                serviceOdometer: vehicle?.LastMaintenanceMiles,
                latestInspection: vehicle?.ParsedLastBesiktningDate,
                formCheckboxes: [
                    Number(vehicle.Attest) ? "attestTravels" : "",
                    Number(vehicle.HideTravel) ? "hideTravelInformation" : "",
                ],
            });
        } else {
            console.log("resetting form");
            props.form?.resetFields();
        }
    }, [
        vehicle?.Vin,
        vehicle?.LicensePlate,
        vehicle?.Brand,
        vehicle?.Model,
        vehicle?.Year,
        vehicle?.Data?.FuelType?.Value,
        vehicle?.Miles,
        vehicle?.MileageAllowance,
        vehicle?.Data?.Consumption?.Value,
        vehicle?.Data?.CO2?.Value,
        vehicle?.VehicleClass,
        acquisitionDate,
        latestService,
        sellDate,
        latestInspection,
        vehicle?.LastMaintenanceMiles,
        vehicle?.Sellprice,
        vehicle?.CostTemplate?.Name,
        vehicle?.ServiceTemplate?.Name,
        vehicle?.Booking,
        vehicle?.DriverID,
        vehicle?.VehicleGroup,
        vehicle?.CompanyId,
    ]);

    const onFinish = (values: FormValues): void => {
        console.log("values in onfinish in updateDrawer: ", values);

        const apiData = {
            vehicleId: props.vehicleId,
            licensePlate: values.licensePlate,
            brand: values.brand,
            acquisitionDate: values.acquisitionDate,
            odometer: values.odometer,
        };

        if (props.formType == "update") {
            //TODO update vehicle to API
            //TODO format values in action
            vehicleActions.updateVehicle(apiData, (res: any) => {
                console.log("vehicleReducer response updatedVehicle: ", res);
            })(dispatch);
        } else if (props.formType == "create") {
            vehicleActions.createVehicle(apiData, (res: any) => {
                console.log("vehicleReducer response createVehicle: ", res);
            })(dispatch);
        } else if (props.formType == "delete") {
            vehicleActions.deleteVehicle(apiData, (res: any) => {
                console.log("vehicleReducer response deleteVehicle: ", res);
            })(dispatch);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("onfininshFailed updateDrawer:", errorInfo);
    };

    const lookupVehicleByLicensePlate = (getLicensePlateFormItemCallback: {
        (): any;
        (): any;
    }) => {
        vehicleActions.lookupVehicleByLicensePlate(
            getLicensePlateFormItemCallback()
        )(dispatch);
    };

    //TODO change placeholder to a formrow argument sent in
    const formRow = (
        formItems: IFormItemProps[],
        rowProps: IRowProps = { justify: "space-around" },
        cols: IColProps[] = [
            { className: "gutter-row", span: 10, xs: 24, xl: 10 },
            { className: "gutter-row", span: 10, xs: 24, xl: 10 },
        ]
    ) => {
        return (
            <>
                <Row {...rowProps}>
                    <Col {...cols[0]}>
                        <Form.Item {...formItems[0]}>
                            <AutoComplete
                                placeholder={
                                    vehicle! && "Specify " + formItems[0]?.label
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col {...cols[1]}>
                        <Form.Item {...formItems[1]}>
                            <AutoComplete
                                placeholder={
                                    vehicle! && "Specify " + formItems[1]?.label
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        );
    };

    const formData = [
        [
            { name: "brand", label: "Brand" },
            { name: "model", label: "Model" },
        ],
        [
            {
                name: "yearModel",
                label: "Year Model",
                // initialValue: vehicle?.Year,
            },
            {
                name: "fuelType",
                label: "Fuel Type",
                // initialValue: vehicle?.Data?.FuelType?.Value,
            },
        ],
    ];

    const formDataOdometer = [
        [
            {
                name: "odometer",
                label: "Odometer",
                // initialValue: vehicle?.Miles,
            },
            {
                name: "mileageAllowance",
                label: "Mileage Allowance",
                // initialValue: vehicle?.MileageAllowance,
            },
        ],
        [
            {
                name: "fuelConsumption",
                label: "Fuel Consumption",
                // initialValue: vehicle?.Data?.Consumption?.Value,
            },
            {
                name: "carbonDioxideEmissions",
                label: "Carbon Dioxide Emissions",
                // initialValue: vehicle?.Data?.CO2?.Value,
            },
        ],
    ];

    const checkBoxesOptions = [
        { label: "Attest travels", value: "attestTravels" },
        { label: "Hide Travel Information", value: "hideTravelInformation" },
    ];

    const checkBoxesOnChange = (checkedValues: CheckboxValueType[]) => {
        console.log("checked = ", checkedValues);
    };

    //TODO make form responsive
    return (
        <>
            <Form
                form={props.form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                name={props.formName}
            >
                <Row justify="space-around">
                    <Col className="gutter-row" span={24} xs={8} xl={24}>
                        <Form.Item name="formCheckboxes">
                            <Checkbox.Group
                                options={checkBoxesOptions}
                                onChange={checkBoxesOnChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="licensePlate"
                            label="License Plate"
                            // initialValue={vehicle?.LicensePlate}
                        >
                            <AutoComplete placeholder={vehicle! && "ABC123"} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="chassiNumber" label="Chassi Number">
                            <AutoComplete
                                placeholder={
                                    vehicle! && "Specify Chassi Number"
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <>
                    <Row justify="space-around">
                        <Col className="gutter-row" xs={20} xl={10}>
                            <Button
                                onClick={() =>
                                    lookupVehicleByLicensePlate(() => {
                                        return props?.form?.getFieldValue(
                                            "licensePlate"
                                        );
                                    })
                                }
                            >
                                Fetch Vehicle
                            </Button>
                        </Col>
                        <Col className="gutter-row" span={10}></Col>
                    </Row>
                </>

                {formData.map((item) => {
                    return formRow(item);
                })}

                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="vehicleClass" label="Vehicle Class">
                            <Select
                                //onChange={}
                                allowClear
                            >
                                <Option value="PB">Private Vehicle</Option>
                                <Option value="TB">
                                    Transportation Vehicle
                                </Option>
                                <Option value="LB">Truck</Option>
                                <Option value="A">Other</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="acquisitionDate"
                            label="Date of acquisition"
                        >
                            <DatePicker
                                defaultValue={vehicle?.ParsedAcquisitionDate}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {formDataOdometer.map((item) => {
                    return formRow(item);
                })}

                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="latestService" label="Latest Service">
                            <DatePicker
                                defaultValue={
                                    vehicle?.ParsedLastMaintenanceDate
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="serviceOdometer"
                            label="Service Odometer"
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="latestInspection"
                            label="Latest Inspection"
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="sellPrice" label="Sell Price">
                            <InputNumber defaultValue={vehicle?.Sellprice} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="sellDate" label="Sell Date">
                            <DatePicker />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="vehicleTemplate"
                            label="Vehicle Template"
                        >
                            <Select allowClear defaultValue={"TODO"}>
                                <Option value="TODO">TODO</Option>
                                <Option value="TODO">TODO</Option>
                                <Option value="TODO">TODO</Option>
                                <Option value="TODO">TODO</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="costTemplate" label="Cost Template">
                            <Select
                                allowClear
                                defaultValue={vehicle?.CostTemplate?.Name}
                            >
                                <Option value="TODO">TODO</Option>
                                <Option value="TODO">TODO</Option>
                                <Option value="TODO">TODO</Option>
                                <Option value="TODO">TODO</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="serviceTemplate"
                            label="Service Template"
                        >
                            <Select allowClear>
                                {/* TODO get all available service templates */}
                                {vehicle?.ServiceTemplates?.map(
                                    (template: any, index: number) => {
                                        return (
                                            <Option value={template.IdTemplate}>
                                                {template.Name}
                                            </Option>
                                        );
                                    }
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="changeTemplate"
                            label="Change Template"
                        >
                            <Select allowClear>
                                {/* TODO get all available Change templates */}
                                <Option value="TODO">TODO</Option>
                                <Option value="TODO">TODO </Option>
                                <Option value="TODO">TODO</Option>
                                <Option value="TODO">TODO</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="status" label="Status">
                            <Select allowClear>
                                <Option value="PB">TODO</Option>
                                <Option value="TB">TODO</Option>
                                <Option value="LB">TODO</Option>
                                <Option value="A">TODO</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="bookable" label="Bookable">
                            <Select allowClear>
                                <Option value="PB">TODO</Option>
                                <Option value="TB">TODO</Option>
                                <Option value="LB">TODO</Option>
                                <Option value="A">TODO</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item name="defaultDriver" label="Default Driver">
                            <Select allowClear>
                                <Option value="TODO">TODO</Option>
                                <Option value="TB">TODO</Option>
                                <Option value="LB">TODO</Option>
                                <Option value="A">TODO</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="vehicleManager"
                            label="Vehicle Manager"
                        >
                            <Select allowClear>
                                <Option value="PB">TODO</Option>
                                <Option value="TB">TODO</Option>
                                <Option value="LB">TODO</Option>
                                <Option value="A">TODO</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={10} xs={24} xl={10}>
                        <Form.Item
                            name="responsibilityNumber"
                            label="Responsibility Number"
                        >
                            <AutoComplete
                                placeholder={
                                    vehicle.VehicleGroup! &&
                                    "Specify Responsibility Number"
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {props.formType !== "create" ? (
                    <Row justify="center">
                        <Col className="gutter-row" span={20}>
                            <Form.Item name="companyId" label="Organisation">
                                <>
                                    {currentOrganisationPath.length > 0 && (
                                        <Cascader
                                            defaultValue={
                                                currentOrganisationPath
                                            }
                                            options={organisationOptions}
                                            changeOnSelect
                                            // value={currentOrgsPath}
                                            // onChange={onChange}
                                        />
                                    )}
                                </>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row justify="center">
                        <Col className="gutter-row" span={20}>
                            <Form.Item name="companyId" label="Organisation">
                                <>
                                    <Cascader
                                        options={organisationOptions}
                                        changeOnSelect
                                        // onChange={onChange}
                                    />
                                </>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                {props.hasFormButtons && (
                    <Row justify="start" className="search-form-row">
                        <Col className="gutter-row" span={10}></Col>

                        <Col className="gutter-row" span={10}>
                            <Row justify="end" className="search-form-row">
                                <Col className="gutter-row" xs={5} xl={10}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {
                                                {
                                                    update: <>Update</>,
                                                    create: <>Create</>,
                                                    delete: <>Delete</>,
                                                }[
                                                    props.formType
                                                        ? props.formType
                                                        : "update"
                                                ]
                                            }
                                        </Button>
                                    </Form.Item>
                                </Col>
                                {props.formType != "create" && (
                                    <Col className="gutter-row" xs={5} xl={10}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                danger
                                                onClick={() =>
                                                    vehicleActions.deleteVehicle(
                                                        props.vehicleId,
                                                        (res: any) => {
                                                            console.log(
                                                                "vehicleReducer response deleteVehicle: ",
                                                                res
                                                            );
                                                        }
                                                    )(dispatch)
                                                }
                                            >
                                                Remove
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                )}
            </Form>
        </>
    );
};

// export default VehicleForm;
const mapStateToProps = (state: {
    widgets: {
        selectedMenu: any;
        allFuncMenu: any;
        submenukey: any;
        submenutitle: any;
        selectedSubmenu: any;
        selectedFuncTitle: any;
    };
    login: { loginUser: any };
}) => {
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        loggedInUser: state.login.loginUser,
    };
};

export default connect(mapStateToProps)(VehicleForm);
