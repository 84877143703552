
import axios from "axios";
//https://cors-anywhere.herokuapp.com/
/*
const instance = axios.create({
    baseURL: 'https://au2box.com/api/app/',
    headers: {'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }//    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Credentials':true
});
*/

const instance = axios.create({
    baseURL: 'https://au2server.com/api/app/',
    headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://au2server.com'
    }
});

// const instance = axios.create({
//     baseURL: 'https://devint.au2server.com/api/app/',
//     headers: { 
//         'Access-Control-Allow-Methods' : '*', 
//         'Access-Control-Allow-Origin': '*',
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Credentials':true,
//     }
// });



export default instance;