import {renderFormatMsg} from "../common/function";
import {
    CarOutlined,
    CalendarOutlined,
    UserSwitchOutlined,
    EnvironmentOutlined,
    ClockCircleOutlined,
    DollarOutlined,
    AreaChartOutlined,
    FileTextOutlined,
    EyeOutlined,
    FileDoneOutlined,
    ToolOutlined,
    LaptopOutlined,
    DashboardOutlined,
    DownOutlined,
    SettingOutlined
} from "@ant-design/icons";
import React, {Component} from "react";

export const MenuList = [
    {
        title: renderFormatMsg("menu.vehicle", "Vehicle"),
        icon: <CarOutlined/>,
        key: "sub1",
        submenu: [
            {
                subTitle: renderFormatMsg("menu.vehicle.vehicleStatus", "Vehicle status"),
                favorite: false,
                key: "6",
                urlpath: "/vehicle/status"
            },
            { 
                subTitle: renderFormatMsg(
                    "menu.vehicle.searchVehicles",
                    "Search vehicles"
                ),
                favorite: false,
                key: "7",
                urlpath: "/vehicle/search"
            },
            /*{
                subTitle: renderFormatMsg(
                    "menu.vehicle.Currentmeterreadings",
                    "Current meter readings"
                ),
                favorite: false,
                key: "8",
                urlpath: "/vehicle/currentmeterreadings"
            },
            {
                subTitle: renderFormatMsg(
                    "menu.vehicle.Batterystatus",
                    "Battery status"
                ),
                favorite: false,
                key: "9",
                urlpath: "/vehicle/batterystatus"
            },*/
            // {
            //     subTitle: renderFormatMsg("menu.vehicle.Refueling", "Refueling"),
            //     favorite: false,
            //     key: "10",
            //     urlpath: "/vehicle/refueling"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.vehicle.Inspections",
            //         "Inspections"
            //     ),
            //     favorite: false,
            //     key: "11",
            //     urlpath: "/vehicle/inspections"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.vehicle.Vehicleservice",
            //         "Vehicle service"
            //     ),
            //     favorite: false,
            //     key: "12",
            //     urlpath: "/vehicle/service"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.vehicle.Vehiclereplacement",
            //         "Vehicle replacement"
            //     ),
            //     favorite: false,
            //     key: "13",
            //     urlpath: "/vehicle/replacement"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.vehicle.Vehicleconfiguration",
            //         "Vehicle configuration"
            //     ),
            //     favorite: false,
            //     key: "15",
            //     urlpath: "/vehicle/configuration"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.vehicle.CO2emissions",
            //         "CO2 emissions"
            //     ),
            //     favorite: false,
            //     key: "16",
            //     urlpath: "/vehicle/co2"
            // },
            {
                subTitle: renderFormatMsg(
                    "menu.vehicle.Notes",
                    "Vehicle Notes"
                ),
                favorite: false,
                key: "116",
                urlpath: "/vehicle/notes"
            }
        ]
    },
    /*{
        title: renderFormatMsg("menu.vehiclecard", "Vehicle card"),
        icon: <CalendarOutlined/>,
        key: "sub2",
        submenu: [
            {
                subTitle: renderFormatMsg(
                    "menu.vehiclecard.Vehicleoverview",
                    "Vehicle overview"
                ),
                favorite: false,
                key: "17",
                urlpath: "/vehiclecard/overview/10"
            },
            {
                subTitle: renderFormatMsg(
                    "menu.vehiclecard.Basicdata",
                    "Basic data"
                ),
                favorite: false,
                key: "18",
                urlpath: "/vehiclecard/basic"
            },
            {
                subTitle: renderFormatMsg("menu.vehiclecard.Economy", "Economy"),
                favorite: false,
                key: "19",
                urlpath: "/vehiclecard/economy"
            },
            {
                subTitle: renderFormatMsg(
                    "menu.vehiclecard.Maintenance",
                    "Maintenance"
                ),
                favorite: false,
                key: "20",
                urlpath: "/vehiclecard/maintenance"
            },
            {
                subTitle: renderFormatMsg("menu.vehiclecard.System", "System"),
                favorite: false,
                key: "21",
                urlpath: "/vehiclecard/system"
            },
            {
                subTitle: renderFormatMsg("menu.vehiclecard.Notes", "Notes"),
                favorite: false,
                key: "22",
                urlpath: "/vehiclecard/notes"
            },
            {
                subTitle: renderFormatMsg(
                    "menu.vehiclecard.Certificatetravel",
                    "Certificate travel"
                ),
                favorite: false,
                key: "23",
                urlpath: "/vehiclecard/certificatetravel"
            }
        ]
    },*/
    {
        title: renderFormatMsg("menu.driver", "Driver"),
        icon: <UserSwitchOutlined/>,
        key: "sub3",
        submenu: [
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.driver.Latestidentifications",
            //         "Latest identifications"
            //     ),
            //     favorite: false,
            //     key: "24",
            //     urlpath: "/driver/lastestIdentifications"
            // },
            {
                subTitle: renderFormatMsg(
                    "menu.driver.Driveroverview",
                    "Driver overview"
                ),
                favorite: false,
                key: "25",
                urlpath: "/driver/overview"
            },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.driver.Driveranalysis",
            //         "Driver analysis"
            //     ),
            //     favorite: false,
            //     key: "26",
            //     urlpath: "/driver/analysis"
            // }
        ]
    },
    {
        title: renderFormatMsg("menu.positions", "Positions"),
        icon: <EnvironmentOutlined/>,
        key: "sub4",
        submenu: [
            {
                subTitle: renderFormatMsg(
                    "menu.positions.Currentpositions",
                    "Current positions"
                ),
                favorite: false,
                key: "27",
                urlpath: "/positions/current"
            },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.positions.Findthenearestvehicle",
            //         "Find the nearest vehicle"
            //     ),
            //     favorite: false,
            //     key: "28",
            //     urlpath: "/positions/findnearest"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.positions.Listofzones",
            //         "List of zones"
            //     ),
            //     favorite: false,
            //     key: "30",
            //     urlpath: "/positions/zones"
            // }
        ]
    },
    {
        title: renderFormatMsg("menu.travel", "Travel"),
        icon: <DashboardOutlined/>,
        key: "sub5",
        submenu: [
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.travel.Ongoingtravel",
            //         "Ongoing travel"
            //     ),
            //     favorite: false,
            //     key: "31",
            //     urlpath: "/travel/ongoing"
            // },
            {
                subTitle: renderFormatMsg(
                    "menu.travel.Completedtrips",
                    "Completed trips"
                ),
                favorite: false,
                key: "32",
                subSubMenu: true
            },
            // {
            //     subTitle: renderFormatMsg("menu.travel.Travellog", "Travel log"),
            //     favorite: false,
            //     key: "33",
            //     urlpath: "/travels/travellog"
            // },
            {
                subTitle: renderFormatMsg(
                    "menu.travel.Congestiontax",
                    "Congestion tax"
                ),
                favorite: false,
                key: "34",
                urlpath: "/travels/congestiontax"
            },
            // {
            //     subTitle: renderFormatMsg("menu.travel.Travelstop", "Travel stop"),
            //     favorite: false,
            //     key: "35",
            //     urlpath: "/tavels/stop"
            // // },
            {
                subTitle: renderFormatMsg(
                    "menu.travel.Travelpurposerules",
                    "Travel purpose rules"
                ),
                favorite: false,
                key: "36",
                urlpath: "/travels/purposerules"
            },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.travel.Autoexportdrivingrecords",
            //         "Autoexport driving records"
            //     ),
            //     favorite: false,
            //     key: "37",
            //     urlpath: "/travels/autoexport"
            // },
        ]
    },
    // {
    //     title: renderFormatMsg("menu.reserve", "Reserve"),
    //     icon: <FileDoneOutlined/>,
    //     key: "sub6",
    //     submenu: [
    //         {
    //             subTitle: renderFormatMsg("menu.reserve.Vehicle", "Vehicle"),
    //             favorite: false,
    //             key: "41",
    //             urlpath: "/reserve/vehicle"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.reserve.Vehicleservice",
    //                 "Vehicle service"
    //             ),
    //             favorite: false,
    //             key: "42",
    //             urlpath: "/reserve/vehicleservice"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.reserve.Bookotherusers",
    //                 "Book other users"
    //             ),
    //             favorite: false,
    //             key: "43",
    //             urlpath: "/reserve/bookotheruser"
    //         }
    //     ]
    // },
    // {
    //     title: renderFormatMsg("menu.alarm", "Alarm"),
    //     icon: <ClockCircleOutlined/>,
    //     key: "sub7",
    //     submenu: [
    //         {
    //             subTitle: renderFormatMsg("menu.alarm.Devicealarm", "Device alarm"),
    //             favorite: false,
    //             key: "44",
    //             urlpath: "/alarm/device"
    //         },
    //         {
    //             subTitle: renderFormatMsg("menu.alarm.Driveralarm", "Driver alarm"),
    //             favorite: false,
    //             key: "45",
    //             urlpath: "/alarm/driver"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.alarm.Servicealarm",
    //                 "Service alarm"
    //             ),
    //             favorite: false,
    //             key: "46",
    //             urlpath: "/alarm/service"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.alarm.Exchangealarm",
    //                 "Exchange alarm"
    //             ),
    //             favorite: false,
    //             key: "47",
    //             urlpath: "/alarm/exchange"
    //         },
    //         {
    //             subTitle: renderFormatMsg("menu.alarm.Zonlarm", "Zonlarm"),
    //             favorite: false,
    //             key: "48",
    //             urlpath: "/alarm/zon"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.alarm.Speed​​alarm",
    //                 "Speed ​​alarm"
    //             ),
    //             favorite: false,
    //             key: "49",
    //             urlpath: "/alarm/speed"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.alarm.Receiverofalarms",
    //                 "Receiver of alarms"
    //             ),
    //             favorite: false,
    //             key: "50",
    //             urlpath: "/alarm/receiver"
    //         }
    //     ]
    // },
    // {
    //     title: renderFormatMsg("menu.maintenance", "Maintenance"),
    //     icon: <ToolOutlined/>,
    //     key: "sub8",
    //     submenu: [
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.maintenance.Completedmaintenance",
    //                 "Completed maintenance"
    //             ),
    //             favorite: false,
    //             key: "51",
    //             urlpath: "/maintenance/completed"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.maintenance.Maintenancework",
    //                 "Maintenance work"
    //             ),
    //             favorite: false,
    //             key: "52",
    //             urlpath: "/maintenance/work"
    //         }
    //     ]
    // },
    // {
    //     title: renderFormatMsg("menu.economy", "Economy"),
    //     icon: <DollarOutlined/>,
    //     key: "sub9",
    //     submenu: [
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.economy.Totalcostofownership(TCO)",
    //                 "Total cost of ownership (TCO)"
    //             ),
    //             favorite: false,
    //             key: "53",
    //             urlpath: "/economy/tco"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.economy.Currentmonthlycost",
    //                 "Current monthly cost"
    //             ),
    //             favorite: false,
    //             key: "54",
    //             urlpath: "/economy/currentmonthlycost"
    //         },
    //         {
    //             subTitle: renderFormatMsg("menu.economy.Leasing", "Leasing"),
    //             favorite: false,
    //             key: "55",
    //             urlpath: "/economy/leasing"
    //         },
    //         {
    //             subTitle: renderFormatMsg("menu.economy.Fuel", "Fuel"),
    //             favorite: false,
    //             key: "56",
    //             urlpath: "/economy/fuel"
    //         }
    //     ]
    // },
    {
        title: renderFormatMsg("menu.analyzes", "Analyzes"),
        icon: <AreaChartOutlined/>,
        key: "sub10",
        submenu: [
            {
                subTitle: renderFormatMsg(
                    "menu.analyzes.Degreeofutilization",
                    "Degree of utilization"
                ),
                favorite: false,
                key: "57",
                urlpath: "/analyzes/utilization"
            },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.analyzes.Exportanalyzes",
            //         "Export analyzes"
            //     ),
            //     favorite: false,
            //     key: "58",
            //     urlpath: "/analyzes/export"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.analyzes.Speeds(ISA)",
            //         "Speeds (ISA)"
            //     ),
            //     favorite: false,
            //     key: "59",
            //     urlpath: "/analyzes/isa"
            // },
            // {
            //     subTitle: renderFormatMsg("menu.analyzes.Fuel", "Fuel"),
            //     favorite: false,
            //     key: "60",
            //     urlpath: "/analyzes/fuel"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.analyzes.Fuel-import",
            //         "Fuel-import"
            //     ),
            //     favorite: false,
            //     key: "61",
            //     urlpath: "/analyzes/fuelimport"
            // },
            // {
            //     subTitle: renderFormatMsg(
            //         "menu.analyzes.Drivingbehavior",
            //         "Driving behavior"
            //     ),
            //     favorite: false,
            //     key: "62",
            //     urlpath: "/analyzes/drivingbehavior"
            // }
        ]
    },
    // {
    //     title: renderFormatMsg("menu.reportTemplates", "Report templates"),
    //     icon: <FileTextOutlined/>,
    //     key: "sub11",
    //     submenu: [
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.reportTemplates.Createyourownreports",
    //                 "Create your own reports"
    //             ),
    //             favorite: false,
    //             key: "63",
    //             urlpath: "/reports/createownreport"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.reportTemplates.Reports",
    //                 "Reports"
    //             ),
    //             favorite: false,
    //             key: "64",
    //             urlpath: "/reports/reports"
    //         },
    //         {
    //             subTitle: renderFormatMsg(
    //                 "menu.reportTemplates.Autoexportreports",
    //                 "Autoexport reports"
    //             ),
    //             favorite: false,
    //             key: "65",
    //             urlpath: "/reports/autoexport"
    //         }
    //     ]
    // },
    {
        title: renderFormatMsg("menu.system", "System"),
        icon: <LaptopOutlined/>,
        key: "sub12",
        submenu: [
            {
                subTitle: renderFormatMsg("menu.system.Overview", "Overview"),
                favorite: false,
                key: "66",
                urlpath: "/system/overview"
            },
            {
                subTitle: renderFormatMsg("menu.system.Details", "Details"),
                favorite: false,
                key: "67",
                urlpath: "/system/details"
            },
            {
                subTitle: renderFormatMsg("menu.system.Activate", "Activate"),
                favorite: false,
                key: "68",
                urlpath: "/system/activate"
            },
            {
                subTitle: renderFormatMsg("menu.system.Boxalarm", "Box alarm"),
                favorite: false,
                key: "69",
                urlpath: "/system/boxalarm"
            }
        ]
    },
    {
        title: "Admin",
        icon: <SettingOutlined/>,
        key: "sub13",
        submenu: [
            {
                subTitle: renderFormatMsg("menu.admin.Permissions", "Permissions"),
                favorite: false,
                key: "80",
                urlpath: "/admin/accesstemplate"
            },
            {
                subTitle: renderFormatMsg("menu.admin.Organisation", "Organisation"),
                favorite: false,
                key: "81",
                urlpath: "/admin/organisation"
            }, 
            {
                subTitle: renderFormatMsg("menu.admin.User", "User"),
                favorite: false,
                key: "82",
                urlpath: "/admin/users"
            },
            {
                //subTitle: renderFormatMsg("menu.admin.User", "User"),
                subTitle:"Data",
                favorite: false,
                key: "83",
                urlpath: "#"
            },
            {
                //subTitle: renderFormatMsg("menu.admin.User", "User"),
                subTitle:"Templates",
                favorite: false,
                key: "84",
                urlpath: "#"
            },
            {
                //subTitle: renderFormatMsg("menu.admin.User", "User"),
                subTitle:"Register Vehicle",
                favorite: false,
                key: "85",
                urlpath: "/admin/registervehicle"
            },
            {
                //subTitle: renderFormatMsg("menu.admin.User", "User"),
                subTitle:"User log",
                favorite: false,
                key: "86",
                urlpath: "/admin/userlog"
            }
        ]
    },
];