import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    Table,
    Button,
    Row,
    Col,
    Modal,
    Affix,
    Popconfirm,
    List,
    Card,
} from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import Header from "../../../components/breadcrumbheader/Header";
import { useHistory } from "react-router-dom";
import { nNotis } from "../../../components/common/function";
import Accessform from "../../../components/forms/accesstemplate/accessfrom";
import * as templateActions from "../../../actions/templates/templates";
import * as accessActions from "../../../actions/access/access";

const AccessTemplates = (props) => {
    const dispatch = useDispatch();
    const routes = [
        {
            path: "",
            breadcrumbName: "Permissions",
        },
        {
            path: "",
            breadcrumbName: "Access Template",
        },
    ];
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const accessTemplates = useSelector(
        (state) => state.templates.accessTemplates
    );

    const [selectedRecord, setSelectedRecord] = useState({});

    const showModal = (accessTemplate = null) => {
        if (accessTemplate) {
            console.log(
                "showModal with accessTemplateId: ",
                accessTemplate.TemplateId
            );
            setSelectedRecord(accessTemplate);
        } else {
            setSelectedRecord({});
        }

        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        console.log("useEffect has been called! will now get ");
        templateActions.getAccessTemplates()(dispatch);
    }, []);

    const modalFooter = (formName) => {
        if (!!Object.keys(selectedRecord).length && selectedRecord) {
            return [
                <Button
                    onClick={() => {
                        console.log(
                            "delete this accesstemplate selectedRecord.TemplateId",
                            selectedRecord.TemplateId
                        );
                        accessActions.deleteAccessTemplate(
                            selectedRecord.TemplateId,
                            () => {
                                nNotis(
                                    "success",
                                    "top",
                                    `Successfully removed Access Template`,
                                    3,
                                    "Removed Access Template"
                                );
                                handleCancel();
                            },
                            () => {
                                nNotis(
                                    "error",
                                    "top",
                                    `Could not remove Access Template; try again later or contact support`,
                                    3,
                                    "Something went wrong when trying to remove Access Template"
                                );
                            }
                        )(dispatch);
                    }}
                    danger
                >
                    Remove
                </Button>,
                <Button
                    type="primary"
                    htmlType="submit"
                    form={formName ? formName : "accessTemplateForm"}
                >
                    Update
                </Button>,
            ];
        } else {
            return [
                <Button
                    type="primary"
                    htmlType="submit"
                    form={formName ? formName : "accessTemplateForm"}
                >
                    Create
                </Button>,
            ];
        }
    };

    return (
        <>
            <div className="functionPagediv">
                <Affix
                    offsetTop={220}
                    onChange={(affixed) => console.log(affixed)}
                    style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
                >
                    <p>
                        <Button
                            size="large"
                            type="primary"
                            onClick={() => showModal()}
                            icon={<FileAddOutlined />}
                        ></Button>
                    </p>
                </Affix>

                {accessTemplates.length > 0 && (
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 6,
                            xxl: 3,
                        }}
                        dataSource={accessTemplates}
                        renderItem={(item) => (
                            <List.Item style={{}}>
                                <Card
                                    title={item.Name}
                                    style={{
                                        boxShadow: "5px 5px 10px 5px lightgray",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Row justify="center">
                                        <Col>
                                            <Button
                                                size="middle"
                                                type="primary"
                                                style={{}}
                                                onClick={() => showModal(item)}
                                            >
                                                Permissions
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                )}
            </div>
            <Modal
                open={visible}
                title="Access Template"
                onCancel={handleCancel}
                footer={modalFooter("accessTemplateForm")}
                width={1000}
            >
                <Accessform
                    selectedRecord={selectedRecord}
                    modelclose={() => {
                        setVisible(false);
                    }}
                    newCheckedValue={(formData, checkboxVal) => {
                        console.log(
                            "Modal formData ",
                            formData,
                            "checkboxVal ",
                            checkboxVal
                        );
                    }}
                    updateCheckedValue={(formData, allVal) => {
                        console.log("formData ", formData);
                        console.log("allVal", allVal);
                    }}
                />
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    console.log("selected menu mapstatetoprops", state);
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
    };
};

export default connect(mapStateToProps)(AccessTemplates);
