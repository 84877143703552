import React, { useEffect, useRef, useState } from 'react';
import { List, Typography, Card, AutoComplete, Form, Row, Col, Input, Collapse, Button, Select, DatePicker, Drawer } from "antd";
import { Redirect } from 'react-router';
import { SearchOutlined } from "@ant-design/icons";
import "../../assets/css/vehicleDrawer.css"
import { useHistory } from "react-router-dom";
import { vehicleStatusData } from "../common/commonArray"
import { useDispatch } from 'react-redux';
import { SearchedVehiData } from '../../actions';

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;



const VehicleInfoDrawer = (props) => {
    
    return (
        <div>
            <p>Vehicle DATA</p>
            <p>vid: {props.vid}</p>
            <p>Battery Status</p>
            <p>Vehicle Service</p>
            <p>Vehicle Replacement</p>
        </div>
    );
}

export default VehicleInfoDrawer;
