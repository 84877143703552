import React, {
    FC,
    useEffect,
    useState,
    useMemo,
    useReducer,
    useRef,
} from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import * as vehicleActions from "../../actions/vehicles/vehicles";
import {
    GoogleMap,
    useJsApiLoader,
    Marker as GoogleMarker,
    Polyline as GooglePolyline,
    InfoWindow,
} from "@react-google-maps/api";
import {
    GoogleMapsLibraries,
    IMapProps,
    IMarkerProps,
    CMarker,
} from "./data.d";

const center = {
    lat: -3.745,
    lng: -38.523,
};

const GenericMap: FC<IMapProps> = (props) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyA58-rBlwfGOHKU3X99wwccgjC8o0kw7uQ",
        libraries: GoogleMapsLibraries,
    });

    const [googleMap, setGoogleMap] = useState<google.maps.Map>();
    const [isRefreshingMapBounds, setIsRefreshingMapBounds] =
        useState<boolean>(true);

    useEffect(() => {
        if (
            isRefreshingMapBounds &&
            googleMap &&
            props.markers &&
            !!props.markers.length
        ) {
            const bounds = new window.google.maps.LatLngBounds();
            props.markers.forEach((marker: CMarker) => {
                bounds.extend(marker.props.position);
            });
            googleMap.fitBounds(bounds);
        }
    }, [googleMap, props.markers]);

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        setGoogleMap(map);
    }, []);

    const onDragEnd = () => {
        setIsRefreshingMapBounds(false);
    };

    const containerStyle = {
        width: props?.mapContainerHeight || "94%",
    };

    return (
        <>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{
                        ...containerStyle,
                        position: "absolute",
                        height: "91%",
                    }}
                    onLoad={onLoad}
                    center={center}
                    zoom={10}
                    onDragEnd={onDragEnd}
                    onClick = {props?.onClick}
                >
                    <>
                        {props.markers && !!props.markers.length && (
                            <>
                                {props.markers.map(
                                    (marker: IMarkerProps) => marker
                                )}
                            </>
                        )}
                    </>
                </GoogleMap>
            ) : null}
        </>
    );
};

export default React.memo(GenericMap);
