import React, { useState, useEffect, useMemo } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import CustomCheckboxGroup from "./CustomCheckboxGroup";
import { useDispatch, useSelector } from "react-redux";
import { nNotis } from "../../common/function";

import * as accessActions from "../../../actions/access/access";

const Accessform = (props) => {
    const [form] = Form.useForm();
    const [checkedRecord, setCheckedRecord] = useState([]);
    const dispatch = useDispatch();

    const accesses = useSelector((state) => state.access.accesses);
    const checkBoxAccesses = useSelector(
        (state) => state.access.checkBoxAccesses
    );
    const selectedRecordMemo = useMemo(() => props.selectedRecord, []);

    useEffect(() => {
        accessActions.getAccesses()(dispatch);

        console.log("useEffect accesses ", accesses);
    }, []);

    useEffect(() => {
        resetForm();
        if (
            !!Object.keys(props.selectedRecord).length &&
            props.selectedRecord != ""
        ) {
            console.log(
                "useEffect props.selectedRecord ",
                props.selectedRecord
            );
            form.setFieldsValue({
                Id: props.selectedRecord.TemplateId,
                Name: props.selectedRecord.Name,
            });

            const checkedAccessesList = Object.values(
                props.selectedRecord.Accesses
            )
                .flat()
                .map((access) => {
                    return access.Id;
                });
            console.log(
                "useEffect props.selectedRecord checkedAccessesList ",
                props.selectedRecord
            );
            setCheckedRecord(checkedAccessesList);
        } else {
            console.log(
                "useEffect props.selectedRecord setCheckedRecord([])",
                props.selectedRecord
            );
            setCheckedRecord([]);
        }
    }, [selectedRecordMemo, props?.selectedRecord?.TemplateId]);

    //TODO parse
    const parseToAccessTemplate = (accessCheckBoxesValues, accesses) => {
        return accessCheckBoxesValues;
    };

    const onFinish = (values) => {
        console.log("onFinish values", values);

        //Update
        if (values.Id !== undefined) {
            onUpdate(values);

            //Create
        } else {
            console.log("onFinish accesses", accesses);
            onCreate(values);
        }
    };

    const onCreate = (values) => {
        //TODO validate more within form item
        console.log(" onFinish onCreate values ", values);

        //API
        //TODO parse to api format
        // accesses && !!Object.keys(accesses).length &&
        accessActions.createAccessTemplate(
            parseToAccessTemplate(values, accesses),
            onCreateSuccess,
            onCreateFail
        )(dispatch);
    };

    //UI oncreate
    const onCreateSuccess = (values) => (response) => {
        console.log(
            "onFinish onCreateSuccess values response",
            values,
            response
        );

        form.resetFields();
        props.newCheckedValue(values, checkBoxAccesses);
        props.modelclose(false);

        return nNotis(
            "success",
            "top",
            `Successfully created Access Template`,
            3,
            "Created Access Template"
        );
    };

    const onCreateFail = () => {
        return nNotis(
            "error",
            "top",
            `Could not create Access Template; try again later or else contact support`,
            7,
            "Something went wrong"
        );
    };

    const onUpdate = (values) => {
        console.log("onfinish onUpdate values ", values);

        accessActions.updateAccessTemplate(
            values,
            () => {
                //TODO update UI
                //UI
                props.updateCheckedValue(values, checkBoxAccesses);
                props.modelclose(false);

                nNotis(
                    "success",
                    "top",
                    `Successfully updated access template`,
                    3,
                    "Updated Access Template"
                );
            },
            () => {
                nNotis(
                    "error",
                    "top",
                    `Could not update access template; try again later or contact support`,
                    6,
                    "Something went wrong"
                );
            }
        )(dispatch);
    };

    const deleteAccessTemplateCallback = () => {
      console.log("deleteAccessTemplateCallback")
    }

    const resetForm = () => {
        // console.log("resetform");
        form.resetFields();
    };

    const insertDataHandler = (formData) => {
        for (let index = 0; index < checkBoxAccesses.length; index++) {
            const element = checkBoxAccesses[index];
            if (element.value == formData.parentId) {
                const newElement = {
                    name: formData.parenTitle,
                    value: formData.parentId,
                    checked: formData.checkAll,
                    underbits: formData.checkedList,
                };
                checkBoxAccesses[index] = newElement;
            }
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            name={props.formName ? props.formName : "accessTemplateForm"}
            id={props.formId ? props.formId : "accessTemplateForm"}
        >
            <Col span={10}>
                {props.selectedRecord ? (
                    <Form.Item
                        label="Id"
                        name="Id"
                        style={{
                            display: "none",
                        }}
                    >
                        <Input />
                    </Form.Item>
                ) : null}
                <Form.Item
                    name="Name"
                    rules={[
                        {
                            required: true,
                            message: "Please enter access template name!",
                        },
                    ]}
                >
                    <Input placeholder="Access Template Name" />
                </Form.Item>
            </Col>

            {/* //TODO checkrecord dont get updated inside children component*/}
            <Row gutter={16}>
                {console.log("render checkBoxAccesses ", checkBoxAccesses)}
                {checkBoxAccesses &&
                    checkBoxAccesses.map((data) => {
                        console.log("render checkedRecord ", checkedRecord);
                        console.log("render data.underbits ", data.underbits);
                        console.log("render data.name ", data.name);
                        return (
                            <CustomCheckboxGroup
                                mainTitle={data.name}
                                values={data.underbits}
                                key={data.name}
                                groupState={insertDataHandler}
                                newCheckedValue={insertDataHandler}
                                updateCheckedValue={insertDataHandler}
                                pid={data.value}
                                checkedRecord={checkedRecord}
                                checkeAll={(value, title) => {
                                    for (
                                        let index = 0;
                                        index < checkBoxAccesses.length;
                                        index++
                                    ) {
                                        const element = checkBoxAccesses[index];
                                        if (element.name == title) {
                                            element.checked = value;
                                        }
                                    }
                                }}
                            />
                        );
                    })}
            </Row>
        </Form>
    );
};

export default Accessform;
