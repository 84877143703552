import React, {useState, useEffect, useCallback, memo } from 'react';
import {List, Timeline, Popover, Select, Input, Form, Button} from 'antd';
import {EllipsisOutlined, ClockCircleOutlined, FileOutlined, SearchOutlined, UserOutlined} from '@ant-design/icons';
import {FaMapMarkerAlt, FaRoad, FaGasPump, FaPencilAlt, FaUserEdit, FaRoute, FaFile} from 'react-icons/fa';

const {Option} = Select;

//TODO whole page re-render when updating a row in list. Because parent compoennt's data changes. Try using memo and useCallback.
const ListRoutes = ({data, updateRoute, onClick, vehicles, editedTravel}) => {
    console.log("data in ListRoutes: ", data)
    console.log("vehicles in ListRoutes: ", vehicles)
    console.log("editedTravel in ListRoutes: ", editedTravel)

    const [listData, setListData] = useState([])
    
    const filterEmptyVehicles = vehicles.filter((vehicle) => vehicle.Driver != ' ')

    const [state, setState] = useState({
        loading: true,
        page: 0,
        offset: 0
    });

    useEffect(() => {
        setListData(data)
    }, [data])

    const showRoute = (routeID) => {
        onClick(routeID);
    }
    
    const IconText = ({extlib = false, bounce = false, icon, text}) => {
        var antIcon = (extlib !== true ? '' : 'anticon');
        bounce = (bounce === true ? 'bounce' : '');
        var iconClass = ((antIcon && bounce) !== '' ? antIcon + ' ' + bounce : antIcon + bounce);
        return (
            <>
                {React.createElement(icon, {style: {marginRight: 8}, className: iconClass})}
                {text}
            </>
        )
    };

    const updateList = useCallback((routeID, itemObj) => {
        const newList = listData.map((item) => {
            if (item.RouteID === routeID) {
                console.log("item.RouteID === routeID listData: ", routeID)
                const updatedItem = {
                    ...item,
                    'DriverId': itemObj.driverId ? itemObj.driverId : item.DriverId,
                    'Driver': itemObj.driverId ? getDriverNameByDriverId(itemObj.driverId) : item.Driver,
                    'Purpose': itemObj.travelPurpose ? itemObj.travelPurpose : item.Purpose, 
                };
                return updatedItem;
            }
            return item;
        });
        console.log("newList in listData: ", newList)
        setListData(newList);
    }, [])
    
    const selectType = useCallback((routeID, currentRouteType, driverId, travelPurpose) => {
        console.log("selectType driverId: ", driverId)
        
        return (
            <Select placeholder="Choose route type" showSearch defaultValue={currentRouteType} style={{width: '100%'}}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value) => {updateRoute(routeID, {'routeTypeId':value})}}>
                <Option value="0">Business Trip</Option>
                <Option value="1">Private Trip</Option>
                <Option value='3'>Refuel</Option>
                <Option value="6">Customer Trip</Option>
            </Select>
        )
    }, [])

    
    const selectDriver = (routeID, routeTypeId, currentDriverName, travelPurpose) => {
        return (
            <Select placeholder="Choose Driver" showSearch defaultValue={currentDriverName} style={{width: '100%'}}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value) => {updateList(routeID, {'driverId': value}) ; updateRoute(routeID, {'driverId': value, 'driverName': getDriverNameByDriverId(value)}) }}>
                {filterEmptyVehicles.map((vehicle) => ( <><Select.Option key={vehicle.key} value={vehicle.DriverID}>{vehicle.Driver}</Select.Option></>) )}
            </Select>
        )
    }

    const onFinishChangePurpose = useCallback((formValues, routeID) => {
        const newPurpose = formValues.updatePurpose
        console.log("onFinishChangePurpose formValues", formValues)
        console.log("onFinishChangePurpose routeID", routeID)
        updateList(routeID, {'travelPurpose' : newPurpose})
        updateRoute(routeID, {'travelPurpose': newPurpose})
    }, [])

    const changeTravelPurpose = useCallback((currentPurpose, RouteID) => {
        return (
            <>
                <Form onFinish={(values) => onFinishChangePurpose(values, RouteID)}>
                    <Form.Item label='Purpose' name='updatePurpose'>
                        <Input placeholder={currentPurpose} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </>
        )
    }, [])

    const getDriverNameByDriverId = (id) => {
        const matchedVehicle = vehicles.find((vehicle) => {return vehicle.DriverID == id})
        console.log("matchedVehicle: ", matchedVehicle)
        if (matchedVehicle) {
            return matchedVehicle.Driver
        } else {
            return null
        }
    }

    //text={item.Driver && item.Driver != " " ? item.Driver : "Driver's name"}
    /* <Popover placement="top" trigger="click" title={'Change Travel purpose'}
                                content={changeTravelPurpose(item.Purpose, item.RouteID)}>
                            <span><IconText extlib={true} icon={FaFile} 
                                text={"Change Purpose"}
                                        key="list-vertical-message"/></span>      
                    </Popover> */
    
    /*<Popover placement="top" trigger="click" title={'Change Route Type'}
                    content={selectType(item.RouteID, item.RouteType, item.DriverId, item.Purpose)}>
            <span><IconText extlib={true} icon={FaPencilAlt} text={ item.RouteType}
                            key="list-vertical-message"/></span>
        </Popover> */
    return (
        <List
            itemLayout="vertical"
            pagination={{
                onChange: page => {
                    var offset = (page === 1 ? 0 : (page * 3 - 3));
                    setState({page: page, offset: offset})
                },
                defaultPageSize: 3,
            }}
            dataSource={listData}
            renderItem={(item, index) => (
                <List.Item
                    key={item.RouteID}
                    actions={[
                        <IconText extlib={true} icon={FaRoad} text={item.Distance} key="list-vertical-star-o"/>,
                        <IconText extlib={true} icon={FaGasPump} text={item.Consumption} key="list-vertical-like-o"/>,
                        <MemoPopoverRouteType item={item} selectType={selectType}/>,
                        <Popover placement="top" trigger="click" title={'Change Driver'}
                                 content={selectDriver(item.RouteID, item.Type, item.Driver, item.Purpose)}>
                            <span><IconText extlib={true} icon={FaUserEdit} text={ item.Driver && item.Driver != ' ' ? item.Driver : "Add Driver"}
                                            key="list-vertical-message"/></span>      
                        </Popover>,
                        <MemoPopoverPurpose item={item} changeTravelPurpose={changeTravelPurpose} title={'Change Travel purpose'}/>,
                    ]}
                    extra={<span className="point" onClick={() => showRoute(item.RouteID)}><IconText
                        extlib={true} icon={FaMapMarkerAlt} bounce={true} text="Visa" key="show-route"/></span>}
                >
                    <List.Item.Meta
                        title={<span>{item.LicensePlate}</span>}
                        description={<span>{item.ID}</span>}
                    />
                    <Timeline>
                        <Timeline.Item color="green">{item.StartAddress}</Timeline.Item>
                        <Timeline.Item dot={<ClockCircleOutlined/>}>
                            {item.TravelTime}
                        </Timeline.Item>
                        <Timeline.Item dot={<FileOutlined/>}>
                            { item.Purpose && item.Purpose.length > 59 ? item.Purpose.slice(0, 59)+"..." : item.Purpose }
                            {<br></br>}
                        </Timeline.Item>
                        <Timeline.Item color="red" style={{paddingBottom: 0}}>{item.StopAddress}</Timeline.Item>
                    </Timeline>
                </List.Item>
            )}
        />
    );
}

const MemoPopoverPurpose = memo(({item, changeTravelPurpose, title}) => {
    const IconText = useCallback(({extlib = false, bounce = false, icon, text}) => {
        var antIcon = (extlib !== true ? '' : 'anticon');
        bounce = (bounce === true ? 'bounce' : '');
        var iconClass = ((antIcon && bounce) !== '' ? antIcon + ' ' + bounce : antIcon + bounce);
        return (
            <>
                {React.createElement(icon, {style: {marginRight: 8}, className: iconClass})}
                {text}
            </>
        )
    }, []);

    return (
        <Popover placement="top" trigger="click" title={title}
                content={changeTravelPurpose(item.Purpose, item.RouteID)}>
            <span><IconText extlib={true} icon={FaFile} 
                text={"Change Purpose"}
                key="list-vertical-message"/>
            </span>      
        </Popover>
    )
})

const MemoPopoverRouteType = memo(({item, selectType}) => {
    const IconText = useCallback(({extlib = false, bounce = false, icon, text}) => {
        var antIcon = (extlib !== true ? '' : 'anticon');
        bounce = (bounce === true ? 'bounce' : '');
        var iconClass = ((antIcon && bounce) !== '' ? antIcon + ' ' + bounce : antIcon + bounce);
        return (
            <>
                {React.createElement(icon, {style: {marginRight: 8}, className: iconClass})}
                {text}
            </>
        )
    }, []);

    return (
        <Popover placement="top" trigger="click" title={'Change Route Type'}
                    content={selectType(item.RouteID, item.RouteType, item.DriverId, item.Purpose)}>
            <span><IconText extlib={true} icon={FaPencilAlt} text={ item.RouteType}
                            key="list-vertical-message"/></span>
        </Popover>
    )
})




export default ListRoutes;