import React, { Component } from "react";
import { Card, Table, Button, Modal, Form, Input } from "antd";
import translation from "../../translation/index";
import { EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { JapLangData } from "../../actions";
import "../../assets/css/language.css";

class JapaneseLanSetting extends React.Component {
  formRef = React.createRef();

  langColumns = [
    {
      title: "Sr. No",
      dataIndex: "srno",
      key: "srno",
      render: (text, record, index) => (
        <span>
          {(this.state.currentPage - 1) * this.state.pageSize + index + 1}
        </span>
      )
    },
    {
      title: "Language Key",
      dataIndex: "languagekey",
      key: "languagekey"
    },
    {
      title: "Language Value",
      dataIndex: "languagevalue",
      key: "languagevalue"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Button
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          size="small"
          onClick={e => this.clickedit(e, record)}
        />
      )
    }
  ];

  langData = [
    {
      languagekey: "welcomeText",
      languagevalue: "ようこそ ライナス"
    },
    {
      languagekey: "todaysDateText",
      languagevalue: "今日の日付"
    },
    {
      languagekey: "totaNumOfTravels",
      languagevalue: "累積走行数"
    },
    {
      languagekey: "totalTravelsTodayText",
      languagevalue: "今日の走行数"
    },
    {
      languagekey: "totalDrivingDisText",
      languagevalue: "累積走行距離"
    },
    {
      languagekey: "totalDrivingDistodayText",
      languagevalue: "今日の走行距離"
    },
    {
      languagekey: "numOfActiveVehiTodayText",
      languagevalue: "今日の稼働車両"
    },
    {
      languagekey: "numOfTravelsText",
      languagevalue: "走行数"
    },
    {
      languagekey: "totalkmText",
      languagevalue: "合計距離"
    },
    {
      languagekey: "activeVehiText",
      languagevalue: "稼働車両数"
    },
    {
      languagekey: "currentAlramText",
      languagevalue: "現在の警告"
    },
    {
      languagekey: "ongoingTravelsText",
      languagevalue: "継続中の走行"
    },
    { languagekey: "table.CurrentAlarm.Level", languagevalue: "レベル" },
    { languagekey: "table.CurrentAlarm.Time", languagevalue: "時間" },
    { languagekey: "table.CurrentAlarm.Device", languagevalue: "端末" },
    { languagekey: "table.CurrentAlarm.Vehicle", languagevalue: "車両" },
    { languagekey: "table.CurrentAlarm.Type", languagevalue: "タイプ" },
    {
      languagekey: "table.OngoingTravels.Travelid",
      languagevalue: "旅行ID"
    },
    {
      languagekey: "table.OngoingTravels.Licenseplate",
      languagevalue: "ナンバープレート"
    },
    { languagekey: "table.OngoingTravels.Driver", languagevalue: "運転者" },
    { languagekey: "table.OngoingTravels.Start", languagevalue: "開始" },
    {
      languagekey: "table.OngoingTravels.Traveltime",
      languagevalue: "移動時間"
    },
    { languagekey: "table.OngoingTravels.Distance", languagevalue: "距離" },
    { languagekey: "bookedVehiText", languagevalue: "予約済みの車両" },
    {
      languagekey: "addWidgetToDashText",
      languagevalue: "ダッシュボードにウィジェットを追加する"
    },
    { languagekey: "widgetsText", languagevalue: "ウィジェット" },
    {
      languagekey: "bookedVehicleDes",
      languagevalue: "予約した車両を表示する"
    },
    {
      languagekey: "tableCurrentAlarmDes",
      languagevalue: "現在のアラームの表"
    },
    {
      languagekey: "tableOngoingTravelsDes",
      languagevalue: "進行中の旅行の表"
    },
    {
      languagekey: "chartNumOfTravelsDes",
      languagevalue: "旅行数の棒グラフ"
    },
    {
      languagekey: "chartTotalKmDes",
      languagevalue: "合計（km）の棒グラフ"
    },
    {
      languagekey: "chartActiveVehicleDes",
      languagevalue: "アクティブな車両の棒グラフ"
    },
    {
      languagekey: "staticTodaysDateDes",
      languagevalue: "今日の日付を表示する"
    },
    {
      languagekey: "staticTotalNumOfTravelsDes",
      languagevalue: "旅行の総数を表示する"
    },
    {
      languagekey: "staticTotalTravelsTodayDes",
      languagevalue: "今日の総旅行数を表示する"
    },
    {
      languagekey: "staticTotalDrivingDisDes",
      languagevalue: "総走行距離を表示"
    },
    {
      languagekey: "staticTotalDrivingDisTodayDes",
      languagevalue: "今日の総走行距離を表示（Km）"
    },
    {
      languagekey: "staticNumOfActiveVehiTodayDes",
      languagevalue: "今日のアクティブな車両の数を表示します"
    },
    { languagekey: "addWidgetsBtn", languagevalue: "ウィジェットを追加する" },
    { languagekey: "changeLayoutBtn", languagevalue: "レイアウトの変更" },
    { languagekey: "changeDoneBtn", languagevalue: "変更が完了しました" },
    { languagekey: "menu.Dashboard", languagevalue: "ダッシュボード" },
    { languagekey: "menu.vehicle", languagevalue: "車両" },
    {
      languagekey: "menu.vehicle.vehicleStatus",
      languagevalue: "車両状態"
    },
    {
      languagekey: "menu.vehicle.searchVehicles",
      languagevalue: "車両検索"
    },
    {
      languagekey: "menu.vehicle.Currentmeterreadings",
      languagevalue: "現在のメーターの読み"
    },
    {
      languagekey: "menu.vehicle.Batterystatus",
      languagevalue: "バッテリー状態"
    },
    { languagekey: "menu.vehicle.Refueling", languagevalue: "給油" },
    { languagekey: "menu.vehicle.Inspections", languagevalue: "検査" },
    {
      languagekey: "menu.vehicle.Vehicleservice",
      languagevalue: "車両サービス"
    },
    {
      languagekey: "menu.vehicle.Vehiclereplacement",
      languagevalue: "車両交換"
    },
    {
      languagekey: "menu.vehicle.Vehicleconfiguration",
      languagevalue: "車両構成"
    },
    {
      languagekey: "menu.vehicle.CO2emissions",
      languagevalue: "CO2排出量"
    },
    { languagekey: "menu.vehiclecard", languagevalue: "車両カード" },
    {
      languagekey: "menu.vehiclecard.Vehicleoverview",
      languagevalue: "車両概要"
    },
    { languagekey: "menu.vehiclecard.Basicdata", languagevalue: "基本データ" },
    { languagekey: "menu.vehiclecard.Economy", languagevalue: "経済" },
    {
      languagekey: "menu.vehiclecard.Maintenance",
      languagevalue: "メンテナンス"
    },
    { languagekey: "menu.vehiclecard.System", languagevalue: "システム" },
    { languagekey: "menu.vehiclecard.Notes", languagevalue: "ノート" },
    {
      languagekey: "menu.vehiclecard.Certificatetravel",
      languagevalue: "証明書旅行"
    },
    { languagekey: "menu.driver", languagevalue: "運転手" },
    {
      languagekey: "menu.driver.Latestidentifications",
      languagevalue: "最新の識別"
    },
    {
      languagekey: "menu.driver.Driveroverview",
      languagevalue: "ドライバーの概要"
    },
    {
      languagekey: "menu.driver.Driveranalysis",
      languagevalue: "ドライバー分析"
    },
    { languagekey: "menu.positions", languagevalue: "ポジション" },
    {
      languagekey: "menu.positions.Currentpositions",
      languagevalue: "現在の位置"
    },
    {
      languagekey: "menu.positions.Findthenearestvehicle",
      languagevalue: "最寄りの車両を探す"
    },
    {
      languagekey: "menu.positions.Create/editzones",
      languagevalue: "ゾーンの作成/編集"
    },
    {
      languagekey: "menu.positions.Listofzones",
      languagevalue: "ゾーンのリスト"
    },
    { languagekey: "menu.travel", languagevalue: "トラベル" },
    {
      languagekey: "menu.travel.Ongoingtravel",
      languagevalue: "進行中の旅行"
    },
    {
      languagekey: "menu.travel.Completedtrips",
      languagevalue: "完了した旅行"
    },
    { languagekey: "menu.travel.Travellog", languagevalue: "旅行ログ" },
    {
      languagekey: "menu.travel.Congestiontax",
      languagevalue: "混雑税"
    },
    {
      languagekey: "menu.travel.Travelstop",
      languagevalue: "トラベルストップ"
    },
    {
      languagekey: "menu.travel.Travelpurposerules",
      languagevalue: "旅行目的のルール"
    },
    {
      languagekey: "menu.travel.Autoexportdrivingrecords",
      languagevalue: "運転記録の自動エクスポート"
    },
    {
      languagekey: "menu.travel.Manuallogbook",
      languagevalue: "手動航海日誌"
    },
    {
      languagekey: "menu.travel.Changeuser",
      languagevalue: "ユーザーを変更する"
    },
    {
      languagekey: "menu.travel.Certificatetravel",
      languagevalue: "証明書旅行"
    },
    { languagekey: "menu.reserve", languagevalue: "予約する" },
    { languagekey: "menu.reserve.Vehicle", languagevalue: "車両" },
    {
      languagekey: "menu.reserve.Vehicleservice",
      languagevalue: "車両サービス"
    },
    {
      languagekey: "menu.reserve.Bookotherusers",
      languagevalue: "他のユーザーを予約する"
    },
    { languagekey: "menu.alarm", languagevalue: "警報" },
    {
      languagekey: "menu.alarm.Devicealarm",
      languagevalue: "デバイスアラーム"
    },
    {
      languagekey: "menu.alarm.Driveralarm",
      languagevalue: "ドライバーアラーム"
    },
    {
      languagekey: "menu.alarm.Servicealarm",
      languagevalue: "サービスアラーム"
    },
    {
      languagekey: "menu.alarm.Exchangealarm",
      languagevalue: "交換アラーム"
    },
    { languagekey: "menu.alarm.Zonlarm", languagevalue: "私のゾーン" },
    {
      languagekey: "menu.alarm.Speed​​alarm",
      languagevalue: "スピードアラーム"
    },
    {
      languagekey: "menu.alarm.Receiverofalarms",
      languagevalue: "アラームの受信者"
    },
    { languagekey: "menu.maintenance", languagevalue: "メンテナンス" },
    {
      languagekey: "menu.maintenance.Completedmaintenance",
      languagevalue: "メンテナンス完了"
    },
    {
      languagekey: "menu.maintenance.Maintenancework",
      languagevalue: "保守作業"
    },
    { languagekey: "menu.economy", languagevalue: "経済" },
    {
      languagekey: "menu.economy.Totalcostofownership(TCO)",
      languagevalue: "総所有コスト（TCO）"
    },
    {
      languagekey: "menu.economy.Currentmonthlycost",
      languagevalue: "現在の月額費用"
    },
    { languagekey: "menu.economy.Leasing", languagevalue: "リース" },
    { languagekey: "menu.economy.Fuel", languagevalue: "燃料" },
    { languagekey: "menu.analyzes", languagevalue: "分析" },
    {
      languagekey: "menu.analyzes.Degreeofutilization",
      languagevalue: "利用度"
    },
    {
      languagekey: "menu.analyzes.Exportanalyzes",
      languagevalue: "輸出分析"
    },
    {
      languagekey: "menu.analyzes.Speeds(ISA)",
      languagevalue: "スピード（ISA）"
    },
    { languagekey: "menu.analyzes.Fuel", languagevalue: "燃料" },
    { languagekey: "menu.analyzes.Fuel-import", languagevalue: "燃料輸入" },
    {
      languagekey: "menu.analyzes.Drivingbehavior",
      languagevalue: "運転行動"
    },
    {
      languagekey: "menu.reportTemplates",
      languagevalue: "レポートテンプレート"
    },
    {
      languagekey: "menu.reportTemplates.Createyourownreports",
      languagevalue: "独自のレポートを作成する"
    },
    { languagekey: "menu.reportTemplates.Reports", languagevalue: "レポート" },
    {
      languagekey: "menu.reportTemplates.Autoexportreports",
      languagevalue: "自動エクスポートレポート"
    },
    { languagekey: "menu.system", languagevalue: "システム" },
    { languagekey: "menu.system.Overview", languagevalue: "概要概要" },
    { languagekey: "menu.system.Details", languagevalue: "詳細" },
    { languagekey: "menu.system.Activate", languagevalue: "アクティベート" },
    { languagekey: "menu.system.Boxalarm", languagevalue: "ボックスアラーム" },
    {
      languagekey: "menu.system.Feedback/Support",
      languagevalue: "フィードバック/サポート"
    },
    {
      languagekey: "menu.system.Autoexportreports",
      languagevalue: "自動エクスポートレポート"
    },
    { languagekey: "menu.show", languagevalue: "公演" },
    {
      languagekey: "menu.show.HideMapView",
      languagevalue: "マップビューを非表示"
    },
    {
      languagekey: "menu.show.Timelimitontravel",
      languagevalue: "旅行の時間制限"
    },
    {
      languagekey: "menu.show.Showonlyunlockedtrips",
      languagevalue: "ロックされていない旅行のみを表示する"
    },
    {
      languagekey: "menu.show.Showonlyowntrips",
      languagevalue: "自分の旅行のみを表示する"
    },
    { languagekey: "menu.show.Showspeed", languagevalue: "速度を表示" },
    { languagekey: "menu.admin", languagevalue: "管理者" },
    { languagekey: "menu.admin.Organisation", languagevalue: "組織" },
    {
      languagekey: "menu.admin.Organisation.Organizationalstructure",
      languagevalue: "組織構造"
    },
    {
      languagekey: "menu.admin.Organisation.Listorganization",
      languagevalue: "リスト編成"
    },
    {
      languagekey: "menu.admin.Organisation.Createaneworganization",
      languagevalue: "新しい組織を作成する"
    },
    { languagekey: "menu.admin.Permissions", languagevalue: "権限" },
    {
      languagekey: "menu.admin.Permissions.Listofpermissiontemplates",
      languagevalue: "権限テンプレートのリスト"
    },
    {
      languagekey: "menu.admin.Permissions.Createpermissiontemplates",
      languagevalue: "権限テンプレートを作成する"
    },
    { languagekey: "menu.admin.User", languagevalue: "ユーザー" },
    {
      languagekey: "menu.admin.User.Listusers",
      languagevalue: "ユーザーを一覧表示する"
    },
    {
      languagekey: "menu.admin.User.Createusers",
      languagevalue: "ユーザーを作成する"
    },
    { languagekey: "menu.admin.User.Userlog", languagevalue: "ユーザーログ" },
    { languagekey: "menu.admin.Data", languagevalue: "データ" },
    {
      languagekey: "menu.admin.Data.Importvehicles",
      languagevalue: "輸入車"
    },
    {
      languagekey: "menu.admin.Data.Importusers",
      languagevalue: "ユーザーのインポート"
    },
    {
      languagekey: "menu.admin.Data.Importtravelpurposes",
      languagevalue: "旅行目的の輸入"
    },
    { languagekey: "menu.admin.Data.Importfuel", languagevalue: "燃料の輸入" },
    {
      languagekey: "menu.admin.Data.Exportvehicles",
      languagevalue: "輸出車両"
    },
    {
      languagekey: "menu.admin.Backupofdata",
      languagevalue: "データのバックアップ"
    },
    { languagekey: "menu.admin.Templates", languagevalue: "テンプレート" },
    {
      languagekey: "menu.admin.Templates.Listofvehicletemplates",
      languagevalue: "車両テンプレートのリスト"
    },
    {
      languagekey: "menu.admin.Templates.Createvehicletemplate",
      languagevalue: "車両テンプレートを作成する"
    },
    {
      languagekey: "menu.admin.Templates.Listofcosttemplates",
      languagevalue: "コストテンプレートのリスト"
    },
    {
      languagekey: "menu.admin.Templates.Createcosttemplate",
      languagevalue: "コストテンプレートを作成する"
    },
    {
      languagekey: "menu.admin.Templates.Listservicetemplate",
      languagevalue: "リストサービステンプレート"
    },
    {
      languagekey: "menu.admin.Templates.Createservicetemplate",
      languagevalue: "サービステンプレートを作成する"
    },
    {
      languagekey: "menu.admin.Templates.Listvehiclereplacementtemplate",
      languagevalue: "車両交換​​テンプレートのリスト"
    },
    {
      languagekey: "menu.admin.Templates.Createvehiclereplacementtemplate",
      languagevalue: "車両交換​​テンプレートを作成する"
    },
    {
      languagekey: "menu.admin.Registervehicles",
      languagevalue: "車両登録"
    },
    { languagekey: "menu.admin.Userlog", languagevalue: "ユーザーログ" },
    { languagekey: "menu.mySetting", languagevalue: "私の設定" },
    { languagekey: "menu.logOut", languagevalue: "ログアウト" }
  ];

  state = {
    currentPage: "1",
    pageSize: "10",
    showModal: false,
    selectedLangKey: "",
    selectedLangValue: "",
    languageKeyInput: "",
    languageValueInput: "",
    langData: this.langData
  };

  componentDidMount() {
    console.log("this.state.langData", this.state.langData);
  }

  onChange = e => {
    this.setState({ currentPage: e.current, pageSize: e.pageSize });
    console.log("current", e);
  };
  clickedit = (e, record) => {
    console.log("record", record);
    this.setState(
      {
        languageKeyInput: record.languagekey,
        languageValueInput: record.languagevalue
      },
      () => {
        this.setState({
          showModal: true
        });
      }
    );

    this.formRef.current &&
      this.formRef.current.setFieldsValue({
        languageKeyInput: record.languagekey,
        languageValueInput: record.languagevalue
      });
  };

  submitedData = values => {
    console.log("Received values of form: ", values);
    const elementsIndex = this.props.japLangData.findIndex(
      element => element.languagekey == values.languageKeyInput
    );
    translation.jp[values.languageKeyInput] = values.languageValueInput;
    console.log("translation.jp", translation);
    console.log("elementsIndex", elementsIndex);
    let newlangData = [...this.props.japLangData];
    newlangData[elementsIndex] = {
      ...newlangData[elementsIndex],
      languagevalue: values.languageValueInput
    };
    console.log("newlangData", newlangData);
    this.props.dispatch(JapLangData(newlangData));
    this.setState({ showModal: false }, () =>
      console.log("showModal", this.state.showModal)
    );
    this.formRef.current.resetFields();
  };

  handleClose = () => {
    this.setState({ showModal: false });
    this.formRef.current.resetFields();
  };

  render() {
    return (
      <>
        <Card
          title="Japanese language list"
          className="inset-box-shadowed-effect"
        >
          <Table
            columns={this.langColumns}
            dataSource={this.props.japLangData}
            /* pagination={true} */
            onChange={e => this.onChange(e)}
            /* pagination={e => this.onChange(e)} */
          />
        </Card>
        <Modal
          title="Edit Language Value"
          visible={this.state.showModal}
          footer={null}
          /* cancelText="Close"
          okText="Save"
          onOk={this.editHandleOk}*/
          onCancel={this.handleClose}
        >
          <Form
            name="EditLangForm"
            layout="vertical"
            ref={this.formRef}
            initialValues={{
              languageKeyInput: this.state.languageKeyInput,
              languageValueInput: this.state.languageValueInput
            }}
            onFinish={this.submitedData}
          >
            <Form.Item label="Language Key" name="languageKeyInput">
              <Input disabled value={this.state.languageKeyInput} />
            </Form.Item>
            <Form.Item label="Language Value" name="languageValueInput">
              <Input />
            </Form.Item>
            <div className="langmodelbtn">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={this.handleClose}>
                  Close
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  // console.log("state mapstatetoprops", state);
  return {
    japLangData: state.language.japLangData
  };
};

export default connect(mapStateToProps)(JapaneseLanSetting);
