import React, { useState, useEffect } from "react";
import {
    Button,
    Row,
    Col,
    Drawer,
    Divider,
    DatePicker,
    Affix,
    Form,
    Input,
    InputNumber,
    AutoComplete,
    Tooltip,
    Select,
    Cascader,
    notification,
} from "antd";
import * as Apis from "../../../utils/api";
import { nNotis } from "../../common/function";
import { useHistory } from "react-router-dom";

import * as organisationActions from "../../../actions/organisations/organisations";

import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../../global-types";
import { IOrganisationFormProps } from "../data";

import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import { useCascaderOrganisations } from "../../../hooks/useCascaderOrganisations";
import moment from "moment";

//TODO skapa först sen uppdatera, så skapas det :(
//TODO uppdatera först sen ,funkar det inte att skapa.
//TODO only able to move a leaf, or a parent to upwards in tree which the children follows.
const OrganisationForm = (props: IOrganisationFormProps) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const { RangePicker } = DatePicker;

    const apiIsLoading = useSelector(
        (state: IRootState) => state.drivers.loading
    );

    const organisation = useSelector(
        (state: IRootState) => state.organisations.organisation
    );

    const createdOrganisation = useSelector(
        (state: IRootState) => state.organisations.createdOrganisation
    );

    const [
        organisationOptions,
        currentOrganisationPath,
        findOrganisationNamePath,
    ] = useCascaderOrganisations(props?.organisationId);

    useEffect(() => {
        resetForm();
        props?.organisationId &&
            props?.organisationId !== "" &&
            organisationActions.getOrganisation(props.organisationId)(dispatch);
    }, [props.organisationId]);

    useEffect(() => {
        console.log("organisation useffect", organisation, "props?.organisationId ", props.organisationId, "props.formName ", props.formName, "props.formId ", props.formId);

        if (
            props?.organisationId &&
            organisation &&
            !!Object.keys(organisation).length
        ) {
            form.setFieldsValue({
                OrganisationName:
                    organisation.OrganisationInformation.OrganisationName,
                Address: organisation.OrganisationInformation.Address,
                CustomerOrganisationId:
                    organisation.OrganisationInformation.CustomerOrganisationId,
                Zipcode: organisation.OrganisationInformation.Zipcode,
                County: organisation.OrganisationInformation.County,
                OrganisationType:
                    organisation.OrganisationInformation.OrganisationType,
                Country: organisation.OrganisationInformation.Country,
                StandardTravelType:
                    organisation.OrganisationInformation.StandardTravelType,
                StandardTravelPurpose:
                    organisation.OrganisationInformation.StandardTravelPurpose,
                InvoiceInformationContactPerson:
                    organisation.InvoiceInformation.ContactPerson,
                InvoiceInformationOrganisationName:
                    organisation.InvoiceInformation.OrganisationName,
                InvoiceInformationLanguage:
                    organisation.InvoiceInformation.Language,
                InvoiceInformationAddress:
                    organisation.InvoiceInformation.Address,
                InvoiceInformationFinancialYearDate:
                    organisation.InvoiceInformation.FinancialYearDate,
                InvoiceInformationZipcode:
                    organisation.InvoiceInformation.Zipcode,
                InvoiceInformationCounty:
                    organisation.InvoiceInformation.County,
                InvoiceInformationInvoiceReference:
                    organisation.InvoiceInformation.InvoiceReference,
            });
        }
    }, [
        organisation?.OrganisationInformation?.OrganisationName,
        organisation?.OrganisationInformation?.CustomerOrganisationId,
        props?.organisationId,
    ]);

    const onFinish = (values: any) => {
        console.log("onFinish values", values);

        if (values.InvoiceInformationFinancialYearDate)
            values.InvoiceInformationFinancialYearDate = [
                moment(values.InvoiceInformationFinancialYearDate[0]).format(
                    "YYYY-MM-DD"
                ),
                moment(values.InvoiceInformationFinancialYearDate[1]).format(
                    "YYYY-MM-DD"
                ),
            ];
        console.log(
            "onFinish values after parsing",
            values,
            "organisationId ",
            props.organisationId
        );

        if (props?.organisationId) {
            onUpdate(values);
        } else {
            onCreate(values);
        }
    };

    const onUpdate = (values: any) => {
        //update
        if (props.organisationId) values.OrganisationId = props.organisationId;
        console.log("onfinish onUpdate values ", values);
        //TODO update UI
        organisationActions.updateOrganisation(
            values,
            () => {
                nNotis(
                    "success",
                    "top",
                    `Successfully updated organisation`,
                    3,
                    "Updated organisation"
                );
            },
            () => {
                nNotis(
                    "error",
                    "top",
                    `Could not update organisation; try again later or contact support`,
                    6,
                    "Something went wrong"
                );
            }
        )(dispatch);
    };

    const onCreate = (values: any) => {
        //TODO validate more within form item
        console.log(" onFinish onCreate values ", values);

        // create
        organisationActions.createOrganisation(
            values,
            onCreateSuccess,
            onCreateFail
        )(dispatch);
    };

    const onCreateSuccess = (values?: any) => (response?: any) => {
        values = { ...values, OrganisationId: response.OrganisationId };
        // values.OrganisationId = response.OrganisationId
        console.log(
            "onFinish onCreateSuccess values response",
            values,
            response
        );

        props?.insertDataHandlerCallback &&
            props?.insertDataHandlerCallback(values);

        return nNotis(
            "success",
            "top",
            `Successfully created organisation`,
            3,
            "Created Organisation"
        );
    };

    const onCreateFail = () => {
        return nNotis(
            "error",
            "top",
            `Could not create organisation; try again later or else contact support`,
            7,
            "Something went wrong"
        );
    };

    const resetForm = () => {
        form.resetFields();
        console.log("resetform");
    };

    const twoColsRowResponsiveProps = {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 12,
        xl: 12,
        style: { marginBottom: 3 },
    };

    const oneColRowResponsiveProps = {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
        style: { marginBottom: 3 },
    };

    const onChangeDateRangePicker = (
        value: DatePickerProps["value"] | RangePickerProps["value"],
        dateString: [string, string] | string
    ) => {
        console.log("Selected Time: ", value);
        console.log("Formatted Selected Time: ", dateString);
    };

    const onOkDateRangePicker = (
        value: DatePickerProps["value"] | RangePickerProps["value"]
    ) => {
        console.log("onOk: ", value);
    };

    return (
        <>
            <Form
                form={form}
                name={props.formName ? props.formName : "organisationForm"}
                id={props.formId ? props.formId : "organisationForm"}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Organisation Name"
                            name="OrganisationName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item label="Address" name="Address">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Organisation Number/ID"
                            name="CustomerOrganisationId"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col {...twoColsRowResponsiveProps}>
                        <Row gutter={12}>
                            <Col {...twoColsRowResponsiveProps}>
                                <Form.Item label="Zipcode" name="Zipcode">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col {...twoColsRowResponsiveProps}>
                                <Form.Item label="County" name="County">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Row gutter={12}>
                            <Col {...twoColsRowResponsiveProps}>
                                <Form.Item
                                    label="Organisation Type"
                                    name="OrganisationType"
                                >
                                    <Select
                                        value={
                                            organisation
                                                ?.OrganisationInformation
                                                ?.OrganisationType
                                        }
                                        options={[
                                            { label: "MOCK this", value: "01" },
                                            {
                                                label: "MOCK this2",
                                                value: "02",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col {...twoColsRowResponsiveProps}>
                                <Form.Item label="Country" name="Country">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Standard Traveltype"
                            name="StandardTravelType"
                        >
                            <Select
                                value={
                                    organisation?.OrganisationInformation
                                        ?.StandardTravelType
                                }
                                options={[
                                    { label: "MOCK this", value: "01" },
                                    {
                                        label: "MOCK this2",
                                        value: "02",
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    {currentOrganisationPath.length > 0 && (
                        <Col {...twoColsRowResponsiveProps}>
                            <Form.Item
                                label="Organisation Path"
                                name="OrganisationPath"
                            >
                                <Cascader
                                    disabled={true}
                                    options={organisationOptions}
                                    defaultValue={currentOrganisationPath}
                                    changeOnSelect
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Standard Travelpurpose"
                            name="StandardTravelPurpose"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left">Invoice Information</Divider>

                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Contact Person"
                            name="InvoiceInformationContactPerson"
                        >
                            <Select
                                value={
                                    organisation?.InvoiceInformation
                                        ?.ContactPerson
                                }
                                options={[
                                    { label: "MOCK this", value: "01" },
                                    {
                                        label: "MOCK this2",
                                        value: "02",
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>

                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Organisation Name"
                            name="InvoiceInformationOrganisationName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Language"
                            name="InvoiceInformationLanguage"
                        >
                            <Select
                                value={
                                    organisation?.InvoiceInformation?.Language
                                }
                                options={[
                                    { label: "MOCK this", value: "01" },
                                    {
                                        label: "MOCK this2",
                                        value: "02",
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Address"
                            name="InvoiceInformationAddress"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                {/* TODO ska räkenskaps år param vara två datum? */}
                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Financial Year Date"
                            name="InvoiceInformationFinancialYearDate"
                        >
                            <RangePicker
                                showTime={{
                                    format: "HH:mm",
                                }}
                                format="YYYY-MM-DD"
                                onChange={onChangeDateRangePicker}
                                onOk={onOkDateRangePicker}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col {...twoColsRowResponsiveProps}>
                        <Row gutter={12}>
                            <Col {...twoColsRowResponsiveProps}>
                                <Form.Item label="Zipcode" name="Zipcode">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col {...twoColsRowResponsiveProps}>
                                <Form.Item label="County" name="County">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col {...twoColsRowResponsiveProps}>
                        <Form.Item
                            label="Invoice Reference"
                            name="InvoiceInformationInvoiceReference"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Row gutter={24}>
                    <Col {...oneColRowResponsiveProps}>
                        {currentOrganisationPath.length > 0 &&
                            props?.driverData &&
                            !!Object.keys(props?.driverData).length && (
                                <Form.Item
                                    label="Organisation"
                                    name="CompanyId"
                                >
                                    <Cascader
                                        options={organisationOptions}
                                        defaultValue={currentOrganisationPath}
                                        changeOnSelect
                                    />
                                </Form.Item>
                            )}
                        {props.driverData &&
                            Object.keys(props.driverData).length === 0 && (
                                <Form.Item
                                    label="Organisation"
                                    name="CompanyId"
                                >
                                    <Cascader
                                        options={organisationOptions}
                                        changeOnSelect
                                    />
                                </Form.Item>
                            )}
                    </Col>
                </Row> */}
            </Form>
        </>
    );
};

export default React.memo(OrganisationForm);
