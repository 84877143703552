import { setLoader } from "../auth";
import { callFail, callSuccess } from "../../utils/callback";
import * as Apis from "../../utils/api";
import { callback } from "chart.js/helpers";
import moment from "moment";
import { useDispatch } from "react-redux";
import type { DateTimeString, DateString, TimeString } from "../actions.d.ts";

export const getVehicleAlarms =
    (
        vehicleId: String,
        dateFrom?: DateTimeString | DateString,
        dateTo?: DateTimeString | DateString,
        callback?: () => {}
    ) =>
    (dispatch: ReturnType<typeof useDispatch<any>>) => {
        dispatch(setLoader(true));

        Apis.getVehicleAlarms(vehicleId)
            .then((response) => {
                console.log(
                    "getVehicleAlarms alarms actions api response: ",
                    response
                );
                if (response.Status === "NOK") {
                    console.log("error", response);
                    dispatch({
                        type: "SET_ERRORS",
                        payload: response.Message,
                    });
                    callFail(callback, response);
                } else {
                    dispatch({
                        type: "GET_VEHICLE_ALARMS",
                        payload: response,
                    });
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.log(
                    "error in getVehicleAlarms actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };
export const getAlarms =
    (
        dateFrom?: DateTimeString | DateString,
        dateTo?: DateTimeString | DateString,
        callback?: () => {}
    ) =>
    (dispatch: ReturnType<typeof useDispatch<any>>) => {
        dispatch(setLoader(true));

        Apis.getAlarms("all", dateFrom, dateTo)
            .then((response) => {
                console.log(
                    "getAlarms alarms actions api response: ",
                    response
                );
                if (response.Status === "NOK") {
                    console.log("error", response);
                    dispatch({
                        type: "SET_ERRORS",
                        payload: response.Message,
                    });
                    callFail(callback, response);
                } else {
                    dispatch({
                        type: "GET_ALARMS",
                        payload: response,
                    });
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.log("error in getAlarms actions api call", error);
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };
