import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    Table,
    Space,
    Button,
    Row,
    Col,
    Modal,
    Skeleton,
    Drawer,
    Affix,
} from "antd";
import { useHistory } from "react-router-dom";
import Header from "../../components/breadcrumbheader/Header";
import "../../assets/css/functionPage.css";
import * as Apis from "../../utils/api";
import {
    DownOutlined,
    FilterOutlined,
    SearchOutlined,
    FormOutlined,
    AppstoreAddOutlined,
    UserAddOutlined,
} from "@ant-design/icons";

import JRTable from "../../components/table/JRTable";
import DriverForm from "../../components/forms/drivers/driver";
import pageLoading from "../../components/common/pageLoading";
import { getDrivers } from "../../actions/drivers/drivers";

//TODO fetch only like 100 drivers. do with LIMIT in api request.
//TODO format cellphone values
const Driveroverview = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    // const [drivers, setDrivers] = useState([]);
    const [visibleUpdateUser, setVisibleUpdateUser] = useState(false);
    const [driverData, setDriverData] = useState({});
    const [rowIndex, setRowIndex] = useState(null);
    const [rowData, setRowData] = useState(null)
    const drivers = useSelector((state) => state.drivers.drivers);

    useEffect(() => {
        getDrivers()(dispatch);
    }, [drivers?.length]);
    const routes = [
        {
            path: "",
            breadcrumbName: "Driver",
        },
        {
            path: "",
            breadcrumbName: "Overview",
        },
    ];
    const openUpdateDrawer = (row) => {
        console.log("openUpdateDrawer row ", row)
        setRowData(row)
        setVisibleUpdateUser(true);
    };
    const onCloseUpdate = () => {
        setVisibleUpdateUser(false);
    };

    const tableRowUpdate = async (rowIndex, data) => {
        //TODO memo update row in table
        const tableData = [...drivers];
        tableData[rowIndex] = data;
        dispatch({
            type: "GET_DRIVERS",
            payload: tableData,
        });
    };

    const onRow = (record, rowIndex) => {
        return {
            onClick: (event) => {
                console.log("drivertable onClick ", record, rowIndex, event);
                //alert(record.vID);
                setDriverData({ ...record });
                setRowIndex(rowIndex);
                openUpdateDrawer(record);
            },
        };
    };

    const drawerFooter = () => {
        return (
            <>
                {!!Object.keys(driverData).length ? (
                    <>
                        <Space>
                            <Button
                                onClick={() => {
                                    setVisibleUpdateUser(false);
                                }}
                                form={"driverForm"}
                                type="primary"
                                key="submit"
                                htmlType="submit"
                            >
                                Update
                            </Button>

                            <Button
                                onClick={() => {
                                    setVisibleUpdateUser(false);
                                }}
                                form={"driverForm"}
                                key="submit"
                                htmlType="submit"
                                danger
                            >
                                Delete
                            </Button>
                        </Space>
                    </>
                ) : (
                    <>
                        <div
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setVisibleUpdateUser(false);
                                }}
                                form={"driverForm"}
                                type="primary"
                                key="submit"
                                htmlType="submit"
                            >
                                Create
                            </Button>
                        </div>
                    </>
                )}
            </>
        );
    };

    //TODO implement so that FullName can be clicked on and nav to that user in user overview
    const columns = [
        {
            title: "Full Name",
            dataIndex: "FullName",
            key: "FullName",
            linkrender: "/user/view/",
            // render: (FullName) => FullName,
            render: (_, rec) => (
              <Space>
                  <a
                      onClick={(e) =>
                          history.push("/vehiclecard/overview/" )
                      }
                  >
                      {rec.FullName}
                  </a>
              </Space>
          )
        },
        {
            title: "Title",
            dataIndex: "Title",
            key: "Title",
            render: (Title) => Title,
        },
        {
            title: "License Plate",
            dataIndex: "LicensePlate",
            key: "LicensePlate",
        },
        {
            title: "Cellphone",
            dataIndex: "Cellphone",
            key: "Cellphone",
        },
        {
            title: "E-post",
            dataIndex: "Email",
            key: "Email",
        },
        {
            title: "Organisation",
            dataIndex: "CompanyName",
            key: "CompanyName",
            render: (CompanyName) => CompanyName,
        },
        {
            title: "RFID",
            dataIndex: "RFID",
            key: "RFID",
        },
    ];
    return (
        <div className="functionPagediv">
            <Affix
                offsetTop={150}
                onChange={(affixed) => console.log(affixed)}
                style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
            >
                <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                        setDriverData({});
                        setVisibleUpdateUser(true);
                    }}
                    icon={<UserAddOutlined />}
                />
            </Affix>
            <Row justify="space-around" align="middle">
                <Col span={23}>
                    <Header routes={routes} filter={false} />
                    {console.log("drivers ", drivers)}
                    <JRTable
                        columns={columns}
                        data={[...drivers]}
                        link="/user/view/"
                        row={onRow}
                        uniqKey={"UserID"}
                        showHashLink={false}
                    />
                </Col>
            </Row>

            {/* TODO make drawer responsive */}
            <Drawer
                title={
                    !!Object.keys(driverData).length
                        ? `Update Driver`
                        : "Create New Driver"
                }
                placement="right"
                size="large"
                height={200}
                onClose={onCloseUpdate}
                open={visibleUpdateUser}
                extra={drawerFooter()}
            >
                {!!Object.keys(driverData).length ? (
                    <>
                        <DriverForm
                            driverData={driverData}
                            tableRowUpdate={tableRowUpdate}
                            rowIndex={rowIndex}
                            rowData={rowData}
                        />
                    </>
                ) : (
                    <>
                        <DriverForm driverData={{}} />
                    </>
                )}
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => {
    console.log("selected menu mapstatetoprops", state);
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        // allWidgetsdata: state.widgets.allWidgets
    };
};

export default connect(mapStateToProps)(Driveroverview);
