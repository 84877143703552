import React, {useEffect} from "react";
import {Route, Redirect, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {checkLogin} from "../actions/login/login";

const AuthRoute = (props) => {
    const {component: Component, user, ...rest} = props;
    const history = useHistory()
    let token = useSelector(state => state.auth.token);
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth.isAuthenticated)
    const loading = useSelector(state => state.auth.checkLoginLoading)

    useEffect(() => {
        if (token && !loading) {
            dispatch(checkLogin(token))
        }
        let path = history.location.pathname;
        if(path !== '/login'){
            localStorage.setItem('pathHistory', history.location.pathname);
        }
    }, [history.location.pathname])

    return (
        <>
            <Route
                {...rest}
                render={(props) => {
                    return auth ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                            }}
                        />
                    );
                }}
            />
        </>
    );
}

// class AuthRoute extends React.PureComponent {
//     componentDidMount() {
//         let token = getStorageValue("Access-token");
//         this.props.dispatch(checkLogin(token));
//     }
//
//     checkLogin = () => {
//         let token = getStorageValue("Access-token");
//         this.props.dispatch(checkLogin(token));
//     }
//
//
//     render() {
//         console.log("dasdad");
//         const {component: Component, user, ...rest} = this.props;
//         return (
//             <Route
//                 render={(props) => {
//                     return this.props.auth ? (
//                         <Component {...props} />
//                     ) : (
//                         <Redirect
//                             to={{
//                                 pathname: '/login',
//                             }}
//                         />
//                     );
//                 }}
//             />
//         );
//     }
// }
//
//
// const mapStateToProps = state => {
//     return {
//         auth: state.auth.isAuthenticated
//     };
// };

export default AuthRoute;