import React, { Component } from "react";
import { Card, Table, Button, Form, Input, Modal } from "antd";
import translation from "../../translation/index";
import { EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { EngLangData } from "../../actions";
import "../../assets/css/language.css";

// console.log("translation", translation["en"]);

class EnglishLanSetting extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // console.log("formRef component di mount", this.formRef);
  }
  // langData = [
  //   {
  //     languagekey: "welcomeText",
  //     languagevalue: "Welcome Linus"
  //   },
  //   {
  //     languagekey: "todaysDateText",
  //     languagevalue: "Today's Date"
  //   },
  //   {
  //     languagekey: "totaNumOfTravels",
  //     languagevalue: "Total Number of Travels"
  //   },
  //   {
  //     languagekey: "totalTravelsTodayText",
  //     languagevalue: "Total Travels Today"
  //   },
  //   {
  //     languagekey: "totalDrivingDisText",
  //     languagevalue: "Total Driving Distance"
  //   },
  //   {
  //     languagekey: "totalDrivingDistodayText",
  //     languagevalue: "Total Driving Distance Today (Km)"
  //   },
  //   {
  //     languagekey: "numOfActiveVehiTodayText",
  //     languagevalue: "Number of Active Vehicles Today"
  //   },
  //   {
  //     languagekey: "numOfTravelsText",
  //     languagevalue: "Number of Travels"
  //   },
  //   {
  //     languagekey: "totalkmText",
  //     languagevalue: "Total (km)"
  //   },
  //   {
  //     languagekey: "activeVehiText",
  //     languagevalue: "Active Vehicles"
  //   },
  //   {
  //     languagekey: "currentAlramText",
  //     languagevalue: "Current Alarms"
  //   },
  //   {
  //     languagekey: "ongoingTravelsText",
  //     languagevalue: "Ongoing Travels"
  //   },
  //   { languagekey: "table.CurrentAlarm.Level", languagevalue: "Level" },
  //   { languagekey: "table.CurrentAlarm.Time", languagevalue: "Time" },
  //   { languagekey: "table.CurrentAlarm.Device", languagevalue: "Device" },
  //   { languagekey: "table.CurrentAlarm.Vehicle", languagevalue: "Vehicle" },
  //   { languagekey: "table.CurrentAlarm.Type", languagevalue: "Type" },
  //   {
  //     languagekey: "table.OngoingTravels.Travelid",
  //     languagevalue: "Travel id"
  //   },
  //   {
  //     languagekey: "table.OngoingTravels.Licenseplate",
  //     languagevalue: "License plate"
  //   },
  //   { languagekey: "table.OngoingTravels.Driver", languagevalue: "Driver" },
  //   { languagekey: "table.OngoingTravels.Start", languagevalue: "Start" },
  //   {
  //     languagekey: "table.OngoingTravels.Traveltime",
  //     languagevalue: "Traveltime"
  //   },
  //   { languagekey: "table.OngoingTravels.Distance", languagevalue: "Distance" },
  //   { languagekey: "bookedVehiText", languagevalue: "Booked Vehicles" },
  //   {
  //     languagekey: "addWidgetToDashText",
  //     languagevalue: "Add Widgets to Dashboard"
  //   },
  //   { languagekey: "widgetsText", languagevalue: "Widgets" },
  //   {
  //     languagekey: "bookedVehicleDes",
  //     languagevalue: "Display booked vehicles"
  //   },
  //   {
  //     languagekey: "tableCurrentAlarmDes",
  //     languagevalue: "Table of current alarms"
  //   },
  //   {
  //     languagekey: "tableOngoingTravelsDes",
  //     languagevalue: "Table of ongoing travels"
  //   },
  //   {
  //     languagekey: "chartNumOfTravelsDes",
  //     languagevalue: "Bar chart for number of travels"
  //   },
  //   {
  //     languagekey: "chartTotalKmDes",
  //     languagevalue: "Bar chart for total(km)"
  //   },
  //   {
  //     languagekey: "chartActiveVehicleDes",
  //     languagevalue: "Bar chart for active vehicles"
  //   },
  //   {
  //     languagekey: "staticTodaysDateDes",
  //     languagevalue: "Display today's date"
  //   },
  //   {
  //     languagekey: "staticTotalNumOfTravelsDes",
  //     languagevalue: "Display total number of travels"
  //   },
  //   {
  //     languagekey: "staticTotalTravelsTodayDes",
  //     languagevalue: "Display total travels today"
  //   },
  //   {
  //     languagekey: "staticTotalDrivingDisDes",
  //     languagevalue: "Display total driving distance"
  //   },
  //   {
  //     languagekey: "staticTotalDrivingDisTodayDes",
  //     languagevalue: "Display total driving distance today (Km)"
  //   },
  //   {
  //     languagekey: "staticNumOfActiveVehiTodayDes",
  //     languagevalue: "Display number of active vehicles today"
  //   },
  //   { languagekey: "addWidgetsBtn", languagevalue: "Add Widgets" },
  //   { languagekey: "changeLayoutBtn", languagevalue: "Change Layout" },
  //   { languagekey: "changeDoneBtn", languagevalue: "Changes Done" },
  //   { languagekey: "menu.Dashboard", languagevalue: "Dashboard" },
  //   { languagekey: "menu.vehicle", languagevalue: "Vehicle" },
  //   {
  //     languagekey: "menu.vehicle.vehicleStatus",
  //     languagevalue: "Vehicle status"
  //   },
  //   {
  //     languagekey: "menu.vehicle.searchVehicles",
  //     languagevalue: "Search vehicles"
  //   },
  //   {
  //     languagekey: "menu.vehicle.Currentmeterreadings",
  //     languagevalue: "Current meter readings"
  //   },
  //   {
  //     languagekey: "menu.vehicle.Batterystatus",
  //     languagevalue: "Battery status"
  //   },
  //   { languagekey: "menu.vehicle.Refueling", languagevalue: "Refueling" },
  //   { languagekey: "menu.vehicle.Inspections", languagevalue: "Inspections" },
  //   {
  //     languagekey: "menu.vehicle.Vehicleservice",
  //     languagevalue: "Vehicle service"
  //   },
  //   {
  //     languagekey: "menu.vehicle.Vehiclereplacement",
  //     languagevalue: "Vehicle replacement"
  //   },
  //   {
  //     languagekey: "menu.vehicle.Vehicleconfiguration",
  //     languagevalue: "Vehicle configuration"
  //   },
  //   {
  //     languagekey: "menu.vehicle.CO2emissions",
  //     languagevalue: "CO2 emissions"
  //   },
  //   { languagekey: "menu.vehiclecard", languagevalue: "Vehicle card" },
  //   {
  //     languagekey: "menu.vehiclecard.Vehicleoverview",
  //     languagevalue: "Vehicle overview"
  //   },
  //   { languagekey: "menu.vehiclecard.Basicdata", languagevalue: "Basic data" },
  //   { languagekey: "menu.vehiclecard.Economy", languagevalue: "Economy" },
  //   {
  //     languagekey: "menu.vehiclecard.Maintenance",
  //     languagevalue: "Maintenance"
  //   },
  //   { languagekey: "menu.vehiclecard.System", languagevalue: "System" },
  //   { languagekey: "menu.vehiclecard.Notes", languagevalue: "Notes" },
  //   {
  //     languagekey: "menu.vehiclecard.Certificatetravel",
  //     languagevalue: "Certificate travel"
  //   },
  //   { languagekey: "menu.driver", languagevalue: "Driver" },
  //   {
  //     languagekey: "menu.driver.Latestidentifications",
  //     languagevalue: "Latest identifications"
  //   },
  //   {
  //     languagekey: "menu.driver.Driveroverview",
  //     languagevalue: "Driver overview"
  //   },
  //   {
  //     languagekey: "menu.driver.Driveranalysis",
  //     languagevalue: "Driver analysis"
  //   },
  //   { languagekey: "menu.positions", languagevalue: "Positions" },
  //   {
  //     languagekey: "menu.positions.Currentpositions",
  //     languagevalue: "Current positions"
  //   },
  //   {
  //     languagekey: "menu.positions.Findthenearestvehicle",
  //     languagevalue: "Find the nearest vehicle"
  //   },
  //   {
  //     languagekey: "menu.positions.Create/editzones",
  //     languagevalue: "Create / edit zones"
  //   },
  //   {
  //     languagekey: "menu.positions.Listofzones",
  //     languagevalue: "List of zones"
  //   },
  //   { languagekey: "menu.travel", languagevalue: "Travel" },
  //   {
  //     languagekey: "menu.travel.Ongoingtravel",
  //     languagevalue: "Ongoing travel"
  //   },
  //   {
  //     languagekey: "menu.travel.Completedtrips",
  //     languagevalue: "Completed trips"
  //   },
  //   { languagekey: "menu.travel.Travellog", languagevalue: "Travel log" },
  //   {
  //     languagekey: "menu.travel.Congestiontax",
  //     languagevalue: "Congestion tax"
  //   },
  //   { languagekey: "menu.travel.Travelstop", languagevalue: "Travel stop" },
  //   {
  //     languagekey: "menu.travel.Travelpurposerules",
  //     languagevalue: "Travel purpose rules"
  //   },
  //   {
  //     languagekey: "menu.travel.Autoexportdrivingrecords",
  //     languagevalue: "Autoexport driving records"
  //   },
  //   {
  //     languagekey: "menu.travel.Manuallogbook",
  //     languagevalue: "Manual logbook"
  //   },
  //   { languagekey: "menu.travel.Changeuser", languagevalue: "Change user" },
  //   {
  //     languagekey: "menu.travel.Certificatetravel",
  //     languagevalue: "Certificate travel"
  //   },
  //   { languagekey: "menu.reserve", languagevalue: "Reserve" },
  //   { languagekey: "menu.reserve.Vehicle", languagevalue: "Vehicle" },
  //   {
  //     languagekey: "menu.reserve.Vehicleservice",
  //     languagevalue: "Vehicle service"
  //   },
  //   {
  //     languagekey: "menu.reserve.Bookotherusers",
  //     languagevalue: "Book other users"
  //   },
  //   { languagekey: "menu.alarm", languagevalue: "Alarm" },
  //   { languagekey: "menu.alarm.Devicealarm", languagevalue: "Device alarm" },
  //   { languagekey: "menu.alarm.Driveralarm", languagevalue: "Driver alarm" },
  //   { languagekey: "menu.alarm.Servicealarm", languagevalue: "Service alarm" },
  //   {
  //     languagekey: "menu.alarm.Exchangealarm",
  //     languagevalue: "Exchange alarm"
  //   },
  //   { languagekey: "menu.alarm.Zonlarm", languagevalue: "Zonlarm" },
  //   { languagekey: "menu.alarm.Speed​​alarm", languagevalue: "Speed ​​alarm" },
  //   {
  //     languagekey: "menu.alarm.Receiverofalarms",
  //     languagevalue: "Receiver of alarms"
  //   },
  //   { languagekey: "menu.maintenance", languagevalue: "Maintenance" },
  //   {
  //     languagekey: "menu.maintenance.Completedmaintenance",
  //     languagevalue: "Completed maintenance"
  //   },
  //   {
  //     languagekey: "menu.maintenance.Maintenancework",
  //     languagevalue: "Maintenance work"
  //   },
  //   { languagekey: "menu.economy", languagevalue: "Economy" },
  //   {
  //     languagekey: "menu.economy.Totalcostofownership(TCO)",
  //     languagevalue: "Total cost of ownership (TCO)"
  //   },
  //   {
  //     languagekey: "menu.economy.Currentmonthlycost",
  //     languagevalue: "Current monthly cost"
  //   },
  //   { languagekey: "menu.economy.Leasing", languagevalue: "Leasing" },
  //   { languagekey: "menu.economy.Fuel", languagevalue: "Fuel" },
  //   { languagekey: "menu.analyzes", languagevalue: "Analyzes" },
  //   {
  //     languagekey: "menu.analyzes.Degreeofutilization",
  //     languagevalue: "Degree of utilization"
  //   },
  //   {
  //     languagekey: "menu.analyzes.Exportanalyzes",
  //     languagevalue: "Export analyzes"
  //   },
  //   { languagekey: "menu.analyzes.Speeds(ISA)", languagevalue: "Speeds (ISA)" },
  //   { languagekey: "menu.analyzes.Fuel", languagevalue: "Fuel" },
  //   { languagekey: "menu.analyzes.Fuel-import", languagevalue: "Fuel-import" },
  //   {
  //     languagekey: "menu.analyzes.Drivingbehavior",
  //     languagevalue: "Driving behavior"
  //   },
  //   { languagekey: "menu.reportTemplates", languagevalue: "Report templates" },
  //   {
  //     languagekey: "menu.reportTemplates.Createyourownreports",
  //     languagevalue: "Create your own reports"
  //   },
  //   { languagekey: "menu.reportTemplates.Reports", languagevalue: "Reports" },
  //   {
  //     languagekey: "menu.reportTemplates.Autoexportreports",
  //     languagevalue: "Autoexport reports"
  //   },
  //   { languagekey: "menu.system", languagevalue: "System" },
  //   { languagekey: "menu.system.Overview", languagevalue: "Overview" },
  //   { languagekey: "menu.system.Details", languagevalue: "Details" },
  //   { languagekey: "menu.system.Activate", languagevalue: "Activate" },
  //   { languagekey: "menu.system.Boxalarm", languagevalue: "Box alarm" },
  //   {
  //     languagekey: "menu.system.Feedback/Support",
  //     languagevalue: "Feedback / Support"
  //   },
  //   {
  //     languagekey: "menu.system.Autoexportreports",
  //     languagevalue: "Autoexport reports"
  //   },
  //   { languagekey: "menu.show", languagevalue: "Show" },
  //   { languagekey: "menu.show.HideMapView", languagevalue: "Hide Map View" },
  //   {
  //     languagekey: "menu.show.Timelimitontravel",
  //     languagevalue: "Time limit on travel"
  //   },
  //   {
  //     languagekey: "menu.show.Showonlyunlockedtrips",
  //     languagevalue: "Show only unlocked trips"
  //   },
  //   {
  //     languagekey: "menu.show.Showonlyowntrips",
  //     languagevalue: "Show only own trips"
  //   },
  //   { languagekey: "menu.show.Showspeed", languagevalue: "Show speed" },
  //   { languagekey: "menu.admin", languagevalue: "Admin" },
  //   { languagekey: "menu.admin.Organisation", languagevalue: "Organisation" },
  //   {
  //     languagekey: "menu.admin.Organisation.Organizationalstructure",
  //     languagevalue: "Organizational structure"
  //   },
  //   {
  //     languagekey: "menu.admin.Organisation.Listorganization",
  //     languagevalue: "List organization"
  //   },
  //   {
  //     languagekey: "menu.admin.Organisation.Createaneworganization",
  //     languagevalue: "Create a new organization"
  //   },
  //   { languagekey: "menu.admin.Permissions", languagevalue: "Permissions" },
  //   {
  //     languagekey: "menu.admin.Permissions.Listofpermissiontemplates",
  //     languagevalue: "List of permission templates"
  //   },
  //   {
  //     languagekey: "menu.admin.Permissions.Createpermissiontemplates",
  //     languagevalue: "Create permission templates"
  //   },
  //   { languagekey: "menu.admin.User", languagevalue: "User" },
  //   { languagekey: "menu.admin.User.Listusers", languagevalue: "List users" },
  //   {
  //     languagekey: "menu.admin.User.Createusers",
  //     languagevalue: "Create users"
  //   },
  //   { languagekey: "menu.admin.User.Userlog", languagevalue: "User log" },
  //   { languagekey: "menu.admin.Data", languagevalue: "Data" },
  //   {
  //     languagekey: "menu.admin.Data.Importvehicles",
  //     languagevalue: "Import vehicles"
  //   },
  //   {
  //     languagekey: "menu.admin.Data.Importusers",
  //     languagevalue: "Import users"
  //   },
  //   {
  //     languagekey: "menu.admin.Data.Importtravelpurposes",
  //     languagevalue: "Import travel purposes"
  //   },
  //   { languagekey: "menu.admin.Data.Importfuel", languagevalue: "Import fuel" },
  //   {
  //     languagekey: "menu.admin.Data.Exportvehicles",
  //     languagevalue: "Export vehicles"
  //   },
  //   { languagekey: "menu.admin.Backupofdata", languagevalue: "Backup of data" },
  //   { languagekey: "menu.admin.Templates", languagevalue: "Templates" },
  //   {
  //     languagekey: "menu.admin.Templates.Listofvehicletemplates",
  //     languagevalue: "List of vehicle templates"
  //   },
  //   {
  //     languagekey: "menu.admin.Templates.Createvehicletemplate",
  //     languagevalue: "Create vehicle template"
  //   },
  //   {
  //     languagekey: "menu.admin.Templates.Listofcosttemplates",
  //     languagevalue: "List of cost templates"
  //   },
  //   {
  //     languagekey: "menu.admin.Templates.Createcosttemplate",
  //     languagevalue: "Create cost template"
  //   },
  //   {
  //     languagekey: "menu.admin.Templates.Listservicetemplate",
  //     languagevalue: "List service template"
  //   },
  //   {
  //     languagekey: "menu.admin.Templates.Createservicetemplate",
  //     languagevalue: "Create service template"
  //   },
  //   {
  //     languagekey: "menu.admin.Templates.Listvehiclereplacementtemplate",
  //     languagevalue: "List vehicle replacement template"
  //   },
  //   {
  //     languagekey: "menu.admin.Templates.Createvehiclereplacementtemplate",
  //     languagevalue: "Create vehicle replacement template"
  //   },
  //   {
  //     languagekey: "menu.admin.Registervehicles",
  //     languagevalue: "Register vehicles"
  //   },
  //   { languagekey: "menu.admin.Userlog", languagevalue: "User log" },
  //   { languagekey: "menu.mySetting", languagevalue: "My Setting" },
  //   { languagekey: "menu.logOut", languagevalue: "Log Out" }
  // ];

  state = {
    currentPage: "1",
    pageSize: "10",
    showModal: false,
    selectedLangKey: "",
    selectedLangValue: "",
    languageKeyInput: "",
    languageValueInput: "",
    updatedlangData: ""
    // langData: this.langData
  };

  onChange = e => {
    this.setState({ currentPage: e.current, pageSize: e.pageSize });
    console.log("current", e);
  };
  clickedit = (e, record) => {
    console.log("record", record);
    this.setState(
      {
        languageKeyInput: record.languagekey,
        languageValueInput: record.languagevalue
      },
      () => {
        this.setState({
          showModal: true
        });
      }
    );
    console.log("clickedit this.formRef.current => ", this.formRef);

    this.formRef.current &&
      this.formRef.current.setFieldsValue({
        languageKeyInput: record.languagekey,
        languageValueInput: record.languagevalue
      });
    console.log("languageKeyInput", this.state.languageKeyInput);
  };

  submitedData = values => {
    console.log("Received values of form: ", values);
    // const elementsIndex = this.state.langData.findIndex(
    //   element => element.languagekey == values.languageKeyInput
    // );
    // console.log("elementsIndex", elementsIndex);
    // let newlangData = [...this.state.langData];
    // newlangData[elementsIndex] = {
    //   ...newlangData[elementsIndex],
    //   languagevalue: values.languageValueInput
    // };

    const elementsIndex = this.props.engLangData.findIndex(
      element => element.languagekey == values.languageKeyInput
    );
    translation.en[values.languageKeyInput] = values.languageValueInput;
    console.log("translation.en", translation);
    console.log("elementsIndex", elementsIndex);
    let newlangData = [...this.props.engLangData];
    newlangData[elementsIndex] = {
      ...newlangData[elementsIndex],
      languagevalue: values.languageValueInput
    };

    // console.log("newlangData", newlangData);
    // this.langDataArr = newlangData;
    // console.log("this.langDataArr", this.langDataArr);
    this.props.dispatch(EngLangData(newlangData));
    // this.setState(
    //   { showModal: false, langData: newlangData, updatedlangData: newlangData },
    //   () => console.log("this.state.langData", this.state.langData)
    // );
    this.setState({ showModal: false });
    this.formRef.current.resetFields();
  };

  handleClose = () => {
    this.setState({ showModal: false });
    this.formRef.current.resetFields();
  };

  langColumns = [
    {
      title: "Sr. No",
      dataIndex: "srno",
      key: "srno",
      render: (text, record, index) => (
        <span>
          {(this.state.currentPage - 1) * this.state.pageSize + index + 1}
        </span>
      )
    },
    {
      title: "Language Key",
      dataIndex: "languagekey",
      key: "languagekey"
    },
    {
      title: "Language Value",
      dataIndex: "languagevalue",
      key: "languagevalue"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Button
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          size="small"
          onClick={e => this.clickedit(e, record)}
        />
      )
    }
  ];

  componentDidMount() {
    //
    console.log("clickedit this.formRef", this.formRef);
    console.log("this.state.langData", this.state.langData);
    console.log("this.langDataArr", this.langDataArr);
  }

  render() {
    return (
      <>
        <Card
          title="English language list"
          className="inset-box-shadowed-effect"
        >
          <Table
            columns={this.langColumns}
            dataSource={this.props.engLangData}
            /* pagination={true} */
            onChange={e => this.onChange(e)}
            /* pagination={e => this.onChange(e)} */
          />
        </Card>
        <Modal
          title="Edit Language Value"
          visible={this.state.showModal}
          footer={null}
          /* cancelText="Close"
          okText="Save"
          onOk={this.editHandleOk}*/
          onCancel={this.handleClose}
        >
          <Form
            name="EditLangForm"
            layout="vertical"
            ref={this.formRef}
            initialValues={{
              languageKeyInput: this.state.languageKeyInput,
              languageValueInput: this.state.languageValueInput
            }}
            onFinish={this.submitedData}
          >
            <Form.Item label="Language Key" name="languageKeyInput">
              <Input disabled value={this.state.languageKeyInput} />
            </Form.Item>
            <Form.Item label="Language Value" name="languageValueInput">
              <Input />
            </Form.Item>
            <div className="langmodelbtn">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={this.handleClose}>
                  Close
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  // console.log("state mapstatetoprops", state);
  return {
    engLangData: state.language.engLangData
  };
};

export default connect(mapStateToProps)(EnglishLanSetting);
