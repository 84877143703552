import { setLoader } from "../auth";
import instance from "../../instance";
import { callFail, callSuccess } from "../../utils/callback";
import getStorageValue from "../../utils/getStorageValue";
import * as Apis from "../../utils/api";
import { callback } from "chart.js/helpers";
import moment from "moment";

export const getVehicles = (callback) => (dispatch) => {
    console.log("getVehicles ACTION");
    dispatch(setLoader(true));
    instance.defaults.headers.common["Access-Token"] =
        getStorageValue("Access-token");
    const url = "all_vehicles";
    instance
        .get(url)
        .then((res) => {
            if (res.data.Status === "NOK") {
                dispatch({
                    type: "SET_ERRORS",
                    payload: res.data.Message,
                });
                callFail(callback, res.data);
            } else {
                dispatch({
                    type: "GET_VEHICLES",
                    payload: res.data,
                });
                typeof callback == "function" && callback(res.data);
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            if (error.response) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error.response,
                });
            }
            dispatch(setLoader(false));
        });
};

export const getVehiclesAPI = (callback) => (dispatch) => {
    dispatch(setLoader(true));

    Apis.getAllVehicles()
        .then((res) => {
            console.log("getAllVehicles vehicles actions api res: ", res);
            if (res.Status === "NOK") {
                console.log("error", res);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res.Message,
                });
                callFail(callback, res);
            } else {
                dispatch({
                    type: "GET_VEHICLES_FORMATTED",
                    payload: res,
                });
                typeof callback == "function" && callback(res);
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in vehicle actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const getVehiclesStatus = (callback) => (dispatch) => {
    dispatch(setLoader(true));

    Apis.vehiclesStatus()
        .then((res) => {
            console.log("getVehiclesStatus vehicles actions api res: ", res);
            if (res.Status === "NOK") {
                console.log("error", res);
                dispatch({
                    type: "SET_ERRORS",
                    payload: res.Message,
                });
                callFail(callback, res);
            } else {
                dispatch({
                    type: "GET_VEHICLES_STATUS",
                    payload: res,
                });
                typeof callback == "function" && callback(res);
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in vehicle actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const lookupVehicleByLicensePlate =
    (licensePlate, callback) => (dispatch) => {
        console.log(
            "lookupVehicleByLicensePlate action licensePlate: ",
            licensePlate.toUpperCase()
        );
        dispatch(setLoader(true));

        const data = {
            LicensePlate: licensePlate.toUpperCase(),
        };

        Apis.lookupVehicleByLicensePlate(data)
            .then((res) => {
                console.log(
                    "lookupVehicleByLicensePlate actions api res: ",
                    res
                );
                if (res.Status) {
                    dispatch(
                        {
                            type: "FETCH_VEHICLE",
                            payload: res,
                        },
                        callback(res)
                    );
                } else {
                    console.log("error", res);
                    dispatch({
                        type: "FETCH_VEHICLE_ERROR",
                        payload: res.Message,
                    });
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.log(
                    "error in lookupVehicleByLicensePlate actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };

export const getVehicle = (vehicleId, callback) => (dispatch) => {
    dispatch(setLoader(true));
    //data.acquisitionDate = moment(data.acquisitionDate).format("YYYY-MM-DD");

    Apis.getVehicle(vehicleId)
        .then((response) => {
            console.log("getVehicle actions api res: ", response);
            if (response.Status === "OK") {
                dispatch(
                    {
                        type: "GET_VEHICLE",
                        payload: response,
                    },
                    callback && callback(response)
                );
            } else {
                console.log("error", response);
                dispatch({
                    type: "GET_VEHICLE_ERROR",
                    payload: response.Message,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in getVehicle actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const updateVehicle = (data, callback) => (dispatch) => {
    dispatch(setLoader(true));
    data.acquisitionDate = moment(data.acquisitionDate).format("YYYY-MM-DD");
    console.log("new date data.acquisitionDate: ", data.acquisitionDate);

    //TODO companyId [], take the last index for the selected value.

    Apis.updateVehicle(data)
        .then((res) => {
            console.log("updateVehicle actions api res: ", res);
            if (res.Status) {
                dispatch(
                    {
                        type: "UPDATE_VEHICLE",
                        payload: res,
                    },
                    callback(res)
                );
            } else {
                console.log("error", res);
                dispatch({
                    type: "UPDATE_VEHICLE_ERROR",
                    payload: res.Message,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in updateVehicle actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const createVehicle = (data, callback) => (dispatch) => {
    dispatch(setLoader(true));
    data.acquisitionDate = moment(data.acquisitionDate).format("YYYY-MM-DD");
    console.log("new date data.acquisitionDate: ", data.acquisitionDate);

    //TODO companyId [], take the last index for the selected value.
    const ApiData = {
        LicensePlate: data.licensePlate.toUpperCase(),
        Miles: data.odometer,
    };

    Apis.createVehicle(ApiData)
        .then((res) => {
            console.log("createVehicle actions api res: ", res);
            if (res.Status) {
                dispatch(
                    {
                        type: "CREATE_VEHICLE",
                        payload: res,
                    },
                    callback(res)
                );
            } else {
                console.log("error", res);
                dispatch({
                    type: "CREATE_VEHICLE_ERROR",
                    payload: res.Message,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in createVehicle actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const deleteVehicle = (vehicleId, callback) => (dispatch) => {
    dispatch(setLoader(true));
    Apis.deleteVehicle(vehicleId)
        .then((res) => {
            console.log("deleteVehicle actions api res: ", res);
            if (res.Status) {
                dispatch(
                    {
                        type: "DELETE_VEHICLE",
                        payload: res,
                    },
                    callback(res)
                );
            } else {
                console.log("error", res);
                dispatch({
                    type: "DELETE_VEHICLE_ERROR",
                    payload: res.Message,
                });
            }
            dispatch(setLoader(false));
        })
        .catch((error) => {
            console.log("error in deleteVehicle actions api call", error);
            if (error) {
                dispatch({
                    type: "SET_ERRORS",
                    payload: error,
                });
            }
            dispatch(setLoader(false));
        });
};

export const getVehicleCurrentPositions =
    (vehicleId, callback) => (dispatch) => {
        dispatch(setLoader(true));
        Apis.getVehiclePositions(vehicleId)
            .then((res) => {
                console.log(
                    "getVehicleCurrentPositions vehicles actions api res: ",
                    res
                );
                if (res.Status === "NOK") {
                    console.log("error", res);
                    dispatch({
                        type: "SET_ERRORS",
                        payload: res.Message,
                    });
                    callFail(callback, res);
                } else {
                    dispatch({
                        type: "GET_VEHICLE_POSITIONS",
                        payload: res,
                    });
                    typeof callback == "function" && callback(res);
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.log(
                    "error in getVehicleCurrentPositions actions api call",
                    error
                );
                if (error) {
                    dispatch({
                        type: "SET_ERRORS",
                        payload: error,
                    });
                }
                dispatch(setLoader(false));
            });
    };
