import en from "./en.json";
import jp from "./jp.json";
import sv from "./sv.json";

const translation = {
  en,
  sv,
  jp
};

export default translation;
