import React, { useEffect, useState } from "react";
import { Image, List, Button, Affix, Drawer, Icon, Avatar } from "antd";
import jsonData from "../../json/overview.json";
import { Chart, registerables } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { connect } from "react-redux";
import { WidthProvider, Responsive } from "react-grid-layout";
import "../../assets/css/react-resize-styles.css";
import "../../assets/css/react-grid-styles.css";
import _ from "lodash";
import RenderGM from "../Map/Google";

import * as Apis from "../../utils/api";

import {
    AppstoreAddOutlined,
    DragOutlined,
    PieChartOutlined,
    BarChartOutlined,
    AlertOutlined,
    BarsOutlined,
    CompassOutlined,
} from "@ant-design/icons";
import Map from "../maps/MapsCurrent";

/*
import CircularGauge, {
  Geometry,
  Scale as CircularScale,
  Size as CircularSize,
  ValueIndicator as CircularValueIndicator,
} from 'devextreme-react/circular-gauge';

import LinearGauge, {
  Label,
  MinorTick,
  Scale as LinearScale,
  Size as LinearSize,
  ValueIndicator as LinearValueIndicator,
} from 'devextreme-react/linear-gauge';

import Slider from 'devextreme-react/slider';

import Indicator from './Indicator.js';*/

import "./style.css";

Chart.register(...registerables);
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const removeStyle = {
    position: "absolute",
    right: "2px",
    top: 0,
    cursor: "pointer",
};
const dashCardStyle = {
    border: "1px solid #000",
    backgroundColor: "#eee",
    textalign: "center",
    padding: "5px",
};

const dashCardStylec = {
    borderRadius: "50%",
    border: "1px solid #000",
    backgroundColor: "#eee",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "5px",
};

const travelData = {
    labels: [
        "9 Jul",
        "10 Jul",
        "11 Jul",
        "12 Jul",
        "13 Jul",
        "14 Jul",
        "15 Jul",
    ],
    datasets: [
        {
            label: "Numbers of travels",
            data: jsonData.travels,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
        },
    ],
};

const distanceData = {
    labels: [
        "9 Jul",
        "10 Jul",
        "11 Jul",
        "12 Jul",
        "13 Jul",
        "14 Jul",
        "15 Jul",
    ],
    datasets: [
        {
            label: "Total(km)",
            data: jsonData.dist,
            backgroundColor: "rgba(255, 206, 86, 0.2)",
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 1,
        },
    ],
};

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};
const larmData = [
    {
        title: "2022-08-18 12:12:12",
        desc: "Enhet [1212] återansluten",
    },
    {
        title: "2022-08-18 12:12:12",
        desc: "Enhet [1212] återansluten",
    },
    {
        title: "2022-08-18 12:12:12",
        desc: "Enhet [1212] återansluten",
    },
    {
        title: "2022-08-18 12:12:12",
        desc: "Enhet [1212] återansluten",
    },
    {
        title: "2022-08-18 12:12:12",
        desc: "Enhet [1212] återansluten",
    },
];
const VehicleDashboard = (props) => {
    const [licensePlate, setLicensePlate] = useState("unknow");
    const [brand, setBrand] = useState("unknow");
    const [model, setModel] = useState("unknow");
    const [year, setYear] = useState("unknow");
    const [miles, setMiles] = useState("unknow");
    const [travels, setTravels] = useState([]);
    const [vData, setvData] = useState("-");
    const [speedValue, setspeedValue] = useState(40);
    const [logo, setLogo] = useState(
        "https://au2box.com/img/car-brands/volvo.png"
    );

    const [items, setItems] = useState("-");
    const [widgets, setWidgets] = useState([
        {
            key: Math.random(),
            name: "Number of Total (km)",
            datagrid: { x: 3, y: 0, w: 3, h: 2 },
            data: distanceData,
            func: "Bar",
        },
        {
            key: Math.random(),
            name: "Total number of travels",
            datagrid: { x: 6, y: 0, w: 3, h: 2 },
            data: travelData,
            func: "Bar",
        },
        {
            key: Math.random(),
            name: "Larm",
            datagrid: { x: 0, y: 2, w: 2, h: 3 },
            data: larmData,
            func: "Larm",
        },
        {
            key: Math.random(),
            name: "Total travels today",
            datagrid: { x: 7, y: 0, w: 1, h: 1 },
            data: jsonData.tdist,
            func: "Div",
        },
        {
            key: Math.random(),
            name: "Total driving distance today (km)",
            datagrid: { x: 8, y: 0, w: 1, h: 1 },
            data: "0",
            func: "Div",
        },
    ]);
    //FASTER WAY TO DO APICALLS!
    useEffect(() => {
        console.log("useEffect has been called! will now get ", props);

        Apis.getVehicle(props.vData)
            .then((res) => {
                if (res.Status === "NOK") {
                    console.log("API error in dashboard of vehiclecard: ", res);
                    //TODO remove dummydata
                    props.pushDashboardData("ABC123");
                } else {
                    console.log("API res in dashboard of vehiclecard:", res);
                    //TODO remove dummydata
                    //setLicensePlate(res.Vehicle.LicensePlate);
                    //TODO also send res data to vehicleoverview async.
                    setLicensePlate("ABC123");
                    props.pushDashboardData("ABC123");
                    setBrand(res.Vehicle.Brand);
                    setModel(res.Vehicle.Model);
                    setYear(res.Vehicle.Year);
                    setMiles(res.Vehicle.Miles);
                    setLogo(res.Vehicle.Logo);
                    setvData(res.Vehicle);

                    console.log("ANTAL resor: ", res.Vehicle.Usage.Count);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const widgetList = [
        {
            icon: <PieChartOutlined />,
            title: "Total number of Travels",
            description: "Pie Diagram for the total number of travels",
            datagrid: { x: 0, y: 0, w: 3, h: 4 },
            data: travelData,
            func: "Pie",
        },
        {
            icon: <BarChartOutlined />,
            title: "Total number of Travels",
            description: "Bar Diagram for the total number of travels",
            datagrid: { x: 0, y: 0, w: 3, h: 2 },
            data: travelData,
            func: "Bar",
        },
        {
            icon: <PieChartOutlined />,
            title: "Number of Total (km)",
            description: "Pie Diagram for the Number of Total (km)",
            datagrid: { x: 0, y: 0, w: 3, h: 4 },
            data: distanceData,
            func: "Pie",
        },
        {
            icon: <BarChartOutlined />,
            title: "Number of Total (km)",
            description: "Bar Diagram for the Number of Total (km)",
            datagrid: { x: 0, y: 0, w: 3, h: 2 },
            data: distanceData,
            func: "Bar",
        },
        {
            icon: <AlertOutlined />,
            title: "Alarms",
            description: "Alarm form hte tracker in the vehicle",
            datagrid: { x: 0, y: 0, w: 3, h: 3 },
            data: larmData,
            func: "Larm",
        },
        {
            icon: <BarsOutlined />,
            title: "Service km/day",
            description: "See when its tiem for service",
            datagrid: { x: 0, y: 0, w: 2, h: 1 },
            data: jsonData.service,
            func: "Div",
        },
        {
            icon: <CompassOutlined />,
            title: "Last Position",
            description: "See the latest position of this vehicle",
            datagrid: { x: 0, y: 0, w: 3, h: 3 },
            data: travelData,
            func: "Map",
        },
    ];
    const addWidget = (name, grid, func, data) => {
        //alert(name)
        setWidgets(
            widgets.concat({
                key: widgets.length + 1,
                name: name,
                datagrid: grid,
                func: func,
                data: data,
            })
        );
    };
    const onLayoutChange = (layout) => {
        console.log(layout);
        console.log("change layoyut....");
        //onLayoutChange();
    };
    const onBreakpointChange = () => {
        /*this.setState({
      breakpoint: breakpoint,
      cols: cols
    });*/
    };
    const onRemoveItem = (index) => {
        console.log("remove");
        setWidgets(_.reject(widgets, { key: index }));
        onLayoutChange();
    };
    const createElement1 = (item) => {
        //console.log("CE1 ", item);
        return (
            <div
                key={item.key}
                style={dashCardStyle}
                data-grid={{
                    x: item.datagrid.x,
                    y: item.datagrid.y,
                    w: item.datagrid.w,
                    h: item.datagrid.h,
                }}
            >
                <span
                    className="remove"
                    style={removeStyle}
                    onClick={onRemoveItem.bind(this, item.key)}
                >
                    x
                </span>
                <h3>
                    <span className="react-grid-dragHandleExample">
                        <DragOutlined />
                    </span>
                    {item.name}
                </h3>
                {createElement(item.func, item.data)}
            </div>
        );
    };
    const barstyle = {
        position: "absolute",
        width: "80%",
        height: "80%",
    };
    const createElement = (func, data) => {
        if (func === "Bar") {
            return (
                <Bar
                    style={barstyle}
                    data={data}
                    options={options}
                    key={Math.random()}
                />
            );
        } else if (func === "Pie") {
            return (
                <Pie
                    style={barstyle}
                    data={data}
                    options={options}
                    key={Math.random()}
                />
            );
        } else if (func === "Larm") {
            return (
                <List
                    itemLayout="horizontal"
                    dataSource={larmData}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.title}
                                description={item.desc}
                            />
                        </List.Item>
                    )}
                />
            );
        } else if (func === "Map") {
            return (
                <RenderGM
                    center={{ lat: 57.783113, lng: 14.161727 }}
                    zoom={13}
                    type="Marker"
                    data={{ lat: 57.783113, lng: 14.161727 }}
                    height="90%"
                />
            );
        } else {
            return (
                <>
                    <div className={"card_header"}>{data}</div>
                </>
            );
        }
    };

    const [visibleWidgets, setVisibleWidgets] = useState(false);
    const onCloseInfo = () => {
        setVisibleWidgets(false);
    };
    const color = "#f05b41";
    const handleSpeedChange = ({ value }) => {
        setspeedValue(value);
    };

    return (
        <>
            <Affix
                offsetTop={150}
                onChange={(affixed) => console.log(affixed)}
                style={{ zIndex: 10, position: "fixed", top: 20, right: 0 }}
            >
                <p>
                    <Button
                        size="large"
                        type="primary"
                        onClick={() => setVisibleWidgets(true)}
                        icon={<AppstoreAddOutlined />}
                    />
                </p>
            </Affix>

            <ResponsiveReactGridLayout
                onLayoutChange={onLayoutChange}
                onBreakpointChange={onBreakpointChange()}
                className="layout"
                breakpoints={{ lg: 996, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 10, md: 10, sm: 10, xs: 10, xxs: 10 }}
                //rowHeight={100}
                isResizable={false}
            >
                <div
                    style={dashCardStylec}
                    key="Vehicle"
                    data-grid={{ x: 0, y: 0, w: 2, h: 2, static: true }}
                >
                    <div>
                        <h2>
                            <b>
                                {licensePlate}
                                <br />
                                {brand} {model}
                                <br />({year})
                            </b>
                        </h2>
                        {logo == "https://au2box.com/img/car-brands/" ? null : (
                            <Image width={96} preview={false} src={logo} />
                        )}
                        <br />
                        <b>KM: {miles}</b>
                    </div>
                </div>
                <div
                    key="Vehicle1"
                    data-grid={{ x: 2, y: 0, w: 5, h: 2, static: true }}
                >
                    <Map id="123" />
                </div>
                <div
                    style={dashCardStylec}
                    key="Vehicle2"
                    data-grid={{ x: 7, y: 0, w: 2, h: 2, static: true }}
                >
                    <div>
                        {licensePlate}
                        <br />
                        {brand} {model}
                        <br />
                        {logo == "https://au2box.com/img/car-brands/" ? null : (
                            <Image width={96} preview={false} src={logo} />
                        )}
                        <br />({year})<br /> KM: {miles}
                    </div>
                </div>

                {_.map(widgets, (item) => createElement1(item))}

                {/*<div key="Vehicle3" data-grid={{ x: 3, y: 0, w:4, h: 2}} >
        <div id="gauge-container"> 
          <div className="left-section">
            <Indicator
              value={speedValue / 2}
              inverted={false}
              startAngle={180}
              endAngle={90} color={color}
            />
            <Indicator
              value={speedValue / 2}
              inverted={true}
              startAngle={-90}
              endAngle={-180}
              color={color}
            />
          </div>
          &nbsp;
          <div className="center-section">
            <CircularGauge value={speedValue}>
              <CircularSize width={260} />
              <CircularScale
                startValue={20}
                endValue={200}
                tickInterval={20}
                minorTickInterval={10}
              />
              <Geometry startAngle={225} endAngle={315} />
              <CircularValueIndicator
                type="twoColorNeedle"
                secondFraction={0.24}
                color="none"
                secondColor={color}
              />
            </CircularGauge>

            <div className="speed-value">
              <span>{speedValue}</span>
            </div>

            <LinearGauge value={50 - speedValue * 0.24} id="fuel-gauge">
              <LinearSize width={90} height={20} />
              <LinearScale
                startValue={0}
                endValue={50}
                tickInterval={25}
                minorTickInterval={12.5}
              >
                <MinorTick visible={true} />
                <Label visible={false} />
              </LinearScale>
              <LinearValueIndicator
                size={8}
                offset={7}
                color={color}
              />
            </LinearGauge>
          </div>
          &nbsp;
          <div className="right-section">
            <Indicator
              value={speedValue / 2}
              inverted={true}
              startAngle={90}
              endAngle={0}
              color={color}
            />
            <Indicator
              value={speedValue / 2}
              inverted={false}
              startAngle={0}
              endAngle={-90}
              color={color}
            />
          </div>
        </div>
        <Slider
          value={speedValue}
          onValueChanged={handleSpeedChange}
          width={155}
          min={0}
          max={200}
          id="slider"
        />
        </div>*/}
            </ResponsiveReactGridLayout>
            <Drawer
                title="Add Widget"
                placement="right"
                onClose={onCloseInfo}
                visible={visibleWidgets}
            >
                <List
                    dataSource={widgetList}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                key={Math.random()}
                                avatar={
                                    <a
                                        onClick={() =>
                                            addWidget(
                                                item.title,
                                                item.datagrid,
                                                item.func,
                                                item.data
                                            )
                                        }
                                    >
                                        <Avatar size={66} icon={item.icon} />
                                    </a>
                                }
                                title={item.title}
                                description={item.description}
                                extra={item.icon}
                            />
                        </List.Item>
                    )}
                />
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => {
    console.log("selected menu mapstatetoprops", state);
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        // allWidgetsdata: state.widgets.allWidgets
    };
};

export default connect(mapStateToProps)(VehicleDashboard);
