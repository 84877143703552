
const initialState = {
  errors: {},
};

const errorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_ERRORS":
      return {
        ...state,
        errors: payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
};

export default errorReducer;
