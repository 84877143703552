import React from "react";
import {connect} from "react-redux";
import "../../../../assets/css/completedTrips1.css";
import {
    Row,
    Col,
    Menu, Space, Form, Input, DatePicker, TimePicker, Select, Button, Dropdown
} from 'antd';
import Header from "../../../../components/breadcrumbheader/Header";
import jsonData from '../../../../json/routes.json'
//import RenderGM from '../Map/Google';
import RenderGM from '../../../../components/Map/Google';
import {Content} from "antd/es/layout/layout";
import ListRoutes from './Route2';
import Modal from "antd/es/modal/Modal";
import moment from "moment";
import {Option} from "antd/es/mentions";
import {DownOutlined} from "@ant-design/icons";

class completedTripsStandard2 extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    state = {
        selectedWidgetComp: [],
        selectedFunctions: [],
        selectedFuncTitle: "",
        visible: false,
        Route: undefined,
    };
    routes = [
        {
            path: '',
            breadcrumbName: 'Travel',
        },
        {
            path: '',
            breadcrumbName: 'Completed Trips',
        },
    ];


    onChange = (date, dateString) => {
        console.log(date, dateString);
    }


    onTableChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    }


    ShowRoute = (route) => {
        this.setState({visible: true})
        this.setState({ Route: jsonData[route] });
    }

    render() {
        const { RangePicker } = DatePicker;
        return (
            <div className="functionPagediv">
                <Header routes={this.routes} floating={true}>
                    <Form
                        name="searchTravelForm"
                        ref={this.formRef}
                    >
                        <Row>
                            <Col className="gutter-row" span={12}> <Input placeholder={'Licence plate'}/></Col>
                            <Col className="gutter-row" span={12}> <Input placeholder={'Travel Id'}/></Col>
                            <Col className="gutter-row label-div" span={24}>
                                <label style={{marginRight: "7px"}}>From and To: </label>
                                <RangePicker showTime />
                            </Col>
                            <Col className="gutter-row" span={12}> <Input placeholder={'From Address'}/></Col>
                            <Col className="gutter-row" span={12}> <Input placeholder={'To Address'}/></Col>

                            <Col className="gutter-row" span={12}>
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    showSearch
                                    placeholder="Select a Driver"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="jack">Gunnar Dahl</Option>
                                    <Option value="lucy">JIBS Student account</Option>
                                    <Option value="tom">Jimmy Thornlof</Option>
                                    <Option value="jack">Jimmy EM</Option>
                                    <Option value="lucy">Joakim Nodfelt</Option>
                                    <Option value="tom">Kund AB</Option>
                                </Select>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    showSearch
                                    placeholder="Select Companies"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="jack">Bilpool</Option>
                                    <Option value="lucy">Filial Nord test</Option>
                                    <Option value="tom">Filial Syd</Option>
                                    <Option value="jack">Gränna</Option>
                                    <Option value="lucy">JIRICOM AB</Option>
                                </Select>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    showSearch
                                    placeholder="Attest Travels"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="jack">Not attested</Option>
                                    <Option value="lucy">Only attested travels</Option>
                                    <Option value="tom">All</Option>
                                </Select>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{width: "100%"}}
                                    showSearch
                                    placeholder="Travel Purpose"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="jack">Alla</Option>
                                    <Option value="lucy">Business</Option>
                                    <Option value="tom">Private</Option>
                                    <Option value="jack">refuelling</Option>
                                    <Option value="lucy">Client</Option>
                                </Select>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Input placeholder={'Travel Purpose'}/>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    showSearch
                                    placeholder="Responsibility number"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="jack">54654</Option>
                                    <Option value="lucy">78975</Option>
                                    <Option value="tom">545689</Option>
                                </Select>
                            </Col>

                        </Row>
                    </Form>
                </Header>

                <Content style={{
                    margin: "12px 0"
                }}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                            <ListRoutes data={jsonData} onClick={this.ShowRoute}/>
                        </Col>
                    </Row>
                </Content>
                <Modal
                    title="Show Route"
                    centered
                    visible={this.state.visible}
                    onOk={() => this.setState({visible: false})}
                    onCancel={() => this.setState({visible: false})}
                    width={"93%"}
                >
                    <RenderGM
                        center={(this.state.Route !== undefined ? {} : { lat: 57.783113, lng: 14.161727 })}
                        zoom={12}
                        type="Route"
                        height={64 + 12}
                        data={this.state.Route}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle
        // allWidgetsdata: state.widgets.allWidgets
    };
};

export default connect(mapStateToProps)(completedTripsStandard2);
