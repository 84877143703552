import type { FC } from "react";
import type {
    AnalysisData,
    DataItem,
    IRootState,
    IVehicleCardDashboard,
    IChartCardDataPoint,
} from "../../components/dashboard/data.d";
import { Suspense, useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import PageLoading from "../../components/common/pageLoading";
import * as VehicleActions from "../../actions/vehicles/vehicles";
import * as AlarmsActions from "../../actions/alarms/alarms";
import * as TravelsActions from "../../actions/travels/travels";

import {
    InfoCircleOutlined,
    ArrowUpOutlined,
    UserOutlined,
    TagOutlined,
    EllipsisOutlined,
} from "@ant-design/icons";
import { TinyArea, TinyColumn, Progress, Bar } from "@ant-design/charts";
import { Column } from "@ant-design/plots";
import { Col, Row, Tooltip, Statistic, Card, Dropdown, Menu } from "antd";
import Yuan from "../../components/dashboard/utils/Yuan";
import { GridContent } from "@ant-design/pro-layout";
import { ChartCard, Field } from "../../components/dashboard/Charts";
// import Bar from "../../components/dashboard/Charts/Bar";
import Trend from "../../components/dashboard/Trend";
import styles from "../../components/dashboard/style.less";
import MiniBar from "../../components/dashboard/Charts/MiniBar";
// import { barConfig } from "@fullcalendar/react";
import TopSearch from "../../components/dashboard/TopSearch";
import JRStatus from "../../components/vehicals/status";

import Map from "../../components/maps/MapsCurrent";
import RenderGM from "../../components/Map/Google";
import PlainMap from "../../components/maps/PlainMap";
import GenericMap from "../../components/maps";
import { RealTimePositionMap } from "../../components/maps/variants/RealTimePositionMap";
import {
    getCurrentDate,
    getFirstDayThisMonthDate,
    getFirstDayThisWeekDate,
    getLastDayThisWeekDate,
} from "../../components/common/function";

const topRightCornerInfoStyles: React.CSSProperties = {
    position: "absolute",
    top: 25,
    right: 25,
};

const topRowResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 12,
    xl: 6,
    style: { marginBottom: 24 },
};

const middleRowResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 12,
    style: { marginBottom: 24 },
};
const bottomRowResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 6,
    style: { marginBottom: 24 },
};

const IntroduceRow = ({
    loading,
    visitData,
    apiData,
}: {
    loading: boolean;
    visitData: DataItem[];
    apiData: any;
}) => (
    <Row gutter={24}>
        <Col {...topRowResponsiveProps}>
            <Card
                bordered={true}
                style={{ boxShadow: "5px 5px 10px 5px lightgray" }}
            >
                <Statistic
                    title="Latest Driver"
                    value={apiData?.DriverCurrent}
                    precision={2}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<UserOutlined />}
                />
            </Card>
        </Col>

        <Col {...topRowResponsiveProps}>
            <Card
                bordered={true}
                style={{ boxShadow: "5px 5px 10px 5px lightgray" }}
            >
                <Statistic
                    title="License Plate"
                    value={apiData?.LicensePlate}
                    precision={2}
                    valueStyle={{ color: "#3f8600" }}
                />
            </Card>
        </Col>
        <Col {...topRowResponsiveProps}>
            <Card
                bordered={true}
                style={{ boxShadow: "5px 5px 10px 5px lightgray" }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Statistic
                            title="Total Travels"
                            value={apiData.TotalTravels}
                            precision={0}
                            valueStyle={{ color: "#3f8600" }}
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title="Travels Today"
                            value={apiData.TravelsToday}
                            precision={0}
                            valueStyle={{ color: "#3f8600" }}
                        />
                    </Col>
                </Row>
            </Card>
        </Col>
        <Col {...topRowResponsiveProps}>
            <Card
                bordered={true}
                style={{ boxShadow: "5px 5px 10px 5px lightgray" }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Statistic
                            title="Total Odometer"
                            value={apiData.TotalOdometer}
                            precision={2}
                            valueStyle={{ color: "#3f8600" }}
                        />
                    </Col>
                    <Col span={12}>
                        <Statistic
                            title="Odometer Today"
                            value={apiData.OdometerToday}
                            precision={2}
                            valueStyle={{ color: "#3f8600" }}
                        />
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>
);

const AlarmsTableColumns = [
    {
        title: "Grade",
        dataIndex: "symbolColor",
        key: "symbolColor",
        render: (symbolColor: React.ReactNode) => (
            <a>
                <JRStatus icon="triangle-exclamation" color={symbolColor} />
            </a>
        ),
    },
    {
        title: "Time",
        dataIndex: "dateTime",
        key: "dateTime",
        render: (text: React.ReactNode) => <a href="/">{text}</a>,
    },
    {
        title: "Type",
        dataIndex: "typeText",
        key: "typeText",
        render: (text: React.ReactNode) => <a href="/">{text}</a>,
    },
];

//todo MAKE RESPONSIVE XL LG ... on row cols
const Dashboard: FC<IVehicleCardDashboard> = (props) => {
    const dispatch = useDispatch();
    const FirstDayThisWeekDate = getFirstDayThisWeekDate("-", true);
    const FirstDayThisMonthDate = getFirstDayThisMonthDate("-", true);
    const CurrentDate = getCurrentDate("-", true);
    console.log(
        "FirstDayThisMonthDate ",
        FirstDayThisMonthDate,
        "FirstDayThisWeekDate ",
        FirstDayThisWeekDate,
        "CurrentDate ",
        CurrentDate
    );
    const alarmsTableRef = useRef<HTMLDivElement>(null);
    const [alarmsTableHeight, setAlarmsTableHeight] = useState(0);

    useEffect(() => {
        VehicleActions.getVehicle(props.vehicleIdFromParams)(dispatch);
        AlarmsActions.getVehicleAlarms(props.vehicleIdFromParams)(dispatch);
        TravelsActions.getVehicleTravels(props.vehicleIdFromParams)(dispatch);

        AlarmsActions.getVehicleAlarms(
            props.vehicleIdFromParams,
            FirstDayThisWeekDate,
            CurrentDate
        )(dispatch);

        AlarmsActions.getVehicleAlarms(
            props.vehicleIdFromParams,
            FirstDayThisMonthDate,
            CurrentDate
        )(dispatch);
    }, [props.vehicleIdFromParams]);

    useEffect(() => {
        if (!alarmsTableRef.current?.clientHeight) {
            return;
        }
        setAlarmsTableHeight(alarmsTableRef?.current?.clientHeight);
    }, [alarmsTableRef?.current?.clientHeight]);

    const vehicle = useSelector(
        (state: IRootState) => state.vehicles.getVehicle
    );

    const vehicleAlarms = useSelector(
        (state: IRootState) => state.alarms.vehicleAlarms
    );

    const parsedVehicleAlarmsTopSearch = useSelector(
        (state: IRootState) => state.alarms.parsedVehicleAlarmsTopSearch
    );

    const VehicleAlarmsWeekGraphData = useSelector(
        (state: IRootState) => state.alarms.VehicleAlarmsWeekGraphData
    );

    const VehicleAlarmsMonthGraphData = useSelector(
        (state: IRootState) => state.alarms.VehicleAlarmsMonthGraphData
    );

    const VehicleTravels = useSelector(
        (state: IRootState) => state.travels.VehicleTravels
    );

    const travelsBarConfig = {
        data: VehicleTravels?.VehicleTravelsWeekGraphData || [],
        height: 400,
        xField: "DayMonthName",
        yField: "Travels",
        label: {
            position: "middle",
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
            },
        },
        meta: {
            DayMonthName: {
                alias: "Day",
            },
            Travels: {
                alias: "Amount of trips",
            },
        },
    };

    const odometerBarConfig = {
        data: VehicleTravels?.VehicleOdometerWeekGraphData || [],
        height: 400,
        xField: "DayMonthName",
        yField: "Odometer",
        label: {
            position: "middle",
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
            },
        },
        meta: {
            daDayMonthNamey: {
                alias: "Day",
            },
            Odometer: {
                alias: "Total kilometer driven",
            },
        },
    };

    const menu = (
        <Menu>
            <Menu.Item>操作一</Menu.Item>
            <Menu.Item>操作二</Menu.Item>
        </Menu>
    );
    const dropdownGroup = (
        <span className={styles.iconGroup}>
            <Dropdown overlay={menu} placement="bottomRight">
                <EllipsisOutlined />
            </Dropdown>
        </span>
    );

    return (
        <>
            {console.log("VehicleTravels ", VehicleTravels)}
            <GridContent>
                <>
                    <Suspense fallback={<PageLoading />}>
                        <IntroduceRow
                            loading={false}
                            visitData={[]}
                            apiData={{
                                DriverCurrent: vehicle.DriverCurrent,
                                BrandLogo: vehicle.BrandLogo,
                                LicensePlate: vehicle.LicensePlate,
                                TotalTravels: VehicleTravels.TotalTravels,
                                TravelsToday: VehicleTravels.TravelsToday,
                                TotalOdometer: VehicleTravels.TotalOdometer,
                                OdometerToday: VehicleTravels.OdometerToday,
                            }}
                        />
                    </Suspense>
                    <Suspense fallback={<PageLoading />}>
                        <Row gutter={24}>
                            <Col {...middleRowResponsiveProps}>
                                <Card
                                    style={{
                                        boxShadow: "5px 5px 10px 5px lightgray",
                                        height: alarmsTableHeight + 2,
                                    }}
                                >
                                    {/* TODO make map responsive */}
                                    {/* <GenericMap /> */}
                                    {/* <PlainMap /> */}
                                    <RealTimePositionMap
                                        vehicleIdFromParams={
                                            props.vehicleIdFromParams
                                        }
                                    />
                                </Card>
                            </Col>
                            <Col {...middleRowResponsiveProps}>
                                <Card
                                    bordered={true}
                                    style={{
                                        boxShadow: "5px 5px 10px 5px lightgray",
                                        height: 530,
                                    }}
                                    ref={alarmsTableRef}
                                >
                                    <TopSearch
                                        title={"Current Alarms"}
                                        loading={false}
                                        graphData={VehicleAlarmsMonthGraphData}
                                        graphData2={VehicleAlarmsWeekGraphData}
                                        searchData={
                                            parsedVehicleAlarmsTopSearch
                                        }
                                        dropdownGroup={dropdownGroup}
                                        columns={AlarmsTableColumns}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Suspense>

                    <Suspense fallback={<PageLoading />}>
                        <Row gutter={24}>
                            <Col {...bottomRowResponsiveProps}>
                                <ChartCard
                                    style={{
                                        boxShadow: "5px 5px 10px 5px lightgray",
                                    }}
                                    title="Travels this week"
                                    action={
                                        <Tooltip title="Graph shows amount of registrered trips each day, one week back in time.">
                                            <InfoCircleOutlined
                                                style={topRightCornerInfoStyles}
                                            />
                                        </Tooltip>
                                    }
                                    total={VehicleTravels?.VehicleTravelsWeekGraphData?.map(
                                        (travelObj: {
                                            Date: string;
                                            Travels: number;
                                        }) => travelObj.Travels
                                    ).reduce(
                                        (
                                            accumulator: number,
                                            current: number
                                        ) => accumulator + current,
                                        0
                                    )}
                                >
                                    <Column {...(travelsBarConfig as any)} />
                                </ChartCard>
                            </Col>
                            <Col {...bottomRowResponsiveProps}>
                                <ChartCard
                                    style={{
                                        boxShadow: "5px 5px 10px 5px lightgray",
                                    }}
                                    title="Odometer this week"
                                    action={
                                        <Tooltip title="Graph show the odometer each day, one week back in time.">
                                            <InfoCircleOutlined
                                                style={topRightCornerInfoStyles}
                                            />
                                        </Tooltip>
                                    }
                                    total={VehicleTravels?.VehicleOdometerWeekGraphData?.map(
                                        (travelObj: {
                                            Date: string;
                                            Odometer: number;
                                        }) => travelObj.Odometer
                                    ).reduce(
                                        (
                                            accumulator: number,
                                            current: number
                                        ) => accumulator + current,
                                        0
                                    )}
                                >
                                    <Column {...(odometerBarConfig as any)} />
                                </ChartCard>
                            </Col>

                            {/* <Col span={6}>
                                <ChartCard
                                    style={{
                                        boxShadow: "5px 5px 10px 5px lightgray",
                                        height: 300,
                                    }}
                                    title="Extras 1"
                                    total="6,500"
                                ></ChartCard>
                            </Col>
                            <Col span={6}>
                                <ChartCard
                                    style={{
                                        boxShadow: "5px 5px 10px 5px lightgray",
                                        height: 300,
                                    }}
                                    title="Extras 2"
                                    total="12,500"
                                ></ChartCard>
                            </Col> */}
                        </Row>
                    </Suspense>
                </>
            </GridContent>
        </>
    );
};
const mapStateToProps = (state: {
    widgets: {
        selectedMenu: any;
        allFuncMenu: any;
        submenukey: any;
        submenutitle: any;
        selectedSubmenu: any;
        selectedFuncTitle: any;
    };
    login: { loginUser: any };
}) => {
    return {
        selectedmenu: state.widgets.selectedMenu,
        allfunctionmenu: state.widgets.allFuncMenu,
        submenukey: state.widgets.submenukey,
        submenutitle: state.widgets.submenutitle,
        selectedSubmenu: state.widgets.selectedSubmenu,
        selectedFuncTitle: state.widgets.selectedFuncTitle,
        loggedInUser: state.login.loginUser,
    };
};

export default connect(mapStateToProps)(Dashboard);
